import { SettingService } from 'src/app/service/setting.service';
import { SeoService } from 'src/app/shared/service/seo.service';
import { InputFilterService } from './../../shared/service/input-filter.service';
import { RegisterService } from './../../service/register.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone, Renderer2, AfterViewInit } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Category } from 'src/app/model/category.model';
import { CategoryService } from 'src/app/service/category.service';
import { environment } from 'src/environments/environment';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { AuthGuard } from 'src/app/auth.guard';
import { Gtag } from 'angular-gtag';

declare var grecaptcha: any;
declare const gapi: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [TranslatePipe]
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  auth: boolean;

  logo: String = '';
  projectName: String = '';
  registerForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  errorMessage = '';
  step = 'first';

  category: string;
  categoryList: any[];
  selectedCategories: number[];

  auth2: any;
  googleClientId: string;
  siteKeyCaptcha: string;
  captchaResponse = 'random';
  gUser = { token: '', email: '', gid: '', name: '', image: '', genre: '' };

  activeLinkTerm: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    public filter: InputFilterService,
    public authGuard: AuthGuard,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private categoryService: CategoryService,
    private ngZone: NgZone,
    private alertMassage: AlertMassageService,
    private settingService: SettingService,
    private translate: TranslatePipe,
    private renderer: Renderer2,
    private seo: SeoService,
    private gtag: Gtag,
  ) {
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')]],
      password_confirmation: ['', [Validators.required, this.passwordConfirmationValidator('password_confirmation')]],
      genre: ['', Validators.required]
    });
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Register',
    })
  }

  passwordConfirmationValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.root.get('password');
      const confirmPassword = control.root.get(controlName);
  
      if (password && confirmPassword && password.value !== confirmPassword.value) {
        return { 'passwordMismatch': true };
      }
  
      return null;
    };
  }
  

  ngOnInit() {
    this.seo.addMetaTag('Daftar');
    this.auth = this.authGuard.isLoggedIn();
    this.getSettingTermCondition();

    if (this.auth === false) {
      this.logo = this.appConfig.logo;
      this.projectName = this.appConfig.appFullName;
      this.getCategoryList();
      this.siteKeyCaptcha = environment.siteKeyCaptcha;
      if (environment.production) {
        this.addRecaptchaScript();
      }
    } else {
      this.navigateUrl.goTo('home');
    }
  }

  ngAfterViewInit() {
    this.googleClientId = environment.googleClientId;
    this.googleInit();
  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      this.categoryList = [];
      data.data.forEach(e => {
        if (e.cat_related_module && e.cat_related_module.includes('register')) {
          this.categoryList.push({
            id: e.cat_id,
            name: e.cat_title
          });
        }
      });
    });
  }

  getSettingTermCondition() {
    this.settingService.getSettingTermCondition().subscribe((data: any) => {
      if (data.status) {
        this.activeLinkTerm = true;
      }
    });
  }

  // Render Captcha
  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: this.siteKeyCaptcha,
      callback: response => {
        this.captchaResponse = response;
      }
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      // tslint:disable-next-line: prefer-const
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.googleClientId,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignup(document.getElementById('googleBtn'));
    });
  }

  public attachSignup(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        const user = {
          token: googleUser.getAuthResponse().id_token,
          email: btoa(profile.getEmail()),
          gid: profile.getId(),
          name: profile.getName(),
          image: profile.getImageUrl()
        };

        this.ngZone.run(() => this.loading = true);
        this.ngZone.run(() => this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', ''));
        this.registerService.registerGoogleCheck(user).subscribe((data: any) => {
          this.ngZone.run(() => this.loading = false);
          if (data && !data.data && !data.status) {
            this.ngZone.run(() => this.gUser.token = user.token);
            this.ngZone.run(() => this.gUser.email = user.email);
            this.ngZone.run(() => this.gUser.gid = user.gid);
            this.ngZone.run(() => this.gUser.name = user.name);
            this.ngZone.run(() => this.gUser.image = user.image);
            this.ngZone.run(() => this.step = 'second');
          } else {
            // tslint:disable-next-line: max-line-length
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailExist'), this.translate.transform('failed'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
            googleUser.disconnect();
          }
        }, (error: any) => {
          this.ngZone.run(() => this.loading = false);
        });
      }, (error) => {
        // console.log('Cancel Google SignIn');
        // console.log('Google', JSON.stringify(error, undefined, 2));
      });
  }

  get form() {
    return this.registerForm.controls;
  }

  checkValidForm() {
    if (!this.registerForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  doSubmit() {
    if (environment.production) {
      this.captchaResponse = grecaptcha.getResponse();
    }
    if (this.step === 'first') { // Register with User Data
      this.validForm = this.checkValidForm();
      if (!this.validForm && this.captchaResponse) {
        const user = {
          username: this.registerForm.value.username,
          password: btoa(this.registerForm.value.password),
          email: btoa(this.registerForm.value.email),
          genre: this.registerForm.value.genre
        };

        this.loading = true;
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
        this.registerService.register(user).subscribe((data: any) => {
            this.loading = false;
            if (data && data.message && data.message.s === 'success') {
              localStorage.setItem('uemail', user.email);
              localStorage.setItem('uisReg', 'register');
              localStorage.setItem('utypeReg', 'normal');
              this.navigateUrl.goTo('register-success');
            } else if (data && data.message && data.message.s === 'failed') {
              // tslint:disable-next-line: max-line-length
              this.errorMessage = this.alertMassage.show(data.message.s, this.translate.transform(data.message.m), this.translate.transform(data.message.s));
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
              setTimeout (() => {
                this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
              }, 5000);
            } else {
              this.errorMessage = this.alertMassage.show('failed', this.translate.transform('registerFailed'), this.translate.transform('failed'));
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
              setTimeout (() => {
                this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
              }, 5000);
            }
          }, (error: any) => {
            this.loading = false;
          }
        );
      }
      this.gtag.event('sign_up', {
        'app_name': `GWP-WEB-${environment.envName}`,
        'screen_name': 'Register',
        'method': 'native',
      })
    } else if (this.step === 'second') { // Register With Google Account
      if (this.captchaResponse) {
        this.gUser.genre = this.registerForm.value.genre;

        this.loading = true;
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
        this.registerService.registerGoogle(this.gUser).subscribe((data: any) => {
          this.loading = false;
          if (data && data.message && data.message.s === 'success') {
            localStorage.setItem('uid', data.data.uid);
            localStorage.setItem('Name', data.data.uusername);
            localStorage.setItem('Fullname', data.data.ufullname);
            localStorage.setItem('login_status', '1');
            localStorage.setItem('login_google', '1');
            this.auth = this.authGuard.isLoggedIn();

            this.navigateUrl.goTo('home');
          } else if (data && data.message && data.message.s === 'failed') {
            // tslint:disable-next-line: max-line-length
            this.errorMessage = this.alertMassage.show(data.message.s, this.translate.transform(data.message.m), this.translate.transform(data.message.s));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
          } else {
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('registerFailed'), this.translate.transform('failed'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
          }
        }, (error) => {
          this.loading = false;
        });
      }
      this.gtag.event('sign_up', {
        'app_name': `GWP-WEB-${environment.envName}`,
        'screen_name': 'Register',
        'method': 'google',
      })
    }
  }

}
