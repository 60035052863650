import { Nl2brPipe } from './../../shared/pipe/nl2br.pipe';
import { NumberingChapterPipe } from './../../shared/pipe/numbering-chapter.pipe';
import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { AuthGuard } from 'src/app/auth.guard';
import { ActivatedRoute } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { CategoryService } from 'src/app/service/category.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatTabChangeEvent } from '@angular/material';
import { MatDialog } from '@angular/material';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GwpSharePublishComponent, ConfirmSharePublishModel } from 'src/app/shared/component/gwp-share-publish/gwp-share-publish.component';
import { SafeHtmlPipe } from 'src/app/shared/pipe/safe-html.pipe';
import { environment } from 'src/environments/environment.prod';
import {Gtag} from 'angular-gtag';

@Component({
  selector: 'app-create-story',
  templateUrl: './create-story.component.html',
  styleUrls: ['./create-story.component.css'],
  providers: [TranslatePipe, Nl2brPipe, BnNgIdleService, NumberingChapterPipe, SafeHtmlPipe]
})
export class CreateStoryComponent implements OnInit, OnDestroy {

  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;

  categoryList: any[];

  activeTab = 0;

  isStoryChanged: boolean;
  dataStoryLoaded: boolean;
  storyId = '';
  storyTitle = 'Judul Cerita';
  storySlug = 'judul-cerita';
  storyStatus = 'labelNotSaveStories';
  isStoryEdit: boolean;
  nextStep: boolean;
  passedStep = true;
  dataStoryForm: any;
  dataStoryOld: any;

  isStoryChapterOrderChanged: boolean;
  isStoryChapterChanged: boolean;
  dataChapterLoaded: boolean;
  addChapter: boolean;
  autoSave = true;
  enableSave: Boolean = false;
  storyChapterId = '';
  storyChapterCounter = '01';
  stroyChapterLastCounter = 1;
  storyChapterTitle = 'Judul Bab';
  storyChapterTitleTemp = 'Judul Bab';
  storyChapterStatus = 'labelNotSaveStories';
  isChapterPublish = false;
  previewChapter: boolean;
  validChapterForm: boolean;
  dataChapterForm: any;
  dataChapterList: any[];
  dataChapterListOld: any[];
  dataChapterListNewOrder: any[];
  showDateChapter: any;
  processStatusChapter: any;
  validLength: Boolean;
  re = /\-/gi;

  reArangeChapterSubscription: any;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  // sprint 14
  copied = false;
  shareUrl = '';
  shareWhatsappUrl: any = '';
  shareMWhatsappUrl: any = '';
  fbUrl = 'https://www.facebook.com/sharer/sharer.php';
  shareFacebookUrl: any = '';
  twUrl = 'https://twitter.com/intent/tweet';
  shareTwitterUrl: any = '';
  alerts: any[] = [];
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private storiesService: StoriesService,
    private categoryService: CategoryService,
    private _location: Location,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    private seo: SeoService,
    private numberingChapter: NumberingChapterPipe,
    private nl2br: Nl2brPipe,
    private safeHtml: SafeHtmlPipe,
    private gtag: Gtag,
  ) {
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Create-Story',
    });
  }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.seo.addMetaTag('Tulis Cerita');
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }

    this.route.params.subscribe( params => {
      if (params && params['story'] && params['slug']) {
        this.isStoryEdit = true;
        this.storyId = params['story'];
        this.storySlug = params['slug'];
        this.getStoryData(params['story'], params['slug'], this.uid);
        this.getChapterListData(params['story'], params['slug'], this.uid);
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params && params['q']) {
        this.activeTab = (params['q'] === 'content' ? 1 : 0);
      }
    });

    if (this.storyId !== '') {
      this.storyStatus = 'lableSavedStories';
    }

    // Auto save (seconds)
    this.bnIdle.startWatching(5).subscribe((isTimedOut: boolean) => {
      if (this.addChapter && this.autoSave && this.dataChapterForm) {
        if ((this.dataChapterForm.description && this.dataChapterForm.description !== '')) {
          // console.log('autosave', this.dataChapterForm);
          if ((this.dataChapterForm.title && this.dataChapterForm.title === '') || !this.dataChapterForm.title) {
            this.dataChapterForm.title = 'Judul Bab';
          }
          this.doActionFormChapter('save');
        }
        this.autoSave = false;
      }
    });

    this.getCategoryList();
  }

  // STORY

  tabStoryChanged(event: MatTabChangeEvent): void {
    this.activeTab = event.index;
    this.ngOnDestroy();
  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      this.categoryList = [];
      data.data.forEach(e => {
        if (e.cat_related_module && e.cat_related_module.includes('writing')) {
          this.categoryList.push({
            id: e.cat_id,
            name: e.cat_title
          });
        }
      });
    });
  }

  getStoryData(sid, slug, uid) {
    this.storiesService.getStoriesById(sid, slug, uid).subscribe((data: any) => {
      this.dataStoryOld = null;
      if (data.status) {
        this.dataStoryOld = {
          id: data.data.story_id,
          title: data.data.story_title,
          synopsis: data.data.story_description,
          tags: '',
          tagList: data.data.story_tags ? data.data.story_tags.split(',') : null,
          genre: data.data.story_category,
          matureType: (data.data.story_mature === '1' ? true : false),
          complete:  (data.data.story_is_complete === '1' ? true : false),
          cover: '',
          coverUrl: data.data.story_image_cover
        };
        this.storyId = data.data.story_id;
        this.storyTitle = data.data.story_title ? (data.data.story_title !== '' ? data.data.story_title : 'Judul Cerita') : 'Judul Cerita';
        this.storySlug = data.data.story_slug ? (data.data.story_slug !== '' ? data.data.story_slug : 'judul-cerita') : 'judul-cerita';
        if (data.data.story_is_published === '1') {
          this.storyStatus = 'labelPublishStories';
        } else {
          this.storyStatus = 'lableSavedStories';
        }

        this.dataStoryForm = this.dataStoryOld;
        this.dataStoryLoaded = true;
      }
    });
  }

  setTitle(event) {
    if (event.title && event.title !== '') {
      this.storyTitle = event.title;
    } else {
      this.storyTitle = 'Judul Cerita';
    }
  }

  checkFormFilled(event) {
    this.isStoryEdit = event.isEdit;
    this.nextStep = event.nextStep;
    this.passedStep = event.passedStep;
    this.dataStoryForm = event.data;
    this.validLength = event.validLength;

    if (JSON.stringify(this.dataStoryForm) === JSON.stringify(this.dataStoryOld)) {
      this.isStoryChanged = false;
      // below condition --> if only change the cover
      if (this.dataStoryForm.cover && this.dataStoryOld.cover && !this.objectsAreSame(this.dataStoryForm.cover, this.dataStoryOld.cover)) {
        this.isStoryChanged = true;
      }
    } else {
      this.isStoryChanged = true;
    }
  }

  objectsAreSame(x, y) {
    let objectsAreSame = true;
    for (const propertyName in x) {
       if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
       }
    }
    return objectsAreSame;
 }

  doActionForm(event) {
    if (this.dataStoryForm && this.dataStoryForm.id && this.dataStoryForm.id !== '') {
      this.storyId = this.dataStoryForm.id;
    }
    if (event === 'save') {
      this.submitCreateStory(this.dataStoryForm, this.storyId, this.uid, this.isStoryEdit);
    } else {
      this.addChapter = true;
    }
  }

  submitCreateStory(dataForm, story, author, isStoryEdit) {
      this.loading = true;
      this.storyStatus = 'labelSavingStories';
      this.storiesService.doSubmitCreateStory(dataForm, story, author, isStoryEdit).subscribe((data: any) => {
        this.loading = false;
        if (data.status) {
          this.storyId = data.data.story;
          this.storySlug = data.data.slug;
          this.storyStatus = 'lableSavedStories';
        } else {
          this.storyStatus = 'lableSavedStoriesFailed';
        }
        if (data.status && isStoryEdit) {
          this.addChapter = false;
          this.isStoryChanged = false;
          this.dataStoryOld = dataForm;
        } else {
          this.addChapter = true;
        }
      }, (error) => {
        this.loading = false;
      });
  }

  cancelCreateStory() {
    if (this.addChapter) {
      this.addChapter = false;
    } else {
      this._location.back();
    }
  }

  // CHAPTER

  getChapterListData(sid, slug, uid) {
    this.storiesService.getChaptersAuthorByStoryId(sid, slug, uid).subscribe((data: any) => {
      this.dataChapterList = [];
      this.dataChapterListOld = [];
      this.stroyChapterLastCounter = 1;
      if (data.status) {
        data.data.forEach(e => {
          this.dataChapterList.push({
            id: e.chapter_id,
            order: this.stroyChapterLastCounter,
            // url: e.story_title ? e.story_title : '-',
            title: e.chapter_title ? e.chapter_title : '-',
            slug: e.chapter_slug ? e.chapter_slug : '-',
            description: e.chapter_text ? e.chapter_text : '-',
            view: e.chapter_views,
            like: e.chapter_likes,
            comment: e.chapter_comments,
            draft: e.chapter_is_draft && e.chapter_is_draft === '1' ? true : false,
            publish: e.chapter_is_publish && e.chapter_is_publish === '1' ? true : false,
            publishDate: e.chapter_publish_date ? e.chapter_publish_date.replace(this.re, '/') : e.chapter_publish_date,
            lastUpdate: e.chapter_modified_date ? e.chapter_modified_date.replace(this.re, '/') : e.chapter_modified_date,
          });
          this.dataChapterListOld.push({
            id: e.chapter_id,
            order: this.stroyChapterLastCounter,
            // url: e.story_title ? e.story_title : '-',
            title: e.chapter_title ? e.chapter_title : '-',
            slug: e.chapter_slug ? e.chapter_slug : '-',
            description: e.chapter_text ? e.chapter_text : '-',
            view: e.chapter_views,
            like: e.chapter_likes,
            comment: e.chapter_comments,
            draft: e.chapter_is_draft && e.chapter_is_draft === '1' ? true : false,
            publish: e.chapter_is_publish && e.chapter_is_publish === '1' ? true : false,
            publishDate: e.chapter_publish_date ? e.chapter_publish_date.replace(this.re, '/') : e.chapter_publish_date,
            lastUpdate: e.chapter_modified_date ? e.chapter_modified_date.replace(this.re, '/') : e.chapter_modified_date,
          });
          this.stroyChapterLastCounter++;
        });
      } else {
        this.dataChapterList = null;
        this.dataChapterListOld = null;
      }
      this.dataChapterLoaded = true;
    });
  }

  doActionFormChapter(event) {
    if (event === 'publish') {
      const message = this.translate.transform('confirmMPublishStories');
      const confirmButton = this.translate.transform('confirmBtnPublishStories');
      const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTPublishStories'), message, 1, confirmButton);
      const dialogRef = this.dialog.open(GwpDialogComponent, {
        maxWidth: '416px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.submitCreateChapter(this.dataChapterForm, this.storyId, this.storyChapterId, this.uid, true, event);
        }
      });
    } else if (event === 'save') {
      this.submitCreateChapter(this.dataChapterForm, this.storyId, this.storyChapterId, this.uid, this.isChapterPublish, event);
    } else if (event === 'preview') {
      this.previewChapter = !this.previewChapter;
      this.storyChapterTitle = this.previewChapter ? this.translate.transform(this.storyChapterTitle) : this.storyChapterTitleTemp;
    }
  }

  doActionListChapter(event) {
    if (event.publish && event.publish === '1') {
      this.publishChapter(this.storyId, event.data.id, this.uid, true);
    } else if (event.publish && event.publish === '0') {
      this.publishChapter(this.storyId, event.data.id, this.uid, false);
    } else if (event.delete) {
      this.deleteChapter(this.storyId, event.data.id, this.uid);
    }
  }

  checkReorderChapterList(event) {
    this.dataChapterListNewOrder = [];
    if (JSON.stringify(event) === JSON.stringify(this.dataChapterListOld)) {
      this.isStoryChapterOrderChanged = false;
    } else {
      this.isStoryChapterOrderChanged = true;
      let index = 1;
      event.forEach(e => {
        this.dataChapterListNewOrder.push({
          id: e.id,
          order: index
        });
        index++;
      });
      this.doSaveReorderChapter();
    }
  }

  doSaveReorderChapter() {
    this.reorderChapter(this.storyId, this.dataChapterListNewOrder, this.uid);
  }

  setChapterTitle(event) {
    if (event.title && event.title !== '') {
      this.storyChapterTitle = event.title;
    } else {
      this.storyChapterTitle = 'Judul Bab';
    }
    this.storyChapterTitleTemp = this.storyChapterTitle;
  }

  checkFormChapterFilled(event) {
    this.validChapterForm = event.formValid ? event.formValid : false;
    this.validChapterForm = event.data && event.data.title && event.data.title !== null ? event.formValid : false;
    this.validChapterForm = event.data && event.data.description && event.data.description !== null ? event.formValid : false;
    this.dataChapterForm = event.data;
    this.autoSave = true;

    this.enableSave = this.validChapterForm;
  }

  createNewChapter() {
    this.dataChapterForm = null;
    this.storyChapterId = '';
    this.storyChapterCounter = this.numberingChapter.transform(this.stroyChapterLastCounter);
    this.storyChapterTitle = 'Judul Bab';
    this.storyChapterTitleTemp = 'Judul Bab';
    this.storyChapterStatus = this.translate.transform('labelNotSaveStories');
    this.showDateChapter = '';
    this.processStatusChapter = '';
    this.isChapterPublish = false;
    this.validChapterForm = false;
    this.previewChapter = false;
    this.addChapter = true;
  }

  editChapter(event) {
    if (event.edit) {
      this.autoSave = false;
      this.enableSave = false;
      const chapter = {
        id: event.data.id,
        title: event.data.title,
        description: this.nl2br.transform(event.data.description, 'blank')
      };
      this.dataChapterForm = chapter;
      this.storyChapterId = event.data.id;
      this.storyChapterCounter = this.numberingChapter.transform(event.data.order);
      this.storyChapterTitle = event.data.title;
      this.storyChapterTitleTemp = event.data.title;
      this.storyChapterStatus = event.data.publish ? 'labelPublishStories' : 'labelDraftStories';
      this.isChapterPublish = event.data.publish;
      this.showDateChapter = event.data.lastUpdate ? event.data.lastUpdate.replace(this.re, '/') : event.data.lastUpdate;
      this.processStatusChapter = '';
      this.validChapterForm = true;
      this.previewChapter = false;
      this.addChapter = true;
    }
  }

  submitCreateChapter(dataForm, story, chapter, author, isChapterPublish, event) {
    this.loading = true;
    this.processStatusChapter = this.translate.transform('labelSavingStories');
    this.enableSave = false;
    this.storiesService.doSubmitCreateChapter(dataForm, story, chapter, author, isChapterPublish).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        this.storyId = data.data.story;
        this.storyChapterId = data.data.chapter;
        this.storyChapterStatus = data.data.status ? 'labelPublishStories' : 'labelDraftStories';
        this.isChapterPublish = data.data.status;
        this.showDateChapter = data.data.date ? data.data.date.replace(this.re, '/') : data.data.date;
        this.processStatusChapter = this.translate.transform('lableAlreadySavedStories');
        this.getChapterListData(this.storyId, this.storySlug, this.uid);

        if (isChapterPublish) {
          if (event === 'publish') {
            this.doClickShare(this.storyId, this.storySlug);
          }
        }

      } else {
        this.processStatusChapter =  this.translate.transform('lableSavedStoriesFailed');
        this.enableSave = true;
      }
    }, (error) => {
      this.loading = false;
      this.enableSave = true;
    });
  }

  doClickShare(id, slug) {
    const url = environment.homeUrl + '/story/' + id + '/' + slug;
    this.copied = false;
    this.shareUrl = url ? url : '';
    this.shareWhatsappUrl = this.safeHtml.transform2('https://web.whatsapp.com/send?text=' + this.shareUrl);
    this.shareMWhatsappUrl = this.safeHtml.transform2('whatsapp://send?text=' + this.shareUrl);
    this.shareFacebookUrl = this.fbUrl + '?u=' + encodeURIComponent(this.shareUrl) + '&amp;src=sdkpreparse';
    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(this.storyTitle) + '&url=' + encodeURIComponent(this.shareUrl);
    const messageShare = this.translate.transform('labelChapterBab') + ' ' + this.storyTitle + ' ' + this.translate.transform('messageChapterSuccessPublish');
    const dialogData = new ConfirmSharePublishModel(this.translate.transform('labelChapterSuccessPublish'), {
      copy: this.shareUrl,
      fb: this.shareFacebookUrl,
      tw: this.shareTwitterUrl,
      mwa: this.shareMWhatsappUrl,
      wa: this.shareWhatsappUrl,
    },
      messageShare,
      this.translate.transform('subMessageChapterSuccessPublish'),
      ( !this.dataStoryOld ) ? this.dataStoryForm.tagList : this.dataStoryOld.tagList
    );
    const dialogRef = this.dialog.open(GwpSharePublishComponent, {
      maxWidth: '272px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.copied) {
        if (dialogResult && dialogResult.copied) {
          //this.addAlert(this.translate.transform('linkCopied'));
        }
      }
    });
    this.navigateUrl.goTo('story/' + id + '/' + slug);
  }

  publishChapter(story, chapter, author, publish) {
    this.loading = true;
    this.storiesService.publishChapter(story, chapter, author, publish).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        const index = this.dataChapterList.findIndex(e => e.id === chapter);
        this.dataChapterList[index].draft = publish ? false : true;
        this.dataChapterList[index].publish = publish ? true : false;
        this.dataChapterList[index].publishDate = data.date ? data.date.replace(this.re, '/') : data.date;
        this.dataChapterList[index].lastUpdate = data.date ? data.date.replace(this.re, '/') : data.date;
        if (publish) {
          this.storyStatus = 'labelPublishStories';
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }

  deleteChapter(story, chapter, author) {
    this.loading = true;
    this.storiesService.deleteChapter(story, chapter, author).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        this.dataChapterList = this.dataChapterList.filter(e => e.id !== chapter);
      }
    }, (error) => {
      this.loading = false;
    });
  }

  reorderChapter(story, chapter, author) {
    this.ngOnDestroyHit();
    this.reArangeChapterSubscription = this.storiesService.reorderChapter(story, chapter, author)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data.status) {
        this.isStoryChapterOrderChanged = false;
        if (chapter) { // Update current order
          chapter.forEach(e => {
            const index = this.dataChapterList.findIndex(dt => dt.id === e.id);
            this.dataChapterList[index].order = e.order;
            this.dataChapterListOld[index].order = e.order;
          });
        }
      }
    }, (error) => {
    });
  }

  cancelCreateChapter() {
    this.addChapter = false;
    // this.dataChapterForm = null;
    // this.validChapterForm = false;
    this.previewChapter = false;
  }

  public ngOnDestroy() {
    // When moving page
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnDestroyHit() {
    // When hitting request
    if (this.reArangeChapterSubscription) {
      this.reArangeChapterSubscription.unsubscribe();
    }
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

}
