import { Pipe, PipeTransform, Inject } from '@angular/core';
import * as moment from 'moment';
import { APP_CONFIG } from '../model/application.properties';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  constructor(
    @Inject(APP_CONFIG) private appConfig,
  ) {}

  transform(date) {
    moment.locale(this.appConfig.defaultLanguage);
    return moment(date).fromNow();
  }

}
