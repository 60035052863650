import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

constructor(
  private http: HttpClient,
  private apiService: ApiService
) { }

  // comment

  getCommentStory(storyId: any, uid: any) {
    const params = '?storyId=' + storyId +  '&uid=' + uid;
    return this.http.get(this.apiService.getCommentStoryURL + params, { headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  sendCommentStory(comment: any, parent: any, story: any, author: any, uid: any, toWho: any = '') {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('story', story);
    formData.append('author', author);
    formData.append('comment', comment);
    formData.append('parent', parent);
    formData.append('toWho', toWho);
    return this.http.post(this.apiService.sendCommentStoryURL, formData);
  }

  editCommentStory(comment: any, commentId: any, uid: any, storyId: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('commentText', comment);
    formData.append('commentId', commentId);
    formData.append('storyId', storyId);
    return this.http.post(this.apiService.sendCommentEditStoryURL, formData);
  }

  editCommentChapter(comment: any, commentId: any, uid: any, sid: any, cid: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('commentText', comment);
    formData.append('commentId', commentId);
    formData.append('sid', sid);
    formData.append('cid', cid);
    return this.http.post(this.apiService.sendCommentEditChapterURL, formData);
  }

  likeCommentStory(commentId: any, uid: any, status: any, storyId: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('uid', uid);
    formData.append('status', status);
    formData.append('storyId', storyId);
    return this.http.post(this.apiService.likedCommentStoryURL, formData);
  }

  reportCommentStory(commentId: any, storyId: any, typeReport: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('storyId', storyId);
    formData.append('typeReport', typeReport);
    formData.append('uid', uid);
    return this.http.post(this.apiService.reportedCommentStoryURL, formData);
  }

  reportStory(storyID: string, typeReport: string, uid: string) {
    const formData: FormData = new FormData();
    formData.append('storyId', storyID);
    formData.append('typeReport', typeReport);
    formData.append('uid', uid);
    return this.http.post(this.apiService.reportedStoryURL, formData);
  }

  deleteCommentStory(commentId: any, storyId: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('story_id', storyId);
    formData.append('uid', uid);
    return this.http.post(this.apiService.deletedCommentStoryURL, formData);
  }

  deleteCommentChildStory(commentId: any, storyId: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('story_id', storyId);
    formData.append('uid', uid);
    return this.http.post(this.apiService.deletedCommentChildStoryURL, formData);
  }

  pinnedCommentStory(commentId: any, uid: any, storyId: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    return this.http.post(this.apiService.pinedCommentStoryURL, formData);
  }

  unPinnedCommentStory(commentId: any, uid: any, storyId: any) {
    const formData: FormData = new FormData();
    formData.append('scomment_id', commentId);
    formData.append('sid', commentId);
    formData.append('uid', uid);
    return this.http.post(this.apiService.unPinedCommentStoryURL, formData);
  }

  // chapter
  getCommentChapter(chapterId: any, uid: any) {
    const params = '?chapterId=' + chapterId +  '&uid=' + uid;
    return this.http.get(this.apiService.getCommentChapterURL + params);
  }

  sendCommentChapter(comment: any, parent: any, story: any, chapter: any, author: any, uid: any, toWho: any = '') {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('story', story);
    formData.append('chapter', chapter);
    formData.append('author', author);
    formData.append('comment', comment);
    formData.append('parent', parent);
    formData.append('toWho', toWho);
    return this.http.post(this.apiService.sendCommentChapterURL, formData);
  }

  likeCommentChapter(chapterId: any, uid: any, status: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', chapterId);
    formData.append('uid', uid);
    formData.append('status', status);
    return this.http.post(this.apiService.likedCommentChapterURL, formData);
  }

  reportCommentChapter(commentId: any,  storyId: any, chapterId: any, typeReport: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', commentId);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    formData.append('typeReport', typeReport);
    formData.append('uid', uid);
    return this.http.post(this.apiService.reportedCommentChapterURL, formData);
  }

  deleteCommentChapter(commentId: any, uid: any, sid: any, cid: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', commentId);
    formData.append('uid', uid);
    formData.append('sid', sid);
    formData.append('cid', cid);
    return this.http.post(this.apiService.deletedCommentChapterURL, formData);
  }

  deleteCommentChildChapter(commentId: any, uid: any, sid: any, cid: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', commentId);
    formData.append('uid', uid);
    formData.append('sid', sid);
    formData.append('cid', cid);
    return this.http.post(this.apiService.deletedCommentChildChapterURL, formData);
  }

  pinnedCommentChapter(commentId: any, uid: any, storyId: any, chapterId: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', commentId);
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    return this.http.post(this.apiService.pinedCommentChapterURL, formData);
  }

  unPinnedCommentChapter(commentId: any, uid: any, storyId: any, chapterId: any) {
    const formData: FormData = new FormData();
    formData.append('ccomment_id', commentId);
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    return this.http.post(this.apiService.unPinedCommentChapterURL, formData);
  }

  getReportCommentType() {
    return this.http.get(this.apiService.getReportCommentTypeURL);
  }

}
