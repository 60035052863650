import { SettingService } from 'src/app/service/setting.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { CategoryService } from 'src/app/service/category.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GoToService } from './../../shared/service/go-to.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { AuthGuard } from 'src/app/auth.guard';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { InputFilterService } from 'src/app/shared/service/input-filter.service';
import { SeoService } from 'src/app/shared/service/seo.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';

@Component({
  selector: 'app-lomba-gwp',
  templateUrl: './lomba-gwp.component.html',
  styleUrls: ['./lomba-gwp.component.css'],
  providers: [TranslatePipe]
})
export class LombaGwpComponent implements OnInit {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  logo: String = '';
  auth: boolean;
  loading: boolean;
  nextForm = 1;

  checkEmailForm: FormGroup;
  registerForm: FormGroup;
  registerFormEvent: FormGroup;
  validForm = false;
  validFormRegister = false;
  validFormRegisterEvent = false;

  errorMessage = '';

  // category: string;
  categoryList: any[];
  selectedCategories: number[];

  enableUsername = false;
  currentUsername: any = null;

  genderList = [{id: 'M', name: 'Laki-laki'}, {id: 'F', name: 'Perempuan'}];
  selectedGender: any;
  birthDay: any;
  formatBirthDate: any;
  startDate: any = new Date();
  minDate = new Date(1945, 12, 31);
  maxDate = new Date();

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    public filter: InputFilterService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private categoryService: CategoryService,
    private datePipe: DatePipe,
    public authGuard: AuthGuard,
    private settingService: SettingService,
    private translate: TranslatePipe,
    private alertMassage: AlertMassageService,
    private renderer: Renderer2,
    private seo: SeoService,
  ) {
    this.logo = this.appConfig.logo;
    this.auth = this.authGuard.isLoggedIn();

    this.checkEmailForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')])]
    });

    this.registerForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      penName: ['', Validators.compose([Validators.maxLength(50), Validators.minLength(4), Validators.required])],
      dateBirth: ['', Validators.required],
      dateBirthDay: [''],
      gender: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')])],
      numberPhone: ['', Validators.compose([Validators.maxLength(13), Validators.required, Validators.pattern('[0-9]+')])],
      genreKarya: ['', Validators.required],
      type: ['']
    }, {validator: this.checkPasswords });

    this.registerFormEvent = this.formBuilder.group({
      fullName: ['', Validators.required],
      penName: ['', Validators.compose([Validators.maxLength(50), Validators.minLength(4), Validators.required])],
      dateBirth: ['', Validators.required],
      dateBirthDay: [''],
      gender: ['', Validators.required],
      email: ['', Validators.required],
      numberPhone: ['', Validators.compose([Validators.maxLength(13), Validators.required, Validators.pattern('[0-9]+')])],
      genreKarya: ['', Validators.required],
      type: ['']
    });
  }

  ngOnInit() {
    this.getSettingLomba();
    this.getCategoryList();
    this.seo.addMetaTag('The Writers\' Show', '', 'The Writers\' Show');
  }

  getSettingLomba() {
    this.settingService.getSettingLomba().subscribe((data: any) => {
      if (!data.status) {
        this.navigateUrl.goTo('home');
      }
    });
  }

  get form() {
    return this.checkEmailForm.controls;
  }

  checkValidForm() {
    if (!this.checkEmailForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  get formRegister() {
    return this.registerForm.controls;
  }

  checkValidFormRegister() {
    if (!this.registerForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  get formRegisterEvent() {
    return this.registerFormEvent.controls;
  }

  checkValidFormRegisterEvent() {
    if (!this.registerFormEvent.valid) {
      return true;
    } else {
      return false;
    }
  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      this.categoryList = [];
      data.data.forEach(e => {
        if (e.cat_related_module && e.cat_related_module.includes('tws')) {
          this.categoryList.push({
            id: e.cat_id,
            name: e.cat_title
          });
        }
      });
    });
  }

  checkPasswords(group: FormGroup) {
    const password        = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    const response        = password === confirmPassword ? null : { notSame: true };
    return response;
  }

  async checkinEmail() {
    this.validForm = this.checkValidForm();
    if (!this.validForm) {
      this.loading = true;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      const data: any = await this.userService.checkUserLomba(this.checkEmailForm.value);
      try {
        if (data.status) {
          if (data.condition === 1) {
            this.errorMessage = this.alertMassage.show('success', this.translate.transform('userAlreadyRegister'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
            this.nextForm = 1;
          } else if (data.condition === 2) {
            this.nextForm = 2;
            const user = {
              uemail: this.checkEmailForm.value.email,
              ufullname: data.data.ufullname,
              uusername: data.data.uusername,
              usex: data.data.usex,
              uphone: data.data.uphone,
              ufavorite_category: data.data.ufavorite_category,
              ubirthday: data.data.ubirthday
            };
            this.setFormRegistrasi(user, 2);
          } else {
            this.nextForm = 3;
            this.setFormRegistrasi({ uemail: this.checkEmailForm.value.email }, 3);
          }
        } else {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('userRegisterFailed'));
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
          setTimeout (() => {
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
          }, 5000);
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    }
  }

  setFormRegistrasi(data: any, type: number) {
    if (type === 3) {
      this.registerForm.controls.email.setValue(data.uemail);
      this.registerForm.controls.type.setValue(1);
    } else if (type === 2) {
      this.registerFormEvent.controls.type.setValue(2);
      this.registerFormEvent.controls.email.setValue(data.uemail);
      this.registerFormEvent.controls.fullName.setValue(data.ufullname);
      this.registerFormEvent.controls.penName.setValue(data.uusername);
      this.currentUsername = data.uusername;
      this.registerFormEvent.controls.gender.setValue(data.usex);
      if (data.usex) {
        this.selectedGender = data.usex;
      }
      this.registerFormEvent.controls.numberPhone.setValue(data.uphone);
      this.registerFormEvent.controls.genreKarya.setValue(data.ufavorite_category);
      if (data.ufavorite_category !== '' || data.ufavorite_category == null) {
        this.selectedCategories = data.ufavorite_category.split(',');
      }

      this.birthDay = data.ubirthday && data.ubirthday !== '0000-00-00' ? new Date(data.ubirthday) : '';
      this.formatBirthDate = '';
      if (this.birthDay !== '') {
        this.formatBirthDate = this.datePipe.transform(this.birthDay, 'dd/MM/yyyy');
      }

      this.registerFormEvent.controls.dateBirth.setValue(this.formatBirthDate);
      this.registerFormEvent.controls.dateBirthDay.setValue(this.birthDay);
    }
  }

  async findDuplicateUsername(event) {
    const checkUsername = event.target.value;
    if (checkUsername === '') {
      return;
    } else if (checkUsername === this.currentUsername) {
      this.enableUsername = false;
      return;
    }

    if (checkUsername) {
      this.userService.checkAvailableUsername(checkUsername).subscribe((data: any) => {
        if (data.status) {
          this.enableUsername = true;
        } else {
          this.enableUsername = false;
        }
      }, (error) => {
        this.enableUsername = true;
      });
    } else {
      this.userService.cancelObservable();
    }
  }

  setBirthDate(event, type) {
    const temp = event.target.value;
    if (temp && temp !== '' && temp.length === 10) {
      const dt = temp.split('/');
      const tgl = dt[2] + '-' + dt[1] + '-' + dt[0];
      this.birthDay = new Date(tgl);
      if (this.birthDay.getTime()) {
        this.formatBirthDate = this.datePipe.transform(this.birthDay, 'dd/MM/yyyy');
        if (type === 3) {
          this.formRegister.dateBirth.setValue(this.formatBirthDate);
          this.formRegister.dateBirthDay.setValue(this.birthDay);
        } else {
          this.formRegisterEvent.dateBirth.setValue(this.formatBirthDate);
          this.formRegisterEvent.dateBirthDay.setValue(this.birthDay);
        }
      }
    }
  }

  todateRegister(value) {
    if (value === '') {
      this.formRegister.dateBirth.setValue('');
      this.formRegister.dateBirthDay.setValue('');
      this.formatBirthDate = '';
      this.birthDay = '';
    } else {
      this.formRegister.dateBirth.setValue(this.datePipe.transform(new Date(value), 'dd/MM/yyyy'));
      this.formRegister.dateBirthDay.setValue(new Date(value));
      this.formatBirthDate = this.datePipe.transform(new Date(value), 'dd/MM/yyyy');
      this.birthDay = new Date(value);
    }
  }

  todateRegisterEvent(value) {
    if (value === '') {
      this.formRegisterEvent.dateBirth.setValue('');
      this.formRegisterEvent.dateBirthDay.setValue('');
      this.formatBirthDate = '';
      this.birthDay = '';
    } else {
      this.formRegisterEvent.dateBirth.setValue(this.datePipe.transform(new Date(value), 'dd/MM/yyyy'));
      this.formRegisterEvent.dateBirthDay.setValue(new Date(value));
      this.formatBirthDate = this.datePipe.transform(new Date(value), 'dd/MM/yyyy');
      this.birthDay = new Date(value);
    }
  }

  async submitRegisterEvent() {
    this.validFormRegisterEvent = this.checkValidFormRegisterEvent();
    if (!this.validFormRegisterEvent) {
      this.loading = true;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      this.formRegisterEvent.dateBirth.setValue(this.datePipe.transform(this.birthDay, 'yyyy/MM/dd'));
      const value: any = await this.userService.registerLombaGWP(this.registerFormEvent.value);
      try {
        if (value.status && value.message && value.message === 'success') {
          this.errorMessage = this.alertMassage.show('success', this.translate.transform('userSuccessRegister'));
          this.registerFormEvent.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'nomail') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailSuccessRegister'));
          this.registerFormEvent.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'failed') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailedRegister'));
        } else if (value.message && value.message === 'already') {
          this.errorMessage = this.alertMassage.show('success', this.translate.transform('emailAlreadyRegister'));
          this.registerFormEvent.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'exist') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailExistRegister'));
          this.registerFormEvent.reset();
          this.nextForm = 1;
        } else {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailedRegister'));
        }
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
        setTimeout (() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);
      } catch (error) {
      } finally {
        this.loading = false;
        this.formRegisterEvent.dateBirth.setValue(this.datePipe.transform(this.birthDay, 'dd/MM/yyyy'));
      }
    }
  }

  async submitRegister() {
    this.validFormRegister = this.checkValidFormRegister();
    if (!this.validFormRegister) {
      this.loading = true;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      this.formRegister.dateBirth.setValue(this.datePipe.transform(this.birthDay, 'yyyy/MM/dd'));
      const value: any = await this.userService.registerLombaGWP(this.registerForm.value);
      try {
        if (value.status && value.message && value.message === 'success') {
          this.errorMessage = this.alertMassage.show('success', this.translate.transform('userSuccessRegister'));
          this.registerForm.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'nomail') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailSuccessRegister'));
          this.registerForm.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'failed') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailedRegister'));
        } else if (value.message && value.message === 'already') {
          this.errorMessage = this.alertMassage.show('success', this.translate.transform('emailAlreadyRegister'));
          this.registerForm.reset();
          this.nextForm = 1;
        } else if (value.message && value.message === 'exist') {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailExistRegister'));
          this.registerForm.reset();
          this.nextForm = 1;
        } else {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailedRegister'));
        }
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
        setTimeout (() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);
      } catch (error) {
      } finally {
        this.loading = false;
        this.formRegister.dateBirth.setValue(this.datePipe.transform(this.birthDay, 'dd/MM/yyyy'));
      }
    }
  }

}
