import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { Router } from '@angular/router';
import { GoToService } from 'src/app/shared/service/go-to.service';

declare let jQuery: any;

@Component({
  selector: 'app-mobile-main-nav',
  templateUrl: './mobile-main-nav.component.html',
  styleUrls: ['./mobile-main-nav.component.css']
})
export class MobileMainNavComponent implements OnInit {
  @Output() settingEmit = new EventEmitter<string>();
  @Output() languageEmit = new EventEmitter<string>();
  @Output() changeLangEmit = new EventEmitter<string>();
  @Output() loginEmit = new EventEmitter<string>();
  @Output() logoutEmit = new EventEmitter<string>();
  @Input() notification: any = 0;
  @Input() setExpand: boolean;
  @Input() langExpand: boolean;
  @Input() defaultLang = 'ID';
  @Input() auth: boolean;

  openAccountMenu: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private router: Router,
    public navigateUrl: GoToService,
  ) { }

  ngOnInit() {
  }

  goToMenu (menu = 'home') {
    if ((menu === 'cstory' || menu === 'my-profile') && !this.auth) {
      this.loginEmit.emit();
    } else if (menu === 'my-profile') {
      this.openAccountMenu = true;
    } else {
      this.navigateUrl.goTo(menu);
    }
  }

  goToAccountMenu(menu) {
    this.openAccountMenu = false;
    this.navigateUrl.goTo(menu);
  }

  clickSetting() {
    this.settingEmit.emit();
  }

  clickLanguage() {
    this.languageEmit.emit();
  }

  changeLanguage(evt) {
    this.changeLangEmit.emit(evt);
  }

  logout() {
    this.openAccountMenu = false;
    this.logoutEmit.emit();
  }

  closeAccountMenu() {
    this.openAccountMenu = false;
  }

}
