import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone, Renderer2, AfterViewInit, AfterContentInit } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment.prod';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';


@Component({
  selector: 'app-setting-notification',
  templateUrl: './setting-notification.component.html',
  styleUrls: ['./setting-notification.component.css'],
  providers: [TranslatePipe]
})

export class SettingNotificationComponent implements OnInit {

  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;
  loading = false;
  settingNotificationForm:  FormGroup;
  alertMessage = '';

  constructor(
    public navigateUrl: GoToService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertMassage: AlertMassageService,
    private renderer: Renderer2,
    private seo: SeoService,
    private translate: TranslatePipe
  ) {

    this.settingNotificationForm = this.formBuilder.group({
      followMe: ['', Validators.required],
      likeChapterStory: ['', Validators.required],
      commentChapterStory: ['', Validators.required]
    });

   }

  ngOnInit() {
    this.seo.addMetaTag('Pengaturan Akun');
    this.loading = true;
    this.userService.getSettingNotification(localStorage.getItem('uid')).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        this.form.followMe.setValue(data.data.isFollowMe === '1' ? true : false);
        this.form.likeChapterStory.setValue(data.data.likeChapterStory === '1' ? true : false);
        this.form.commentChapterStory.setValue(data.data.commentChapterStory === '1' ? true : false);
      }
    }, (error: any) => {
      this.loading = false;
      console.log(error);
    });
  }

  get form() {
    return this.settingNotificationForm.controls;
  }

  saveSetting() {
    if (this.settingNotificationForm.invalid) {
      console.log(1);
      this.settingNotificationForm.markAllAsTouched();
    } else {
      console.log(2);
      this.loading = true;
      this.userService.saveSettingNotification(
        localStorage.getItem('uid'),
        this.form.followMe.value === true ? 1 : 0,
        this.form.likeChapterStory.value === true ? 1 : 0,
        this.form.commentChapterStory.value === true ? 1 : 0
      ).then((response: any) => {
        this.loading = false;
        if (response.status) {
          this.alertMessage = this.alertMassage.show('success', this.translate.transform('notificationChangeSuccess'));
        } else {
          this.alertMessage = this.alertMassage.show('failed',  this.translate.transform('notificationChangeFailed'));
        }
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.alertMessage);
        setTimeout (() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);
      }).catch((error: any) => {
        this.loading = false;
        console.log(error);
      });
    }
  }

}
