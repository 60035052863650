import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-gwp-dialog',
  templateUrl: './gwp-dialog.component.html',
  styleUrls: ['./gwp-dialog.component.css']
})
export class GwpDialogComponent implements OnInit, AfterContentChecked {
  type: number;
  title: string;
  message: string;
  confirmButton: string;
  datas: any;
  selectDatas: any;
  object: any;
  constructor(public dialogRef: MatDialogRef<GwpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.type    = data.type;
    this.title   = data.title;
    this.message = data.message;
    this.confirmButton = data.confirmButton;
    this.datas   = data.datas;
    this.object  = data.object;
  }

  ngOnInit() {
    if (this.type === 2) {
      this.datas.map((list: any, index: any) => {
        if (index === 0) {
          list.checked = true;
        } else {
          list.checked = false;
        }
      });
    }
  }

  ngAfterContentChecked() {
    // console.log(this.datas);
  }

  onSelect(data) {
    this.selectDatas = data;
  }

  onConfirm(): void {
    if (this.type === 1) {
      this.dialogRef.close(true);
    } else {
      this.selectDatas = (this.selectDatas) ? this.selectDatas : this.datas.find( (x: any) => x.checked === true).rt_id;
      this.dialogRef.close({'data' : this.selectDatas, 'status': true});
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}

export class ConfirmDialogModel {
  constructor(
    public type: number,
    public title: string,
    public message: string,
    public datas: any,
    public confirmButton = 'confirmSure',
    public object = null) {
  }
}
