import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  providers: [TranslatePipe]
})

export class FooterComponent implements OnInit {

  projectYear: string;
  projectCompany: string;

  email: any      = 'mailto:customercare@gwp.id';
  facebook: any   = 'https://www.facebook.com/gwpid/';
  instagram: any  = 'https://www.instagram.com/gwp_id/';
  twitter: any    = 'https://twitter.com/GWP_ID';
  appver: any     = 'v3.0.0';

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
  ) { }

  ngOnInit() {
    this.projectYear = this.appConfig.year;
    this.projectCompany = this.appConfig.company;
  }

  testClick() {
    console.log('Click', 'Footer');
  }

}
