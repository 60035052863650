import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { UserService } from 'src/app/service/user.service';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { environment } from 'src/environments/environment.prod';
declare let jQuery: any;

@Component({
  selector: 'app-popular-author',
  templateUrl: './popular-author.component.html',
  styleUrls: ['./popular-author.component.css'],
  providers: [TranslatePipe]
})
export class PopularAuthorComponent implements OnInit {

  loading: boolean;
  isParam: boolean;
  activePage = 1;
  limit = 12;
  totalData = 0;
  noData: boolean;
  authorList: any[] = [];

  // sprint 8
  uid: any;
  loginStatus: string;
  auth: boolean;


  // sprint 10
  defaultImage: any;
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private route: ActivatedRoute,
    private userService: UserService,
    public authGuard: AuthGuard,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Penulis Populer');
    this.defaultImage = environment.imageAuthorDefault;
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
      this.loginStatus = localStorage.getItem('login_status');
    }
    this.route.params.subscribe(params => {
      this.auth = this.authGuard.isLoggedIn();
      if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
      }

      if (localStorage.getItem('login_status')) {
        this.uid = localStorage.getItem('uid');
        this.loginStatus = localStorage.getItem('login_status');
      }
      if (this.isParam) {
        this.getAuthorPopular();
      }
    });

    if (!this.isParam) {
      this.getAuthorPopular();
    }
  }

  getAuthorPopular() {
    this.loading = true;
    this.userService.getPopularAuthor(this.limit, this.activePage, 'list', this.uid !== '' ? this.uid : '').subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.authorList = [];
        data.data.forEach(e => {
          this.authorList.push({
            id: e.uid,
            src: e.uimage ? e.uimage : null,
            author: e.ufullname ? e.ufullname : e.uusername,
            authorId: '@' + e.uusername,
            stories: e.total_stories,
            followed: e.followed
          });
        });
        this.totalData = data.totalData;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  onChangePage(page) {
    this.activePage = page;
    this.navigateUrl.goTo('popular-author/p/' + this.activePage);
  }

  followAuthor(author) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    } else {
      this.userService.followUser(this.uid, author.id, 'follow').then((data: any) => {
        if (data.status) {
          author.followed = true;
        }
      }).catch((error: any) => {
      });
    }
  }
  unfollowAuthor(author) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    } else {
      this.userService.followUser(this.uid, author.id, 'unfollow').then((data: any) => {
        if (data.status) {
          author.followed = false;
        }
      }).catch((error: any) => {
      });
    }
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }
}
