import { SeoService } from 'src/app/shared/service/seo.service';
import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, OnChanges, OnDestroy, Inject } from '@angular/core';
import { PublishService } from 'src/app/service/publish.service';
import { ActivatedRoute } from '@angular/router';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';


@Component({
  selector: 'app-published-story',
  templateUrl: './published-story.component.html',
  styleUrls: ['./published-story.component.css']
})
export class PublishedStoryComponent implements OnInit {

  loading: boolean;
  isParam: boolean;
  activePage = 1;
  limit = 9;
  totalData = 0;
  noData: boolean;
  publishList: any[] = [];
  defaultImage: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private publishService: PublishService,
    private route: ActivatedRoute,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Cerita Terbit');
    this.route.params.subscribe( params => {
      if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
      } else {
        this.isParam = false;
        this.activePage = 1;
      }
      this.defaultImage = environment.imageStoryDefaultV2;
      this. getPublish();
    });
  }

   stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  getPublish() {
    this.loading = true;
    this.publishService.getPublishPagination(
      this.limit,
      this.activePage
    ).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.publishList = [];
        data.data.forEach(e => {
          this.publishList.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : this.defaultImage),
            title: e.story_title ? e.story_title : '-',
            lastRead: e.lastRead,
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            authhorUid: e.uid,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            link: e.pstory_link_ecomerce,
            price: e.pstory_price,
            synopsis: e.story_description ? this.stripHtml(e.story_description) : '-',
            formats: e.published_formats.length >= 2 ? e.published_formats.slice(0,2) : e.published_formats, // the req states that the format set would only be 2
            nformats: e.published_formats.length
          });
        });
        this.totalData = data.totalData;
      } else {
        this.publishList = [];
        this.totalData = 0;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error: any) => {
        this.loading = false;
        console.log(error);
    });
  }

  onChangePage(page) {
    this.activePage  = page;
    this.navigateUrl.goTo(
      'published-story/p/' + this.activePage
    );
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  openLinkPublished(url: any) {
    window.open(url, '_blank');
  }

}
