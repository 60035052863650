import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { PageService } from 'src/app/service/page.service';
import { PublishService } from 'src/app/service/publish.service';
import { SeoService } from 'src/app/shared/service/seo.service';
import { GwpService } from './../../service/gwp.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from 'gsap';

declare var ResizeObserver: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit, AfterViewInit {
  isOnMobile: boolean;
  isOnSmallMobile: boolean;
  screenWidth: number;
  loading = false;
  nav = false;
  limit = 10;
  marqueeContentsMobile: string[] = [];
  marqueeContents: string[] = [];
  marqueeReverseContents: string[] = [];
  defaultLandingPagePublishedSlider = 'assets/images/slider-home-1.jpg';
  defaultLandingPagePublishedSlider1 = 'assets/images/slider-home-2.jpg';
  socialProofs = [];
  totalStory = 0;
  totalGenre = 0;
  totalMember = 0;
  customOptions: OwlOptions = {
    items: 1,
    loop: false,
    mouseDrag: true,
    autoplay: false,
    center: false,
    dots: false,
    autoHeight: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
        autoWidth: true
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
    nav: true,
    navText: [
      '<div class="arrow-circle"><i class="fas fa-arrow-left" aria-hidden="true"></i></div>',
      '<div class="arrow-circle"><i class="fas fa-arrow-right" aria-hidden="true"></i></div>'
    ]
  };

  @ViewChild('imageContainer', { static: false }) imageContainer!: ElementRef;

  constructor(
    private publishService: PublishService,
    private aboutUsService: PageService,
    private router: Router,
    private authGuard: AuthGuard,
    private seo: SeoService,
    private gwpService: GwpService
  ) {
    this.getScreenSize();
  }

  ngAfterViewInit(): void {
    if (this.isOnMobile) {
      this.startMarqueeHero();
    }

    if (this.screenWidth <= 768) {
      this.startMarqueePublishers();
    }
  }

  ngOnInit() {

    if (this.screenWidth <= 1024) {
      this.isOnMobile = true;
    }
    if (this.screenWidth <= 768) {
      this.isOnSmallMobile = true;
    }
    this.seo.addMetaTag('Gramedia Writing Project');
    // current handle. Currently this page is only accessible before login.
    // Once logged in, redirect to /home
    if (this.authGuard.isLoggedIn()) {
      this.router.navigate(['/home']);
    }
    // this.getDisclaimerData();
    this.getSocialProofs();
    this.getTotalGSM();

    this.publishService.getPublishedStoriesImages(this.limit).subscribe((data: any) => {
      if (data.data) {
        let images: string[] = [];
        data.data.forEach(element => {
          if (element.story_image_cover && element.story_image_cover !== '') {
            try {
              let url = new URL(element.story_image_cover);
              images.push(url.toString());
            } catch (e) {

            }
          }
        });
        let middleInd = images.length / 2;
        if (middleInd < 1 && middleInd > 0) {
          middleInd = 1;
        } else if (middleInd <= 0) {
          middleInd = 0;
        }

        this.marqueeContentsMobile = images;
        this.marqueeContents = images.slice(0, middleInd);
        this.marqueeReverseContents = images.slice(middleInd, images.length);

        if (images.length < this.limit) {
          if (this.marqueeContents.length < 5) {
            const nAdd = 5 - this.marqueeContents.length;
            for (let index = 0; index < nAdd; index++) {
              this.marqueeContents.push(this.getDefaultPublishedSlider(index));
            }
          }
          if (this.marqueeReverseContents.length < 5) {
            const nAdd = 5 - this.marqueeReverseContents.length;
            for (let index = 0; index < nAdd; index++) {
              this.marqueeReverseContents.push(this.getDefaultPublishedSlider(index));
            }
          }
        }
      }
    });
    if (this.screenWidth <= 1024) {
      this.isOnMobile = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  getDefaultPublishedSlider(ind: number): string {
    if (ind % 2 == 0) {
      return this.defaultLandingPagePublishedSlider
    }
    return this.defaultLandingPagePublishedSlider1;
  }

  getDisclaimerData() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  getSocialProofs() {
    this.loading = true;

    this.aboutUsService.getSocialProofs().subscribe((data: any) => {
      if (data.data) {
        this.socialProofs = data.data;
        this.loading = false;
      }
    });
  }

  getTotalGSM() {
    this.gwpService.getCountSGM().subscribe((data: any) => {
      if (data.data) {
        this.totalStory = data.data.cStory;
        this.totalGenre = data.data.cGenre;
        this.totalMember = data.data.cMember;
      }
    });
  }

  startMarqueeHero() {
    const marquee = document.querySelector('.marquee-horizontal') as HTMLElement;

    const observer = new ResizeObserver(() => {
      const width = marquee.scrollWidth;

      gsap.to(marquee, {
        x: -width / 3,
        duration: 10,
        repeat: -1,
        ease: 'none',
      });
    });

    observer.observe(marquee);
  }

  startMarqueePublishers() {
    const marquee = document.querySelector('.marquee-horizontal-publishers') as HTMLElement;

    const observer = new ResizeObserver(() => {
      const width = marquee.scrollWidth;

      gsap.to(marquee, {
        x: -width / 2,
        duration: 20,
        repeat: -1,
        ease: 'none',
      });
    });

    observer.observe(marquee);
  }

}
