import { UserService } from 'src/app/service/user.service';
import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, Renderer2, NgZone, AfterViewInit, Input } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from 'src/app/model/user.model';
import { AuthGuard } from 'src/app/auth.guard';
import { environment } from 'src/environments/environment.prod';
import { LoginService } from 'src/app/service/login.service';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { NotifService } from 'src/app/service/notif.service';
import { SomethingChangeService } from 'src/app/service/something-change.service';
import { RegisterService } from 'src/app/service/register.service';
import { CategoryService } from 'src/app/service/category.service';


declare let jQuery: any;
declare const gapi: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [TranslatePipe]
})

export class HeaderComponent implements OnInit, AfterViewInit {
  @Output() sideNavToogle = new EventEmitter<string>();
  @ViewChild('errMsg', { static: false }) errMsg: ElementRef;
  @Input() mobileNav: boolean;
  @Input() hide: boolean;
  @Input() showBtn: boolean;

  logo: String = '';
  loginForm: FormGroup;

  loading: Boolean = false;
  validForm = false;
  errorMessage = '';

  auth: boolean;
  uid: string;
  user: User[];
  email: string;
  password: string;
  uFullName: string;
  profileImage: string;
  profileImageDefault = environment.imageAuthorDefault;
  blogUrl = environment.blogUrl;
  homeURL: string;

  auth2: any;
  googleClientId: string;

  settingExpand: boolean;
  languageExpand: Boolean = false;
  defaultLanguage: any;
  intervalActive: any;
  newNotification: any = 0;
  myUid: any;
  unverified: boolean;

  slidesCategory: any[] = [];


  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    public authGuard: AuthGuard,
    private loginService: LoginService,
    private registerService: RegisterService,
    private alertMassage: AlertMassageService,
    private categoryService: CategoryService,
    private translate: TranslatePipe,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private userService: UserService,
    private notifService: NotifService,
    private someThingChange: SomethingChangeService
  ) {
    this.defaultLanguage = localStorage.getItem('user_language') ? localStorage.getItem('user_language') : this.appConfig.defaultLanguage;
    this.appConfig.defaultLanguage = this.defaultLanguage;
    this.intervalActive = setInterval(() => {
      this.activeIntervalNotif();
    }, 120000);

    this.someThingChange.getMessage().subscribe((data) => {
      if (data.event === 'ballNotif') {
        this.newNotification = data.message;
      }
      if (data.event === 'editprofile') {
        if (localStorage.getItem('login_status')) {
          this.myUid = localStorage.getItem('uid');
          this.uid = localStorage.getItem('uid');
          this.updateData();
        }
      }
      if (data.event === 'editprofilepict') {
        this.profileImage = localStorage.getItem('profile_image')
      }
    });
  }

  ngOnInit() {
    this.logo = this.appConfig.logo;
    this.auth = this.authGuard.isLoggedIn();
    this.getSlideCategory();

    if (localStorage.getItem('Fullname')) {
      this.uid = localStorage.getItem('uid');
      this.uFullName = (localStorage.getItem('Fullname') !== 'null') ? localStorage.getItem('Fullname') : localStorage.getItem('Name');
      this.profileImage = localStorage.getItem('profile_image') ? localStorage.getItem('profile_image') : this.profileImageDefault;
      this.updateData();
      this.activeIntervalNotif();
    }

    this.homeURL = "/home";
    if (!this.auth) {
      clearInterval(this.intervalActive);
      this.loginForm = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
        rememberme: ['']
      });
      this.homeURL = "/";
      return;
    }
  }

  updateData() {
    this.userService.getUserById(this.myUid, this.uid).subscribe((data: any) => {
      this.user = data.data;
      localStorage.setItem('Name', data.data.uusername);
      localStorage.setItem('Fullname', data.data.ufullname);
      localStorage.setItem('profile_image', data.data.uimage ? data.data.uimage : this.profileImageDefault);
      localStorage.setItem('login_status', '1');
      localStorage.setItem('login_google', '0');
      localStorage.setItem('user_language', data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage);
      this.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
      this.appConfig.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
      this.uFullName = ((data.data.ufullname && data.data.ufullname !== 'null') ? data.data.ufullname : data.data.uusername);
      this.profileImage = localStorage.getItem('profile_image');
    }, (error: any) => {
      // console.log(error);
    });
  }

  activeIntervalNotif() {
    this.notifService.getCounterNewNotification(this.uid).subscribe((data: any) => {
      if (data.status) {
        this.newNotification = data.data;
      }
    }, (error: any) => {
      // console.log(error);
    });
  }
  ngAfterViewInit() {
    if (!this.auth) {
      this.googleClientId = environment.googleClientId;
      this.googleInit();
    }
  }

  doClickSideNav() {
    const route = 'sideNav';
    this.sideNavToogle.emit(route);
  }

  doClickLogin(event) {
    jQuery('#loginModal').appendTo('body').modal(event);
  }

  get form() {
    return this.loginForm.controls;
  }

  checkValidForm() {
    if (!this.loginForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  login() {
    this.unverified = false;
    this.validForm = this.checkValidForm();
    if (!this.validForm) {
      const user = {
        email: btoa(this.loginForm.value.email),
        password: btoa(this.loginForm.value.password),
        rememberme: this.loginForm.value.rememberme
      };

      this.loading = true;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      this.loginService.loginCheck(user).subscribe((data: any) => {
        this.user = data.data;
        this.loading = false;
        if (this.user != null) {
          if (data.data === '0') {
            this.navigateUrl.goTo('forgot-password');
          } else if (data.data.ustatus && data.data.ustatus === 'verified') {
            localStorage.setItem('uid', data.data.uid);
            localStorage.setItem('Name', data.data.uusername);
            localStorage.setItem('Fullname', data.data.ufullname);
            localStorage.setItem('profile_image', data.data.uimage);
            localStorage.setItem('login_status', '1');
            localStorage.setItem('login_google', '0');
            localStorage.setItem('user_language', data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage);
            this.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
            this.appConfig.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
            this.uFullName = ((data.data.ufullname && data.data.ufullname !== 'null') ? data.data.ufullname : data.data.uusername);
            this.profileImage = localStorage.getItem('profile_image');
            this.doClickLogin('hide');
            this.auth = this.authGuard.isLoggedIn();
            // location.reload();
            this.navigateUrl.goTo('home');
          } else {
            this.unverified = true;
            this.email = (data.data && data.data.uemail) ? atob(data.data.uemail) : null;
          }
        } else {
          this.errorMessage = this.alertMassage.show('failed', this.translate.transform('loginFailed'));
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
          setTimeout(() => {
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
          }, 5000);
        }
      });
    }
  }

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.googleClientId,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        const user = {
          token: googleUser.getAuthResponse().id_token,
          email: btoa(profile.getEmail()),
          gid: profile.getId(),
          name: profile.getName(),
          image: profile.getImageUrl()
        };
        this.ngZone.run(() => this.loading = true);
        this.ngZone.run(() => this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', ''));
        this.loginService.loginGoogle(user).subscribe((data: any) => {
          this.ngZone.run(() => this.loading = false);
          if (data && data.message && data.message.s === 'success') {
            localStorage.setItem('uid', data.data.uid);
            localStorage.setItem('Name', data.data.uusername);
            localStorage.setItem('Fullname', data.data.ufullname);
            localStorage.setItem('profile_image', data.data.uimage ? data.data.uimage : this.profileImageDefault);
            localStorage.setItem('login_status', '1');
            localStorage.setItem('login_google', '1');
            localStorage.setItem('user_language', data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage);
            this.appConfig.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
            this.defaultLanguage = data.data.ulanguage ? data.data.ulanguage : this.defaultLanguage;
            this.uFullName = ((data.data.ufullname && data.data.ufullname !== 'null') ? data.data.ufullname : data.data.uusername);
            this.profileImage = localStorage.getItem('profile_image');
            this.ngZone.run(() => this.doClickLogin('hide'));
            this.ngZone.run(() => this.auth = this.authGuard.isLoggedIn());
            this.ngZone.run(() => this.navigateUrl.goTo('home'));
          } else {
            // tslint:disable-next-line: max-line-length
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailed'), this.translate.transform('failed'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout(() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
            googleUser.disconnect();
          }
        }, (error: any) => {
          this.ngZone.run(() => this.loading = false);
        });
      }, (error) => {
        console.log('Cancel Google SignIn');
        console.log('Google', JSON.stringify(error, undefined, 2));
      });
  }

  getSlideCategory() {
    this.categoryService.getCategory().subscribe((data: any) => {
      if (data.data) {
        this.slidesCategory = [];
        data.data.forEach(e => {
          if (e.cat_related_module && e.cat_related_module.includes('home')) {
            this.slidesCategory.push({
              src: e.cat_image ? e.cat_image : environment.imageCategoryDefautl,
              id: e.cat_id,
              text: e.cat_title,
              slug: e.cat_slug
            });
          }
        });
        this.slidesCategory.push({
          src: null,
          id: 0,
          text: 'LAST',
          slug: 'last'
        });
      }
    });
  }

  signOut() {
    // const auth2 = gapi.auth2.getAuthInstance();
    // auth2.signOut().then(function () {
    //   console.log('User signed out.');
    // });
  }

  logout() {
    if (localStorage.getItem('login_google') && localStorage.getItem('login_google') === '1') {
      this.signOut();
    }
    this.authGuard.logout();
  }

  clickSetting() {
    this.settingExpand = !this.settingExpand;
  }

  clickLanguage() {
    this.languageExpand = !this.languageExpand;
  }

  goToPage(page, login = false) {
    if (login && this.auth) {
      this.navigateUrl.goTo(page);
    } else if (login && !this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
    } else {
      this.navigateUrl.goTo(page);
    }
  }

  // sprint 6
  changeLanguage(language: any) {
    this.loading = true;
    this.userService.updateLanguageUser(this.uid, language).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        this.defaultLanguage = language;
        this.appConfig.defaultLanguage = language;
        localStorage.setItem('user_language', language);
        location.reload();
      }
    }, (error: any) => {
      this.loading = false;
    });
  }

  onErrorImage(event) {
    event.target.src = this.profileImageDefault;
  }

  resendVerification() {
    this.loading = true;
    this.registerService.resendVerif(this.email).subscribe((data: any) => {
      this.loading = false;
      if (data && data.message && data.message.s === 'success') {
        this.errorMessage = this.alertMassage.show('success', this.translate.transform('resendVSuccess'));
      } else {
        this.errorMessage = this.alertMassage.show('failed', this.translate.transform('resendVFailed'));
      }
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
      setTimeout(() => {
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
      }, 5000);
    }, (error) => {
      this.loading = false;
    });
  }

}
