import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Notification } from '../model/notif.model';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()

export class NotifService {
    token: string;

    constructor(private http: HttpClient, private apiService: ApiService) { }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    getPager(totalItems: number, currentPage: number = 1, pageSize: number) {
        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    // sprint 6
    getMyNotification(uid: any) {
      const params = '?uid=' + uid;
      return this.http.get(this.apiService.getNotifURL + params);
    }

    getCounterNewNotification(uid: any) {
      const params = '?uid=' + uid;
      return this.http.get(this.apiService.counterNotification + params);
    }

    makeReadNotification(uid: any, un_id: any) {
      const formData: FormData = new FormData();
      formData.append('uid', uid);
      formData.append('un_id', un_id);
      return this.http.post(this.apiService.makeReadNotification, formData);

    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

}
