import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialString'
})
export class InitialStringPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) return '';

    const parts = value.split(' ');
    let initials = parts.map(part => part[0].toUpperCase()).join('');

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return initials;
  }
}
