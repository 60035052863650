import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';

@Component({
  selector: 'app-register-activation',
  templateUrl: './register-activation.component.html',
  styleUrls: ['./register-success.component.css']
})
export class RegisterActivationComponent implements OnInit {
  logo: String = '';
  auth: boolean;
  paramU: string;
  paramM: string;
  failed: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private route: ActivatedRoute,
    private userService: UserService,
    public authGuard: AuthGuard,
    private seo: SeoService
  ) {
    this.logo = this.appConfig.logo;
    this.auth = this.authGuard.isLoggedIn();
  }

  ngOnInit() {
    this.seo.addMetaTag('Aktivasi Pendaftaran');
    this.paramU = this.route.snapshot.queryParamMap.get('u');
    this.paramM = this.route.snapshot.queryParamMap.get('m');

    this.userService.userActivation(this.paramU, this.paramM).subscribe((data: any) => {
      if (data.data === null) {
        this.failed = 'failed';
      } else if (data.data === '0') {
        this.failed = 'already';
      } else {
        this.failed = '';
      }
    });
  }
}
