import { CanonicalService } from './../../shared/service/canonical.service';
import { TruncatePipe } from './../../shared/pipe/truncate.pipe';
import { SeoService } from './../../shared/service/seo.service';
import { Component, OnInit, Inject, AfterContentChecked, ViewChild, ElementRef, HostListener } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { AuthGuard } from 'src/app/auth.guard';
import { ActivatedRoute, Router } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { CommentService } from 'src/app/service/comment.service';
import { environment } from 'src/environments/environment.prod';
import { SafeHtmlPipe } from 'src/app/shared/pipe/safe-html.pipe';
import { MyLibraryService } from 'src/app/service/my-library.service';
import { ConfirmShareDialogModel, GwpShareDialogComponent } from 'src/app/shared/component/gwp-share-dialog/gwp-share-dialog.component';
import { MatDialog } from '@angular/material';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';

declare let jQuery: any;

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.css'],
  providers: [TranslatePipe, TruncatePipe, SafeHtmlPipe]
})
export class StoryDetailComponent implements OnInit, AfterContentChecked {
  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;

  activeTab = 0;

  storyId = '';
  storyImage: string;
  storyTitle = '-';
  storySynopsis: any;
  storySlug: string;
  storyAuthor: string;
  storyAuthorId: string;
  storyModifiedDate: string;
  storyViews: number;
  storyLikes: number;
  storyComments: number;
  storyCategoryId: any;
  storyInMylibrary: Boolean = false;
  storyInMyLibraryId: any = null;
  storyComplete: Boolean = false;
  defaultImage: string;
  iconShare: string;
  iconCollection: string;
  iconThreeDots: string;

  storyData: any;
  chapterListData: any[];
  slidesRecomendedStories: any[] = [];
  chapterNumberCounter = 1;

  shareUrl = '';
  shareWhatsappUrl: any = '';
  shareMWhatsappUrl: any = '';
  fbUrl = 'https://www.facebook.com/sharer/sharer.php';
  shareFacebookUrl: any = '';
  twUrl = 'https://twitter.com/intent/tweet';
  shareTwitterUrl: any = '';
  alerts: any[] = [];

  // sprint 4
  storyCommentListData: any[] = [];

  // sprint 5
  reportCommentType: any [] = [];

  // sprint 8
  idNotClosed: any = 0;
  re = /\-/gi;

  defaultGenreImageMap: Map<string, string>;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private seo: SeoService,
    private cononical: CanonicalService,
    private dialog: MatDialog,
    private translate: TranslatePipe,
    private truncateText: TruncatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private router: Router,
    private storiesService: StoriesService,
    private commentService: CommentService,
    private safeHtml: SafeHtmlPipe,
    private myLibrary: MyLibraryService
  ) {
  }

  ngOnInit() {
    this.iconCollection = environment.iconCollection;
    this.iconShare = environment.iconShare;
    this.iconThreeDots = environment.iconThreeDots;
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
    this.storyImage = this.defaultImage;

    this.shareUrl = environment.homeUrl + this.router.url.split('?')[0];
    this.shareWhatsappUrl = this.safeHtml.transform2('https://web.whatsapp.com/send?text=' + this.shareUrl);
    this.shareMWhatsappUrl = this.safeHtml.transform2('whatsapp://send?text=' + this.shareUrl);
    this.shareFacebookUrl = this.fbUrl + '?u=' + encodeURIComponent(this.shareUrl) + '&amp;src=sdkpreparse';
    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(this.storyTitle) + '&url=' + encodeURIComponent(this.shareUrl.split('?')[0]);
    this.getReportCommentType();
    this.route.params.subscribe( params => {
      if (params && params['story'] && params['slug']) {
        this.storyId = params['story'];
        this.storySlug = params['slug'];
        this.getStoryDetail(this.storyId, this.storySlug, this.uid);
        this.getChapterListData(this.storyId, this.storySlug, this.uid);
        this.getCommentStory(this.storyId, this.uid);
      }
    });

    this.defaultGenreImageMap = new Map<string, string>();
    // this.defaultGenreImageMap.set("26", environment.imageThrillerDefault);
    // this.defaultGenreImageMap.set("100", environment.imageFictionDefault);
    this.defaultGenreImageMap.set("26", environment.imageStoryDefaultV2);
    this.defaultGenreImageMap.set("100", environment.imageStoryDefaultV2);
  }

  getCommentStory(storyId: any, uid: any) {
    this.commentService.getCommentStory(storyId, uid).subscribe((data: any) => {
      if (data.status) {
        this.storyCommentListData = data.data;
      }
    }, (error: any) => {
    });

    this.route.queryParams.subscribe(params => {
      if (params && params['q']) {
        this.activeTab = (params['q'] === 'content' ? 1 : 0);
      }
    });
  }

  reportStory() {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    const message = 'Mengapa anda melaporkan tulisan ini?';
    const dialogData = new ConfirmDialogModel(2, 'Laporkan Tulisan', message, this.reportCommentType);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '536px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.status) {
        console.log(dialogResult);
        // hit endpoint report story
        this.commentService.reportStory(
          this.storyId,
          dialogResult.data,
          this.uid
        ).subscribe((response: any) => {
          if (response.status) {
            console.log("reported");
          }
        }, (error: any) => {
          console.log("failed on report");
          console.log(error);
        });
      }
    });
  }

  ngAfterContentChecked() {
    const imageUrl = this.storyImage ? this.storyImage : environment.homeUrl + '/' + this.defaultImage;
    this.seo.updateMetaTag(this.storyTitle, this.shareUrl, this.truncateText.transform(this.storySynopsis, 150), imageUrl);

    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(this.storyTitle) + '&url=' + encodeURIComponent(this.shareUrl.split('?')[0]);
  }

  getStoryDetail(sid, slug, uid) {
    this.loading = true;
    this.storiesService.getStoriesDetailById(sid, slug, uid).subscribe((data: any) => {
      this.loading = false;
      this.storyData = null;
      if (data.status && data.data) {
        var defImage = this.defaultGenreImageMap.get(data.data.story_category);
        if (defImage == undefined) {
          defImage = this.defaultImage;
        }
        this.storyData = {
          synopsis: data.data.story_description ? data.data.story_description : '-',
          tags: data.data.story_tags ? data.data.story_tags : '-',
          tagList: data.data.story_tags ? data.data.story_tags.split(',') : null,
          genre: data.data.cat_title ? data.data.cat_title : '-',
          matureType: (data.data.story_mature === '1' ? true : false),
          synopsis_count: data.data.story_description_count
        };
        this.storyId = sid;
        this.storyTitle = data.data.story_title ? data.data.story_title : '-';
        this.storySlug = slug;
        this.storySynopsis = data.data.story_description ? data.data.story_description : '-';
        this.storyAuthor = data.data.uusername ? data.data.uusername : '-';
        this.storyAuthorId = data.data.story_author_id ? data.data.story_author_id : '-';
        this.storyModifiedDate = data.data.story_modified_date ? data.data.story_modified_date.replace(this.re, '/') : null;
        this.storyViews = data.data.story_views ? data.data.story_views : 0;
        this.storyLikes = data.data.story_likes ? data.data.story_likes : 0;
        this.storyComments = data.data.story_comments ? data.data.story_comments : 0;
        this.storyImage =  data.data.story_image_cover ? data.data.story_image_cover : defImage;
        this.storyCategoryId = data.data.story_category;
        this.storyInMylibrary = data.data.story_in_library ? true : false;
        this.storyInMyLibraryId = data.data.story_in_library ? data.data.story_in_library : null;
        this.storyComplete = ( data.data.story_is_complete === '0' ) ? false : true;
        this.getSlideRecomendedStories(this.storyCategoryId, this.uid);
      }
    }, (error) => {
      this.loading = false;
    });
  }

  editStories() {
    this.navigateUrl.goTo('ustory/' + this.storyId + '/' + this.storySlug, {q: 'detail'});
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  getChapterListData(sid, slug, uid) {
    this.storiesService.getChaptersByStoryId(sid, slug, uid).subscribe((data: any) => {
      this.chapterListData = [];
      this.chapterNumberCounter = 1;
      if (data.status) {
        data.data.forEach(e => {
          this.chapterListData.push({
            id: e.chapter_id,
            order: this.chapterNumberCounter,
            title: e.chapter_title ? e.chapter_title : '-',
            slug: e.chapter_slug ? e.chapter_slug : '-',
            view: e.chapter_views,
            like: e.chapter_likes,
            comment: e.chapter_comments,
            draft: e.chapter_is_draft && e.chapter_is_draft === '1' ? true : false,
            publish: e.chapter_is_publish && e.chapter_is_publish === '1' ? true : false,
            publishDate: e.chapter_publish_date ? e.chapter_publish_date.replace(this.re, '/') : e.chapter_publish_date,
            lastUpdate: e.chapter_modified_date ? e.chapter_modified_date.replace(this.re, '/') : e.chapter_modified_date,
            lastRead: e.last_read ? true : false,
          });
          this.chapterNumberCounter++;
        });
      } else {
        this.chapterListData = null;
      }
    });
  }

  gotoLastRead() {
    if (this.chapterListData) {
      let isLastRead = this.chapterListData.find(e => e.lastRead === true);
      if (isLastRead) {
        this.navigateUrl.goTo('chapter/' + this.storyId + '/' + isLastRead.id + '/' + isLastRead.slug);
      } else {
        isLastRead = this.chapterListData[0];
        this.navigateUrl.goTo('chapter/' + this.storyId + '/' + isLastRead.id + '/' + isLastRead.slug);
      }
    }
  }

  doClickShare() {
    const dialogData = new ConfirmShareDialogModel(this.translate.transform('labelStoriesShare'), {
      copy: this.shareUrl,
      fb: this.shareFacebookUrl,
      tw: this.shareTwitterUrl,
      mwa: this.shareMWhatsappUrl,
      wa: this.shareWhatsappUrl,
    });
    const dialogRef = this.dialog.open(GwpShareDialogComponent, {
      maxWidth: '310px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.copied) {
        this.addAlert(this.translate.transform('linkCopied'));
      }
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }

  // sprint 4
  commendSend(item: any) {
    this.commentService.sendCommentStory(item.comment, item.parentId, this.storyId, this.storyAuthorId, this.uid, item.toWho ? item.toWho : '')
    .subscribe((response: any) => {
      if (response.status) {
        if (item.parentId === 0) {
          this.storyComments++;
        } else {
          this.idNotClosed = item.parentId;
        }
        this.getCommentStory(this.storyId, this.uid);
      }
    }, (error: any) => {
    });
  }

  // sprint 4
  eventEmit(item: any) {

    if (item.type === 1) {

      if (item.position === 1) {
        this.commentService.deleteCommentStory(
          item.commentId,
          this.storyId,
          this.uid
        ).subscribe((response: any) => {
          if (response.status) {
            this.storyComments--;
            this.getCommentStory(this.storyId, this.uid);
          }
        }, (error: any) => {
        });
      } else {
        this.commentService.deleteCommentChildStory(
          item.commentId,
          this.storyId,
          this.uid
        ).subscribe((response: any) => {
          if (response.status) {
            this.getCommentStory(this.storyId, this.uid);
          }
        }, (error: any) => {
        });
      }

    } else if (item.type === 2) {
      this.commentService.pinnedCommentStory(
        item.commentId,
        this.uid,
        this.storyId
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentStory(this.storyId, this.uid);
        }
      }, (error: any) => {
      });
    } else if (item.type === 3) {
      this.commentService.reportCommentStory(
        item.commentId,
        this.storyId,
        item.typeReport,
        this.uid
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentStory(this.storyId, this.uid);
        }
      }, (error: any) => {
      });
    } else {
      this.commentService.unPinnedCommentStory(
        item.commentId,
        this.uid,
        this.storyId
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentStory(this.storyId, this.uid);
        }
      }, (error: any) => {
      });
    }
  }

  // sprint 5
  getSlideRecomendedStories(catId: any, uid: any) {
    this.storiesService.getSectionRecomendedStories(catId, uid).subscribe((data: any) => {
      if (data.data) {
        data.data.forEach(e => {
          this.slidesRecomendedStories.push({
            id: e.story_id,
            uid: e.uid,
            src: e.story_image_cover ? e.story_image_cover : this.defaultImage,
            slug: e.story_slug,
            title: e.story_title,
            authorId: e.uusername,
            author: e.ufullname,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            complete: ( e.story_is_complete === '0' ) ? false : true
          });
        });
      }
    }, (error: any) => {
    });
  }

  clickViewAllRecomendation() {
    this.navigateUrl.goTo('recomendation');
  }

  // sprint 5
  // detailStory(story) {
  //   this.navigateUrl.goTo('story/' + story.id + '/' + story.slug);
  // }

  // detailAuthorClick(story) {
  //   this.navigateUrl.goTo('author/' + story.uid);
  // }

  // detailAuthor() {
  //   this.navigateUrl.goTo('author/' + this.storyAuthorId);
  // }

  // sprint 5
  getReportCommentType() {
    this.commentService.getReportCommentType().subscribe((data: any) => {
      if (data.status) {
        this.reportCommentType = data.data;
      }
    }, (error: any)  => {
    });
  }

  // sprint 6
  toFromMylibrary() {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    if (this.storyInMylibrary) {
      const sfId = [{'sf_id': this.storyInMyLibraryId}];
      this.myLibrary.removeFromMyLibrary(JSON.stringify(sfId), this.uid).subscribe((response: any) => {
        if (response.status) {
          this.storyInMylibrary = false;
          alert("Berhasil dihapus dari collection");
        }
      }, (error: any) => {
      });
    } else {
      this.myLibrary.addStoriesToMyLibrary(this.storyId , this.uid).subscribe((response: any) => {
        if (response.status) {
          this.storyInMylibrary = true;
          alert("Berhasil ditambahkan ke collection");
        }
      }, (error: any) => {
      });
    }
  }

  // sprint 10
  commentEditSend(data) {
    this.commentService.editCommentStory(data.comment, data.id, this.uid, this.storyId).subscribe((response: any) => {
      if (response.status) {
        if (data.parentId !== 0) {
          this.idNotClosed = data.parentId;
        }
        this.getCommentStory(this.storyId, this.uid);
      }
    }, (error: any) => {
    });
  }

  login() {
    jQuery('#loginModal').appendTo('body').modal('show');
    return;
  }
}
