import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MyLibraryService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getMyAllMyLibrary(page: any, limit: any, uid: any) {
    const params = '?page=' + page + '&limit=' + limit + '&uid=' + uid;
    return this.http.get(this.apiService.getMylibraryURL + params);
  }

  addStoriesToMyLibrary(sf_story_id: any, sf_uid: any) {
    const formData: FormData = new FormData();
    formData.append('sf_story_id', sf_story_id);
    formData.append('sf_uid', sf_uid);
    return this.http.post(this.apiService.addToMylibraryURL, formData);
  }

  removeFromMyLibrary(sf_id: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('sf_id', sf_id);
    formData.append('uid', uid);
    return this.http.post(this.apiService.removeFromMyLibraryURL, formData);
  }
}
