import { Component, OnInit, Input, AfterContentChecked, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from './../../../shared/pipe/translate.pipe';

@Component({
  selector: 'app-story-detail-table-content',
  templateUrl: './story-detail-table-content.component.html',
  styleUrls: ['./story-detail-table-content.component.css'],
  providers: [TranslatePipe],
})
export class StoryDetailTableContentComponent implements OnInit, AfterContentChecked {

  @Input() chapterList: any;
  @Input() storyId: string;

  chapterListData: any[];

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.chapterListData = this.chapterList ? this.chapterList : null;
  }

}
