import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'app-mat-snack-bar',
  templateUrl: './mat-snack-bar.component.html',
  styleUrls: ['./mat-snack-bar.component.css']
})
export class MatSnackBarComponent implements OnInit, OnDestroy {
  count = 0;

  constructor(
          public snackBar: MatSnackBar,
          @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  // this function will open up snackbar on top right position with custom background color (defined in css)
	openSnackBar(message: string, action: string, className: string) {
       
    let msg = this.snackBar.open(message, action, {
	      duration: 4000,
	      verticalPosition: 'top',
	      horizontalPosition: 'end',
	      panelClass: [className],
      });

      msg.afterDismissed().subscribe(() => {
        console.log('The snack-bar was dismissed');
      });
   
    }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.close(true);
  }

  getCount() {
    for (let i = 0; i < this.data.length; i++)
      if (this.data[i].notifyNumber > 0) this.count++;
  }

  close($event: any) {
    this.count = this.count - 1;
    if (this.count >= 1) $event.path[2].hidden = true; // target .toast-notification-component
    if (this.count === 0) this.snackBar.dismiss();
  }

}
