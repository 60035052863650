import { SeoService } from 'src/app/shared/service/seo.service';
import { AuthGuard } from 'src/app/auth.guard';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { PageService } from 'src/app/service/page.service';
import { Gtag } from 'angular-gtag';
import { environment } from 'src/environments/environment';

declare let jQuery: any;

@Component({
  selector: 'app-guidance',
  templateUrl: './guidance.component.html',
  styleUrls: ['./guidance.component.css'],
  providers: [TranslatePipe]
})
export class GuidanceComponent implements OnInit {

  auth: boolean;
  loading: boolean;

  tujuan: string;
  guidanceData: any[];

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private pageService: PageService,
    private seo: SeoService,
    private gtag: Gtag,
  ) {
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Guidance'
    });
  }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.getGuidanceData();
    this.seo.addMetaTag('Panduan');
  }

  getGuidanceData() {
    this.loading = true;
    this.pageService.getGuidance().subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.guidanceData = data.data;
        let i = 0;
        this.guidanceData.forEach(e => {
          i++;
          e.guidance_counter = i;
          e.guidance_question = e.guidance_question.replace(/\\/g, '');
          e.guidance_answer = e.guidance_answer.replace(/\\/g, '');
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }

  goToCreateStory () {
    if (this.auth) {
      this.navigateUrl.goTo('cstory');
    } else {
      jQuery('#loginModal').appendTo('body').modal('show');
    }
  }

}
