import { Component, OnInit, Inject, Output, EventEmitter, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import { ToolbarService, LinkService, ImageService, HtmlEditorService, CountService, ResizeService, QuickToolbarService, PasteCleanupService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/service/api.service';
import { StoriesService } from 'src/app/service/stories.service';

import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import { ImageHandler, Options } from 'ngx-quill-upload';
import { QuillEditorComponent } from 'ngx-quill';

Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/imageResize', ImageResize);

interface ImageSettingPath {
  saveUrl: string;
  path: string;
}

@Component({
  selector: 'app-create-story-content',
  templateUrl: './create-story-content.component.html',
  styleUrls: ['./create-story-content.component.css'],
  // tslint:disable-next-line: max-line-length
  providers: [TranslatePipe, ToolbarService, LinkService, ImageService, HtmlEditorService, CountService, ResizeService, QuickToolbarService, PasteCleanupService]
})
export class CreateStoryContentComponent implements OnInit, AfterViewInit {

  // @ViewChild('float', { static: true }) writingFloatObj: CheckBoxComponent;
  // @ViewChild('writingGWP', { static: true }) public writingObj: RichTextEditorComponent;
  @ViewChild('writingGWP', { static: false }) writingObj: QuillEditorComponent;

  @Output() checkChapterForm = new EventEmitter<any>(true);
  @Output() setTitle = new EventEmitter<any>(true);
  @Input() inAuthor = '';
  @Input() inChapterCounter = '01';
  @Input() inDataChapterForm: any;

  @Input() chapterPreview: boolean;

  chapterForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  screenWidth: number;
  wordsCount: number = 0;
  quillEditor: any;

  public quillStyles: object = {
    height: 'calc(100vh - 230px)'
  }
  public quillOptions: object = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['link', 'clean', 'image']
    ],
    imageHandler: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          let author = localStorage.getItem('uid');
          this.storiesService.uploadChapterImage(file, author).subscribe((data: any) => {
            if (data.status) {
              let img_url = `${this.apiService.uploadImageChapterURL}/${author}/${data.data.raname}`;
              resolve(img_url);
            }
          }, (error) => {
            console.error('Error', error);
            return reject(error);
          });
        });
      },
      accepts: ['png', 'jpg', 'jpeg']
    } as Options,
    imageResize: true,
    clipboard: {
      matchers: [
        ['pre', this.quillFilterTag],
        ['code', this.quillFilterTag]
      ]
    }
  }

  public textDescription: HTMLElement;
  public title = '';
  public description = ``;
  public tools: object = {
    enableFloating: true,
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'ClearFormat', 'Image', '|',
      'Undo', 'Redo']
    };
  public pasteCleanupSettings: object = {
    prompt: false,
    plainText: false,
    keepFormat: true,
    deniedTags: ['a', 'h1', 'h2', 'h3', 'h4', 'h5', 'button', 'img'],
    deniedAttrs: ['class', 'title', 'id'],
    allowedStyleProps: ['text-align', 'text-decoration']
  };
  public placeholder: String;
  public height = 400;
  public insertImageSettings: ImageSettingPath = {
    saveUrl: this.apiService.uploadImageChapterURL,
    path: this.apiService.uploadImageChapterURL
  };

  public onImageUploading = (args) => {
    // console.log('Upload', args);
  }

  public onImageUploadSuccess = (args: any) => {
    // console.log('Success', args);
    // console.log('Name', args.e.currentTarget.getResponseHeader('name'));
    if (args.e.currentTarget.getResponseHeader('name') != null) {
        args.file.name = args.e.currentTarget.getResponseHeader('name');
        const filename: any = document.querySelectorAll('.e-file-name')[0];
        filename.innerHTML = args.file.name.replace(document.querySelectorAll('.e-file-type')[0].innerHTML, '');
        filename.title = args.file.name;
    } else {
      const file = JSON.parse(args.e.currentTarget.response);
      const rnm = (file.data ? (file.data.raname ? file.data.raname : null) : null);
      args.file.name = rnm;
      const filename: any = document.querySelectorAll('.e-file-name')[0];
      filename.innerHTML = args.file.name.replace(document.querySelectorAll('.e-file-type')[0].innerHTML, '');
      filename.title = args.file.name;
    }
  }

  public onImageRemoving = (args) => {
    // console.log('Remove', args);
  }

  constructor(
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    public authGuard: AuthGuard,
    public sanitizer: DomSanitizer,
    private apiService: ApiService,
    private translate: TranslatePipe,
    private storiesService: StoriesService
  ) {
    this.placeholder = this.translate.transform('placeholderWriteYourStory');
    this.getScreenSize();
    if (this.screenWidth <= 480) {
      this.height = 200;
    }
  }

  ngOnInit() {
    this.insertImageSettings.saveUrl = this.insertImageSettings.saveUrl + '/' + this.inAuthor;
    this.insertImageSettings.path = this.insertImageSettings.path + '/' + this.inAuthor + '/';

    if (this.inDataChapterForm && this.inDataChapterForm.title) {
      this.title = this.inDataChapterForm.title;
    }

    (async () => {
      // Do something before delay
      // console.log('before delay');

      await this.delay(300);
      if (this.inDataChapterForm && this.inDataChapterForm.description) {
        this.description = this.inDataChapterForm.description;

        this.writingObj.quillEditor.clipboard.dangerouslyPasteHTML(this.description);
        this.countWords(this.writingObj.quillEditor.getText());
      }

      // Do something after
      // console.log('after delay');
    })();

    this.chapterForm = this.formBuilder.group({
      id: [(this.inDataChapterForm && this.inDataChapterForm.id ? this.inDataChapterForm.id : '')],
      title: [this.title, Validators.compose([Validators.maxLength(250)])],
      description: [this.description]
    });
  }

  ngAfterViewInit() {
    // const writingObj: RichTextEditorComponent = this.writingObj;
    // setTimeout(() => {
    //   // tslint:disable-next-line: deprecation
    //   this.textDescription = writingObj.contentModule.getEditPanel() as HTMLElement;
    // }, 200);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  get form() {
    return this.chapterForm.controls;
  }

  setChapterTitle() {
    const titleValue: string = this.chapterForm.controls.title.value;
    const data = {
      title: titleValue
    };
    this.setTitle.emit(data);
  }

  checkValidForm() {
    if (!this.chapterForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  checkFormFilled() {
    let data: any;
    this.chapterForm.controls.title.setValue(this.title);
    this.chapterForm.controls.description.setValue(this.description);
    this.validForm = this.checkValidForm();
    if (!this.validForm) {
      data = {
        formValid: true,
        data: this.chapterForm.value
      };
    } else {
      data = {
        formValid: false
      };
    }

    this.checkChapterForm.emit(data);
  }

  countWords(text: string) {
    text = text.replace(/(<([^>]+)>)/ig, " ");
    text = text.replace(/\s/g,' ');

    let text_arr = text.split(' ');
    text_arr = text_arr.filter(function(e){ return e });
    this.wordsCount = text_arr.length;
  }

  onCreate(evt) {
    // const instance: any = this.writingObj;
    // this.writingObj.element.focus();
    // instance.contentModule.getDocument().addEventListener('keydown', function(e: any): void {
    //   if (e.key === 's' && e.ctrlKey === true) {
    //       e.preventDefault(); // to prevent default ctrl+s action
    //       instance.updateValue(); // to update the value after editing
    //   }
    // });
  }

  trustPreviewChapter() {
    return this.sanitizer.bypassSecurityTrustHtml(this.description);
    // return this.sanitizer.bypassSecurityTrustUrl(this.description);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  quillOnContentUpdate(evt: any){
    let quill = evt.editor;

    if (evt.source === 'user'){
      this.countWords(quill.getText().trim());
      this.description = this.writingObj.quillEditor.root.innerHTML;
      this.checkFormFilled();
    }
  }

  quillFilterTag(node: Node, delta: any){
    let ops = [];
    delta.ops.forEach(op => {
      if (op.insert && typeof op.insert === 'string') {
        ops.push({
          insert: op.insert
        });
      }
    });

    delta.ops = ops;
    return delta;
  }
}
