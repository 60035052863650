import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberingChapter'
})
export class NumberingChapterPipe implements PipeTransform {

  transform(value: number): any {
    if (!value) { return '' + value; }
    if (value < 10) {
      return '0' + value;
    }
    return '' + value;
  }
}
