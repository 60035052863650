import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../model/category.model';
import { ApiService } from './api.service';

@Injectable()
export class CategoryService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  // Sprint 2
  getCategory() {
    return this.http.get<Category[]>(this.apiService.getCategoryURL);
  }

}
