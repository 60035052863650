import { Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-selectsize',
  templateUrl: './selectsize.component.html',
  styleUrls: ['./selectsize.component.css']
})
export class SelectsizeComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<any>();

  private _data: any;
  private _checkable: any;

  public selected = [];
  selectForm: FormGroup;
  valid = true;


  constructor(
    private formBuilder: FormBuilder
  ) {
    this.selectForm = this.formBuilder.group({
        isian: ['', Validators.required],
    });
  }

  get isian() {
    return this.selectForm.controls;
  }

  submitForm() {

  }

  @Input()
  set data(param: any) {
    this._data = param;
  }

  @Input()
  set checkable(param: any) {
    if (param) {
      console.log(param);
      this.selected = param;
    }
  }

  get data(): any {
    return this._data;
  }


  ngOnInit() {

  }

  getValues() {
    (this.selected.length < 1) ? this.valid = false : this.valid = true;
    this.messageEvent.emit(this.selected);
  }
}
