import { SeoService } from 'src/app/shared/service/seo.service';
import { CategoryService } from 'src/app/service/category.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { ActivatedRoute } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
  providers: [TranslatePipe]
})
export class CategoryComponent implements OnInit {

  loading: boolean;
  isParam: boolean;
  activePage = 1;
  limit = 10;
  totalData = 0;
  noData: boolean;
  storyList: any[] = [];
  defaultImage: string;
  defaultGenreImageMap: Map<string, string>;

  orderingOptions = [{id: 'asc', text: 'labelOrderByAsc'},
                    {id: 'desc', text: 'labelOrderByDesc'},
                    {id: 'newest', text: 'labelOrderByNewest'},
                    {id: 'oldest', text: 'labelOrderByOldest'},
                    {id: 'popular', text: 'lableOrderByPopular'}];
  orderingActiveId = 'popular';
  orderingActiveText = 'lableOrderByPopular';
  categoryList: any[] = [{id: 0, text: 'labelAll', slug: 'all'}];
  categoryActiveId = 'all';
  categoryActiveText = 'labelAll';

  mobileGenreList: boolean;
  mobileSortList: boolean;
  re = /\-/gi;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private storiesService: StoriesService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Genre');
    this.getCategoryList();
    this.defaultImage = environment.imageStoryDefaultV2;
    this.defaultGenreImageMap = new Map<string, string>();
    // this.defaultGenreImageMap.set("26", environment.imageThrillerDefault);
    // this.defaultGenreImageMap.set("100", environment.imageFictionDefault);
    this.defaultGenreImageMap.set("26", environment.imageStoryDefaultV2);
    this.defaultGenreImageMap.set("100", environment.imageStoryDefaultV2);

    if (localStorage.getItem('genreLastOrderOption')) {
      const selectedOrder = this.orderingOptions.find(x => x.id === localStorage.getItem('genreLastOrderOption'));
      this.orderingActiveId = selectedOrder.id;
      this.orderingActiveText = selectedOrder.text;
    }

    this.route.params.subscribe( params => {
      if (params && params['page'] && params['cat']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
        this.categoryActiveId = params ? (params['cat'] ? params['cat'] : '') : '';
        const selectedCategory = this.categoryList.find(x => x.slug === this.categoryActiveId);
        this.categoryActiveText = selectedCategory ? selectedCategory.text : '';
      } else if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
        this.categoryActiveId = '';
      } else if (params && params['cat']) {
        this.isParam = true;
        this.activePage = 1;
        this.categoryActiveId = params ? (params['cat'] ? params['cat'] : '') : '';
        const selectedCategory = this.categoryList.find(x => x.slug === this.categoryActiveId);
        this.categoryActiveText = selectedCategory ? selectedCategory.text : '';
      }

      if (this.isParam) {
        this.getAllStories();
      }
    });

    if (!this.isParam) {
      this.getAllStories();
    }

  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      if (data.data) {
        data.data.forEach(e => {
          if (e.cat_related_module && e.cat_related_module.includes('categories')) {
            this.categoryList.push({
              id: e.cat_id,
              text: e.cat_title,
              slug: e.cat_slug
            });
            // Sometimes this service late, so categorize name not appear
            if (this.categoryActiveId && (this.categoryActiveId === e.cat_slug)) {
              this.categoryActiveText = e.cat_title;
            }
          }
        });
      }
    }, (error) => {
    });
  }

  getAllStories () {
    this.loading = true;
    this.storiesService.getAllStories(this.activePage, this.limit, this.orderingActiveId, this.categoryActiveId).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.storyList = [];
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.storyList.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : defImage),
            title: e.story_title ? e.story_title : '-',
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            synopsis: e.story_description ? e.story_description : '-',
            lastUpdate: e.story_modified_date ? e.story_modified_date.replace(this.re, '/') : e.story_modified_date,
            complete: ( e.story_is_complete === '0' ) ? false : true
          });
        });
        this.totalData = data.totalData;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  changeOrder(evt) {
    this.mobileGenreList = false;
    this.mobileSortList = false;
    const selectedOrder = this.orderingOptions.find(x => x.id === evt.id);
    this.orderingActiveId = selectedOrder.id;
    this.orderingActiveText = selectedOrder.text;
    localStorage.setItem('genreLastOrderOption', selectedOrder.id);
    this.getAllStories();
  }

  onChangePage(page) {
    this.activePage = page;
    if (this.categoryActiveId && this.categoryActiveId !== undefined) {
      this.navigateUrl.goTo('genre/p/' + this.activePage + '/c/' + this.categoryActiveId);
    } else {
      this.navigateUrl.goTo('genre/p/' + this.activePage);
    }
  }

  changeCategory(evt) {
    this.mobileGenreList = false;
    this.mobileSortList = false;
    if (this.categoryActiveId === evt.slug) {
      return;
    } else {
      const selectedCategory = this.categoryList.find(x => x.slug === evt.slug);
      this.categoryActiveId = selectedCategory ? selectedCategory.slug  : evt.slug;
      this.categoryActiveText = selectedCategory ? selectedCategory.text : '';
      this.activePage = 1;
      this.getAllStories();
    }
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  openMobileGenreList() {
    this.mobileGenreList = true;
  }
  closeMobileGenreList() {
    this.mobileGenreList = false;
  }

  openMobileSortList() {
    this.mobileSortList = true;
  }
  closeMobileSortList() {
    this.mobileSortList = false;
  }

}
