export const environment = {
  production: false,
  homeUrl: 'http://localhost:4200',
  // apiUrl: 'http://localhost/gwp-api/index.php/',
  apiUrl: 'https://staging.gwp.id/api/',
  imageStoryDefault: 'assets/images/default/story-cover-default.png',
  imageStoryDefaultV2: 'assets/images/default/story-cover-default-v2.png',
  imageThrillerDefault: 'assets/images/default/thriller-cover-default.png',
  imageFictionDefault: 'assets/images/default/fiction-cover-default.png',
  imageCreateStoryDefault: 'assets/images/default/create-story-cover-default.jpg',
  imageCoverDefault: 'assets/images/default/no-cover-picture.png',
  imageAuthorDefault: 'assets/images/default/no-display-picture.png',
  imageCategoryDefautl: 'assets/images/default/category-bg-default.jpg',
  imageEventDefault: 'assets/images/default/event-default.png',
  imagePageDefault: 'assets/images/guidance-default-img.jpg',
  imagePath: '/images-data/',
  imageStoryPath: 'img-stories/',
  imageAuthorPath: 'img-author/',
  imageEventPath: 'img-event/',
  imagePagePath: 'img-pages/',
  imagesPublishedStoryPath: 'img-publish/',
  imageNoFollower: 'assets/images/default/no-follower.png',
  imageNoFollowing: 'assets/images/default/no-following.png',
  imageNoCategories: 'assets/images/default/no-categories.png',
  imageNoCollection: 'assets/images/default/no-collection-1.png',
  imageNoEvent: 'assets/images/default/no-event.png',
  imageNoNotification: 'assets/images/default/no-notification-1.png',
  imageNoContinueReading: 'assets/images/default/no-continue-reading.png',
  imageNoMyStories: 'assets/images/default/no-workstories-1.png',
  imageNoStories: 'assets/images/default/no-stories.png',
  siteKeyCaptcha: '6LcOwrYUAAAAALM94xXZKjCfJra5f99sxrDOujhU',
  midtransClientKeyDev: 'SB-Mid-server-J58r3SBeGGVCmdFQ1i9LSqOz',
  midtransClientKeyProd: 'Mid-client-pLzj59SiyiEZuAbt',
  midtransServerKeyDev: '',
  midtransServerKeyProd: '',
  googleClientId: '1042815718101-gd3t78jamtgqeahgq5i8uieokumfjbs3.apps.googleusercontent.com',
  googleAnalyticsMeasurementID: 'G-H9G51N39QP',
  envName: 'development',
  iconShare: 'assets/images/icon/gwpicon-share.png',
  iconCollection: 'assets/images/icon/gwpicon-collection.png',
  iconThreeDots: 'assets/images/icon/gwpicon-three-dots.png',
};
