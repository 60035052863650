import { SeoService } from 'src/app/shared/service/seo.service';
import { User } from './../../model/users.model';
import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { AuthGuard } from 'src/app/auth.guard';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { SomethingChangeService } from 'src/app/service/something-change.service';
import { ImageTransform, ImageCroppedEvent, base64ToFile, Dimensions } from 'ngx-image-cropper';
import { SettingService } from 'src/app/service/setting.service';

declare let jQuery: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [TranslatePipe]
})
export class ProfileComponent implements OnInit, OnDestroy {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  auth: boolean;
  loading: boolean;
  loadingProfile: boolean;
  uid: string;
  myUid: string;

  defaultCoverImage: string;
  defaultProfileImage: string;
  defaultStoryImage: string;
  coverImage: any;
  profileImage: any;
  user: User;

  loadingButton: boolean;
  typeButton: any = 1;

  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;

  countStory = 0;
  countFollowing = 0;
  countFollower = 0;

  tabActive = 1;
  modalEditProfile: boolean;

  navigationSubscription;

  pictureChangedEvent: any = '';
  croppedPicture: any = '';
  canvasPictureRotation = 0;
  rotationPicture = 0;
  scalePicture = 1;
  showCropperPicture = false;
  containWithinAspectRatioPicture = false;
  transformPicture: ImageTransform = {};

  coverChangedEvent: any = '';
  croppedCover: any = '';
  canvasCoverRotation = 0;
  rotationCover = 0;
  scaleCover = 1;
  showCropperCover = false;
  containWithinAspectRatioCover = false;
  transformCover: ImageTransform = {};

  constructor(
    private route: ActivatedRoute,
    public authGuard: AuthGuard,
    private translate: TranslatePipe,
    private navigateUrl: GoToService,
    private userService: UserService,
    private router: Router,
    private alertMassage: AlertMassageService,
    private renderer: Renderer2,
    private somethingChange: SomethingChangeService,
    private seo: SeoService,
    private settingService: SettingService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    this.seo.addMetaTag('Profile Saya');
  }

  initialiseInvites() {
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.myUid = localStorage.getItem('uid');
    }

    this.route.params.subscribe(params => {
      if (!params['author']) {
        this.typeButton = 1; // Edit profile button
        this.uid = localStorage.getItem('uid');
      } else {
        if (localStorage.getItem('uid') === params['author']) {
          this.typeButton = 1; // Edit profile button
          this.uid = localStorage.getItem('uid');
        } else {
          this.typeButton = 2; // Follow button
          this.uid = params['author'];
        }
      }
    });

    this.defaultCoverImage = environment.imageCoverDefault;
    this.defaultProfileImage = environment.imageAuthorDefault;
    this.defaultStoryImage = environment.imageStoryDefault;
    this.coverImage = this.defaultCoverImage;
    this.profileImage = this.defaultProfileImage;
    this.getProfileData(this.myUid, this.uid);
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  changeTabCounter(event) {
    if (event === 'following') {
      this.tabActive = 3;
    } else if (event === 'follower') {
      this.tabActive = 2;
    } else {
      this.tabActive = 1;
    }
  }

  changeTabFollow(event) {
    this.tabActive = event.active;
  }

  getProfileData(myUid, uid) {
    this.loadingProfile = true;
    this.userService.getUserById(myUid, uid).subscribe((data: any) => {
      this.loadingProfile = false;
      this.user = data.data;
      this.coverImage = this.user.ucover_image ? this.user.ucover_image : this.defaultCoverImage;
      this.profileImage = this.user.uimage;
      localStorage.setItem('profile_image', this.profileImage);

      if (this.user.counter && this.user.counter.followed) {
        this.typeButton = 3; // Unfollow button
      }

      this.facebookUrl = this.user.ufacebook_id ? this.user.ufacebook_id : null;
      this.twitterUrl = this.user.utwitter_id ? this.user.utwitter_id : null;
      this.instagramUrl = this.user.uinstagram_id ? this.user.uinstagram_id : null;

      this.countStory = this.user.counter ? (this.user.counter.story ? this.user.counter.story : 0) : 0;
      this.countFollower = this.user.counter ? (this.user.counter.follower ? this.user.counter.follower : 0) : 0;
      this.countFollowing = this.user.counter ? (this.user.counter.following ? this.user.counter.following : 0) : 0;
    }, (error: any) => {
      this.loadingProfile = false;
    });
  }

  onActionFollow(action) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    this.loadingButton = true;
    this.userService.followUser(this.myUid, this.uid, action).then((data: any) => {
      this.loadingButton = false;
      if (data.status) {
        if (action === 'follow') {
          this.typeButton = 3;
          this.countFollower = this.countFollower + 1;
        } else if (action === 'unfollow') {
          this.typeButton = 2;
          this.countFollower = this.countFollower - 1;
        }
      }
    }).catch((error: any) => {
      this.loadingButton = false;
      console.log(error);
    });
  }

  onActionFollowTab(event) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (event && event.status) {
      if (event.action === 'follow' && this.myUid === this.uid) {
        this.countFollowing = this.countFollowing + 1;
      } else if (event.action === 'unfollow' && this.myUid === this.uid) {
        this.countFollowing = this.countFollowing - 1;
      }
    }
  }

  editProfile(event) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    this.modalEditProfile = true;
    jQuery('#editProfileModal').appendTo('body').modal({
      backdrop: 'static',
      // keyboard: false
    });
  }

  saveProfileData(event) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (event) {
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      const errorMessage = this.alertMassage.show(event.response, event.message);
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', errorMessage);
      setTimeout(() => {
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
      }, 5000);
      if (event.response && event.response === 'success') {
        this.somethingChange.sendMessage('editprofile', 'changeData');
        this.closeEditProfile(event);
        this.initialiseInvites();
      }
    }
  }

  closeEditProfile($event) {
    this.modalEditProfile = false;
    jQuery('#editProfileModal').modal('hide');
    this.getProfileData(this.myUid, this.uid);
  }

  setDefaultProfile() {
    this.profileImage = null;
  }

  setDefaultCover() {
    this.coverImage = this.defaultCoverImage;
  }

  // Image Profile Change Setting
  fileChangeEvent(event: any): void {
    this.modalEditProfile = false;
    jQuery('#editProfileModal').modal('hide');
    jQuery('#editProfilePictureModal').appendTo('body').modal({
      backdrop: 'static'
    });
    this.pictureChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedPicture = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropperPicture = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasPictureRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasPictureRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transformPicture.flipH;
    const flippedV = this.transformPicture.flipV;
    this.transformPicture = {
      ...this.transformPicture,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transformPicture = {
      ...this.transformPicture,
      flipH: !this.transformPicture.flipH
    };
  }

  flipVertical() {
    this.transformPicture = {
      ...this.transformPicture,
      flipV: !this.transformPicture.flipV
    };
  }

  resetImage() {
    this.scalePicture = 1;
    this.rotationPicture = 0;
    this.canvasPictureRotation = 0;
    this.transformPicture = {};
  }

  zoomOut() {
    this.scalePicture -= .1;
    this.transformPicture = {
      ...this.transformPicture,
      scale: this.scalePicture
    };
  }

  zoomIn() {
    this.scalePicture += .1;
    this.transformPicture = {
      ...this.transformPicture,
      scale: this.scalePicture
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatioPicture = !this.containWithinAspectRatioPicture;
  }

  updateRotation() {
    this.transformPicture = {
      ...this.transformPicture,
      rotate: this.rotationPicture
    };
  }

  saveProfilPicture() {
    // console.log('Image', this.croppedPicture);
    this.loading = true;
    this.settingService.changeProfilePicture(this.uid, this.croppedPicture).subscribe((data: any) => {
      this.loading = false;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      let errorMessage: any;
      if (data.status) {
        errorMessage = this.alertMassage.show('success', 'Gambar profile berhasil diubah.');
        this.user.uimage = this.croppedPicture;
        this.profileImage = this.croppedPicture;
        localStorage.setItem('profile_image', this.croppedPicture);
        this.somethingChange.sendMessage('editprofilepict', 'changeData');
        jQuery('#editProfilePictureModal').modal('hide');
      } else {
        errorMessage = this.alertMassage.show('failed', 'Gambar profile gagal diubah.');
      }
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', errorMessage);
      setTimeout(() => {
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
      }, 5000);
    }, (error: any) => {
      this.loading = false;
    }, () => {
    });
  }

  // Cover Profile Change Setting
  fileCoverChangeEvent(event: any): void {
    this.modalEditProfile = false;
    jQuery('#editProfileModal').modal('hide');
    jQuery('#editProfileCoverModal').appendTo('body').modal({
      backdrop: 'static'
    });
    this.coverChangedEvent = event;
  }

  imageCoverCropped(event: ImageCroppedEvent) {
    this.croppedCover = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }

  imageCoverLoaded() {
    this.showCropperCover = true;
    // console.log('Image loaded');
  }

  cropperCoverReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageCoverFailed() {
    // console.log('Load failed');
  }

  rotateCoverLeft() {
    this.canvasCoverRotation--;
    this.flipCoverAfterRotate();
  }

  rotateCoverRight() {
    this.canvasPictureRotation++;
    this.flipCoverAfterRotate();
  }

  private flipCoverAfterRotate() {
    const flippedH = this.transformCover.flipH;
    const flippedV = this.transformCover.flipV;
    this.transformCover = {
      ...this.transformCover,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipCoverHorizontal() {
    this.transformCover = {
      ...this.transformCover,
      flipH: !this.transformCover.flipH
    };
  }

  flipCoverVertical() {
    this.transformCover = {
      ...this.transformCover,
      flipV: !this.transformCover.flipV
    };
  }

  resetImageCover() {
    this.scaleCover = 1;
    this.rotationCover = 0;
    this.canvasCoverRotation = 0;
    this.transformCover = {};
  }

  zoomOutCover() {
    this.scaleCover -= .1;
    this.transformCover = {
      ...this.transformCover,
      scale: this.scaleCover
    };
  }

  zoomInCover() {
    this.scaleCover += .1;
    this.transformCover = {
      ...this.transformCover,
      scale: this.scaleCover
    };
  }

  toggleCoverContainWithinAspectRatio() {
    this.containWithinAspectRatioCover = !this.containWithinAspectRatioCover;
  }

  updateCoverRotation() {
    this.transformCover = {
      ...this.transformCover,
      rotate: this.rotationCover
    };
  }

  saveProfilCover() {
    this.loading = true;
    this.settingService.changeProfileCover(this.uid, this.croppedCover).subscribe((data: any) => {
      this.loading = false;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      let errorMessage: any;
      if (data.status) {
        errorMessage = this.alertMassage.show('success', 'Gambar cover berhasil diubah.');
        this.user.ucover_image = this.croppedCover;
        this.coverImage = this.croppedCover;
        jQuery('#editProfileCoverModal').modal('hide');
      } else {
        errorMessage = this.alertMassage.show('failed', 'Gambar cover gagal diubah.');
      }
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', errorMessage);
      setTimeout(() => {
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
      }, 5000);
    }, (error: any) => {
      this.loading = false;
    }, () => {
    });
  }

}
