import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(value: string, args: string): any {
    if (!value) { return value; }
    let breakTag = '<br>';
    if (args && args === 'blank') {
      breakTag = '';
    } else if (args && args === 'space') {
      breakTag = ' ';
    }

    value = (value + '').replace(/\\'/g, '\'');
    value = (value + '').replace(/\\"/g, '"');

    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n|\\r|\\n)/g, breakTag);
  }

}
