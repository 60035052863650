import { NumberingChapterPipe } from './../pipe/numbering-chapter.pipe';
import { CanonicalService } from './../service/canonical.service';
import { SeoService } from './../service/seo.service';
import { SafeHtmlPipe } from './../pipe/safe-html.pipe';
import { GwpPaginationComponent } from './../component/gwp-pagination/gwp-pagination.component';
import { PaginationComponent } from './../component/pagination/pagination.component';
import { ReadMoreComponent } from './../component/read-more/read-more.component';
import { ShortNumberPipe } from './../pipe/short-number.pipe';
import { AlertMassageService } from './../service/alert-massage.service';
import { InputFilterService } from './../service/input-filter.service';
import { LangService } from './../service/lang.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LocalStorageService } from '../service/local-storage.service';
import { TranslatePipe } from '../pipe/translate.pipe';
import { GoToService } from '../service/go-to.service';
import { TruncatePipe } from '../pipe/truncate.pipe';
import { InitialStringPipe } from '../pipe/initial-string.pipe';

import { FormsModule } from '@angular/forms';

// sprint 3
import { SearchExpandComponent } from './../component/user-search/search-expand/search-expand.component';
import { UserSearchComponent } from './../component/user-search/user-search.component';


// sprint 4
import { StripHtmlPipe } from '../pipe/strip-html.pipe';
import { TimeAgoPipe } from '../pipe/time-ago.pipe';
import { GwpDialogComponent } from './../component/gwp-dialog/gwp-dialog.component';
import { HighlightSearchPipe } from '../pipe/highlight-search.pipe';
import { HighlightBoldPipe } from '../pipe/highlight-bold.pipe';
import { Nl2brPipe } from '../pipe/nl2br.pipe';
import { GwpShareDialogComponent } from '../component/gwp-share-dialog/gwp-share-dialog.component';


// sprint 14
import { GwpSharePublishComponent } from '../component/gwp-share-publish/gwp-share-publish.component';
import { AlertModule } from 'ngx-bootstrap/alert';

/**
 * Created by samuel on 24/01/2020.
 */

@NgModule({
    declarations: [
        SafeHtmlPipe,
        TranslatePipe,
        ShortNumberPipe,
        TruncatePipe,
        ReadMoreComponent,
        PaginationComponent,
        GwpPaginationComponent,
        SearchExpandComponent,
        UserSearchComponent,
        StripHtmlPipe,
        TimeAgoPipe,
        HighlightSearchPipe,
        HighlightBoldPipe,
        GwpDialogComponent,
        GwpShareDialogComponent,
        Nl2brPipe,
        NumberingChapterPipe,
        InitialStringPipe,
        GwpSharePublishComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        AlertModule.forRoot(),
    ],
    entryComponents: [GwpDialogComponent, GwpShareDialogComponent, GwpSharePublishComponent],
    exports: [
        SafeHtmlPipe,
        TranslatePipe,
        CommonModule,
        RouterModule,
        ShortNumberPipe,
        TruncatePipe,
        ReadMoreComponent,
        PaginationComponent,
        GwpPaginationComponent,
        SearchExpandComponent,
        UserSearchComponent,
        StripHtmlPipe,
        TimeAgoPipe,
        HighlightSearchPipe,
        HighlightBoldPipe,
        GwpDialogComponent,
        GwpShareDialogComponent,
        Nl2brPipe,
        NumberingChapterPipe,
        InitialStringPipe,
        GwpSharePublishComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                LocalStorageService,
                LangService,
                GoToService,
                InputFilterService,
                AlertMassageService,
                SeoService,
                CanonicalService
            ]
        };
    }
}
