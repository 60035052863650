import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
//import * as Rollbar from 'rollbar';
//import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarComponent } from "./component/mat-snack-bar/mat-snack-bar.component";

export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(){

    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        /*if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        request = request.clone({ headers: request.headers.set('Freaky', 'Jolly') });*/
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                console.log("HAHAHA");
                console.log(error.status);
              if (error.status === 401) {
                // refresh token
              }else if(error.status === 0){
                //this.snackBar.openSnackBar("No response from server",'X','red-snackbar');
                return throwError("No response from server");
              } else {
                return throwError(error);
              }
            })
          );    

        /*return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                    }
                    console.log(1111111111111111111111111);
                    console.log(errorMessage);
                    //this.snackBar.open(errorMessage, 'X', {panelClass: ['error']});
                   // window.alert(errorMessage);
                    return throwError(errorMessage);
                })
            )*/
    }
}

