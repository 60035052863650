import { SeoService } from 'src/app/shared/service/seo.service';
import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { AlertMassageService } from './../../shared/service/alert-massage.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { APP_CONFIG } from './../../shared/model/application.properties';
import { GoToService } from './../../shared/service/go-to.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [TranslatePipe]
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  logo: String = '';
  projectName: String = '';
  changePasswordForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  errorMessage = '';

  mEncode: string;
  passwordSuccess = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private alertMassage: AlertMassageService,
    private translate: TranslatePipe,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private seo: SeoService
  ) {
    this.changePasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')]],
      rePassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')]]
    }, {
      validator: this.checkPasswords
    });
  }

  ngOnInit() {
    this.logo = this.appConfig.logo;
    this.projectName = this.appConfig.appFullName;
    this.mEncode = this.route.snapshot.queryParamMap.get('m');
    this.changePasswordForm.controls.email.setValue(this.mEncode);
    this.changePasswordForm.controls.email.updateValueAndValidity();
    this.seo.addMetaTag('Perbarui Sandi');
  }

  get form() {
    return this.changePasswordForm.controls;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.rePassword.value;
    return pass === confirmPass ? null : { doesMatchPassword: true };
  }

  doSubmit() {
    this.validForm = this.checkValidForm();
    this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
    if (!this.validForm) {
      const user = {
        email: this.mEncode,
        password: btoa(this.changePasswordForm.value.password)
      };
      this.passwordSuccess = false;
      this.loading = true;
      this.loginService.updatePassword(user).subscribe((data: any) => {
          this.passwordSuccess = true;
          this.loading = false;
          if (data && data.message) {
            this.errorMessage = this.alertMassage.show(data.message.s, this.translate.transform(data.message.m));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
          }
        }, () => {
          this.passwordSuccess = true;
          this.loading = false;
        }
      );
    }
  }

  checkValidForm() {
    if (!this.changePasswordForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.changePasswordForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}

}
