import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { PageService } from 'src/app/service/page.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css'],
  providers: [TranslatePipe]
})
export class DisclaimerComponent implements OnInit {
  loading: boolean;

  disclaimerData: any[];

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private pageService: PageService,
    private seo: SeoService
  ) {}

  ngOnInit() {
      this.getDisclaimerData();
      this.seo.addMetaTag('Disclaimer');
  }

  getDisclaimerData() {
    this.loading = true;
    this.pageService.getDisclaimer().subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.disclaimerData = data.data;
        this.disclaimerData.forEach(e => {
          e.disclaimer_title = e.disclaimer_title.replace(/\\/g, '');
          e.disclaimer_content = e.disclaimer_content.replace(/\\/g, '');
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }

}
