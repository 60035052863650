import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Posts } from '../model/blog.model';
import { ApiService } from './api.service';

@Injectable()
export class BlogService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getPosts() {
    let blogURL = "/feed/json";

    return this.http.get<Posts[]>(blogURL, {
      headers: {
        'Accept': 'application/json',
        'rejectUnauthorized': 'false'
      }
    });
  }

}
