import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Activity } from '../model/activity.model';
import { ApiService } from './api.service';

@Injectable()

export class ActivityUserService {

  constructor(private location: Location, private http: HttpClient, private apiService: ApiService) { }

  getLikeStoryById(uid: string, storyId: string) {
    return this.http.get<Activity>(this.apiService.getLikeStoryByIdURL + uid + '/' + storyId);
  }

  getLikeChapterById(uid: string, storyId: number, chapterId: string) {
    return this.http.get<Activity>(this.apiService.getLikeChapterByIdURL + uid + '/' + storyId + '/' + chapterId);
  }

  likeStory(uid: string, storyId: string, authorId: string) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('authorId', authorId);

    return this.http.post<Activity>(this.apiService.likeStoryURL, formData);
  }

  unlikeStory(uid: string, storyId: string, authorId: string) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('authorId', authorId);

    return this.http.post<Activity>(this.apiService.unlikeStoryURL, formData);
  }

  likeChapter(uid: string, storyId: string, chapterId: string, authorId: string) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    formData.append('authorId', authorId);

    return this.http.post<Activity>(this.apiService.likeChapterURL, formData);
  }

  unlikeChapter(uid: string, storyId: string, chapterId: string, authorId: string) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    formData.append('authorId', authorId);

    return this.http.post<Activity>(this.apiService.unlikeChapterURL, formData);
  }

  likeCommentStory(commentId: string, uid: string) {
    const formData: FormData = new FormData();
    formData.append('commentId', commentId);
    formData.append('uid', uid);

    return this.http.post<Activity>(this.apiService.likeCommentStoryURL, formData);
  }

  unlikeCommentStory(commentId: string, uid: string) {
    const formData: FormData = new FormData();
    formData.append('commentId', commentId);
    formData.append('uid', uid);

    return this.http.post<Activity>(this.apiService.unlikeCommentStoryURL, formData);
  }

  likeCommentChapter(ccommentId: string, uid: string) {
    const formData: FormData = new FormData();
    formData.append('ccommentId', ccommentId);
    formData.append('uid', uid);

    return this.http.post<Activity>(this.apiService.likeCommentChapterURL, formData);
  }

  unlikeCommentChapter(ccommentId: string, uid: string) {
    const formData: FormData = new FormData();
    formData.append('ccommentId', ccommentId);
    formData.append('uid', uid);

    return this.http.post<Activity>(this.apiService.unlikeCommentChapterURL, formData);
  }

  storyView(storyId: string) {
    return this.http.get<Activity>(this.apiService.storyViewURL + storyId);
  }

  storyViewLog(uid: string, storyId: string) {
    return this.http.get<Activity>(this.apiService.storyViewLogURL + uid + '/' + storyId);
  }

  chapterViewLog(uid: string, storyId: string, chapterId: string) {
    return this.http.get<Activity>(this.apiService.chapterViewLogURL + uid + '/' + storyId + '/' + chapterId);
  }

  addCommentStory(commentText: string, uid: string, storyId: string) {
    const formData: FormData = new FormData();
    formData.append('commentText', commentText);
    formData.append('uid', uid);
    formData.append('storyId', storyId);

    return this.http.post<Activity>(this.apiService.addCommentStoryURL, formData);
  }

  addCommentChapter(commentChapterText: string, uid: string, storyId: string, chapterId: string) {
    const formData: FormData = new FormData();
    formData.append('commentChapterText', commentChapterText);
    formData.append('uid', uid);
    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);

    return this.http.post<Activity>(this.apiService.addCommentChapterURL, formData);
  }

  reportCommentStory(commentId: string) {
    return this.http.get<Activity>(this.apiService.reportCommentStoryURL + commentId);
  }

  reportCommentChapter(commentId: string) {
    return this.http.get<Activity>(this.apiService.reportCommentChapterURL + commentId);
  }

  getlikeCommentStory(commentId: string, uid: string) {
    return this.http.get<Activity>(this.apiService.getlikeCommentStoryURL + commentId + '/' + uid);
  }

  getlikeCommentChapter(ccommentId: string, uid: string) {
    return this.http.get<Activity>(this.apiService.getlikeCommentChapterURL + ccommentId + '/' + uid);
  }

}
