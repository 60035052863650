import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { map, catchError, tap } from 'rxjs/operators';
import { User } from '../model/users.model';
import {ApiService} from './api.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()

export class LoginService {

    constructor(private http: HttpClient, private apiService: ApiService) { }

    // Sprint 1
    loginCheck(data) {
      const formData: FormData = new FormData();
      formData.append('email', data.email);
      formData.append('password', data.password);
      return this.http.post<User>(this.apiService.loginURL, formData);
    }

    // Sprint 1
    loginGoogle(data) {
      const formData: FormData = new FormData();
      formData.append('email', data.email);
      formData.append('gid', data.gid);
      formData.append('name', data.name);
      return this.http.post<User>(this.apiService.loginGoogleURL, formData);
    }

    forgotPassword(data) {
      const formData: FormData = new FormData();
      formData.append('email', data.email);
      return this.http.post<User>(this.apiService.forgotPasswordURL, formData);
    }

    updatePassword(data) {
      const formData: FormData = new FormData();
      formData.append('mEncode', data.email);
      formData.append('p', data.password);
      return this.http.post<User>(this.apiService.updatePasswordURL, formData);
    }

}
