import { LangService } from './../service/lang.service';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Created by samuel silalahi on 24/01/2020.
 */

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private lang: LangService) {
  }

  transform(value: any): any {
      let required = '';

      if (value.indexOf('*') !== -1 && value.trim().indexOf(' ') === -1) {
          required = '*';
          value = value.replace('*', '');
      } else if (value.trim().indexOf(' ') !== -1) {
          return value;
      }
      if (!value) {
          return;
      }
      return this.lang.translate(value) + required;
  }
}
