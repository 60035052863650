import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { PageService } from 'src/app/service/page.service';
import { Gtag } from 'angular-gtag';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  providers: [TranslatePipe]
})
export class FaqComponent implements OnInit {
  loading: boolean;

  faqDataAll: any[];
  faqData: any[];
  keyword: any;
  noData: Boolean = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private pageService: PageService,
    private seo: SeoService,
    private gtag: Gtag,
  ) {
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'FAQ'
    });
  }

  ngOnInit() {
    this.getFaqData();
    this.seo.addMetaTag('Bantuan');
  }

  getFaqData() {
    this.loading = true;
    this.pageService.getFaq().subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.faqData = data.data;
        if (this.faqData.length > 0) {
            this.noData = false;
            this.faqData.forEach(e => {
              e.uhelp_question = e.uhelp_question.replace(/\\/g, '');
              e.uhelp_answer = e.uhelp_answer.replace(/\\/g, '');
            });
            this.faqDataAll = data.data;
            this.faqDataAll.forEach(e => {
              e.uhelp_question = e.uhelp_question.replace(/\\/g, '');
              e.uhelp_answer = e.uhelp_answer.replace(/\\/g, '');
            });
        } else {
          this.noData = true;
        }
      }
    }, (error) => {
      this.loading = false;
    });
  }

  searchFaq() {
    if (!this.keyword || this.keyword === '') {
      this.faqData = this.faqDataAll;
    } else {
      let index = 0;
      const arr = [];
      this.faqData = [];
      this.faqDataAll.forEach(e => {
        if (e.uhelp_question.toLowerCase().includes(this.keyword.toLowerCase()) ||
            e.uhelp_answer.toLowerCase().includes(this.keyword.toLowerCase())) {
          if (arr.indexOf(index) === -1) {
            arr.push(index);
            this.faqData.push({
              uhelp_question: e.uhelp_question,
              uhelp_answer: e.uhelp_answer
            });
          }
        }
        index++;
      });
      this.noData = this.faqData.length > 0 ? false : true;
    }
  }

  resetFaq() {
    if (!this.keyword || this.keyword === '') {
      this.faqData = this.faqDataAll;
      this.noData = this.faqData.length > 0 ? false : true;
    }
  }

}
