import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user.model';
import { ApiService } from './api.service';

@Injectable()
export class RegisterService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  register(data) {
    const formData: FormData = new FormData();
    formData.append('username', data.username);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('genre', data.genre);
    return this.http.post<User>(this.apiService.registerURL, formData);
  }

  registerGoogleCheck(data) {
    const formData: FormData = new FormData();
    formData.append('email', data.email);
    formData.append('gid', data.gid);
    return this.http.post<User>(this.apiService.registerGoogleCheckURL, formData);
  }

  registerGoogle(data) {
    const formData: FormData = new FormData();
    formData.append('email', data.email);
    formData.append('gid', data.gid);
    formData.append('name', data.name);
    formData.append('genre', data.genre);
    return this.http.post<User>(this.apiService.registerGoogleURL, formData);
  }

  resendVerif(email) {
    const formData: FormData = new FormData();
    formData.append('email', btoa(email));
    return this.http.post<User>(this.apiService.resendVerifURL, formData);
  }

}
