import { Component, OnInit, Inject, AfterContentChecked, HostListener } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/service/event.service';
import { environment } from 'src/environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeHtmlPipe } from 'src/app/shared/pipe/safe-html.pipe';
import { SeoService } from 'src/app/shared/service/seo.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css'],
  providers: [TranslatePipe, SafeHtmlPipe]
})
export class EventDetailComponent implements OnInit, AfterContentChecked {
  loading: boolean;

  defaultImage: string;
  eventDetail: any;
  noEvent: boolean;
  isParam: boolean;
  eventId: string;
  eventSlug: string;
  eventTitle = '-';
  eventImage: any;

  shareUrl = '';
  shareWhatsappUrl: any = '';
  shareMWhatsappUrl: any = '';
  fbUrl = 'https://www.facebook.com/sharer/sharer.php';
  shareFacebookUrl: any = '';
  twUrl = 'https://twitter.com/intent/tweet';
  shareTwitterUrl: any = '';
  alerts: any[] = [];

  // Sprint 10
  screenHeight: number;
  screenWidth: number;
  eventData: any[] = [];
  eventCarouselWidth = 308;
  eventCustomOptions: OwlOptions = {
    autoWidth: true,
    margin: 16,
    dots: false,
    items: 3,
    nav: true,
    navText: [
      '<i class="icon-chevron-left" aria-hidden="true"></i>',
      '<i class="icon-chevron-right" aria-hidden="true"></i>'
    ]
  };
  re = /\-/gi;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    private eventService: EventService,
    private seo: SeoService,
    private safeHtml: SafeHtmlPipe,
    private gtag: Gtag,
  ) {
    this.getScreenSize();
    if (this.screenWidth <= 320) {
      this.eventCarouselWidth = 290;
      this.eventCustomOptions.items = 1;
    } else if (this.screenWidth <= 1024) {
      this.eventCarouselWidth = 328;
      this.eventCustomOptions.items = 1;
    }
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Event-Detail'
    });
  }

  ngOnInit() {
    this.noEvent = true;
    this.defaultImage = environment.imageEventDefault;
    this.eventImage = this.defaultImage;

    this.shareUrl = environment.homeUrl + this.router.url;
    this.shareWhatsappUrl = this.safeHtml.transform2('https://web.whatsapp.com/send?text=' + this.shareUrl);
    this.shareMWhatsappUrl = this.safeHtml.transform2('whatsapp://send?text=' + this.shareUrl);
    this.shareFacebookUrl = this.fbUrl + '?u=' + encodeURIComponent(this.shareUrl) + '&amp;src=sdkpreparse';
    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(this.eventTitle) + '&url=' + encodeURIComponent(this.shareUrl.split('?')[0]);

    this.route.params.subscribe(params => {
      if (params) {
        this.isParam = true;
        this.eventId = params['id'];
        this.eventSlug = params['slug'];
        this.getEventDetail(this.eventId, this.eventSlug);
      }
    });

    if (!this.isParam) {
      this.navigateUrl.goTo('event');
    }
  }

  ngAfterContentChecked() {
    const imageUrl = this.eventImage ? this.eventImage : environment.homeUrl + '/' + this.defaultImage;
    this.seo.updateMetaTag(this.eventTitle, this.shareUrl, this.eventTitle, imageUrl);

    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(this.eventTitle) + '&url=' + encodeURIComponent(this.shareUrl.split('?')[0]);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  getEventDetail(id, slug) {
    this.loading = true;
    this.eventService.getEventById(id, slug).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.noEvent = false;
        this.eventDetail = data.data;
        this.eventTitle = data.data.event_title ? data.data.event_title : '-';
        this.eventImage = data.data.event_image ? data.data.event_image : this.defaultImage;

        if (data.data.event_recomended) {
          this.eventData = [];
          data.data.event_recomended.forEach(e => {
            this.eventData.push({
              id: e.event_id,
              status: e.event_status,
              title: e.event_title,
              slug: e.event_slug,
              startDate: e.event_start_date ? e.event_start_date.replace(this.re, '/') : e.event_start_date,
              startTime: e.event_start_time,
              endDate: e.event_end_date ? e.event_end_date.replace(this.re, '/') : e.event_end_date,
              endTime: e.event_end_time,
              address: e.event_address,
              src: e.event_image ? e.event_image : this.defaultImage
            });
          });
        }
      }
      if (data.status) {
        this.noEvent = false;
      } else {
        this.noEvent = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  trustPreviewEvent(event) {
    return this.sanitizer.bypassSecurityTrustHtml(event);
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  setDefaultImage() {
    this.eventImage = this.defaultImage;
  }

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.addAlert(this.translate.transform('linkCopied'));
  }

  popupwindow(url, title, w, h) {
    const y = window.outerHeight / 2 + window.screenY - ( h / 2);
    const x = window.outerWidth / 2 + window.screenX - ( w / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }

}
