import { SeoService } from 'src/app/shared/service/seo.service';
import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { AlertMassageService } from './../../shared/service/alert-massage.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login.service';
import { Gtag } from 'angular-gtag'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [TranslatePipe]
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  logo: String = '';
  projectName: String = '';
  forgotForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  errorMessage = '';

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private alertMassage: AlertMassageService,
    private translate: TranslatePipe,
    private renderer: Renderer2,
    private seo: SeoService,
    private gtag: Gtag,
  ) {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]]
    });
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Forgot-Password'
    });
  }

  ngOnInit() {
    this.logo = this.appConfig.logo;
    this.projectName = this.appConfig.appFullName;
    this.seo.addMetaTag('Lupa Kata Sandi');
  }

  get form() {
    return this.forgotForm.controls;
  }

  doSubmit() {
    this.validForm = this.checkValidForm();
    this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
    if (!this.validForm) {
      const user = {
        email: btoa(this.forgotForm.value.email)
      };

      this.loading = true;
      this.loginService.forgotPassword(user).subscribe((data: any) => {
          this.loading = false;
          if (data && data.message) {
            // tslint:disable-next-line: max-line-length
            this.errorMessage = this.alertMassage.show(data.message.s, this.translate.transform(data.message.m), this.translate.transform(data.message.s));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
          }
        }, () => {
          this.loading = false;
        }
      );
    }
  }

  checkValidForm() {
    if (!this.forgotForm.valid) {
      return true;
    } else {
      return false;
    }
  }

}
