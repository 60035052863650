import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { WindowRefService } from './service/window-ref.service';
import { routing } from './app.routing';
import { UserService } from './service/user.service';
import { User } from './model/users.model';
declare var jQuery: any;

@Injectable()
export class AuthGuard implements CanActivate {
  loading: boolean;

  constructor(
    private location: Location,
    private winRef: WindowRefService,
    private loginService: UserService,
    private router: Router,
  ) {}

  canActivate(): boolean {
    let isLoggedIn = this.isLoggedIn();
    if (!isLoggedIn) {
      this.router.navigate(['/login']);
      return isLoggedIn;
    }
    return isLoggedIn;
  }

  public isLoggedIn(): boolean {
    let status = false;
    if (localStorage.getItem('login_status') === '1') {
      status = true;
    } else {
      status = false;
    }
    return status;
  }

  logout(): void {
    this.loginService.logout(localStorage.getItem('uid')).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.data === '1') {
          localStorage.removeItem('login_status');
          localStorage.removeItem('uid');
          localStorage.removeItem('Name');
          localStorage.removeItem('Fullname');
          localStorage.removeItem('story_id');
          localStorage.removeItem('user_language');
          this.location.go('');
          location.reload();
        } else {
          this.loading = false;
          return;
        }
      }, (error) => {
        this.loading = false;
        return;
      }
    );
  }
}
