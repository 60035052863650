import { CanonicalService } from './../../shared/service/canonical.service';
import { SeoService } from './../../shared/service/seo.service';
import { environment } from 'src/environments/environment.prod';
import { SafeHtmlPipe } from './../../shared/pipe/safe-html.pipe';
import { Component, OnInit, Inject, AfterContentChecked, ElementRef, ViewChild, SecurityContext, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { CommentService } from 'src/app/service/comment.service';
import { UserService } from 'src/app/service/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Nl2brPipe } from 'src/app/shared/pipe/nl2br.pipe';
import { ConfirmShareDialogModel, GwpShareDialogComponent } from 'src/app/shared/component/gwp-share-dialog/gwp-share-dialog.component';
import { MatDialog } from '@angular/material';
import { Gtag } from 'angular-gtag';

declare let jQuery: any;

@Component({
  selector: 'app-chapter-detail',
  templateUrl: './chapter-detail.component.html',
  styleUrls: ['./chapter-detail.component.css'],
  providers: [TranslatePipe, SafeHtmlPipe, Nl2brPipe]
})
export class ChapterDetailComponent implements OnInit, AfterContentChecked {

  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;

  storyId = '';
  storyTitle = '';
  storySlug = '';
  chapterId = '';
  chapterSlug = '';

  activeTab: any;
  defaultImage: string;
  chapterList: any[];
  chapterData: any;
  chapterTitle = '-';
  chapterSynopsis = '-';
  chapterImage = '';
  chapterNumber = 1;
  chapterNumberCounter = 1;
  chapterAuthor = '';
  chapterViewCount = 0;
  chapterLikeCount = 0;
  chapterCommentCount = 0;
  chapterComment = 0;
  chapterListShow: boolean;
  mChapterListShow: boolean;
  chapterIsVote: boolean;
  authorIsFollow: boolean;
  printer: any;

  prevChapter: any;
  nextChapter: any;

  alerts: any[] = [];
  shareUrl = '';
  shareWhatsappUrl: any = '';
  shareMWhatsappUrl: any = '';
  fbUrl = 'https://www.facebook.com/sharer/sharer.php';
  shareFacebookUrl: any = '';
  twUrl = 'https://twitter.com/intent/tweet';
  shareTwitterUrl: any = '';
  queryParam = '';
  queryParamBack = '';

  showComment: boolean;
  chapterCommentListData: any[] = [];


  // sprint 4
  storyAuthorId: any;


  // sprint 5
  authorUsername: any = '';
  authorName: any = '';
  authorStories: Number = 0;
  authorImageProfile: any = '';
  defaultAuthor: any = '';
  authorId: any = '';
  reportCommentType: any[] = [];

  // sprint 8
  idNotClosed: any = 0;
  screenWidth: number;
  hideFooter: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private seo: SeoService,
    private dialog: MatDialog,
    private translate: TranslatePipe,
    public safeHtml: SafeHtmlPipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private router: Router,
    private storiesService: StoriesService,
    private commentService: CommentService,
    private userService: UserService,
    private _location: Location,
    public sanitizer: DomSanitizer,
    private nl2br: Nl2brPipe,
    private gtag: Gtag,
  ) {
    this.getScreenSize();
    if (this.screenWidth <= 1024) {
      this.hideFooter = true;
    }
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Chapter-Detail'
    });
  }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.getReportCommentType();
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
    this.defaultAuthor = environment.imageAuthorDefault;

    this.getShareUrl(this.router.url, this.chapterTitle);

    this.route.params.subscribe( params => {
      if (params && params['story'] && params['chapter'] && params['slug']) {
        this.storyId = params['story'];
        this.chapterId = params['chapter'];
        this.chapterSlug = params['slug'];
        this.getChapterDetail(this.storyId, this.chapterId, this.chapterSlug, this.uid);
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params && params['q']) {
        this.queryParam = params['q'];
        this.queryParamBack = (params['q'] === 'read' ? 'content' : params['q']);
      }
    });
    this.printer = console.log;
  }

  ngAfterContentChecked() {
    const imageUrl = this.chapterImage ? this.chapterImage : environment.homeUrl + '/' + this.defaultImage;
    this.seo.updateMetaTag(this.chapterTitle, this.shareUrl, this.chapterSynopsis, imageUrl);
  }

  getShareUrl(url, title) {
    this.shareUrl = environment.homeUrl + url;
    this.shareWhatsappUrl = this.safeHtml.transform2('https://web.whatsapp.com/send?text=' + this.shareUrl);
    this.shareMWhatsappUrl = this.safeHtml.transform2('whatsapp://send?text=' + this.shareUrl);
    this.shareFacebookUrl = this.fbUrl + '?u=' + encodeURIComponent(this.shareUrl) + '&amp;src=sdkpreparse';
    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(this.shareUrl.split('?')[0]);
  }

  getCommentChapter(chapterId: any, uid: any) {
    this.commentService.getCommentChapter(chapterId, uid).subscribe((data: any) => {
      console.log(data);
      if (data.status) {
        this.chapterCommentListData = data.data;
      }
    }, (error: any) => {
      // console.log(error);
    });

    this.route.queryParams.subscribe(params => {
      if (params && params['q']) {
        this.activeTab = (params['q'] === 'content' ? 1 : 0);
      }
    });
  }

  getChapterDetail(sid, cid, slug, uid) {
    this.loading = true;
    this.storyId = sid;
    this.storiesService.getChaptersDetailById(sid, cid, slug, uid).subscribe((data: any) => {
      this.loading = false;
      this.chapterData = null;
      this.chapterList = [];
      this.chapterNumberCounter = 1;
      if (data.status && data.data) {
        this.getCommentChapter(this.chapterId, this.uid);
        this.storyAuthorId = data.data.chapter_author_id;
        this.chapterData = {
          title: data.data.chapter_title ? data.data.chapter_title : '-',
          text: data.data.chapter_text ? this.safeHtml.transform(this.nl2br.transform(data.data.chapter_text, 'blank')) : '-'
        };
        this.chapterTitle = data.data.chapter_title ? data.data.chapter_title : '-';
        this.chapterSynopsis = data.data.chapter_synopsis ? data.data.chapter_synopsis : '-';
        this.chapterImage = data.data.story_image_cover ? data.data.story_image_cover : null;
        this.storyTitle = data.data.story_title ? data.data.story_title : '';
        this.storySlug = data.data.story_slug ? data.data.story_slug : '';
        this.chapterComment = data.data.chapter_comments ? data.data.chapter_comments : 0;
        this.chapterIsVote = data.data.vote ? (data.data.vote !== '0' ? true : false) : false;
        this.chapterAuthor = data.data.ufullname ? data.data.ufullname : (data.data.uusername ? data.data.uusername : '-');
        this.chapterViewCount = data.data.chapter_views ? data.data.chapter_views : 0;
        this.chapterLikeCount = data.data.chapter_likes ? data.data.chapter_likes : 0;
        this.chapterCommentCount = data.data.chapter_comments ? data.data.chapter_comments : 0;

        // sprint 5
        this.authorUsername = data.author.uusername;
        this.authorName     = data.author.ufullname;
        this.authorStories  = data.author.stories;
        this.authorImageProfile    = data.author.uimage ? data.author.uimage : this.defaultAuthor;
        this.authorIsFollow = data.author.followed;
        this.authorId       = data.author.uid;

        this.getShareUrl('/chapter/' + sid + '/' + cid + '/' + slug, this.chapterTitle);
      }

      if (data.status && data.chapter) {
        data.chapter.forEach(e => {
          this.chapterList.push({
            id: e.chapter_id,
            order: this.chapterNumberCounter,
            title: e.chapter_title ? e.chapter_title : '-',
            slug: e.chapter_slug ? e.chapter_slug : '-',
          });
          this.chapterNumberCounter++;
        });

        // Check chapter number
        const checkNumber = this.chapterList.find(e => e.id === cid);
        this.chapterNumber = checkNumber ? checkNumber.order : 1;
        // Check prev & next chapter
        const indexChapter = this.chapterList.findIndex(e => e.id === cid);
        this.prevChapter = ((indexChapter - 1) > -1) ? this.chapterList[(indexChapter - 1)] : null;
        this.nextChapter = ((indexChapter + 1) < this.chapterList.length) ? this.chapterList[(indexChapter + 1)] : null;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  gotoChapter(event) {
    if (event && event.id) {
      this.chapterListShow = false;
      this.mChapterListShow = false;
    }
  }

  gotoPrevChapter() {
    if (this.prevChapter && this.prevChapter.id) {
      this.navigateUrl.goTo('chapter/' + this.storyId + '/' + this.prevChapter.id + '/' + this.prevChapter.slug, {q: this.queryParam});
    }
  }

  gotoNextChapter() {
    if (this.nextChapter && this.nextChapter.id) {
      this.navigateUrl.goTo('chapter/' + this.storyId + '/' + this.nextChapter.id + '/' + this.nextChapter.slug, {q: this.queryParam});
    }
  }

  doClickAuthor(event) {
    jQuery('#authorModal').appendTo('body').modal(event);
  }

  doShowChapterList() {
    this.chapterListShow = !this.chapterListShow;
  }

  doShowMChapterList() {
    this.mChapterListShow = !this.mChapterListShow;
  }

  doVote() {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
    } else {
      this.chapterIsVote = !this.chapterIsVote;
      this.storiesService.doVoteChapter(this.storyId, this.chapterId, this.uid, this.authorId, this.chapterIsVote).
      subscribe((data: any) => {
        if (data.status) {
          this.chapterIsVote = this.chapterIsVote;
          if (this.chapterIsVote) {
            this.addAlert(this.translate.transform('voteStorySuccess'));
          }
        } else {
          this.chapterIsVote = !this.chapterIsVote;
          this.addAlert(this.translate.transform('voteStoryFailed'));
        }
      }, (error) => {
        this.chapterIsVote = !this.chapterIsVote;
        this.addAlert(this.translate.transform('voteStoryFailed'));
      });
    }
  }


  doShowComment() {
    this.showComment = !this.showComment;
  }

  back() {
    if (this.queryParamBack === 'notification') {
      this._location.back();
    } else {
      this.navigateUrl.goTo('story/' + this.storyId + '/' + this.storySlug, {q: this.queryParamBack});
    }
  }

  doClickShare() {
    const dialogData = new ConfirmShareDialogModel(this.translate.transform('labelStoriesChapter'), {
      copy: this.shareUrl,
      fb: this.shareFacebookUrl,
      tw: this.shareTwitterUrl,
      mwa: this.shareMWhatsappUrl,
      wa: this.shareWhatsappUrl,
    });
    const dialogRef = this.dialog.open(GwpShareDialogComponent, {
      maxWidth: '310px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.copied) {
        this.addAlert(this.translate.transform('linkCopied'));
      }
    });
  }

  // copyLink(inputElement) {
  //   inputElement.select();
  //   document.execCommand('copy');
  //   inputElement.setSelectionRange(0, 0);
  //   this.addAlert(this.translate.transform('linkCopied'));
  // }

  // popupwindow(url, title, w, h) {
  //   const y = window.outerHeight / 2 + window.screenY - ( h / 2);
  //   const x = window.outerWidth / 2 + window.screenX - ( w / 2);
  //   return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  // }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }


  // sprint 4 redha
  commendSend(item: any) {
    this.commentService.sendCommentChapter(
      item.comment, item.parentId, this.storyId, this.chapterId, this.authorId, this.uid, item.toWho ? item.toWho : ''
    ).subscribe((response: any) => {
      if (response.status) {
        if (item.parentId === 0) {
          this.chapterCommentCount++;
          this.chapterComment = this.chapterCommentCount;
        } else {
          this.idNotClosed = item.parentId;
        }
        this.getCommentChapter(this.chapterId, this.uid);
      }
    }, (error: any) => {
      // console.log(error);
    });
  }

  // sprint 4
  eventEmit(item: any) {

    if (item.type === 1) {

      if (item.position === 1) {
        this.commentService.deleteCommentChapter(
          item.ccomment_id,
          this.uid,
          this.storyId,
          this.chapterId,
        ).subscribe((response: any) => {
          if (response.status) {
            this.chapterCommentCount--;
            this.chapterComment = this.chapterCommentCount;
            this.getCommentChapter(this.chapterId, this.uid);
          }
        }, (error: any) => {
          // console.log(error);
        });
      } else {
        this.commentService.deleteCommentChildChapter(
          item.ccomment_id,
          this.uid,
          this.storyId,
          this.chapterId,
        ).subscribe((response: any) => {
          if (response.status) {
            this.getCommentChapter(this.chapterId, this.uid);
          }
        }, (error: any) => {
          // console.log(error);
        });
      }

    } else if (item.type === 2) {
      this.commentService.pinnedCommentChapter(
        item.ccomment_id,
        this.uid,
        this.storyId,
        this.chapterId
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentChapter(this.chapterId, this.uid);
        }
      }, (error: any) => {
        // console.log(error);
      });
    } else if (item.type === 3) {
      this.commentService.reportCommentChapter(
        item.ccomment_id,
        this.storyId,
        this.chapterId,
        item.typeReport,
        this.uid
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentChapter(this.chapterId, this.uid);
        }
      }, (error: any) => {
        // console.log(error);
      });
    } else {
      this.commentService.unPinnedCommentChapter(
        item.commentId,
        this.uid,
        this.storyId,
        this.chapterId
      ).subscribe((response: any) => {
        if (response.status) {
          this.getCommentChapter(this.chapterId, this.uid);
        }
      }, (error: any) => {
        // console.log(error);
      });
    }
  }

  // sprint 5
  onErrorImageAuthor(event) {
    event.target.src = this.defaultAuthor;
  }

  doFollow(action: any) {
    this.userService.followUser(this.uid, this.authorId, action).then((data: any) => {
      if (data.status) {
       this.authorIsFollow = !this.authorIsFollow;
      }
    }).catch((error: any) => {
      // console.log(error);
    });
  }

   // sprint 5
   getReportCommentType() {
    this.commentService.getReportCommentType().subscribe((data: any) => {
      if (data.status) {
        this.reportCommentType = data.data;
      }
    }, (error: any)  => {
      // console.log(error);
    });
  }

  // sprint 5
  detailAuthor() {
    jQuery('#authorModal').modal('hide');
  }

  // sprint 10
  commentEditSend(data) {
    this.commentService.editCommentChapter(data.comment, data.id, this.uid, this.storyId, this.chapterId).subscribe((response: any) => {
      if (response.status) {
        if (data.parentId !== 0) {
          this.idNotClosed = data.parentId;
        }
        this.getCommentChapter(this.chapterId, this.uid);
      }
    }, (error: any) => {
    });
  }

  getHtml(html){
    return this.sanitizer.sanitize(SecurityContext.HTML,this.sanitizer.bypassSecurityTrustHtml(html));
    // return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

}
