import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { User } from 'src/app/model/user.model';
import { CommentService } from 'src/app/service/comment.service';
import { Nl2brPipe } from './../../../shared/pipe/nl2br.pipe';

declare let jQuery: any;

@Component({
  selector: 'app-chapter-detail-comment',
  templateUrl: './chapter-detail-comment.component.html',
  styleUrls: ['./chapter-detail-comment.component.css'],
  providers: [ TranslatePipe, Nl2brPipe ]
})
export class ChapterDetailCommentComponent implements OnInit {

  @Output() closeComment = new EventEmitter<any>(true);
  @Input() storyId: any;
  @Input() chapterId: any;
  @Input() chapterNumber: any;
  @Input() chapterTitle: any;
  @Input() chapterImage: any;
  @Input() chapterAuthor: any;
  @Input() chapterViewCounter: any;
  @Input() chapterLikeCounter: any;
  @Input() chapterCommentCounter: any;
  @Input() auth: boolean;
  @Input() uid: any;

  loading: boolean;
  loginStatus: string;

  defaultImage: string;

  // sprint 4 redha
  commentForm: FormGroup;
  commentChildForm: FormGroup;
  commentEdit: FormGroup;

  @Input() ownerStory: any;
  moreComment: Boolean = false;
  commentList: any;
  @Output() commenSend: EventEmitter<any> = new EventEmitter();
  @Output() eventEmit: EventEmitter<any> = new EventEmitter();
  @Output() commentEditSend: EventEmitter<any> = new EventEmitter();
  @Input() dataTypeReportComment: any;

  // sprint 7
  logo: String = '';
  user: User[];

  @ViewChild('errMsg', { static: false }) errMsg: ElementRef;
  idAfterReply: any = 0;
  dataEditComment: any;
  commentEditMention: String = '';
  textIndent = 120;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private translate: TranslatePipe,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public navigateUrl: GoToService,
    private commentService: CommentService,
    private nl2br: Nl2brPipe
  ) {

    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });

    this.commentChildForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });

    this.commentEdit = this.formBuilder.group({
      commentEdit: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.logo = this.appConfig.logo;
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  doCloseComment() {
    this.closeComment.emit({
      close: true
    });
  }


  // sprint 4 redha

  get form() {
    return this.commentForm.controls;
  }

  get forms() {
    return this.commentChildForm.controls;
  }

  get formEdit() {
    return this.commentEdit.controls;
  }

  sendComment() {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    if (!this.commentForm.invalid) {
      this.commenSend.emit({comment: this.form.comment.value, parentId: 0});
      this.commentForm.reset();
    }
  }

  sendCommentChild(data: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    if (!this.commentChildForm.invalid) {
      this.commenSend.emit({comment: this.forms.comment.value, parentId: data.ccomment_id, toWho: data.uid});
      this.commentChildForm.reset();
    }
  }

  goToUser(item) {
    console.log(item);
  }

  @Input()
  set idNotClosed(param: any) {
    console.log(param);
    this.idAfterReply = param;
  }

  get idNotClosed(): any {
    return this.idAfterReply;
  }

  @Input()
  set comment(param: any) {
    this.commentList = param;
    console.log(param);
    this.commentList.map((list: any, index: any) => {
      list.seeChild = this.idNotClosed === list.ccomment_id;
      list.reply = false;
      list.edit = false;
      list.alreadyEdited = list.ccomment_is_edited === '1';

      if (list.childComment.length > 0) {
        list.childComment.map((item: any, i: any) => {
          item.reply = false;
          item.edit = false;
          item.alreadyEdited = list.ccomment_is_edited === '1';
        });
      }
    });
  }

  get comment(): any {
    return this.commentList;
  }

  openReply(data) {
    this.commentForm.reset();
    this.commentChildForm.reset();
    this.commentList.map((list: any, index: any) => {
      if (list.ccomment_id === data.ccomment_id) {
        list.reply = !list.reply;
      } else {
        list.reply = false;
      }
      if (list.childComment.length > 0) {
        list.childComment.map((item: any, i: any) => {
          if (item.ccomment_id === data.ccomment_id) {
            item.reply = !item.reply;
          } else {
            item.reply = false;
          }
        });
      }
    });
  }

  openChildComment(data) {
    this.commentList.map((list: any, index: any) => {
      if (list.ccomment_id === data.ccomment_id) {
        list.seeChild = !list.seeChild;
      } else {
        list.seeChild = false;
      }
    });
  }


  openEdit(data, position, type) {
    console.log(data);
    if (type === 2) {
      this.formEdit.commentEdit.setValue('');
      data.edit = !data.edit;
    } else {
      this.dataEditComment = data;
      this.commentEditMention = (data.uusername && data.uusername !== '') ? '@' + data.uusername : '@' + data.ufullname;
      const indent = this.commentEditMention.replace(/\s/g, '');
      this.textIndent = ((indent.length + 1) * 10) + 5;
      data.ccomment_text = this.nl2br.transform(data.ccomment_text, 'blank');
      this.formEdit.commentEdit.setValue(data.ccomment_text.replace(/<br\s*\/?>/gi, '\n'));
      if (position === 1) {
        this.commentList.map((list: any, index: any) => {
          if (list.ccomment_id === data.ccomment_id) {
            list.edit = !list.edit;
          } else {
            list.edit = false;
          }
          if (list.childComment.length > 0) {
            list.childComment.map((item: any, i: any) => {
              item.edit = false;
            });
          }
        });
      } else {
        this.commentList.map((list: any, index: any) => {
          list.edit = false;
          if (list.childComment.length > 0) {
            list.childComment.map((item: any, i: any) => {
              if (item.ccomment_id === data.ccomment_id) {
                item.edit = !item.edit;
              } else {
                item.edit = false;
              }
            });
          }
        });
      }
    }
  }

  sendEditComment(data) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentEdit.invalid) {
      this.commentEditSend.emit({comment: this.formEdit.commentEdit.value, id: data.ccomment_id, parentId: 0});
      this.commentEdit.reset();
    }
  }

  sendEditCommentChild(data, parentId) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentEdit.invalid) {
      this.commentEditSend.emit({comment: this.formEdit.commentEdit.value, id: data.ccomment_id, parentId: parentId});
      this.commentEdit.reset();
    }
  }

  deleteComment(comment: any, position: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    const message = this.translate.transform('confirmMDeleteComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTDeleteComment'), message, '',
                       this.translate.transform('confirmDelete'));
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({ccomment_id: comment.ccomment_id, type: 1, position: position});
      }
    });
  }

  pinComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    const message = this.translate.transform('confirmMPinComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTPinComment'), message, '');
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({ccomment_id: comment.ccomment_id, type: 2});
      }
    });
  }

  reportComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    if (comment.reported) {
      return;
    }

    const message = this.translate.transform('confirmMReportComment');
    const dialogData = new ConfirmDialogModel(2, this.translate.transform('confirmTReportComment'), message, this.dataTypeReportComment);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '536px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.status) {
        this.eventEmit.emit({ccomment_id: comment.ccomment_id, type: 3, typeReport: dialogResult.data});
        comment.reported = true;
      }
    });
  }

  likeComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    let likeStatus = 1;
    if (comment.is_like) {
      likeStatus = 0;
    }
    this.commentService.likeCommentChapter(
      comment.ccomment_id, this.uid, likeStatus
    ).subscribe((response: any) => {
      if (response.status) {
        if (likeStatus === 1) {
          comment.ccomment_likes ++;
          comment.is_like = true;
        } else {
          if (comment.ccomment_likes >= 0) {
            comment.ccomment_likes --;
          } else {
            comment.ccomment_likes = 0;
          }
          comment.is_like = false;
        }
      }
    }, (error: any) => {
    });
  }

  unPinComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }

    const message = this.translate.transform('confirmMUnpinComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTUnpinComment'), message, '');
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '536px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({commentId: comment.ccomment_id, type: 6});
      }
    });
  }

  viewMoreComment() {
    this.moreComment = !this.moreComment;
  }

}
