import { SeoService } from 'src/app/shared/service/seo.service';
import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, OnChanges, OnDestroy, Inject } from '@angular/core';
import { PublishService } from 'src/app/service/publish.service';
import { ActivatedRoute } from '@angular/router';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { MyLibraryService } from 'src/app/service/my-library.service';
import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.css'],
  providers: [ TranslatePipe ]
})
export class MyLibraryComponent implements OnInit {

  loading: boolean;
  isParam: boolean;
  activePage: any = 1;
  limit = 9;
  totalData = 0;
  noData: boolean;
  libraryList: any[] = [];
  defaultImage: string;
  uid: any;
  dataToDelete: any[] = [];
  enableDelete: Boolean = false;

  // sprint 10
  noCollection: String;
  re = /\-/gi;

  // sprint 14
  enabledDelete: Boolean = false;
  labelBtnDelete: String = this.translate.transform('labelChooseCheckbox');
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private publishService: PublishService,
    private route: ActivatedRoute,
    private myLibrary: MyLibraryService,
    private seo: SeoService,
    private translate: TranslatePipe,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Koleksiku');
    this.defaultImage = environment.imageStoryDefaultV2;
    this.noCollection = environment.imageNoCollection;
    this.uid = localStorage.getItem('uid');

    this.route.params.subscribe( params => {
      if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
      } else {
        this.isParam = false;
        this.activePage = 1;
      }
      this.getMyLibrary();
    });
  }

  getMyLibrary() {
    this.loading = true;
    this.myLibrary.getMyAllMyLibrary(
      this.activePage,
      this.limit,
      this.uid
    ).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.libraryList = [];
        data.data.forEach(e => {
          this.libraryList.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : this.defaultImage),
            title: e.story_title ? e.story_title : '-',
            lastRead: e.lastRead,
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            authhorUid: e.uid,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            link: e.pstory_link_ecomerce,
            price: e.pstory_price,
            synopsis: e.story_description ? this.stripHtml(e.story_description) : '-',
            lastUpdate: e.story_modified_date ? e.story_modified_date.replace(this.re, '/') : e.story_modified_date,
            checked: false,
            checked_text: 'labelChooseCheckbox',
            sf_id: e.sf_id,
            complete: ( e.story_is_complete === '0' ) ? false : true
          });
        });
        this.totalData = data.totalData;
      } else {
        this.libraryList = [];
        this.totalData = 0;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error: any) => {
        this.loading = false;
        console.log(error);
    });
  }

  stripHtml(html) {
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  onChangePage(page) {
    this.activePage  = page;
    this.navigateUrl.goTo(
      'koleksiku/p/' + this.activePage
    );
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  // sprint 6
  chooseToDelete(item: any) {
    item.checked      = !item.checked;
    item.checked_text = item.checked ? 'labelCancelChooseCheckbox' : 'labelChooseCheckbox';
    this.dataToDelete = [];
    this.libraryList.filter((row: any) => {
      if (row.checked === true) {
        this.enableDelete = true;
        this.dataToDelete.push({'sf_id': row.sf_id, 'storyId': row.id});
      }
    });

    this.enableDelete = ( this.dataToDelete.length > 0 ) ? true : false;
  }

  deleteStoryInLibrary () {
    let message;
    if (this.dataToDelete.length > 1) {
      message = this.translate.transform('confirmFDeleteLibrary') + ' ' + this.dataToDelete.length + ' ' + this.translate.transform('confirmMLDeleteLibrary') + ' ';
    } else {
      message = this.translate.transform('confirmFDeleteLibrary') + ' ' + this.translate.transform('confirmSingleDeleteLibrary') + ' ';
    }
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTDeleteLibrary'), message, '', this.translate.transform('confirmDelete'), this.translate.transform('confirmNLDeleteLibrary'));
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.myLibrary.removeFromMyLibrary(
          JSON.stringify(this.dataToDelete),
          this.uid
        ).subscribe((data: any) => {
          if (data.status) {
            this.enableDelete = false;
            if ( this.dataToDelete.length === this.libraryList.length) {
              const nowPage = (this.activePage > 1) ? this.activePage - 1 : 1;
              if (nowPage === 1) {
                this.navigateUrl.goToWithSamePage('koleksiku/p/1');
              } else {
                this.activePage = (this.activePage > 1) ? this.activePage - 1 : 1;
                this.onChangePage(this.activePage);
              }
            } else {
              this.getMyLibrary();
            }
          }
        }, (error: any) => {
          console.log(error);
        });
      }
    });
  }

  showCheckBox() {
    this.enabledDelete = ! this.enabledDelete;
    if (this.enabledDelete) {
      this.labelBtnDelete = this.translate.transform('labelCancelChooseCheckbox');
    } else {
      this.labelBtnDelete = this.translate.transform('labelChooseCheckbox');
    }
  }

  deleteStoryThisLibrary(item: any) {
    const message = this.translate.transform('confirmFDeleteLibrary') + ' ' + this.translate.transform('confirmSingleDeleteLibrary') + ' ';
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTDeleteLibrary'), message, '', this.translate.transform('confirmDelete'), this.translate.transform('confirmNLDeleteLibrary'));
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const dataDelete = [];
        dataDelete.push({'sf_id': item.sf_id, 'storyId': item.id});
        if (item.checked) {
          this.dataToDelete.forEach(function(row, index, object) {
            if (row.sf_id === item.sf_id) {
              object.splice(index, 1);
            }
          });
          this.enableDelete  = ( this.dataToDelete.length > 0 ) ? true : false;
        }
        this.myLibrary.removeFromMyLibrary(
          JSON.stringify(dataDelete),
          this.uid
        ).subscribe((data: any) => {
          if (data.status) {
            this.libraryList.forEach(function(row, index, object) {
              if (row.sf_id === item.sf_id) {
                object.splice(index, 1);
              }
            });
            if (this.libraryList.length < 1) {
              const nowPage = (this.activePage > 1) ? this.activePage - 1 : 1;
              if (nowPage === 1) {
                this.navigateUrl.goToWithSamePage('koleksiku/p/1');
              } else {
                this.activePage = (this.activePage > 1) ? this.activePage - 1 : 1;
                this.onChangePage(this.activePage);
              }
            }
          }
        }, (error: any) => {
          console.log(error);
        });
      }
    });

  }

}
