import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, AfterContentChecked {

  @Input() sizePaging = '';
  @Input() totalData = 0;
  @Input() totalPage = 1;
  @Input() activePage = 1;
  @Output() doChangePage: EventEmitter<any> = new EventEmitter();

  firstBtnShow: boolean;
  prevBtnShow: boolean;
  nextBtnShow: boolean;
  lastBtnShow: boolean;
  selectedPage = 1;
  pageItem: any[] = [];

  constructor() { }

  ngOnInit() {
    console.log('OnInitPage', 'Yes');
    this.reCalculate(this.totalPage);
  }

  ngAfterContentChecked() {
    console.log('OnAfterContentCheckedPage', 'Yes');
    this.reCalculate(this.totalPage);
  }

  reCalculate(totalPage) {
    if (this.totalPage > 0) {
      this.pageItem = [];
      for (let index = 1; index < this.totalPage + 1; index++) {
        this.pageItem.push({
          id: index,
          text: index
        });
      }
    }

    if (totalPage === 1) {
      this.firstBtnShow = false;
      this.prevBtnShow = false;
      this.nextBtnShow = false;
      this.lastBtnShow = false;
    } else if (totalPage === 2) {
      if (this.selectedPage < totalPage) {
        this.firstBtnShow = false;
        this.prevBtnShow = false;
        this.nextBtnShow = true;
        this.lastBtnShow = true;
      } else {
        this.firstBtnShow = true;
        this.prevBtnShow = true;
        this.nextBtnShow = false;
        this.lastBtnShow = false;
      }
    }
  }

  doSelectFirstPage() {
    if ((this.selectedPage - 1) < 1) {
      return;
    } else {
      this.selectedPage = 1;
      this.doChangePage.emit(this.selectedPage);
    }
  }

  doSelectPrevPage(selectedPage) {
    if (selectedPage <= 1) {
      return;
    } else {
      this.selectedPage = selectedPage - 1;
      this.doChangePage.emit(this.selectedPage);
    }
  }

  doSelectPage(selectedPage) {
    if (this.activePage === selectedPage) {
      return;
    } else {
      this.selectedPage = selectedPage;
      this.doChangePage.emit(this.selectedPage);
    }
  }

  doSelectNextPage(selectedPage) {
    if (selectedPage >= (this.totalPage)) {
      return;
    } else {
      this.selectedPage = selectedPage + 1;
      this.doChangePage.emit(this.selectedPage);
    }
  }

  doSelectLastPage() {
    if ((this.selectedPage + 1) > this.totalPage) {
      return;
    } else {
      this.selectedPage = this.totalPage;
      this.doChangePage.emit(this.selectedPage);
    }
  }

}
