import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';

@Component({
  selector: 'app-create-story-table-content',
  templateUrl: './create-story-table-content.component.html',
  styleUrls: ['./create-story-table-content.component.css'],
  providers: [TranslatePipe]
})
export class CreateStoryTableContentComponent implements OnInit {

  @Output() listChapterAction = new EventEmitter<any>(true);
  @Output() addChapterAction = new EventEmitter<any>(true);
  @Output() editChapterAction = new EventEmitter<any>(true);
  @Output() orderChapterAction = new EventEmitter<any>(true);
  @Input() inStoriesId = '';
  @Input() inAuthor = '';
  dataInChapter: any;
  @Input()
  set inChapterData(data: any) {
    if (data && data.length < 1) {
      this.dataInChapter = false;
    } else {
      this.dataInChapter = data;
    }
  }

  get inChapterData(): any {
    return this.dataInChapter;
  }

  loading: Boolean = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // console.log('Chapter', this.inChapterData);
  }

  clickAddChapter() {
    this.addChapterAction.emit();
  }

  clickEditChapter(event) {
    this.editChapterAction.emit({data: event, edit: true});
  }

  clickPublishChapter(event) {
    const message = this.translate.transform('confirmMPublishStories');
    const confirmButton = this.translate.transform('confirmBtnPublishStories');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTPublishStories'), message, 1, confirmButton);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.listChapterAction.emit({data: event, publish: '1'});
      }
    });
  }

  clickUnpublishChapter(event) {
    const message = this.translate.transform('confirmMUnpublishStories');
    const confirmButton = this.translate.transform('confirmBtnUnpublishStories');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTUnpublishStories'), message, 1, confirmButton);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.listChapterAction.emit({data: event, publish: '0'});
      }
    });
  }

  clickDeleteChapter(event) {
    const message = this.translate.transform('confirmMDelChapter');
    const confirmButton = this.translate.transform('confirmTDelChapter');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmBtnDelChapter'), message, 1, confirmButton);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.listChapterAction.emit({data: event, delete: true});
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.inChapterData, event.previousIndex, event.currentIndex);
    // console.log('New', this.inChapterData);
    this.orderChapterAction.emit(this.inChapterData);
  }

}
