import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

// sprint 3
import { SearchingDefault } from 'src/app/model/searching-default.model';
import { SearchingService } from 'src/app/service/searching.service';
import { AuthGuard } from 'src/app/auth.guard';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


// sprint 10
import { Location } from '@angular/common';

@Component({
  selector: 'app-searching',
  templateUrl: './searching.component.html',
  styleUrls: ['./searching.component.css']
})
export class SearchingComponent implements OnInit, OnDestroy {

  auth: boolean;
  uid: string;
  loading: boolean;

  inputSearch = '';
  listSearching: SearchingDefault;
  _last: any;
  _popular: any;
  _author: any;
  _story: any;
  _tag: any;

  searchSubscription: any;
  historySubscription: any;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();


  constructor(
    private searchingService: SearchingService,
    public authGuard: AuthGuard,
    public navigateUrl: GoToService,
    private seo: SeoService,
    private location: Location
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Gramedia Writing Project');
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    this.historySearch();
  }

  public ngOnDestroy() {
    // When moving page
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnDestroyHit() {
    // When hitting request
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.historySubscription) {
      this.historySubscription.unsubscribe();
    }
  }

  historySearch() {
    this.historySubscription = this.searchingService.historySearch(this.uid).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if (data.status) {
        this.listSearching = data.data;
        this._last = this.listSearching.last && this.listSearching.last.length > 0 ? this.listSearching.last : null;
        this._popular = this.listSearching.popular && this.listSearching.popular.length > 0 ? this.listSearching.popular : null;
        this._author = this.listSearching.author && this.listSearching.author.length > 0 ? this.listSearching.author : null;
        this._story = this.listSearching.story && this.listSearching.story.length ? this.listSearching.story : null ;
        this._tag = this.listSearching.tag && this.listSearching.tag.length ? this.listSearching.tag : null;
      }
    }, (error: any) => {
    });
  }

  async searchEvent(evt) {
    if (this.inputSearch && this.inputSearch !== '' && this.inputSearch.length >= 3) {
      this.sugestiontSearch(this.inputSearch);
    } else {
      this.historySearch();
    }
  }

  sugestiontSearch(search: string) {
    this.ngOnDestroyHit();
    const res = search.slice(0, 1);
    if (res === '#') {
      search = search.substr(1);
      search = '%23' + search;
    }
    this.searchSubscription = this.searchingService.suggestSearch(search, this.uid).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if (data.status) {
        this.listSearching = data.data;
        this._last = this.listSearching.last && this.listSearching.last.length > 0 ? this.listSearching.last : null;
        this._popular = this.listSearching.popular && this.listSearching.popular.length > 0 ? this.listSearching.popular : null;
        this._author = this.listSearching.author && this.listSearching.author.length > 0 ? this.listSearching.author : null;
        this._story = this.listSearching.story && this.listSearching.story.length ? this.listSearching.story : null ;
        this._tag = this.listSearching.tag && this.listSearching.tag.length ? this.listSearching.tag : null;
      }
    }, (error: any) => {
    });
  }

  doSelectItem(id: any, text: any, slug: any, type: any) {
    const searchData = {
      id: id,
      text: text,
      slug: slug,
      type: type
    };
    this.onItemSelectedSearch(searchData);
  }

  onItemSelectedSearch(event) {
    if (event && event.type && event.text) {
      this.inputSearch = event.text;
      this.search(event.text, event.id, event.type);

      if (event.id && event.slug && event.type === 'story') {
        this.navigateUrl.goTo('story/' + event.id + '/' + event.slug);
      } else if (event.id && event.type === 'author') {
        this.navigateUrl.goTo('author/' + event.id);
      } else {
        this.navigateUrl.goTo('search', {q: event.text, t: event.type});
      }
    }
  }

  goToSearch() {
    if (this.inputSearch && this.inputSearch !== '') {
      this.search(this.inputSearch, null, 'text');
      this.navigateUrl.goTo('search', {q: this.inputSearch, t: 'text'});
    }
  }

  search(term: any, refId: any, type: any) {
    this.searchingService.search(term, refId, type, this.uid).subscribe((response: any) => {
    }, (error: any) => {
    });
  }

  deleteSearchText(event) {
    let search = null;
    if (event && event.us_id) {
      search =  event.us_id;
    }
    this.searchingService.deleteSearch(search, this.uid).subscribe((response: any) => {
      if (response.status) {
        if (this.listSearching && this.listSearching.last) {
          this._last = this._last.filter(e =>  e.us_id !== search);
        }
      }
    }, (error: any) => {
    });
  }

  deleteAllSearchText() {
    this.loading = true;
    this.searchingService.clearSearch(this.uid).subscribe((response: any) => {
      this.loading = false;
      if (response.status) {
        this._last = null;
      }
    }, (error: any) => {
      this.loading = false;
    });
  }

  clearSearch() {
    this.inputSearch = '';
    this.historySearch();
  }

  back() {
    this.location.back();
  }

}
