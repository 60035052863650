import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SettingService } from 'src/app/service/setting.service';
import { SomethingChangeService } from 'src/app/service/something-change.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  loading: boolean;
  opened: boolean;
  activeMenu: string;
  disabledHeaderFor: string[] = ['/login', '/register', '/register-success', '/forgot-password', '/change-password',
                                '/the-writers-show',  '/cstory', '/ustory', '/msearch'];
  hideHeaderFor: string[] = ['/chapter'];
  disabledMobileNavFor: string[] = ['/chapter'];
  showMobileNavFor: string[] = ['/home', '/my-profile', '/koleksiku',
                                '/notification', 'work-stories',
                                '/setting-account', '/reset-password'];
  showHeader = true;
  hideHeader = false;
  showMobileNav = true;
  showLomba = false;
  showButton = false;
  screenWidth: number;
  isOnMobile: boolean;

  constructor(
    private router: Router,
    public navigateUrl: GoToService,
    private settingService: SettingService,
    private somethingChange: SomethingChangeService
  ) {
    this.getScreenSize();
    if (this.screenWidth < 768) {
      this.hideHeaderFor.push('/term-condition');
    } else {
      this.hideHeaderFor = this.hideHeaderFor.filter(e => e !== '/term-condition');
    }
    this.routeEvent(this.router);
  }

  ngOnInit() {
    this.getSettingLomba();
    if (this.screenWidth <= 1024) {
      this.isOnMobile = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  routeEvent(router: Router) {
    router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.activeMenu = this.router.url.replace('/', '');
          if (this.activeMenu === 'term-condition') {
            this.showButton = false;
          } else {
            this.showButton = true;
          }
          if (this.disabledHeaderFor.find(x => this.router.url.indexOf(x) !== -1)) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
          if (this.hideHeaderFor.find(x => this.router.url.indexOf(x) !== -1)) {
            this.hideHeader = true;
          } else {
            this.hideHeader = false;
          }
          // if (this.disabledMobileNavFor.find(x => this.router.url.indexOf(x) !== -1)) {
          //   this.showMobileNav = false;
          // } else {
          //   this.showMobileNav = true;
          // }
          if (this.showMobileNavFor.find(x => this.router.url.indexOf(x) !== -1)) {
            this.showMobileNav = true;
          } else {
            this.showMobileNav = false;
          }
          if (this.router.url === '/') {
            this.showMobileNav = true;
          }


          const urlChecking = this.router.url.substr(1, 6);
          if  (urlChecking !== 'search') {
            this.somethingChange.sendMessage('search', 'changeUrl');
          }
        }
      }
    );
  }

  sideNavToogle() {
    this.opened = !this.opened;
  }

  goToMenu (menu = 'home') {
    // this.navigateUrl.goTo(menu);
    this.sideNavToogle();
    this.activeMenu = menu;
  }

  getSettingLomba() {
    this.settingService.getSettingLomba().subscribe((data: any) => {
      if (data.status) {
        this.showLomba = true;
      }
    });
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { GoToService } from 'src/app/shared/service/go-to.service';

@Pipe({ name: 'removeSlash' })
export class RemoveSlashPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      return value.replace(/\\/g, '');
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'removeAmpChar' })
export class RemoveAmpCharPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      return value.replace(/&amp;/g, '&');
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'dateTimeFormatFilter' })
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return date.replace(/\s/g, 'T');
    }
  }
}

@Pipe({ name: 'removeQuestCharFirst' })
export class RemoveQuestCharFirstPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const firstChar = value.charAt(0);
    if (firstChar === '?') {
      return value.replace(/^\?/, '');
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'removeNewLine' })
export class RemoveNewLinePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/\\n/g, '');
  }
}
