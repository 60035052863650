import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../model/user.model';
import { Subscribe } from '../model/subscribe.model';
import { ApiService } from './api.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()

export class UserService {
  response: any;

  constructor(private http: HttpClient, private apiService: ApiService) { }

  // Sprint 2 & Sprint 5
  getFollowerByid(uid, author) {
    const params = '?uid=' + uid + '&author=' + author;
    return this.http.get(this.apiService.getFollowerUrl + params);
  }

  // Sprint 2 & Sprint 5
  getFollowingByid(uid, author) {
    const params = '?uid=' + uid + '&author=' + author;
    return this.http.get<any[]>(this.apiService.getFollowingUrl + params);
  }

  // Sprint 5
  followUser(uid, author, action) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('author', author);
    formData.append('act', action);

    return new Promise((resolve, reject) => {
      this.http.post<any>(this.apiService.followAuthorUrl, formData).subscribe(res => {
        resolve(res);
      }, (error: any) => {
        reject({'title': error.error.status, 'message': error.error.message});
      });
    });
  }

  // Sprint 2
  getUserById(uid: any, author: any) {
    const params = '?uid=' + uid + '&author=' + author;
    return this.http.get<User[]>(this.apiService.getUserURL + params).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  // Sprint 2
  getPopularAuthor(limit, page = 1, type = '', uid = '') {
    let params = '?limit=' + limit + '&page=' + page;
    if (type !== '') {
      params += '&type=' + type;
    }
    if (uid !== '') {
      params += '&uid=' + uid;
    }
    return this.http.get<User[]>(this.apiService.popularAuthorURL + params);
  }

  // Sprint 8
  checkUserLomba(param: any) {
    const formData: FormData = new FormData();
    formData.append('uemail', btoa(param.email));

    return new Promise((resolve, reject) => {
        this.http.post<User>(this.apiService.checkUserLombaURL, formData, {}).subscribe((res: any) => {
          resolve(res);
        }, (error: any) => {
          resolve(error);
        });
    });
  }

  registerLombaGWP(param: any) {
    const formData: FormData = new FormData();
    formData.append('uusername', param.penName);
    formData.append('uemail', btoa(param.email));
    formData.append('ufullname', param.fullName);
    formData.append('ubirthday', param.dateBirth);
    formData.append('usex', param.gender);
    formData.append('uphone', param.numberPhone);
    formData.append('ufavorite_category', param.genreKarya);
    formData.append('type', param.type);
    if (param.type === 1) {
      formData.append('upassword', btoa(param.password));
    }
    return new Promise((resolve, reject) => {
        this.http.post<User>(this.apiService.registerLombaURL, formData, {}).subscribe((res: any) => {
            resolve(res);
          }, (error: any) => {
            console.log(error);
            resolve(error);
          });
    });
  }

  // Sprint 3 & Sprint 5
  cancelObservable() {
    this.response.unsubscribe();
  }

  // Sprint 3 & Sprint 5
  checkAvailableUsername(username: string) {
    this.response =  this.http.get(this.apiService.checkUsernameURL + '?username=' + username, {});
    return this.response;
  }

  userActivation(paramU: string, paramM: string) {
    const formData: FormData = new FormData();

    formData.append('u', paramU);
    formData.append('m', paramM);

    return this.http.post<User>(this.apiService.userActivationURL, formData);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  logout(uid: string) {
    const formData: FormData = new FormData();

    formData.append('uid', uid);

    return this.http.post<User>(this.apiService.logoutURL, formData);
  }

  subscribe(emailSubscribe: string, browser: string) {
    const formData: FormData = new FormData();
    formData.append('emailSubscribe', emailSubscribe);
    formData.append('browser', browser);
    return this.http.post<Subscribe>(this.apiService.subscribeURL, formData);
  }

  handleError(error: HttpErrorResponse) {
    // alert("lalalalalalalala");
    return throwError(error);
  }

  // // sprint 3
  // getSuggestionSearching(term: any, uid: any) {
  //   const param: string = (uid !== '' && !uid) ? '?userSearch=' + term + '&uid=' + uid : '?userSearch=' + term;
  //   this.response =  this.http.get(this.apiService.suggestSearchURL + param);
  //   return this.response;
  // }

  // // sprint 3
  // getDefaultSearching(uid: any) {
  //   const param: string = (uid !== '' && !uid) ? '&uid=' + uid : '';
  //   this.response =  this.http.get(this.apiService.suggestSearchURL + param);
  //   return this.response;
  // }

  // // sprint 3
  // cancelGetSuggestionSearching() {
  //   this.response.unsubscribe();
  // }

  // // sprint 3
  // saveSearching(term: any, uid: any) {
  //   const formData: FormData = new FormData();
  //   formData.append('term', term);
  //   formData.append('uid', uid);
  //   return new Promise((resolve, reject) => {
  //       this.http.post(this.apiService.searchURL, formData).subscribe((res: any) => {
  //           resolve(res);
  //         }, (error: any) => {
  //           reject(error);
  //         });
  //   });
  // }

   // sprint 3
   changePasswordUser(uid: any, oldPassword: any, newPassword: any, confirmationNewPassword: any) {
    const formData: FormData = new FormData();
    formData.append('uid', btoa(uid));
    formData.append('oldPassword', btoa(oldPassword));
    formData.append('newPassword', btoa(newPassword));
    formData.append('confirmNewPassword', btoa(confirmationNewPassword));
    return new Promise((resolve, reject) => {
        this.http.post(this.apiService.changePasswordUserURL, formData).subscribe((res: any) => {
            resolve(res);
          }, (error: any) => {
            reject(error);
          });
    });
  }

  // sprint 4
  getSettingNotification(uid: any) {
    const params = '?uid=' + uid;
    return this.http.get(this.apiService.getSetupUserNotificationURL + params);
  }

   // sprint 4
  saveSettingNotification(uid: any, notifFollowMe: any, notifLikeChapterStory: any, notifCommentChapterStory: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('notifFollowMe', notifFollowMe);
    formData.append('notifLikeChapterStory', notifLikeChapterStory);
    formData.append('notifCommentChapterStory', notifCommentChapterStory);

    return new Promise((resolve, reject) => {
        this.http.post(this.apiService.saveSetupUserNotificationURL, formData).subscribe((res: any) => {
            resolve(res);
          }, (error: any) => {
            reject(error);
          });
    });
  }

  // sprint 6
  updateLanguageUser(uid: any, language: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('language', language);
    return this.http.post(this.apiService.updateLanguageUserURL, formData);
  }

  getUserLanguage(uid: any) {
    const params = '?uid=' + uid;
    return this.http.get(this.apiService.getUserLanguage + params);
  }

}
