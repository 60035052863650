import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from '../../service/user.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  searchForm: FormGroup;

  loading: boolean;
  submitted = false;

  search: string;

  genSet: any;
  wattpad: any;
  mail: any;
  twitter: any;
  facebook: any;
  instagram: any;

  logo: String = '';

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private formBuilder: FormBuilder,
    private location: Location,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.logo = this.appConfig.logoWhite;
    this.searchForm = this.formBuilder.group({
      search: ['']
    });
  }

  get f() {
    return this.searchForm.controls;
  }

  actionSearch() {
    this.loading = true;
    if (this.searchForm.invalid) {
      return;
    } else {
      this.location.go('search-result?search=' + this.f.search.value);
      location.reload();
    }
  }
}
