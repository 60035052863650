import { SeoService } from 'src/app/shared/service/seo.service';
import { environment } from 'src/environments/environment.prod';
import { PublishService } from './../../service/publish.service';
import { StoriesService } from 'src/app/service/stories.service';
import { BlogService } from 'src/app/service/blog.service';
import { GwpService } from './../../service/gwp.service';
import { TranslatePipe } from './../../shared/pipe/translate.pipe';
import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { CategoryService } from 'src/app/service/category.service';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from 'src/app/service/user.service';
import { AuthGuard } from 'src/app/auth.guard';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { SettingService } from 'src/app/service/setting.service';
import { Gtag } from 'angular-gtag';

declare let jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [TranslatePipe]
})
export class HomeComponent implements OnInit {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;
  subscribeEmail: string;
  invalidEmail: boolean;
  browser: string;
  errorMessage = '';
  blogUrl = environment.blogUrl;

  limit = 6
  isDragging: boolean;
  homeCustomOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplay: true,
    center: true,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: true,
    navText: [
      '<i class="icon-arrow-left" aria-hidden="true"></i>',
      '<i class="icon-arrow-right" aria-hidden="true"></i>'
    ]
  };
  slidesHome = [
    {
      id: 'gwp',
      src: 'assets/images/banner/main.png',
      alt: 'Gramedia Writing Project',
      link: 'https://gwp.id/',
      target: '_blank',
    }
  ];
  categoryCustomOptions: OwlOptions = {
    autoWidth: true,
    margin: 10,
    dots: false,
    nav: false,
    items: 8,
  };
  slidesCategory: any[] = [];
  screenHeight: number;
  screenWidth: number;
  storyCarouselWidth = 200;
  storyCustomOptions: OwlOptions = {
    autoWidth: true,
    margin: 16,
    dots: false,
    nav: false,
    items: 6,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
  };
  slidesPopularStories: any[] = [];
  slidesNewestStories: any[] = [];
  slidesRecomendedStories: any[] = [];
  slidesPublishedStories: any[] = [];
  slidesContinueReadingStories: any[];
  callBackContinueReadingStories: boolean;
  callBackRecomendedStories: boolean;
  authorCarouselWidth = 168;
  authorCustomOptions: OwlOptions = {
    autoWidth: true,
    margin: 48,
    dots: false,
    nav: false,
    items: 6,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
  };
  slidesPopularAuthor: any[] = [];
  blogData: any[] = [];

  totalStory = 0;
  totalGenre = 0;
  totalMember = 0;

  // sprint 5
  defaultImage: string;
  defaultAuthor: string;

  defaultGenreImageMap: Map<string, string>;
  isNewsletterOnMobile: boolean;

  constructor(
    public navigateUrl: GoToService,
    public authGuard: AuthGuard,
    private router: Router,
    private translate: TranslatePipe,
    private categoryService: CategoryService,
    private storiesService: StoriesService,
    private publishService: PublishService,
    private userService: UserService,
    private gwpService: GwpService,
    private alertMassage: AlertMassageService,
    private settingService: SettingService,
    private renderer: Renderer2,
    private seo: SeoService,
    private blogService: BlogService,
    private gtag: Gtag,
  ) {
    this.getScreenSize();
    if (this.screenWidth <= 1024) {
      this.storyCarouselWidth = 124;
      this.authorCarouselWidth = 88;

      this.authorCustomOptions.margin = 24;
      this.authorCustomOptions.mouseDrag = true;
      this.authorCustomOptions.touchDrag = true;
      this.authorCustomOptions.pullDrag = true;

      this.storyCustomOptions.mouseDrag = true;
      this.storyCustomOptions.touchDrag = true;
      this.storyCustomOptions.pullDrag = true;
    }
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Home'
    });
  }

  ngOnInit() {
    this.seo.addMetaTag('Beranda');
    this.defaultImage = environment.imageStoryDefaultV2;
    this.defaultAuthor = environment.imageAuthorDefault;
    this.auth = this.authGuard.isLoggedIn();
    this.defaultGenreImageMap = new Map<string, string>();
    // this.defaultGenreImageMap.set("26", environment.imageThrillerDefault);
    // this.defaultGenreImageMap.set("100", environment.imageFictionDefault);
    this.defaultGenreImageMap.set("26", environment.imageStoryDefaultV2);
    this.defaultGenreImageMap.set("100", environment.imageStoryDefaultV2);
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }
    if (this.screenWidth <= 1024) {
      this.isNewsletterOnMobile = true;
    }

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.auth = this.authGuard.isLoggedIn();
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
      if (this.auth && !this.callBackContinueReadingStories) { // Callback after login
        this.getSlideContinueReadingStories(this.uid);
      }
      if (this.auth && !this.callBackRecomendedStories) { // Callback after login
        this.getSlideRecomendedStories(this.uid);
      }
    });

    this.getSlideHome();
    this.getSlideCategory();

    if (this.auth) {
      this.getSlideContinueReadingStories(this.uid);
      this.getSlideRecomendedStories(this.uid);
    }

    this.getSlideNewestStories();
    this.getSlidePopularStories();
    this.getTotalGSM();
    this.getSlidePublishedStories();
    this.getAuthorPopular();
    this.getBlogPosts();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  getBlogPosts() {
    this.blogService.getPosts().subscribe((res: any) => {
      if (res.items.length > 0) {
        let counter = 0;
        res.items.forEach((val: any) => {
          if (counter < 3) {
            counter++;
            let publish_date = new Date(val.date_published);
            val['tanggal'] = publish_date.toLocaleDateString('id-ID');
            this.blogData.push(val);
          }
        });
      }
    });
  }

  getSlideHome() {
    this.settingService.getSettingBanners().subscribe((data: any) => {
      if (data.status) {
        this.slidesHome = [];
        data.data.forEach(e => {
          if (e.banner_image) {
            this.slidesHome.push({
              id: e.banner_id,
              src: e.banner_image,
              alt: e.banner_title,
              link: (e.banner_link ? e.banner_link : 'https://gwp.id/'),
              target: '_blank',
            });
          }
        });
      }
    });
  }

  getSlideCategory() {
    this.categoryService.getCategory().subscribe((data: any) => {
      if (data.data) {
        this.slidesCategory = [];
        data.data.forEach(e => {
          if (e.cat_related_module && e.cat_related_module.includes('home')) {
            this.slidesCategory.push({
              src: e.cat_image ? e.cat_image : environment.imageCategoryDefautl,
              id: e.cat_id,
              text: e.cat_title,
              slug: e.cat_slug
            });
          }
        });
        this.slidesCategory.push({
          src: null,
          id: 0,
          text: 'LAST',
          slug: 'last'
        });
      }
    });
  }

  clickCategory(evt) {
    if (evt.slug === 'last') {
      return;
    } else {
      localStorage.setItem('genreLastOrderOption', 'popular');
    }
  }

  getSlideContinueReadingStories(uid) {
    this.storiesService.getContinueReading(1, this.limit, uid).subscribe((data: any) => {
      if (data.data) {
        this.callBackContinueReadingStories = true;
        this.slidesContinueReadingStories = [];
        data.data.forEach(e => {
          this.slidesContinueReadingStories.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : this.defaultImage,
            title: e.story_title,
            authorId: e.uusername,
            author: e.ufullname,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            complete: (e.story_is_complete === '0') ? false : true
          });
        });
      }
    });
  }

  getSlidePopularStories() {
    this.storiesService.getSectionStories(this.limit, 'popular').subscribe((data: any) => {
      if (data.data) {
        this.slidesPopularStories = [];
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.slidesPopularStories.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : defImage,
            title: e.story_title,
            authorId: e.uusername,
            author: e.ufullname,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            complete: (e.story_is_complete === '0') ? false : true
          });
        });
      }
    });
  }

  getTotalGSM() {
    this.gwpService.getCountSGM().subscribe((data: any) => {
      if (data.data) {
        this.totalStory = data.data.cStory;
        this.totalGenre = data.data.cGenre;
        this.totalMember = data.data.cMember;
      }
    });
  }

  getSlideNewestStories() {
    this.storiesService.getSectionStories(this.limit, 'newest').subscribe((data: any) => {
      if (data.data) {
        this.slidesNewestStories = [];
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.slidesNewestStories.push({
            id: e.story_id,
            uid: e.uid,
            src: e.story_image_cover ? e.story_image_cover : defImage,
            slug: e.story_slug,
            title: e.story_title,
            authorId: e.uusername,
            author: e.ufullname,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            complete: (e.story_is_complete === '0') ? false : true
          });
        });
      }
    });
  }

  // sprint 5
  getSlideRecomendedStories(uid: any) {
    this.storiesService.getSectionRecomendedStories('', uid).subscribe((data: any) => {
      if (data.data) {
        this.callBackRecomendedStories = true;
        this.slidesRecomendedStories = [];
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.slidesRecomendedStories.push({
            id: e.story_id,
            uid: e.uid,
            src: e.story_image_cover ? e.story_image_cover : defImage,
            slug: e.story_slug,
            title: e.story_title,
            authorId: e.uusername,
            author: e.ufullname,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            complete: (e.story_is_complete === '0') ? false : true
          });
        });
      }
    }, (error: any) => {
    });
  }

  getSlidePublishedStories() {
    this.publishService.getSectionPublishedStories(this.limit).subscribe((data: any) => {
      if (data.data) {
        this.slidesPublishedStories = [];
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.slidesPublishedStories.push({
            id: e.pstory_story_id,
            slug: e.pstory_slug,
            uid: e.uid,
            src: e.story_image_cover ? e.story_image_cover : defImage,
            title: e.pstory_title ? e.pstory_title : '-',
            authorId: e.uusername ? e.uusername : '-',
            author: e.ufullname ? e.ufullname : '-',
            description: e.pstory_sinopsis ? e.pstory_sinopsis : '-',
            price: e.pstory_price ? e.pstory_price : 0,
            link: e.pstory_link_ecomerce,
            book_format: (e.pstory_book_format_name && e.pstory_book_format_name.length > 0) ? e.pstory_book_format_name : null,
            book_format_v2: (e.pstory_book_format_name_v2 && e.pstory_book_format_name_v2.length > 0) ? e.pstory_book_format_name_v2 : null
          });
        });
      }
    });
  }

  getAuthorPopular() {
    this.userService.getPopularAuthor(5, 1, '', this.uid !== '' ? this.uid : '').subscribe((data: any) => {
      if (data.data) {
        this.slidesPopularAuthor = [];
        data.data.forEach(e => {
          this.userService.getUserById(e.uid, e.uid).subscribe((data: any) => {
            this.slidesPopularAuthor.push({
              uid: e.uid,
              src: e.uimage || null,
              author: e.ufullname || e.uusername,
              authorId: e.uusername || '',
              stories: e.total_stories,
              followed: e.followed,
              following: data.data.counter.following,
              follower: data.data.counter.follower,
            });
          });
        });
      }
    });
  }

  clickViewAllStories(evt) {
    localStorage.setItem('genreLastOrderOption', evt.order);
  }

  setCategoryWidth(text: string = '') {
    let width = 134;
    const str = text.replace(/\s/g, '');
    if (str !== '') {
      width = (str.length * 10) + 20;
    }
    return width;
  }

  // Sprint 5
  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  onErrorImageAuthor(event) {
    event.target.src = this.defaultAuthor;
  }

  // sprint 5
  eventIwant() {
    if (this.auth) {
      this.navigateUrl.goTo('cstory');
    } else {
      jQuery('#loginModal').appendTo('body').modal('show');
    }
  }


  followAuthor(author) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    } else {
      this.userService.followUser(this.uid, author.uid, 'follow').then((data: any) => {
        if (data.status) {
          author.followed = true;
        }
      }).catch((error: any) => {
      });
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendSubscribe(): void {
    this.invalidEmail = false;
    // send message to subscribers via observable subject
    if (this.subscribeEmail !== '' && this.validateEmail(this.subscribeEmail)) {
      this.loading = true;
      this.browser = window.navigator.userAgent;
      this.userService.subscribe(this.subscribeEmail, this.browser).subscribe((data: any) => {
        this.loading = false;
        if (data.status) {
          if (data.data && data.data.mail) {
            this.errorMessage = this.alertMassage.show('success', this.translate.transform('subscribeSuccess'));
          } else {
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('subscribeNotSend'));
          }
        } else {
          if (data.data && data.data.message && data.data.message === 'exist') {
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('subscribeExist'));
          } else {
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('subscribeFailed'));
          }
        }
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
        setTimeout(() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);
      }, () => {
        this.errorMessage = this.alertMassage.show('failed', this.translate.transform('subscribeFailed'));
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
        setTimeout(() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);
        this.loading = false;
      });
    } else {
      this.invalidEmail = true;
    }
  }

}
