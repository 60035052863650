import { GwpService } from './service/gwp.service';
import { RegisterService } from './service/register.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { GlobalErrorHandler } from './global-error-handler';

import { WindowRefService } from './service/window-ref.service';
import { routing } from './app.routing';
import { AuthGuard } from './auth.guard';
import { GlobalVariables } from './global.variables';

import { AppComponent } from './app.component';

import { ActivityUserService } from './service/activity-user.service';
import { PageService } from './service/page.service';
import { EventService } from './service/event.service';
import { UserService } from './service/user.service';
import { CategoryService } from './service/category.service';
import { StoriesService } from './service/stories.service';
import { StoryService } from './service/story.service';
import { PublishService } from './service/publish.service';
import { BankService } from './service/bank.service';
import { StoneService } from './service/stone.service';
import { RedeemService } from './service/redeem.service';
import { SettingService } from './service/setting.service';
import { LoginService } from './service/login.service';
import { TopupStonesService } from './service/topup-stones.service';
import { NotifService } from './service/notif.service';
import { TransactionsService } from './service/transactions.service';
import { BlogService } from './service/blog.service';
import { ApiService } from './service/api.service';

import { TemplateModule } from './template/template.module';
import { DatePipe } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBarComponent } from './component/mat-snack-bar/mat-snack-bar.component';

import { InterceptService } from './service/intercept.service';

// New Adding By GWP Team Here
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { APP_CONFIG, appConfig } from './shared/model/application.properties';
import { SharedModule } from './shared/shared/shared.module';
import { MatSlideToggleModule, MatSidenavModule } from '@angular/material';
import { CarouselModule } from 'ngx-owl-carousel-o';

// sprint 3
import { SearchingService } from 'src/app/service/searching.service';

// sprint 4
import { CommentService } from 'src/app/service/comment.service';

// sprint 6
import { MyLibraryService } from 'src/app/service/my-library.service';
import { SomethingChangeService } from 'src/app/service/something-change.service';

// sprint 8f
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';

// 
import { GtagModule } from 'angular-gtag';
import { environment } from 'src/environments/environment';
import { QuillModule, QuillService } from 'ngx-quill';

registerLocaleData(localeId, 'id');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TemplateModule,
    MatSnackBarModule,
    SharedModule.forRoot(),
    MatSlideToggleModule,
    MatSidenavModule,
    CarouselModule,
    NgxMaskModule.forRoot(),
    GtagModule.forRoot({ trackingId: environment.googleAnalyticsMeasurementID, trackPageviews: true }),
    QuillModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'id-ID' },
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: { duration: 2500 }
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    MatSnackBarComponent,
    InterceptService,
    WindowRefService,
    AuthGuard,
    GlobalVariables,
    ActivityUserService,
    PageService,
    EventService,
    UserService,
    CategoryService,
    StoriesService,
    StoryService,
    PublishService,
    StoneService,
    RedeemService,
    BankService,
    SettingService,
    TopupStonesService,
    NotifService,
    TransactionsService,
    BlogService,
    ApiService,
    DatePipe,
    // Revamp
    GwpService,
    LoginService,
    RegisterService,
    SearchingService,
    CommentService,
    MyLibraryService,
    SomethingChangeService,
    QuillService,
    { provide: APP_CONFIG, useValue: appConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
