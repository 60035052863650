import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from './api.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class TopupStonesService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {}

  // topupStone(params) {
  //   let SERVER_KEY: any;
  //   if (location.origin === 'https://gwp.id') {
  //     SERVER_KEY = environment.midtransServerKeyProd;
  //   } else {
  //     SERVER_KEY = environment.midtransServerKeyDev;
  //   }

  //   const AUTH_STRING = btoa(SERVER_KEY + ':');

  //   const date = this.datePipe.transform(new Date(), 'yyyyMMdd');
  //   const order_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
  //   const time = new Date().getTime().toString();
  //   const rand_num = (Math.floor(Math.random() * 10000) + 10000)
  //     .toString()
  //     .substring(1);
  //   const order_id = 'TUS-' + date + '-' + time + '-' + rand_num;
  //   const name =
  //     params.testing.ufullname !== '' &&
  //     params.testing.ufullname !== null &&
  //     params.testing.ufullname !== 'NULL'
  //       ? params.testing.ufullname
  //       : params.testing.uusername;
  //   const address =
  //     params.testing.uaddress1 !== '' &&
  //     params.testing.uaddress1 !== null &&
  //     params.testing.uaddress1 !== 'NULL'
  //       ? params.testing.uaddress1
  //       : params.testing.uaddress2 !== '' &&
  //         params.testing.uaddress2 !== null &&
  //         params.testing.uaddress2 !== 'NULL'
  //       ? params.testing.uaddress2
  //       : '';

  //   const arrayTransaction = {
  //     order_id: order_id,
  //     gross_amount: params.tr_nominal,
  //     order_date: order_date,
  //   };

  //   const arrayItem = {
  //     id: params.tr_id,
  //     price: params.tr_nominal,
  //     quantity: 1,
  //     amount: params.tr_qty,
  //     bonus: params.tr_free,
  //     name: params.tr_qty + ' Blue Stones',
  //     merchant_name: 'Gramedia',
  //   };

  //   // OPTIONAL
  //   const billing_address = {
  //     first_name: name,
  //     address: address,
  //     city: params.testing.ucity,
  //     phone: params.testing.uphone,
  //     country_code: 'IDN',
  //   };

  //   const arrayCustomer = {
  //     user_id: params.testing.uid,
  //     first_name: name,
  //     email: params.testing.uemail,
  //     phone: params.testing.uphone,
  //     billing_address: billing_address,
  //   };

  //   const stringTransaction = JSON.stringify(arrayTransaction);
  //   const stringItem = JSON.stringify(arrayItem);
  //   const stringCustomer = JSON.stringify(arrayCustomer);

  //   const formData: FormData = new FormData();

  //   formData.append('transaction_details', stringTransaction);
  //   formData.append('item_details', stringItem);
  //   formData.append('customer_details', stringCustomer);

  //   return this.http.post<any[]>(this.apiService.getTokenSnapURL, formData);
  // }
}
