import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../../service/category.service';
import { SettingService } from '../../../service/setting.service';

import { DateAdapter, MAT_DATE_FORMATS} from '@angular/material';

import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/date.adapter';

// sprint xx + sprint 3
import { Province } from '../../../model/province.model';
import { City } from '../../../model/city.model';
import { User } from '../../../model/users.model';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { environment } from 'src/environments/environment.prod';
import { InputFilterService } from 'src/app/shared/service/input-filter.service';
import { Nl2brPipe } from './../../../shared/pipe/nl2br.pipe';

declare let jQuery: any;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    Nl2brPipe
  ]
})
export class EditProfileComponent implements OnInit {
  @Input() dataProfile: User;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() saveProfileEvent = new EventEmitter<any>();
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;
  @Output() changePictureEvent = new EventEmitter<any>();
  @Output() changeCoverEvent = new EventEmitter<any>();

  auth: boolean;
  loading: boolean;
  uid: string;

  defaultCoverImage: string;
  defaultProfileImage: string;
  coverImage: any;
  profileImage: any;

  profileForm: FormGroup;
  validForm: boolean;
  errorMessage = '';
  genderList = [{id: 'M', name: 'Laki-laki'}, {id: 'F', name: 'Perempuan'}];
  selectedGender: any;
  categoryList: any[];
  provinceList: Province[];
  selectedProvince: any;
  cityList: City[];
  selectedCity: any;

  selectedCategories: String[];
  currentUsername = '';
  enableUsername = false;
  birthDay: any;
  formatBirthDate: any;
  startDate: any = new Date();
  minDate = new Date(1945, 12, 31);
  maxDate = new Date();
  selectedProfileFile: File;
  selectedCoverFile: File;

  constructor(
    private route: ActivatedRoute,
    public authGuard: AuthGuard,
    private translate: TranslatePipe,
    private formBuilder: FormBuilder,
    public filter: InputFilterService,
    private userService: UserService,
    private categoryService: CategoryService,
    private settingService: SettingService,
    private datePipe: DatePipe,
    private nl2br: Nl2brPipe) {

    }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }

    this.defaultCoverImage = environment.imageCoverDefault;
    this.defaultProfileImage = environment.imageAuthorDefault;

    this.birthDay = this.dataProfile && this.dataProfile.ubirthday && this.dataProfile.ubirthday !== '0000-00-00' ?
    new Date(this.dataProfile.ubirthday) : '';
    this.formatBirthDate = '';
    if (this.birthDay !== '') {
      this.formatBirthDate = this.datePipe.transform(this.birthDay, 'dd/MM/yyyy');
    }

    let dsc = '';
    if (this.dataProfile && this.dataProfile.udescription) {
      dsc = this.dataProfile.udescription;
      const re = /\\n/gi;
      dsc = dsc.replace(re, '\r\n');
    }

    this.profileForm = this.formBuilder.group({
      uid: [this.uid, Validators.required],
      cover: [this.dataProfile && this.dataProfile.ucover_image ? this.dataProfile.ucover_image : ''],
      image: [this.dataProfile && this.dataProfile.uimage ? this.dataProfile.uimage : ''],
      username: [this.dataProfile && this.dataProfile.uusername ? this.nl2br.transform(this.dataProfile.uusername, 'blank') : '',
      Validators.compose([Validators.maxLength(50), Validators.minLength(4), Validators.required])],
      fullname: [this.dataProfile && this.dataProfile.ufullname ?
        this.nl2br.transform(this.dataProfile.ufullname, 'blank') : '', Validators.required],
      email: [this.dataProfile && this.dataProfile.uemail ? this.dataProfile.uemail : ''],
      description: [this.dataProfile && this.dataProfile.udescription ? this.nl2br.transform(dsc, 'blank') : '',
      Validators.compose([Validators.maxLength(250)])],
      birthPlace: [this.dataProfile && this.dataProfile.ubirthday_place ? this.dataProfile.ubirthday_place : '', Validators.required],
      birthDate: [this.formatBirthDate , Validators.required],
      birthDay: [this.birthDay !== '' ? this.birthDay : ''],
      sex: [this.dataProfile && this.dataProfile.usex ? this.dataProfile.usex : ''],
      address: [this.dataProfile && this.dataProfile.uaddress1 ? this.dataProfile.uaddress1 : '',
      Validators.compose([Validators.maxLength(250)])],
      province: [this.dataProfile && this.dataProfile.upropinsi ? this.dataProfile.upropinsi : ''],
      provinceId: [this.dataProfile && this.dataProfile.upropinsi_id ? this.dataProfile.upropinsi_id : '', Validators.required],
      city: [this.dataProfile && this.dataProfile.ucity ? this.dataProfile.ucity : ''],
      cityId: [this.dataProfile && this.dataProfile.ucity_id ? this.dataProfile.ucity_id : '', Validators.required],
      phone: [this.dataProfile && this.dataProfile.uphone ? this.dataProfile.uphone : '', Validators.compose([Validators.maxLength(13)])],
      genre: [this.dataProfile && this.dataProfile.ufavorite_category ? this.dataProfile.ufavorite_category : '', Validators.required],
      facebook: [this.dataProfile && this.dataProfile.ufacebook_id ? this.dataProfile.ufacebook_id : ''],
      twitter: [this.dataProfile && this.dataProfile.utwitter_id ? this.dataProfile.utwitter_id : ''],
      instagram: [this.dataProfile && this.dataProfile.uinstagram_id ? this.dataProfile.uinstagram_id : ''],
    });

    this.currentUsername = this.dataProfile && this.dataProfile.uusername ? this.dataProfile.uusername : '';

    this.coverImage = this.dataProfile && this.dataProfile.ucover_image ? this.dataProfile.ucover_image : this.defaultCoverImage;
    this.profileImage = this.dataProfile && this.dataProfile.uimage ? this.dataProfile.uimage : this.defaultProfileImage;

    if (this.dataProfile && this.dataProfile.ufavorite_category) {
      this.selectedCategories = this.dataProfile.ufavorite_category.split(',');
    }

    this.getCategoryList();
    this.getProvinceList();

    if (!this.form.provinceId.value || this.form.provinceId.value === '' || this.form.provinceId.value === '0') {
      this.profileForm.controls.cityId.disable();
    } else {
      this.profileForm.controls.cityId.enable();
    }

    if (this.dataProfile && this.dataProfile.usex) {
      this.selectedGender = this.dataProfile.usex;
    }
    if (this.dataProfile && this.dataProfile.upropinsi_id) {
      this.selectedProvince = this.dataProfile.upropinsi_id;
      this.getCityList(this.dataProfile.upropinsi_id);
    }
    if (this.dataProfile && this.dataProfile.ucity_id) {
      this.selectedCity = this.dataProfile.ucity_id;
    }

  }

  get form() {
    return this.profileForm.controls;
  }

  setBirthDate(event) {
    const temp = event.target.value;
    if (temp && temp !== '' && temp.length === 10) {
      const dt = temp.split('/');
      const tgl = dt[2] + '-' + dt[1] + '-' + dt[0];
      this.birthDay = new Date(tgl);
      if (this.birthDay.getTime()) {
        this.formatBirthDate = this.datePipe.transform(this.birthDay, 'dd/MM/yyyy');
        this.form.birthDate.setValue(this.formatBirthDate);
        this.form.birthDay.setValue(this.birthDay);
      }
    }
  }

  todate(value) {
    if (value === '') {
      this.form.birthDate.setValue('');
      this.form.birthDay.setValue('');
      this.formatBirthDate = '';
      this.birthDay = '';
    } else {
      this.form.birthDate.setValue(this.datePipe.transform(new Date(value), 'dd/MM/yyyy'));
      this.form.birthDay.setValue(new Date(value));
      this.formatBirthDate = this.datePipe.transform(new Date(value), 'dd/MM/yyyy');
      this.birthDay = new Date(value);
    }
  }

  getProvinceList() {
    this.settingService.getProvince().subscribe((data: any) => {
      this.provinceList = data.data;
    }, (error: any) => {
    });
  }

  getCityList(id) {
    if (id && id !== undefined) {
      this.settingService.getCityByProvinceCode(id).subscribe((data: any) => {
        if (data.data) {
          this.cityList = data.data;
        }
      }, (error: any) => {
      });
    }
  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      this.categoryList = [];
      data.data.forEach(e => {
        if (e.cat_related_module && e.cat_related_module.includes('myprofile')) {
          this.categoryList.push({
            id: e.cat_id,
            name: e.cat_title
          });
        }
      });
    }, (error: any) => {
    });
  }

  async eventProvince(event) {
    if (event && event.province_name && event.province_name !== undefined) {
      this.form.province.setValue(event.province_name);
    }
    this.form.cityId.setValue('');
    this.form.city.setValue('');
    if (!this.form.provinceId.value || this.form.provinceId.value === '' || this.form.provinceId.value === '0') {
      this.profileForm.controls.cityId.disable();
    } else {
      this.profileForm.controls.cityId.enable();
      this.getCityList(event.province_code);
    }
  }

  async eventCity(event) {
    if (event && event.city_name && event.city_name !== undefined) {
      this.form.city.setValue(event.city_name);
    }
  }

  async findDuplicateUsername(event) {
    const checkUsername = event.target.value;
    if (checkUsername === '') {
      return;
    } else if (checkUsername === this.currentUsername) {
      this.enableUsername = false;
      return;
    }

    if (checkUsername) {
      this.userService.checkAvailableUsername(checkUsername).subscribe((data: any) => {
        if (data.status) {
          this.enableUsername = true;
        } else {
          this.enableUsername = false;
        }
      }, (error) => {
        this.enableUsername = true;
      });
    } else {
      this.userService.cancelObservable();
    }
  }

  uploadImageProfile() {
    jQuery('#file').trigger('click');
  }

  uploadImageCover() {
    jQuery('#cover').trigger('click');
  }

  onFileUpload(event) {
    this.changePictureEvent.emit(event);
    // this.selectedProfileFile = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = () => {
    //   const image: any = new Image();
    //   image.src = reader.result;
    //   image.onload = function() {
    //     jQuery('#imageProfilePreview').attr('src', image.src);
    //   };
    // };

    // reader.readAsDataURL(this.selectedProfileFile);
  }

  onFileUploadCover(event) {
    this.changeCoverEvent.emit(event);
    // this.selectedCoverFile = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = () => {
    //   const image: any = new Image();
    //   image.src = reader.result;
    //   image.onload = function() {
    //     jQuery('#imageCoverPreview').attr('src', image.src);
    //   };
    // };

    // reader.readAsDataURL(this.selectedCoverFile);
  }

  checkValidForm() {
    if (!this.profileForm.valid) {
        return true;
    } else {
        return false;
    }
  }

  saveProfile() {
    this.validForm = this.checkValidForm();
    if (!this.validForm && !this.enableUsername) {
      this.loading = true;
      this.form.birthDate.setValue(this.datePipe.transform(this.birthDay, 'yyyy/MM/dd'));
      this.settingService.updateProfile(this.selectedProfileFile, this.selectedCoverFile, this.form).subscribe( (data: any) => {
        this.loading = false;
        if (data.status) {
          this.saveProfileEvent.emit({
            response: 'success',
            message: this.translate.transform('profileChangeSuccess')
          });
        } else {
          this.saveProfileEvent.emit({
            response: 'failed',
            message: this.translate.transform('profileChangeFailed')
          });
        }
      }, (error: any) => {
        this.loading = false;
      }, () => {
        this.form.birthDate.setValue(this.datePipe.transform(this.birthDay, 'dd/MM/yyyy'));
      });
    }
  }

  receiveMessage($event) {
    this.profileForm.controls.genre.setValue($event);
  }

  onErrorImageCover(event) {
    event.target.src = this.defaultCoverImage;
    this.coverImage = this.defaultCoverImage;
  }

  onErrorImage(event) {
    event.target.src = this.defaultProfileImage;
    this.profileImage = this.defaultProfileImage;
  }

  setDefaultCover() {
    this.coverImage = this.defaultCoverImage;
  }

  setDefaultProfile() {
    this.profileImage = this.defaultProfileImage;
  }

  changeDate(event) {
    this.form.birthDate.setValue(event.value);
  }

}
