import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// declare const environment;
// const BASE_URL = ENV.API_URL;
// const SETUP_ID = ENV.SETUP_ID;
// const VERSION = ENV.VERSION;

@Injectable()
export class ApiService {

  constructor() {
    // console.log(environment.production);
  }

  /* URL API */
    urlAPI: string = environment.apiUrl;
    // urlAPI: String = 'http://192.168.1.2/gwp-api/index.php/';

    /*---------------------------------- Activity User URL API Service ------------------------- */
    activitiesAPI: string = this.urlAPI + 'activities_api/';

    getLikeStoryByIdURL: string         = this.activitiesAPI + 'likeStoryById/';
    getLikeChapterByIdURL: string       = this.activitiesAPI + 'likeChapterById/';
    likeStoryURL: string                = this.activitiesAPI + 'likeStory/';
    unlikeStoryURL: string              = this.activitiesAPI + 'unlikeStory/';
    likeChapterURL: string              = this.activitiesAPI + 'likeChapter/';
    unlikeChapterURL: string            = this.activitiesAPI + 'unlikeChapter/';
    likeCommentStoryURL: string         = this.activitiesAPI + 'likeCommentStory';
    unlikeCommentStoryURL: string      = this.activitiesAPI + 'unlikeCommentStory';
    likeCommentChapterURL: string       = this.activitiesAPI + 'likeCommentChapter';
    unlikeCommentChapterURL: string    = this.activitiesAPI + 'unlikeCommentChapter';
    storyViewURL: string                = this.activitiesAPI + 'storyView/';
    storyViewLogURL: string             = this.activitiesAPI + 'storyViewLog/';
    chapterViewLogURL: string           = this.activitiesAPI + 'chapterViewLog/';
    addCommentStoryURL: string          = this.activitiesAPI + 'addCommentStory';
    addCommentChapterURL: string        = this.activitiesAPI + 'addCommentChapter';
    reportCommentStoryURL: string       = this.activitiesAPI + 'reportCommentStory/';
    reportCommentChapterURL: string     = this.activitiesAPI + 'reportCommentChapter/';
    getlikeCommentStoryURL: string      = this.activitiesAPI + 'getlikeCommentStory/';
    getlikeCommentChapterURL: string    = this.activitiesAPI + 'getlikeCommentChapter/';
    /* ----------------------------------------------------------------------------------------- */


    /*---------------------------------- Comment URL API SERVICE ---------------------------------- */
    // sprint 4
    commentAPI: string                        = this.urlAPI     + 'comment_api/';

    getCommentStoryURL: string                = this.commentAPI + 'getCommentStory/';
    sendCommentStoryURL: string               = this.commentAPI + 'sendCommentStory/';
    likedCommentStoryURL: string              = this.commentAPI + 'likeCommentStory/';
    reportedCommentStoryURL: string           = this.commentAPI + 'reportCommentStory/';
    reportedStoryURL: string                  = this.commentAPI + 'reportStory/';
    deletedCommentStoryURL: string            = this.commentAPI + 'deleteCommentStory/';
    deletedCommentChildStoryURL: string       = this.commentAPI + 'deleteCommentChildStory/';
    pinedCommentStoryURL: string              = this.commentAPI + 'pinCommentStory/';
    unPinedCommentStoryURL: string            = this.commentAPI + 'unPinCommentStory/';

    getCommentChapterURL: string              = this.commentAPI + 'getCommentChapter/';
    sendCommentChapterURL: string             = this.commentAPI + 'sendCommentChapter/';
    likedCommentChapterURL: string            = this.commentAPI + 'likeCommentChapter/';
    reportedCommentChapterURL: string         = this.commentAPI + 'reportCommentChapter/';
    deletedCommentChapterURL: string          = this.commentAPI + 'deleteCommentChapter/';
    deletedCommentChildChapterURL: string     = this.commentAPI + 'deleteCommentChildChapter/';
    pinedCommentChapterURL: string            = this.commentAPI + 'pinCommentChapter/';
    unPinedCommentChapterURL: string          = this.commentAPI + 'unPinCommentChapter/';

    getReportCommentTypeURL: string           = this.commentAPI + 'get_report_comment_type/';

    sendCommentEditStoryURL: string           = this.commentAPI + 'sendCommentEditStory/';
    sendCommentEditChapterURL: string         = this.commentAPI + 'sendCommentEditChapter/';
    /* ----------------------------------------------------------------------------------------- */

    /*---------------------------------- Bank URL API Service ---------------------------------- */
    bankAPI: string = this.urlAPI + 'bank_api/';
    /* ----------------------------------------------------------------------------------------- */


    /*---------------------------------- Category URL API Service ------------------------------ */
    categoryAPI: string       = this.urlAPI + 'category_api/';
    getCategoryURL: string    = this.categoryAPI + 'category';
    /* ----------------------------------------------------------------------------------------- */

    /* ------------------- Event URL API Service ------------------- */
    eventAPI: string = this.urlAPI + 'event_api/';
    eventURL: string          = this.eventAPI + 'event/';
    eventDetailURL: string    = this.eventAPI + 'eventDetail/';
    /* ------------------------------------------------------------- */

    /*---------------------------------- My Profile URL API Service ---------------------------- */
    myProfileAPI: string = this.urlAPI + 'myprofile_api/';

    storyByUidURL: string                 = this.myProfileAPI + 'storyByUid/';

    readingByIdURL: string                = this.myProfileAPI + 'readingByid/';
    followByUidURL: string                = this.myProfileAPI + 'followByUid/';
    /* ----------------------------------------------------------------------------------------- */


    /*---------------------------------- Notification URL API Service -------------------------- */
    notificationAPI: string                 = this.urlAPI + 'notification_api/';

    getNotifURL: string                     = this.notificationAPI + 'get_notification_user/';

    // sprint 4
    getSetupUserNotificationURL: string     = this.notificationAPI + 'notificationUser/';
    saveSetupUserNotificationURL: string    = this.notificationAPI + 'notificationUserUpdate/';

    // sprint 6
    counterNotification: string   = this.notificationAPI + 'get_new_notification_user';
    makeReadNotification: string  = this.notificationAPI + 'makeReadNotification';
    /* ----------------------------------------------------------------------------------------- */


    /*---------------------------------- Pages URL API Service --------------------------------- */
    pageAPI: string = this.urlAPI + 'page_api/';
    pagesURL: string  = this.pageAPI + 'page/';

    /* ----------------------------------------------------------------------------------------- */


    /*------------------------------- Published Stories URL API Service ------------------------ */
    publishStoryAPI: string = this.urlAPI + 'publish_api/';

    // sprint 4
    publishStoryPaginationURL: string = this.publishStoryAPI + 'publishPagination/';
    publishStoryByIdURL: string       = this.publishStoryAPI + 'publishById/';

    // After Revamp
    sectionPublishedStoryURL: string  = this.publishStoryAPI + 'sectionPublish';
    publishedStoriesImagesURL: string  = this.publishStoryAPI + 'getPublishedImages';
    /* ----------------------------------------------------------------------------------------- */


    /* ------------------- Redeem URL API Service ------------------- */
    redeemAPI: string = this.urlAPI + 'redeem_api/';
    redeemURL: string     = this.redeemAPI + 'redeem/';
    /* -------------------------------------------------------------- */

    /* ------------------- Search URL API Service ------------------- */
    searchAPI: string = this.urlAPI + 'search_api/';
    /* -------------------------------------------------------------- */

    /* ------------------- Setting URL API Service ------------------- */
    settingAPI: string            = this.urlAPI + 'setting_api/';
    updateProfileURL: string      = this.settingAPI + 'updateProfile';
    changeProfilePictureURL: string = this.settingAPI + 'changeProfilePicture';
    changeProfileCoverURL: string = this.settingAPI + 'changeCoverPicture';
    getSettingLombaURL: string    = this.settingAPI + 'setting';
    getSettingMaintenance: string = this.settingAPI + 'active';
    getSettingBanners: string     = this.settingAPI + 'banner';
    getSettingTerm: string        = this.settingAPI + 'term';
    getSettingTermContent: string = this.settingAPI + 'termContent';

    // sprint 3 + sprint 5
    provinceAPI: string   = this.urlAPI + 'province_api/';
    provinceURL: string   = this.provinceAPI + 'province';
    cityAPI: string       = this.urlAPI + 'city_api/';
    cityURL: string       = this.cityAPI + 'city';

    /* -------------------------------------------------------------- */


    /* ------------------- Stone URL API Service ------------------- */
    stoneAPI: string  = this.urlAPI + 'stone_api/';
    /* --------------------------------------------------------------- */

    /* ------------------- Stories URL API Service ------------------- */
    storiesAPI: string = this.urlAPI + 'stories_api/';
    // countStoriesURL: string                       = this.storiesAPI + 'countStories';
    paginationStoriesURL: string                  = this.storiesAPI + 'storiesPagination';

    // chaptersByStoryIdNewURL: string               = this.storiesAPI + 'storyChaptersNew/';
    // chaptersByStoryIdDetailURL: string            = this.storiesAPI + 'storyChaptersDetail/';

    commentChapterURL: string                     = this.storiesAPI + 'commentChapter/';

    continueReadingStoriesURL: string             = this.storiesAPI + 'continueReading';
    popularStoriesAllURL: string                  = this.storiesAPI + 'popularStoriesAll';
    lockChapterURL: string                        = this.storiesAPI + 'lockChapter';
    unlockChapterURL: string                      = this.storiesAPI + 'unlockChapter';
    getUnlockChapterURL: string                   = this.storiesAPI + 'getUnlockChapterByParams';
    storiesByCategoryURL: string                  = this.storiesAPI + 'storiesByCategory/';
    storiesByCategoryPaginationURL: string        = this.storiesAPI + 'storiesByCategoryPagination/';
    popularStoriesByCategoryURL: string           = this.storiesAPI + 'popularStoriesByCategory/';
    popularStoriesByCategoryPaginationURL: string = this.storiesAPI + 'popularStoriesByCategoryPagination/';

    // After Revamp
    allStoriesURL: string                         = this.storiesAPI + 'stories';
    sectionStoriesURL: string                     = this.storiesAPI + 'sectionStories';
    authorStoriesUrl: string                      = this.storiesAPI + 'authorStories';
    createStoriesURL: string                      = this.storiesAPI + 'createStories';
    updateStoriesURL: string                      = this.storiesAPI + 'updateStories';
    deleteStoriesURL: string                      = this.storiesAPI + 'deleteStories';
    getstoriesByIdURL: string                     = this.storiesAPI + 'storiesById/'; // For Author
    getStoriesDetailByIdURL: string               = this.storiesAPI + 'storiesDetailById/'; // For Visitor
    chapterVoteByIdURL: string                    = this.storiesAPI + 'chapterVoteById';

    createChapterURL: string                      = this.storiesAPI + 'createChapter';
    updateChapterURL: string                      = this.storiesAPI + 'updateChapter';
    uploadImageChapterURL: string                 = this.storiesAPI + 'uploadImageChapter';
    getChapterByIdURL: string                     = this.storiesAPI + 'chapterById';
    getChaptersAuthorByStoryIdURL: string         = this.storiesAPI + 'chaptersAuthorByStories'; // For Author
    getChaptersByStoryIdURL: string               = this.storiesAPI + 'chaptersByStories'; // For Visitor
    getChaptersDetailByIdURL: string              = this.storiesAPI + 'chaptersDetailById';
    deleteChapterURL: string                      = this.storiesAPI + 'deleteChapter';
    publishChapterURL: string                     = this.storiesAPI + 'publishChapter';
    reorderChapterURL: string                     = this.storiesAPI + 'reorderChapter';
    publishStoryURL: string                       = this.storiesAPI + 'publishStory';

    // sprint 5
    sectionRecomendedStoriesURL: String           = this.storiesAPI + 'sectionRecomendationStories';
    recomendedStoriesURL: String                  = this.storiesAPI + 'recomendationStories';

    /* --------------------------------------------------------------- */

    /* ------------- Additional Stories URL API Service ------------- */
    storyAPI: string  = this.urlAPI + 'story_api/';
    storyURL: string          = this.storyAPI + 'story/';
    commentStoryURL: string   = this.storyAPI + 'comment/';
    addStoryURL: string       = this.stoneAPI + 'add';
    /* --------------------------------------------------------------- */

    /* ------------------- User URL API Service ------------------- */
    userAPI: string   = this.urlAPI + 'user_api/';

    loginURL: string                 = this.userAPI + 'login'; // # Sprint 1
    loginGoogleURL: string           = this.userAPI + 'loginGoogle'; // # Sprint 1
    getUserURL: string               = this.userAPI + 'user/'; // Sprint 2
    popularAuthorURL: string         = this.userAPI + 'popularAuthor'; // # Sprint 2
    forgotPasswordURL: string        = this.userAPI + 'sendEmail'; // # Sprint 1
    updatePasswordURL: string        = this.userAPI + 'updatePassword'; // # Sprint 1
    registerURL: string              = this.userAPI + 'register'; // # Sprint 1
    registerGoogleCheckURL: string   = this.userAPI + 'registerGoogleCheck'; // Sprint 3
    registerGoogleURL: string        = this.userAPI + 'registerGoogle'; // # Sprint 1
    userActivationURL: string        = this.userAPI + 'userActivation';
    subscribeURL: string             = this.userAPI + 'subscribe'; // Sprint 5
    logoutURL: string                = this.userAPI + 'logout';
    checkUsernameURL: string         = this.userAPI + 'checkUsername';
    getFollowerUrl                   = this.userAPI + 'listFollowerById'; // Sprint 2
    getFollowingUrl                  = this.userAPI + 'listFollowingById'; // Sprint 2
    followAuthorUrl                  = this.userAPI + 'followAuthor'; // Sprint 2
    changePasswordUserURL            = this.userAPI + 'changePasswordUser';
    updateLanguageUserURL            = this.userAPI + 'update_language_user';
    getUserLanguage                  = this.userAPI + 'get_user_language';
    resendVerifURL                   = this.userAPI + 'resendVerification';

    /* -------------------------------------------------------------- */

    /* ------------------- Transaction URL API Service ------------------- */
    transactionAPI: string  = this.urlAPI + 'transaction_api/';

    getTokenSnapURL: string                 = this.transactionAPI + 'getTokenSnap';
    /* ------------------------------------------------------------------- */

    // After Sam & Redha

    /* ------------------- GENERAL GWP ------------------- */
    gwpAPI: string  = this.urlAPI + 'gwp_api/';
    countSGMURL: string     = this.gwpAPI + 'countSGM';
    /* ------------------------------------------------ */

    /* ------------------- Lomba GWP / The Writers' Show ------------------- */
    lombaAPI: string           = this.urlAPI + 'competition_api/';
    registerLombaURL: string   = this.lombaAPI + 'register';
    checkUserLombaURL: string  = this.lombaAPI + 'checkUserLomba';
    /* -------------------------------------------------- */

    /* ------------------- ABOUT US ------------------- */
    aboutusAPI: string  = this.urlAPI + 'aboutus_api/';
    aboutusURL: string     = this.aboutusAPI + 'aboutus';
    socialProofsURL: string = this.aboutusAPI + 'socialProofs';
    /* ------------------------------------------------ */

    /* ------------------- GUIDANCE ------------------- */
    guidanceAPI: string  = this.urlAPI + 'guidance_api/';
    guidanceURL: string     = this.guidanceAPI + 'guidance';
    /* ------------------------------------------------- */

    /* ------------------- FAQ ------------------- */
    userHelpAPI: string  = this.urlAPI + 'userhelp_api/';
    userHelpURL: string     = this.userHelpAPI + 'userhelp';
    /* ------------------------------------------- */

    /* ------------------- DISCLAIMER ------------------- */
    disclaimerAPI: string  = this.urlAPI + 'disclaimer_api/';
    disclaimerURL: string     = this.disclaimerAPI + 'disclaimer';
    /* -------------------------------------------------- */


    /* ------------------- User Search URL API Service ------------------- */
    // sprint 3 & Sprint 5
    userSearchAPI: string     = this.urlAPI + 'user_search_api/';
    historySearchURL: string  = this.userSearchAPI + 'history';
    searchURL: string         = this.userSearchAPI + 'search';
    suggestSearchURL: string  = this.userSearchAPI + 'suggest';
    deleteSearchURL: string   = this.userSearchAPI + 'delete';
    clearSearchURL: string    = this.userSearchAPI + 'clear';

    searchAuthorURL: string   = this.userSearchAPI + 'author';
    searchStoryURL: string    = this.userSearchAPI + 'story';
    searchTagURL: string      = this.userSearchAPI + 'tag';
    /* --------------------------------------------------------------- */

    // Sprint 6
    /* ------------------- My Library ------------------- */
    myLibraryAPI: string           = this.urlAPI + 'mylibrary_api/';
    getMylibraryURL: string        = this.myLibraryAPI + 'get_all_my_library';
    addToMylibraryURL: string      = this.myLibraryAPI + 'add_story_to_my_library';
    removeFromMyLibraryURL: string = this.myLibraryAPI + 'remove_story_from_my_library';
    /* --------------------------------------------------------------- */

    /* ------------------- Wordpress BLOG -------------------- */
    blogAPI: string = this.urlAPI + 'blog_api/';
    getPostsURL: string = this.blogAPI + 'postList';
    /* ------------------------------------------------------- */
}
