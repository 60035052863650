import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GlobalVariables {
  imagesUrlAlias = 'https://staging-gwp.gramedia.io/images-data/';

  imagesStoryUrlAlias: string = this.imagesUrlAlias + 'img-stories/';
  imagesStoryDefault = 'assets/images/cover-notfound.jpg';

  imagesAuthorUrlAlias: string = this.imagesUrlAlias + 'img-author/';
  imagesAuthorDefault = 'assets/images/default-user-image.png';

  imagesEventUrlAlias: string = this.imagesUrlAlias + 'img-event/';
  imagesEventDefault = 'assets/images/default-event.jpg';

  imagesPageUrlAlias: string = this.imagesUrlAlias + 'img-pages/';
  imagesPageDefault = 'assets/images/guidance-default-img.jpg';

  imagesPublishedStoryUrlAlias: string = this.imagesUrlAlias + 'img-publish/';

  siteKeyCaptcha = '6LdaZ4sUAAAAAH9ZPNoJtDMX-fwFTD1GopAT0Hwg';

  constructor() {}
}
