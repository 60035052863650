import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { EventService } from 'src/app/service/event.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { AuthGuard } from 'src/app/auth.guard';

declare let jQuery: any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  providers: [TranslatePipe]
})
export class EventComponent implements OnInit {

  auth: boolean;
  loading: boolean;

  defaultImage: string;
  eventData: any[] = [];
  eventFilterList = [];
  eventFilter = 'all';
  noEvent: boolean;
  isParam: boolean;
  nextPage = 0;
  prevPage = 0;
  totalPage = 0;
  activePage = 1;
  limit = 8;
  totalData = 0;

  // sprint 10
  imageNoEvent: String;
  re = /\-/gi;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private route: ActivatedRoute,
    public authGuard: AuthGuard,
    private eventService: EventService,
    private seo: SeoService
  ) {}

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.seo.addMetaTag('Acara');
    this.noEvent = true;
    this.defaultImage = environment.imageEventDefault;
    this.imageNoEvent = environment.imageNoEvent;

    this.eventFilterList = [{id: 'all', name: this.translate.transform('eventAll')},
                            {id: 'live', name: this.translate.transform('eventLive')},
                            {id: 'coming', name: this.translate.transform('eventComing')},
                            {id: 'done', name: this.translate.transform('eventDone')}];

    this.route.params.subscribe( params => {
      if (params) {
        this.isParam = true;
        this.eventFilter = params ? (params['filter'] ? params['filter'] : 'all') : 'all';
        this.activePage = Number(params ? (params['page'] ? params['page'] : 1) : 1);
        this.getEventData(this.eventFilter, this.activePage, this.limit);
        this.nextPage = this.activePage + 1;
        this.prevPage = this.activePage - 1;
      }
    });

    if (!this.isParam) {
        this.getEventData(this.eventFilter, this.activePage, this.limit);
        this.nextPage = this.activePage + 1;
        this.prevPage = this.activePage - 1;
    }

  }

  changePage(page) {
    if (page && (page > 0 && page <= this.totalPage)) {
      this.navigateUrl.goTo('events/page/' + page + '/' + this.eventFilter);
    }
  }

  getEventData(filter, page, limit) {
    this.loading = true;
    this.eventService.getEvent(filter, page, limit).subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.eventData = [];
        data.data.forEach(e => {
          this.eventData.push({
            id: e.event_id,
            status: e.event_status,
            title: e.event_title,
            slug: e.event_slug,
            startDate: e.event_start_date ? e.event_start_date.replace(this.re, '/') : e.event_start_date,
            startTime: e.event_start_time,
            endDate: e.event_end_date ? e.event_end_date.replace(this.re, '/') : e.event_end_date,
            endTime: e.event_end_time,
            address: e.event_address,
            src: e.event_image ? e.event_image : this.defaultImage
          });
        });
        if (data.status) {
          this.noEvent = false;
        } else {
          this.noEvent = true;
        }
        this.activePage = Number(data.activePage);
        this.totalPage = data.totalPage;
        this.totalData = data.totalData;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  async filterEvent(event) {
    this.navigateUrl.goTo('events/page/1/' + event.id);
  }

  onChangePage(page) {
    this.activePage = page;
    this.navigateUrl.goTo('events/page/' + page + '/' + this.eventFilter);
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  goToLogin () {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
    }
  }

}
