import { SeoService } from 'src/app/shared/service/seo.service';
import { SearchingService } from 'src/app/service/searching.service';
import { CategoryService } from 'src/app/service/category.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { AuthGuard } from 'src/app/auth.guard';
import { environment } from 'src/environments/environment.prod';
import { Nl2brPipe } from './../../shared/pipe/nl2br.pipe';
import { MatTabChangeEvent } from '@angular/material';

@Component({
  selector: 'app-search-page-detail',
  templateUrl: './search-page-detail.component.html',
  styleUrls: ['./search-page-detail.component.css'],
  providers: [TranslatePipe, Nl2brPipe]
})
export class SearchPageDetailComponent implements OnInit {

  auth: boolean;
  loading: boolean;
  uid: string;

  tempSearchText: any = '';
  searchText: any = '';
  searchType: any = 'text';
  splitTag: any;

  storyList: any[] = [];
  activePageStory = 1;
  isParamStory: boolean;
  noDataStory: boolean;
  limitStory = 10;
  totalDataStory = 0;
  defaultImageStory: string;

  authorList: any[] = [];
  activePageAuthor = 1;
  isParamAuthor: boolean;
  noDataAuthor: boolean;
  limitAuthor = 12;
  totalDataAuthor = 0;
  defaultImageAuthor: string;

  tagList: any[] = [];
  activePageTag = 1;
  limitTag = 10;

  orderingOptions = [{id: 'asc', text: 'labelOrderByAsc'},
                    {id: 'desc', text: 'labelOrderByDesc'},
                    {id: 'newest', text: 'labelOrderByNewest'},
                    {id: 'oldest', text: 'labelOrderByOldest'},
                    {id: 'popular', text: 'lableOrderByPopular'}];
  orderingActiveId = 'popular';
  orderingActiveText = 'lableOrderByPopular';
  categoryList: any[] = [{id: 0, text: 'labelAll', slug: 'all'}];
  categoryActiveId = 'all';

  mobileGenreList: boolean;
  mobileSortList: boolean;
  mobileGenreSortBtn: boolean = true;
  re = /\-/gi;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private searchingService: SearchingService,
    private userService: UserService,
    private seo: SeoService,
    private nl2br: Nl2brPipe
  ) {

  }

  ngOnInit() {
    this.seo.addMetaTag('Gramedia Writing Project');
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImageStory = environment.imageStoryDefault;
    this.defaultImageAuthor = environment.imageAuthorDefault;

    this.getCategoryList();

    this.route.queryParams.subscribe(params => {
      if (params && params['q']) {
        this.searchText = params['q'];
        this.tempSearchText = params['q'];
        const res = this.searchText.slice(0, 1);
        if (res === '#') {
          this.searchText = this.searchText.substr(1);
          this.searchText = '%23' + this.searchText;
        }
      }
      if (params && params['t']) {
        this.searchType = params['t'];
      }

      this.getAllStories();
      this.getAllAuthor();
      // this.getAllTag();
    });

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index > 0) {
      this.mobileGenreSortBtn = false;
    } else {
      this.mobileGenreSortBtn = true;
    }
  }

  getCategoryList() {
    this.categoryService.getCategory().subscribe((data: any) => {
      if (data.data) {
        data.data.forEach(e => {
          if (e.cat_related_module && e.cat_related_module.includes('categories')) {
            this.categoryList.push({
              id: e.cat_id,
              text: e.cat_title,
              slug: e.cat_slug
            });
          }
        });
      }
    }, (error) => {
    });
  }

  getAllTag() {
    this.searchingService.getTag(this.activePageTag, this.limitTag, this.searchText).subscribe((data: any) => {
      if (data.data) {
        this.tagList = data.data;
      }
    }, (error: any) => {
    });
  }

  getAllAuthor() {
    this.searchingService.getAuthor(this.uid, this.activePageAuthor, this.limitAuthor, this.searchText).subscribe((data: any) => {
      if (data.data) {
        this.authorList = data.data;
        this.totalDataAuthor = data.totalData;
      } else {
        this.authorList = [];
        this.totalDataAuthor = 0;
      }

      if (data.status) {
        this.noDataAuthor = false;
      } else {
        this.noDataAuthor = true;
      }

    }, (error: any) => {
    });
  }

  getAllStories() {
    this.loading = true;
    this.searchingService.getStory(
      this.activePageStory,
      this.limitStory,
      this.orderingActiveId,
      this.categoryActiveId,
      this.searchText,
      this.searchType
    ).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.storyList = [];
        data.data.forEach(e => {
          this.storyList.push({
            id: e.story_id,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : this.defaultImageStory),
            title: e.story_title ? e.story_title : '-',
            slug: e.story_slug ? e.story_slug : '-',
            author: e.ufullname ? this.nl2br.transform(e.ufullname, 'blank') : '-',
            authorId: this.nl2br.transform(e.uusername, 'blank'),
            view: e.story_views,
            like: e.story_likes,
            uid: e.uid,
            comment: e.story_comments,
            synopsis: e.story_description ? this.nl2br.transform(e.story_description, 'space') : '-',
            lastUpdate: e.story_modified_date ? e.story_modified_date.replace(this.re, '/') : e.story_modified_date,
            complete: ( e.story_is_complete === '0' ) ? false : true
          });
        });
        this.totalDataStory = data.totalData;
      }

      if (data.status) {
        this.noDataStory = false;
      } else {
        this.noDataStory = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  changeOrder(evt) {
    this.mobileGenreList = false;
    this.mobileSortList = false;
    const selectedOrder = this.orderingOptions.find(x => x.id === evt.id);
    this.orderingActiveId = selectedOrder.id;
    this.orderingActiveText = selectedOrder.text;
    localStorage.setItem('searchLastOrderOption', selectedOrder.id);
    this.getAllStories();
  }

  onChangePageStory(page) {
    this.activePageStory  = page;
    this.getAllStories();
  }

  onChangePageAuthor(page) {
    this.activePageAuthor  = page;
    this.getAllAuthor();
  }

  changeCategory(event) {
    this.mobileGenreList = false;
    this.mobileSortList = false;
    this.activePageStory = 1;
    this.categoryActiveId = event.slug;
    this.getAllStories();
  }

  // tabFollow
  onActionFollow(event, action) {
    let index: any;
    index = this.authorList.findIndex(e => e.uid === event.uid);
    this.authorList[index].process = true;

    this.userService.followUser(this.uid, event.uid, action).then((data: any) => {
      this.authorList[index].process = false;

      if (data.status) {
        if (action === 'follow') {
          this.authorList[index].followed = true;
        } else if (action === 'unfollow') {
          this.authorList[index].followed = false;
        }
      }
    }).catch((error: any) => {
      this.authorList[index].process = false;
    });
  }

  onErrorImageStory(event) {
    event.target.src = this.defaultImageStory;
  }

  onErrorImageAuthor(event) {
    event.target.src = this.defaultImageAuthor;
  }

  openMobileGenreList() {
    this.mobileGenreList = true;
  }
  closeMobileGenreList() {
    this.mobileGenreList = false;
  }

  openMobileSortList() {
    this.mobileSortList = true;
  }
  closeMobileSortList() {
    this.mobileSortList = false;
  }

}
