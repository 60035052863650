import { Injectable, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';

import { MatSnackBarComponent } from '../component/mat-snack-bar/mat-snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

  config: any;
  // name = 'Angular 4';
  // message: string = 'Snack Bar opened.';
  actionButtonLabel = 'X';
  action = true;
  setAutoHide = true;
  autoHide = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  addExtraClass = true;

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private snackBarComponent: MatSnackBarComponent
  ) {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = this.verticalPosition;
    this.config.horizontalPosition = this.horizontalPosition;
    this.config.duration = this.setAutoHide ? this.autoHide : 0;
    this.config.panelClass = 'error';
    // this.config.extraClasses=  ['notif-success'];
    // this.config.panelClass = 'notif-success';
    // this.config.extraClasses = this.addExtraClass ? ['test'] : undefined;
    // this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

    }

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message);
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button.
      // In the third, we send in the css class for the snack bar.
     // let snackBarRef = this.snackBar.open(message, 'X', {panelClass: ['error']});
      /*snackBarRef.afterDismissed().subscribe(() => {
        console.log('The snack-bar was dismissed');
      });*/
      // let snackBarRef = this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, this.config);
      /*snackBarRef.onAction().subscribe(() => {
        console.log('The snack-bar action was triggered!');
      });*/
      this.snackBarComponent.openSnackBar(message, 'X', 'red-snackbar');
      /*this.snackBar.openFromComponent(MatSnackBarComponent , {
        duration: 9999999,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: [
          {
            notifyNumber: 1,
            routerLinkUrl: '/reports',
            routerLinkText: 'Go Download Reports',
            message: 'reports are ready to download in the "Reports" section.',
            icon: 'si-document-f',
          },
          {
            notifyNumber: 2,
            routerLinkUrl: '/support/tickets',
            routerLinkText: 'View Tickets',
            message: 'support ticket needs your attention.',
            icon: 'si-document-f', // todo: find correct icon reference
          },
        ],
      });*/
      // snackBarRef.dismiss();

    });
  }

}
