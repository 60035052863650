import { SeoService } from 'src/app/shared/service/seo.service';
import { User } from './../../model/user.model';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2, NgZone, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { LoginService } from 'src/app/service/login.service';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { environment } from 'src/environments/environment.prod';
import { RegisterService } from 'src/app/service/register.service';
import { Gtag } from 'angular-gtag';

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [TranslatePipe]
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  logo: String = '';
  loginForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  errorMessage = '';

  auth: boolean;
  user: User[];
  email: string;
  password: string;

  auth2: any;
  googleClientId: string;
  unverified: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    public authGuard: AuthGuard,
    private loginService: LoginService,
    private registerService: RegisterService,
    private alertMassage: AlertMassageService,
    private translate: TranslatePipe,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private seo: SeoService,
    private gtag: Gtag,
  ) {
    this.gtag.event('screen_view', {
      'app_name': `GWP-WEB-${environment.envName}`,
      'screen_name': 'Login',
    });
  }

  ngOnInit() {
    this.seo.addMetaTag('Masuk');
    this.logo = this.appConfig.logo;
    this.auth = this.authGuard.isLoggedIn();

    if (this.auth === false) {
      this.loginForm = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
        rememberme: ['']
      });
      return;
    } else {
      this.navigateUrl.goTo('home');
    }
  }

  ngAfterViewInit() {
    if (!this.auth) {
      this.googleClientId = environment.googleClientId;
      this.googleInit();
    }
  }

  get form() {
    return this.loginForm.controls;
  }

  checkValidForm() {
    if (!this.loginForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  login() {
    this.unverified = false;
    this.validForm = this.checkValidForm();
    if (!this.validForm) {
      const user = {
        email: btoa(this.loginForm.value.email),
        password: btoa(this.loginForm.value.password),
        rememberme: this.loginForm.value.rememberme
      };

      this.loading = true;
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', '');
      this.loginService.loginCheck(user).subscribe((data: any) => {
          this.user = data.data;
          this.loading = false;
          if (this.user != null) {
            if (data.data === '0') {
              this.navigateUrl.goTo('forgot-password');
            } else if (data.data.ustatus && data.data.ustatus === 'verified') {
              localStorage.setItem('uid', data.data.uid);
              localStorage.setItem('Name', data.data.uusername);
              localStorage.setItem('Fullname', data.data.ufullname);
              localStorage.setItem('login_status', '1');
              localStorage.setItem('login_google', '0');

              this.gtag.event('login', {
                'app_name': `GWP-WEB-${environment.envName}`,
                'screen_name': 'Login',
                'method': 'native',
              });
              this.navigateUrl.goTo('home');
            } else {
              this.unverified = true;
              this.email = (data.data && data.data.uemail) ? atob(data.data.uemail) : null;
            }
          } else {
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('loginFailed'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
          }
        });
    }
  }

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.googleClientId,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        const user = {
          token: googleUser.getAuthResponse().id_token,
          email: btoa(profile.getEmail()),
          gid: profile.getId(),
          name: profile.getName(),
          image: profile.getImageUrl()
        };
        this.ngZone.run(() => this.loading = true);
        this.ngZone.run(() => this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', ''));
        this.loginService.loginGoogle(user).subscribe((data: any) => {
          this.ngZone.run(() => this.loading = false);
          if (data && data.message && data.message.s === 'success') {
              localStorage.setItem('uid', data.data.uid);
              localStorage.setItem('Name', data.data.uusername);
              localStorage.setItem('Fullname', data.data.ufullname);
              localStorage.setItem('login_status', '1');
              localStorage.setItem('login_google', '1');
              this.navigateUrl.goTo('home');
              this.ngZone.run(() => this.auth = this.authGuard.isLoggedIn());
              this.ngZone.run(() => this.navigateUrl.goTo('home'));
          } else {
            // tslint:disable-next-line: max-line-length
            this.errorMessage = this.alertMassage.show('failed', this.translate.transform('emailFailed'), this.translate.transform('failed'));
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
            setTimeout (() => {
              this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
            }, 5000);
            googleUser.disconnect();
          }
        }, (error: any) => {
          this.ngZone.run(() => this.loading = false);
        });
      }, (error) => {
        console.log('Cancel Google SignIn');
        console.log('Google', JSON.stringify(error, undefined, 2));
      });
  }

  resendVerification() {
    this.loading = true;
    this.registerService.resendVerif(this.email).subscribe((data: any) => {
      this.loading = false;
      if (data && data.message && data.message.s === 'success') {
        this.errorMessage = this.alertMassage.show('success', this.translate.transform('resendVSuccess'));
      } else {
        this.errorMessage = this.alertMassage.show('failed', this.translate.transform('resendVFailed'));
      }
      this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
      setTimeout (() => {
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
      }, 5000);
    }, (error) => {
      this.loading = false;
    });
  }

}
