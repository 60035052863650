import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoToService {

constructor(private router: Router) { }

goTo(url = '', queryParams = null) {
  // console.log('goTo Service : ', url);
  if (url) {
    this.router.navigate(['/' + url], {queryParams: queryParams});
    window.scrollTo(0, 0);
  }
}

// Sprint 3 *with same page angular onInit cant response
goToWithSamePage(url: any) {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then( (response: any) => {
    this.router.navigate([url]);
  });
}

}
