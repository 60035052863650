import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) { }

  addMetaTag(title?: any, url?: any, description?: any, image?: any) {
    this.meta.addTags([
      {name: 'og:type', content: 'story'},
      {name: 'og:title', content: title ? title : 'Gramedia Writing Project'},
      {name: 'og:site_name', content: this.document.location.origin},
      {name: 'og:url', content: url ? url : this.document.location.origin},
      {name: 'og:description', content: description ? description : ''},
      {name: 'og:image', content: image ? image : (this.document.location.origin + '/assets/images/logo/logo.png')},

      {name: 'twitter:card', content: 'product'},
      {name: 'twitter:site', content: '@GWP_ID'},
      {name: 'twitter:site:id', content: '@GWP_ID'},
      {name: 'twitter:title', content: title ? title : 'Gramedia Writing Project'},
      {name: 'twitter:description', content: description ? description : ''},
      {name: 'twitter:image', content: image ? image : (this.document.location.origin + '/assets/images/logo/logo.png')},
    ]);
    this.updateTitle(title);
  }

  updateMetaTag(title?: any, url?: any, description?: any, image?: any) {
    this.meta.updateTag({name: 'og:title', content: title ? title : 'Gramedia Writing Project'});
    this.meta.updateTag({name: 'og:url', content: url ? url : this.document.location.origin});
    this.meta.updateTag({name: 'og:description', content: description ? description : ''});
    this.meta.updateTag({name: 'og:image', content: image ? image : (this.document.location.origin + '/assets/images/logo/logo.png')});
    this.meta.updateTag({name: 'twitter:title', content: title ? title : 'Gramedia Writing Project'});
    this.meta.updateTag({name: 'twitter:description', content: description ? description : ''});
    this.meta.updateTag({name: 'twitter:image', content: image ? image : (this.document.location.origin + '/assets/images/logo/logo.png')});
    this.updateTitle(title);
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  // updateDescription(desc: string) {
  //   this.meta.updateTag({ name: 'description', content: desc });
  // }

  // updateOgUrl(url: string) {
  //   this.meta.updateTag({ property: 'og:url', content: url });
  // }

  // updateOgTitle(title: string) {
  //   this.meta.updateTag({ property: 'og:title', content: title });
  // }

  // updateOgDescription(description: string) {
  //   this.meta.updateTag({ property: 'og:description', content: description });
  // }

  // updateOgImage(image: string) {
  //   this.meta.updateTag({ property: 'og:image', content: image });
  // }

}
