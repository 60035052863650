import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {

  // sprint 4
  transform(value: string): any {
      let val =  value.replace(/<.*?>/g, ''); // replace tags
      val = val.replace(/&nbsp;/gi, '');
      val = val.replace(/\\\\/gi, '');
      val = val.replace(/\\s/gi, '\'s');
      return val;
  }

}
