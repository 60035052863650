import { environment } from 'src/environments/environment.prod';
import { Component, OnInit, ViewChild, ElementRef, Inject, Output, EventEmitter, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { UserService } from 'src/app/service/user.service';
import { AuthGuard } from 'src/app/auth.guard';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { MatSlideToggleChange } from '@angular/material';
import {
  ToolbarService, LinkService, ImageService,
  HtmlEditorService, CountService, ResizeService, QuickToolbarService,
  PasteCleanupService, RichTextEditorComponent
} from '@syncfusion/ej2-angular-richtexteditor';

import { DomSanitizer } from '@angular/platform-browser';
import { QuillEditorComponent } from 'ngx-quill';

export interface Tags {
  name: string;
}

@Component({
  selector: 'app-create-story-form',
  templateUrl: './create-story-form.component.html',
  styleUrls: ['./create-story-form.component.css'],
  providers: [
    TranslatePipe, ToolbarService, LinkService, ImageService,
    HtmlEditorService, CountService, ResizeService, QuickToolbarService,
    PasteCleanupService
  ],
  styles: []
})
export class CreateStoryFormComponent implements OnInit, AfterViewInit {

  @Output() checkForm = new EventEmitter<any>(true);
  @Output() setTitle = new EventEmitter<any>(true);
  @Input() inCategoryList: any[];
  @Input() isEdit = false;
  @Input() inDataStoryForm: any;
  @ViewChild('tagInput', { static: true }) tagInputRef: ElementRef;
  @ViewChild('uploadImage', { static: true }) uplodImageRef: ElementRef;
  @ViewChild('writingGWP', { static: false }) writingObj: QuillEditorComponent;
  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  userIsMature: Boolean = false;
  storyForm: FormGroup;
  loading: Boolean = false;
  validForm = false;
  validLength = {
    synopsis: true,
    title: true
  }
  charLimit = { // Set max character limit for inputs
    title: 50,
    synopsis: 800
  };

  tagList: string[] = [];

  selectedCategories: number[];

  selectedFile: any;
  selectedFileName = '';
  defaultImage = true;
  imagePreview: any;

  formDisabled: boolean;
  matureChecked: boolean;
  completeChecked: boolean;
  wordsCount: number = 0;

  defaultGenreImageMap: Map<string, string>;

  // sprint 12
  // public textDescription: HTMLElement;
  public synopsis = ``;
  public tools: object = {
    enableFloating: true,
    items: [
      'Bold', 'Italic', 'Underline', '|',
      'Alignments', '|',
      'Indent', 'Outdent', '|'
    ]
  };
  public placeholder: String;
  public height: Number = 400;
  public pasteCleanupSettings: object = {
    prompt: false,
    plainText: false,
    keepFormat: true,
    deniedTags: ['a', 'h1', 'h2', 'h3', 'h4', 'h5', 'button', 'img'],
    deniedAttrs: ['class', 'title', 'id'],
    allowedStyleProps: ['text-align', 'text-decoration']
  };

  public quillStyles: object = {
    height: '400px'
  }
  public quillOptions: object = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'align': [] }],
      [{ 'indent': '-1' }, { 'indent': '+1' }]
    ],
    clipboard: {
      matchers: [
        ['pre', this.quillFilterTag],
        ['code', this.quillFilterTag],
        ['img', this.quillFilterTag]
      ]
    }
  }

  setTag = "";
  constructor(
    public navigateUrl: GoToService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public authGuard: AuthGuard,
    private translate: TranslatePipe,
    private alertMassage: AlertMassageService,
    private renderer: Renderer2 ) {
      this.placeholder = this.translate.transform('placeholderSynopsis');
    }

  ngOnInit() {
    this.defaultGenreImageMap = new Map<string, string>();
    // this.defaultGenreImageMap.set("26", environment.imageThrillerDefault);
    // this.defaultGenreImageMap.set("100", environment.imageFictionDefault);
    this.defaultGenreImageMap.set("26", environment.imageStoryDefaultV2);
    this.defaultGenreImageMap.set("100", environment.imageStoryDefaultV2);
    this.imagePreview = environment.imageCreateStoryDefault;

    if (this.inDataStoryForm && this.inDataStoryForm.tagList) {
      this.tagList = this.inDataStoryForm.tagList;
    }
    if (this.inDataStoryForm && this.inDataStoryForm.genre) {
      this.selectedCategories = this.inDataStoryForm.genre;
    }
    if (this.inDataStoryForm && this.inDataStoryForm.matureType) {
      this.matureChecked = this.inDataStoryForm.matureType;
    }
    if (this.inDataStoryForm && this.inDataStoryForm.complete) {
      this.completeChecked = this.inDataStoryForm.complete;
    }
    if (this.inDataStoryForm && this.inDataStoryForm.coverUrl) {
      this.imagePreview = this.inDataStoryForm.coverUrl;
      this.defaultImage = false;
    }
    if (this.inDataStoryForm && this.inDataStoryForm.cover) {
      this.selectedFile = this.inDataStoryForm.cover;
      this.onRenderFileUpload(this.selectedFile);
    }

    (async () => {
      // Do something before delay
      // console.log('before delay');

      await this.delay(300);
      if (this.inDataStoryForm && this.inDataStoryForm.synopsis) {
        this.synopsis = this.inDataStoryForm.synopsis;
        this.writingObj.quillEditor.clipboard.dangerouslyPasteHTML(this.synopsis);
      }
      // Do something after
      // console.log('after delay');
    })();

    this.storyForm = this.formBuilder.group({
      id: [(this.inDataStoryForm && this.inDataStoryForm.id ? this.inDataStoryForm.id : '')],
      title: [(this.inDataStoryForm && this.inDataStoryForm.title ? this.inDataStoryForm.title : '')],
      synopsis: [(this.inDataStoryForm && this.inDataStoryForm.synopsis ? this.synopsis : '')],
      tags: [(this.inDataStoryForm && this.inDataStoryForm.tags ? this.inDataStoryForm.tags : '')],
      tagList: [(this.inDataStoryForm && this.inDataStoryForm.tagList ? this.inDataStoryForm.tagList : '')],
      genre: [(this.inDataStoryForm && this.inDataStoryForm.genre ? this.inDataStoryForm.genre : '')],
      matureType: [this.inDataStoryForm && this.inDataStoryForm.matureType ? this.inDataStoryForm.matureType : ''],
      cover: [this.inDataStoryForm && this.inDataStoryForm.cover ? this.inDataStoryForm.cover : ''],
      coverUrl: [this.inDataStoryForm && this.inDataStoryForm.coverUrl ? this.inDataStoryForm.coverUrl : ''],
      complete: [this.inDataStoryForm && this.inDataStoryForm.complete ? this.inDataStoryForm.complete : '']
    });

    this.getUserAge();
  }

  get form() {
    return this.storyForm.controls;
  }

  getUserAge() {
    let uid = localStorage.getItem('uid');
    this.userService.getUserById(uid, uid).subscribe((data: any) => {
      if (data.data) {
        if (data.data.uage < 18) {
          this.matureChecked = false;
        } else {
          this.userIsMature = true;
        }
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  preventMaxLength(evt: any) {
    let evt_value: string = evt.target.value;
    if (evt_value.length >= this.charLimit.title){
      evt.preventDefault();
    }
  }

  setStoryTitle() {
    const titleValue: string = this.storyForm.controls.title.value;
    const data = {
      title: titleValue
    };
    this.setTitle.emit(data);
    this.checkFormFilled();
  }

  checkFormFilled() {
    let data: any;
    this.synopsis = this.synopsis ? this.synopsis : '';

    const titleValue: string = this.storyForm.controls.title.value;
    this.storyForm.controls.synopsis.setValue(this.synopsis);
    const synopsisValue: string = this.storyForm.controls.synopsis.value;
    const tagsLength: number = this.tagList.length;
    this.storyForm.controls.tagList.setValue(this.tagList);
    const genreValue: string = this.storyForm.controls.genre.value;
    this.storyForm.controls.matureType.setValue(this.matureChecked ? this.matureChecked : false);
    this.storyForm.controls.complete.setValue(this.completeChecked ? this.completeChecked : false);
    this.storyForm.controls.cover.setValue(this.selectedFile ? this.selectedFile : '');

    // this.validLength.synopsis = this.synopsis.length > this.charLimit.synopsis ? false : true;
    this.validLength.synopsis = this.wordsCount <= 100;
    this.validLength.title = titleValue.length > this.charLimit.title ? false : true;

    let defaultGenreImage = this.defaultGenreImageMap.get(genreValue);
    if (defaultGenreImage == undefined) {
      defaultGenreImage = environment.imageCreateStoryDefault;
    }
    if (this.defaultImage) {
      this.imagePreview = defaultGenreImage;
    }

    if (this.isEdit) {
      data = {
        isEdit: true,
        nextStep: false,
        passedStep: false,
        formValid: !this.validForm ? true : false,
        data: this.storyForm.value
      };
    } else if (titleValue && synopsisValue && tagsLength > 0 && genreValue) {
      data = {
        isEdit: false,
        nextStep: true,
        passedStep: false,
        formValid: !this.validForm ? true : false,
        data: this.storyForm.value
      };
    } else {
      data = {
        isEdit: false,
        nextStep: false,
        passedStep: true,
        formValid: !this.validForm ? true : false,
        data: this.storyForm.value
      };
    }

    // check if all input form length is valid
    data.validLength = this.checkValidLength(this.validLength);
    data.nextStep = data.validLength ? data.nextStep : false;

    this.checkForm.emit(data);
  }

  checkValidLength(data) {
    let valid = Object.keys(data).map(function(k){return data[k]});
    return valid.every(Boolean);
  }

  checkValidForm() {
    if (!this.storyForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  submit() {
    // console.log('SUBMIT CREATE STORY');
  }

  selectFileUpload(): void {
    this.uplodImageRef.nativeElement.click();
  }

  onFileUpload(event) {
    this.loading = true;
    this.selectedFile = event.target && event.target.files[0] ? event.target.files[0] : null;
    const reader = new FileReader();
    reader.onload = () => {
      if (this.selectedFile.size > 500000) {
        this.loading = false;
        this.defaultImage = true;
        const imagesSrc = environment.imageCreateStoryDefault;
        this.imagePreview = imagesSrc;
        this.selectedFile = null;
        event.target.value = '';
        this.checkFormFilled();

        let errorMessage = this.alertMassage.show('failed', this.translate.transform('labelUploadCoverErrorSize'));
        this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', errorMessage);
        setTimeout (() => {
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
        }, 5000);

        return;
      } else {
        this.loading = false;
        this.defaultImage = false;
        this.imagePreview = reader.result;
        this.selectedFileName = this.selectedFile.name;
        this.checkFormFilled();
      }

      const image: any = new Image();
      image.src = reader.result;
    };

    if (!this.selectedFile) {
      this.loading = false;
      this.checkFormFilled();
      return;
    }

    reader.readAsDataURL(this.selectedFile);
  }

  onRenderFileUpload(selectedFile) {
    this.loading = true;
    this.selectedFile = selectedFile;
    const reader = new FileReader();
    reader.onload = () => {

      this.loading = false;
      this.defaultImage = false;
      this.imagePreview = reader.result;
      this.selectedFileName = this.selectedFile.name;

      const image: any = new Image();
      image.src = reader.result;
    };

    reader.readAsDataURL(this.selectedFile);
  }

  onMatureChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.matureChecked = true;
    } else {
      this.matureChecked = false;
    }
    this.checkFormFilled();
  }

  onCompleteChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.completeChecked = true;
    } else {
      this.completeChecked = false;
    }
    this.checkFormFilled();
  }

  focusTagInput(): void {
    this.tagInputRef.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.storyForm.controls.tags.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space' || event.code === 'Enter') {
        this.addTag(inputValue);
        this.storyForm.controls.tags.setValue('');
      } else if (event.code === 'Digit3' || event.key === '#') {
        this.addTag(inputValue);
        this.storyForm.controls.tags.setValue('#');
      }
    }
  }

  onBlur(evt): void {
    const inputValue: string = this.storyForm.controls.tags.value;
    // if (inputValue) {
    //   this.addTag(inputValue);
    //   this.storyForm.controls.tags.setValue('');
    // }
    this.setTag = inputValue;
    this.onChange(inputValue);
  }

  countWords(text: string) {
    text = text.replace(/(<([^>]+)>)/ig, " ");
    text = text.replace(/\s/g,' ');

    let text_arr = text.split(' ');
    text_arr = text_arr.filter(function(e){ return e });
    this.wordsCount = text_arr.length;
  }

  quillOnContentUpdate(evt: any){
    let quill = evt.editor;

    if (evt.source === 'user'){
      this.countWords(quill.getText().trim());
      let quill_text = quill.getText().trim();

      // if(quill_text.length >= this.charLimit.synopsis){
      //   this.validLength.synopsis = false;
      // } else {
      //   this.validLength.synopsis = true;
      // }
      this.validLength.synopsis = this.wordsCount > 100;

      this.synopsis = this.writingObj.quillEditor.root.innerHTML;
      this.checkFormFilled();
    }
  }

  quillFilterTag(node: Node, delta: any){
    let ops = [];
    delta.ops.forEach(op => {
      if (op.insert && typeof op.insert === 'string') {
        ops.push({
          insert: op.insert
        });
      }
    });

    delta.ops = ops;
    return delta;
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ' || tag[tag.length - 1] === '#') {
      tag = tag.slice(0, -1);
    }
    // const regex = /[.,\s]/g;
    // const regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
    // const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    const regex = /[ `!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    if (tag.length > 0 && !this.tagList.find(x => x === tag)) {
      this.tagList.push(tag ? tag.replace(regex, '_') : tag);
    }
    this.checkFormFilled();
  }

  // Solving generate hashtag in mobile
  onChange(inputValue: string) {
    const str = inputValue;
    const res = str.split(/[\s,.]+/);
    for(let i = 0; i<res.length; i++){
      if(res[i].includes("#")) {
        const tmp = res[i].split("#");
          for (let n = 0; n < tmp.length; n++) {
            if (tmp[n] !== "") {
                this.addTag("#" + tmp[n]);
              }
          }
      } else {
        this.addTag(res[i]);
      }
    }
    this.storyForm.controls.tags.setValue('');
  }

  removeTag(tag?: string): void {
    if (!!tag) {
      const index: number = this.tagList.indexOf(tag);
      if (index !== -1) {
          this.tagList.splice(index, 1);
      }
    } else {
      this.tagList.splice(-1);
    }
    this.checkFormFilled();
  }

  async onSelectGenre(event) {
    this.checkFormFilled();
  }

  // sprint 12
  onCreate(evt) {
    // const instance: any = this.writingObj;
    // this.writingObj.element.focus();
    // instance.contentModule.getDocument().addEventListener('keydown', function(e: any): void {
    //   if (e.key === 's' && e.ctrlKey === true) {
    //       e.preventDefault(); // to prevent default ctrl+s action
    //       instance.updateValue(); // to update the value after editing
    //   }
    // });
  }

  ngAfterViewInit() {
    // const writingObj: RichTextEditorComponent = this.writingObj;
    // setTimeout(() => {
    //   // tslint:disable-next-line: deprecation
    //   this.textDescription = writingObj.contentModule.getEditPanel() as HTMLElement;
    // }, 200);
  }


  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
