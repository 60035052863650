import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertMassageService {
  constructor() {}

  show(status: string = '', message: string = '', messageTitle: string = '') {
    let msg = '';
    // let title = '';
    // if (messageTitle !== '') { title = '<strong>' + messageTitle + '!</strong> '; }
    switch (status) {
      case 'success':
        msg = '<div class="alert alert-success alert-white rounded">' +
                  '<button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>' +
                  '<div class="icon">' +
                      '<i class="fa fa-check-circle"></i>' +
                  '</div>' +
                  // title +
                  message +
              '</div>';
        break;
      case 'info':
        msg = '<div class="alert alert-info alert-white rounded">' +
                  '<button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>' +
                  '<div class="icon">' +
                      '<i class="fa fa-info-circle"></i>' +
                  '</div>' +
                  // title +
                  message +
              '</div>';
        break;
      case 'warning':
        msg = '<div class="alert alert-warning alert-white rounded">' +
                    '<button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>' +
                    '<div class="icon">' +
                        '<i class="fa fa-warning-circle"></i>' +
                    '</div>' +
                    // title +
                    message +
                '</div>';
        break;
      case 'failed':
      case 'unmail':
      case 'error':
        msg = '<div class="alert alert-danger alert-white rounded">' +
                  '<button type="button" data-dismiss="alert" aria-hidden="true" class="close">×</button>' +
                  '<div class="icon">' +
                      '<i class="fa fa-times-circle"></i>' +
                  '</div>' +
                  // title +
                  message +
              '</div>';
        break;
      default:
        msg = '';
        break;
    }

    return msg;
  }

}
