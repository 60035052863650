import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-list-follow',
  templateUrl: './list-follow.component.html',
  styleUrls: ['./list-follow.component.css']
})
export class ListFollowComponent implements OnInit {

  noAssets: String;

  @Input() totalItems = 0;
  @Output() eventEmitter = new EventEmitter<any>(true);

  private _data: any;
  private _length: number;
  private _empty = false;

  constructor() {

  }

  setDefaultPic(index) {
    this.items[index].uimage = 'assets/images/default-user-image.png';
  }

  @Input()
  set items(param: any) {
    this._data = param;
  }

  get items(): any {
    return this._data;
  }

  @Input()
  set length(param: any) {
    this._length = param;
    this._empty = param > 0 ? false : true;
  }

  get length(): any {
    return this._length;
  }

  followAction(index: number, uid: number, isMeFollow: any, page: any, type: any) {
    this.items[index].isMeFollow = isMeFollow === 0 ? 1 : 0;
    this.eventEmitter.emit({'uid': uid, 'type': type, 'page': page, 'isMeFollow': this.items[index].isMeFollow});
  }

  ngOnInit() {
    console.log(this.length);
    if (this.items && this.items.length) {
      console.log(this.items);
    }
  }

}
