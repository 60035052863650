import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.css']
})

export class RegisterSuccessComponent implements OnInit {

  logo: String = '';
  email: string;
  isReg: string;
  typeReg: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private seo: SeoService
  ) {}

  ngOnInit() {
    this.seo.addMetaTag('Registrasi Berhasil');
    this.logo = this.appConfig.logo;
    this.email = atob(localStorage.getItem('uemail'));
    this.isReg = localStorage.getItem('uisReg');
    this.typeReg = localStorage.getItem('utypeReg');
    if (!this.isReg) {
      this.navigateUrl.goTo('home');
    }
  }

}
