import { SeoService } from 'src/app/shared/service/seo.service';
import { Event } from './../../model/event.model';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { environment } from 'src/environments/environment.prod';
import { NotifService } from 'src/app/service/notif.service';
import { SomethingChangeService } from 'src/app/service/something-change.service';

@Component({
  selector: 'app-notification-user',
  templateUrl: './notification-user.component.html',
  styleUrls: ['./notification-user.component.css']
})
export class NotificationUserComponent implements OnInit {

  loading = false;
  defaultAuthor: string;
  defaultImage: string;
  newestNotification: any[] = [];
  oldestNotification: any[] = [];
  noData: Boolean = false;
  uid: any;
  countNewestNotification: any = 0;
  onChangeNotification: EventEmitter<any> = new EventEmitter<any>();

  // sprint 10
  imageNoNotification: String;
  constructor(
    public navigateUrl: GoToService,
    private notifService: NotifService,
    private someThingChange: SomethingChangeService,
    private seo: SeoService
  ) {

  }

  ngOnInit() {
    this.seo.addMetaTag('Notifikasi');
    this.defaultImage  = environment.imageStoryDefault;
    this.defaultAuthor = environment.imageAuthorDefault;
    this.imageNoNotification  = environment.imageNoNotification;
    this.uid                 = localStorage.getItem('uid');
    this.getNotificationMe();
  }

  getNotificationMe() {
    this.loading = true;
    this.notifService.getMyNotification(this.uid ).subscribe((data: any) => {
      this.loading = false;
      if (data.status) {
        this.newestNotification = [];
        this.oldestNotification = [];
        if ( data.data.newest && data.data.newest.length > 0) {
          this.countNewestNotification = data.data.newest.length;
          data.data.newest.forEach(e => {
              this.newestNotification.push({
                id: e.un_id,
                uid: e.uid,
                notif_type: e.un_type ? e.un_type : null,
                activity: e.notif_response_type_text,
                activity_type: e.notif_response_type,
                uimage: e.uimage,
                fullname: e.ufuulaname,
                username: e.uusername,
                story_title: e.story_title ? e.story_title : null,
                chapter_title: e.chapter_title ? e.chapter_title : null,
                chapter_slug: e.chapter_slug ? e.chapter_slug : null,
                story_cover: e.story_image_thumb ? e.story_image_thumb : null,
                story_id : e.story_id ? e.story_id : null,
                story_slug: e.story_slug ? e.story_slug : null,
                notif_date: e.notif_date ? e.notif_date : null,
                notif_unread: e.notif_unread ? e.notif_unread : null,
                notif_read_date: e.notif_read_date ? e.notif_read_date : null,
                notif_text: e.notif_text ? e.notif_text : null,
                chapter_id: e.chapter_id ? e.chapter_id : null,
                un_read: e.un_read ? e.un_read : null,
                date: e.un_created_date ? e.un_created_date : null
              });
          });
        } else {
          this.countNewestNotification = 0;
        }

        if (data.data && data.data.oldest) {
          data.data.oldest.forEach(e => {
            this.oldestNotification.push({
              id: e.un_id,
              uid: e.uid,
              notif_type: e.un_type ? e.un_type : null,
              activity: e.notif_response_type_text,
              activity_type: e.notif_response_type,
              uimage: e.uimage,
              fullname: e.ufuulaname,
              username: e.uusername,
              story_title: e.story_title ? e.story_title : null,
              chapter_title: e.chapter_title ? e.chapter_title : null,
              story_cover: e.story_image_thumb ? e.story_image_thumb : null,
              story_id : e.story_id ? e.story_id : null,
              story_slug: e.story_slug ? e.story_slug : null,
              chapter_slug: e.chapter_slug ? e.chapter_slug : null,
              notif_date: e.notif_date ? e.notif_date : null,
              notif_unread: e.notif_unread ? e.notif_unread : null,
              notif_read_date: e.notif_read_date ? e.notif_read_date : null,
              notif_text: e.notif_text ? e.notif_text : null,
              chapter_id: e.chapter_id ? e.chapter_id : null,
              un_read: e.un_read ? e.un_read : null,
              date: e.un_created_date ? e.un_created_date : null
            });
          });
        }
      }
    }, (error: any) => {
      this.loading = false;
      console.log(error);
    });
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  onErrorImageAuthor(event) {
    event.target.src = this.defaultAuthor;
  }

  authorProfile(notif) {
    this.navigateUrl.goTo('author/' + notif.uid);
    if (notif.un_read === '0') {
      this.notifService.makeReadNotification(this.uid, notif.id).subscribe((response: any) => {
        if (response.status) {
          notif.un_read = '1';
          this.countNewestNotification--;
          const count = this.countNewestNotification;
          this.someThingChange.sendMessage('ballNotif', count);
        }
      }, (error: any) => {
      });
    }
  }

  storyDetail(notif) {
    if (notif.chapter_id === '' || !notif.chapter_id) {
      this.navigateUrl.goTo('story/' + notif.story_id + '/' + notif.story_slug);
    } else {
      this.navigateUrl.goTo('chapter/' + notif.story_id + '/' + notif.chapter_id + '/' + notif.chapter_slug, {q: 'notification'});
    }

    if (notif.un_read === '0') {
      this.notifService.makeReadNotification(this.uid, notif.id).subscribe((response: any) => {
        if (response.status) {
          notif.un_read = '1';
          this.countNewestNotification--;
          const count = this.countNewestNotification;
          this.someThingChange.sendMessage('ballNotif', count);
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  notifDetail(notif) {
    if (notif.un_read === '0') {
      this.notifService.makeReadNotification(this.uid, notif.id).subscribe((response: any) => {
        if (response.status) {
          notif.un_read = '1';
          this.countNewestNotification--;
          const count = this.countNewestNotification;
          this.someThingChange.sendMessage('ballNotif', count);
        }
      }, (error: any) => {
        // console.log(error);
      });
    }

    if (notif.notif_type === 'follow') {
        this.navigateUrl.goTo('author/' + notif.uid);
    } else if (notif.notif_type === 'vote') {
        if (notif.chapter_id === '' || !notif.chapter_id) {
          this.navigateUrl.goTo('story/' + notif.story_id + '/' + notif.story_slug);
        } else {
          this.navigateUrl.goTo('chapter/' + notif.story_id + '/' + notif.chapter_id + '/' + notif.chapter_slug, {q: 'notification'});
        }
    } else if (notif.notif_type === 'comment') {
        if (notif.chapter_id === '' || !notif.chapter_id) {
          this.navigateUrl.goTo('story/' + notif.story_id + '/' + notif.story_slug);
        } else {
          this.navigateUrl.goTo('chapter/' + notif.story_id + '/' + notif.chapter_id + '/' + notif.chapter_slug, {q: 'notification'});
        }
    }
  }

}
