import { TermConditionComponent } from './components/term-condition/term-condition.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

// Revamp
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterSuccessComponent } from './components/register/register-success.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/forgot-password/change-password.component';
import { EventComponent } from './components/event/event.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { AboutComponent } from './components/about/about.component';
import { GuidanceComponent } from './components/guidance/guidance.component';
import { FaqComponent } from './components/faq/faq.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CategoryComponent } from './components/category/category.component';
import { PopularAuthorComponent } from './components/popular-author/popular-author.component';
import { ContinueReadingComponent } from './components/continue-reading/continue-reading.component';
import { WorkStoriesComponent } from './components/work-stories/work-stories.component';
import { CreateStoryComponent } from './components/create-story/create-story.component';
import { SearchingComponent } from './components/searching/searching.component';
import { SearchPageDetailComponent } from './components/search-page-detail/search-page-detail.component';
import { ChangePasswordUserComponent } from './components/change-password-user/change-password-user.component';
import { StoryDetailComponent } from './components/story-detail/story-detail.component';
import { NotificationUserComponent } from './components/notification-user/notification-user.component';
import { SettingNotificationComponent } from './components/setting-notification/setting-notification.component';
import { PublishedStoryComponent } from './components/published-story/published-story.component';
import { ChapterDetailComponent } from './components/chapter-detail/chapter-detail.component';
import { MyLibraryComponent } from './components/my-library/my-library.component';
import { RecomendationStoryComponent } from './components/recomendation-story/recomendation-story.component';
import { LombaGwpComponent } from './components/lomba-gwp/lomba-gwp.component';
import { RegisterActivationComponent } from './components/register/register-activation.component';
import { LandingComponent } from './components/landing/landing.component';

const routes: Routes = [
  { path: '', component: LandingComponent, data: { title: 'Selamat Datang' } },
  { canActivate: [AuthGuard], path: 'home', component: HomeComponent, data: { title: 'Selamat Datang' } },
  { path: 'about', component: AboutComponent, data: { title: 'Tentang Kami' } },
  { path: 'events', component: EventComponent, data: { title: 'Acara' } },
  { path: 'events/page/:page/:filter', component: EventComponent, data: { title: 'Acara' } },
  { path: 'events/:id/:slug', component: EventDetailComponent, data: { title: 'Acara' } },
  { path: 'faq', component: FaqComponent, data: { title: 'Bantuan' } },
  { path: 'guidance', component: GuidanceComponent, data: { title: 'Panduan' } },
  { path: 'disclaimer', component: DisclaimerComponent, data: { title: 'Disclaimer' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'register', component: RegisterComponent, data: { title: 'Daftar' } },
  { path: 'register-success', component: RegisterSuccessComponent, data: { title: 'Aktivasi Pendaftaran' } },
  { path: 'register-activation', component: RegisterActivationComponent, data: { title: 'Aktivasi Pendaftaran' } },
  { path: 'genre', component: CategoryComponent, data: { title: 'Genre' } },
  { path: 'genre/p/:page/c/:cat', component: CategoryComponent, data: { title: 'Genre' } },
  { path: 'genre/p/:page', component: CategoryComponent, data: { title: 'Genre' } },
  { path: 'genre/c/:cat', component: CategoryComponent, data: { title: 'Genre' } },

  { path: 'msearch', component: SearchingComponent, data: { title: 'Search' } },
  { path: 'search', component: SearchPageDetailComponent, data: { title: 'Cari Judul Cerita, Penulis' } },

  { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Ubah Kata Sandi' } },
  { canActivate: [AuthGuard], path: 'reset-password', component: ChangePasswordUserComponent, data: { title: 'Ubah Kata Sandi' } },
  { canActivate: [AuthGuard], path: 'setting-account', component: SettingNotificationComponent, data: { title: 'Pengaturan Notifikasi' } },
  { canActivate: [AuthGuard], path: 'notification', component: NotificationUserComponent, data: { title: 'Notifikasi' } },

  { path: 'published-story', component: PublishedStoryComponent, data: { title: 'Telah Terbit' } },
  { path: 'published-story/p/:page', component: PublishedStoryComponent, data: { title: 'Telah Terbit' } },

  { canActivate: [AuthGuard], path: 'my-profile', component: ProfileComponent, data: { title: 'Profile Saya' } },
  { canActivate: [AuthGuard], path: 'my-profile/p/:page', component: ProfileComponent, data: { title: 'Profile Saya' } },

  { path: 'author/:author', component: ProfileComponent, data: { title: 'Penulis' } },
  { path: 'author/:author/p/:page', component: ProfileComponent, data: { title: 'Penulis' } },

  { path: 'popular-author', component: PopularAuthorComponent, data: { title: 'Penulis Populer' } },
  { path: 'popular-author/p/:page', component: PopularAuthorComponent, data: { title: 'Penulis Populer' } },
  { canActivate: [AuthGuard], path: 'continue-reading', component: ContinueReadingComponent, data: { title: 'Lanjut Membaca' } },
  { canActivate: [AuthGuard], path: 'continue-reading/p/:page', component: ContinueReadingComponent, data: { title: 'Lanjut Membaca' } },
  { canActivate: [AuthGuard], path: 'work-stories', component: WorkStoriesComponent, data: { title: 'Cerita Saya' } },
  { canActivate: [AuthGuard], path: 'work-stories/p/:page', component: WorkStoriesComponent, data: { title: 'Cerita Saya' } },
  { canActivate: [AuthGuard], path: 'cstory', component: CreateStoryComponent, data: { title: 'Tulis Cerita' } },
  { canActivate: [AuthGuard], path: 'ustory/:story/:slug', component: CreateStoryComponent, data: { title: 'Tulis Cerita' } },
  { path: 'story/:story/:slug', component: StoryDetailComponent, data: { title: 'Baca Cerita' } },
  { path: 'chapter/:story/:chapter/:slug', component: ChapterDetailComponent, data: { title: 'Baca Cerita' } },
  { path: 'recomendation', component: RecomendationStoryComponent, data: { title: 'Rekomendasi Cerita' } },
  { path: 'recomendation/p/:page', component: RecomendationStoryComponent, data: { title: 'Rekomendasi Cerita' } },
  { canActivate: [AuthGuard], path: 'koleksiku', component: MyLibraryComponent },
  { canActivate: [AuthGuard], path: 'koleksiku/p/:page', component: MyLibraryComponent },
  { path: 'the-writers-show', component: LombaGwpComponent, data: { title: 'The Writers\' Show' } },
  { path: 'lomba-gwp', component: LombaGwpComponent, data: { title: 'The Writers\' Show' } },
  { path: 'term-condition', component: TermConditionComponent, data: { title: 'Term & Condition' } },
  {
    path: '**', redirectTo: '/home', pathMatch: 'full'
  }
];

export const routing = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  anchorScrolling: 'enabled',
});
