import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Provinsi } from '../model/provinsi.model';
import { Kabupaten } from '../model/kabupaten.model';
import { BankList } from '../model/bankList.model';
import { Bank } from '../model/bank.model';
import { Setting } from '../model/setting.model';
import { ApiService } from './api.service';

// sprint xx + sprint 3
import { Province } from '../model/province.model';
import { City } from '../model/city.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()

export class SettingService {

    constructor(private http: HttpClient, private apiService: ApiService) { }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    updateProfile(photoProfile: File, coverProfile: File, profileForm: any) {
        const formData: FormData = new FormData();
        if (photoProfile) {
            formData.append('imageProfile', photoProfile, photoProfile.name);
        }
        if (coverProfile) {
            formData.append('coverProfile', coverProfile, coverProfile.name);
        }
        formData.append('uid', profileForm.uid.value);
        formData.append('username', profileForm.username.value);
        formData.append('fullname', profileForm.fullname.value);
        formData.append('email', btoa(profileForm.email.value));
        formData.append('description', profileForm.description.value);
        formData.append('birthPlace', profileForm.birthPlace.value);
        formData.append('birthDate', profileForm.birthDate.value);
        formData.append('sex', profileForm.sex.value);
        formData.append('address', profileForm.address.value);
        formData.append('provinceId', profileForm.provinceId.value);
        formData.append('province', profileForm.province.value);
        formData.append('cityId', profileForm.cityId.value);
        formData.append('city', profileForm.city.value);
        formData.append('phone', profileForm.phone.value);
        formData.append('genre', profileForm.genre.value);
        formData.append('facebook', profileForm.facebook.value);
        formData.append('twitter', profileForm.twitter.value);
        formData.append('instagram', profileForm.instagram.value);

        return this.http.post<Setting>(this.apiService.updateProfileURL, formData);
    }

    changeProfilePicture(uid: any, photoProfile: any) {
      const formData: FormData = new FormData();
      if (photoProfile) {
          formData.append('imageProfile', photoProfile);
      }
      formData.append('uid', uid);
      return this.http.post<Setting>(this.apiService.changeProfilePictureURL, formData);
    }

    changeProfileCover(uid: any, photoProfile: any) {
      const formData: FormData = new FormData();
      if (photoProfile) {
          formData.append('imageCover', photoProfile);
      }
      formData.append('uid', uid);
      return this.http.post<Setting>(this.apiService.changeProfileCoverURL, formData);
    }

    getsetting() {
        return this.http.get<Setting[]>(this.apiService.settingAPI);
    }

    getSettingById(id: number) {
        return this.http.get<Setting>(this.apiService.settingAPI + id);
    }

    // Sprint 3 + Sprint 5
    getProvince() {
      return this.http.get<Province[]>(this.apiService.provinceURL);
    }

    // Sprint 3 + Sprint 5
    getCityByProvinceCode(proCode: any) {
        return this.http.get<City[]>(this.apiService.cityURL  + '?provinceCode=' + proCode);
    }

    getSettingLomba() {
      return this.http.get(this.apiService.getSettingLombaURL);
    }

    getSettingMaintenance() {
      return this.http.get(this.apiService.getSettingMaintenance);
    }

    getSettingBanners() {
      return this.http.get(this.apiService.getSettingBanners);
    }

    getSettingTermCondition() {
      return this.http.get(this.apiService.getSettingTerm);
    }

    getSettingTermConditionContent() {
      return this.http.get(this.apiService.getSettingTermContent);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log('${operation} failed: ${error.message}');
            return of(result as T);
        };
    }
}
