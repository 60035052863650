import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { SearchingService } from 'src/app/service/searching.service';
declare let jQuery: any;

@Component({
  selector: 'app-gwp-share-publish',
  templateUrl: './gwp-share-publish.component.html',
  styleUrls: ['./gwp-share-publish.component.css'],
  providers: [TranslatePipe]
})
export class GwpSharePublishComponent implements OnInit {
  title: string;
  shareUrl: string;
  shareFbUrl: string;
  shareTwUrl: string;
  shareWaUrl: string;
  shareMWaUrl: string;
  link: string;
  message: any;
  subMessage: any;
  hashtag: any;
  alerts: any[] = [];
  uid: any;

  constructor(public translate: TranslatePipe, public dialogRef: MatDialogRef<GwpSharePublishComponent>,
    private navigateUrl: GoToService,
    private searchingService: SearchingService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmSharePublishModel) {
      this.title  = data.title;
      this.link   = data.link;
      this.message  = data.message;
      this.subMessage = data.subMessage;
      this.hashtag  = (data.hashtag && data.hashtag.length > 0) ? data.hashtag : false;
      if (data && data.link) {
        this.shareUrl = data.link.copy;
        this.shareFbUrl = data.link.fb;
        this.shareTwUrl = data.link.tw;
        this.shareWaUrl = data.link.wa;
        this.shareMWaUrl = data.link.mwa;
      }
    }

  ngOnInit() {
    this.uid = localStorage.getItem('uid');
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  popupwindow(url, title, w, h) {
    const y = window.outerHeight / 2 + window.screenY - ( h / 2);
    const x = window.outerWidth / 2 + window.screenX - ( w / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    this.addAlert(this.translate.transform('linkCopied'));
    inputElement.setSelectionRange(0, 0);
    //this.dialogRef.close(false);
    //this.dialogRef.close({'copied' : true});
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  tagEvent(tag: any) {
    let param: String = tag;
    param  = param.replace(/[^a-zA-Z ]/g, '');
    param  = param.split(' ').join('-');
    this.navigateUrl.goTo('search', {q: param , t: 'tag'});
    this.searchingService.search(tag, null, 'tag', this.uid).subscribe((response: any) => {
    }, (error: any) => {
    });
    this.dialogRef.close(false);
  }

}

export class ConfirmSharePublishModel {
  constructor(
    public title: string,
    public link: any,
    public message: any = false,
    public subMessage: any = false,
    public hashtag: any = false
  ) {

  }
}
