import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { SearchingDefault } from 'src/app/model/searching-default.model';
import { SearchingService } from 'src/app/service/searching.service';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SomethingChangeService } from 'src/app/service/something-change.service';

declare let jQuery: any;

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @Input() auth: boolean;
  @Input() author: boolean;
  @ViewChild('searchInput', { static: true }) searchInputRef: ElementRef;

  inputSearch: any;
  isOpen: boolean;
  screenWidth: number;
  listSearching: SearchingDefault;

  searchSubscription: any;
  historySubscription: any;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private searchingService: SearchingService,
    public navigateUrl: GoToService,
    private somethingChange: SomethingChangeService
  ) {
    this.somethingChange.getMessage().subscribe((response: any) => {
      if (response.event === 'search' && response.message === 'changeUrl') {
        this.inputSearch = '';
      }
    });
    this.getScreenSize();
  }

  ngOnInit() { }

  public ngOnDestroy() {
    // When moving page
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnDestroyHit() {
    // When hitting request
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.historySubscription) {
      this.historySubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
      this.screenWidth = window.innerWidth;
  }

  openSearch() {
    this.isOpen = true;
    if (this.inputSearch &&  this.inputSearch !== '' && this.inputSearch.length >= 3) {
      this.searchEvent();
    } else {
      this.historySearch();
    }
  }

  closeSearch() {
    this.isOpen = false;
  }

  historySearch() {
    this.historySubscription = this.searchingService.historySearch(this.author).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if (data.status) {
        this.listSearching = data.data;
      }
    }, (error: any) => {
    });
  }

  async searchEvent() {
    if (this.inputSearch && this.inputSearch !== '' && this.inputSearch.length >= 3) {
      this.sugestiontSearch(this.inputSearch);
    } else {
      this.historySearch();
    }
  }

  sugestiontSearch(search: string) {
    this.ngOnDestroyHit();
    const res = search.slice(0, 1);
    if (res === '#') {
      search = search.substr(1);
      search = '%23' + search;
    }
    this.searchSubscription = this.searchingService.suggestSearch(search, this.author).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if (data.status) {
        this.listSearching = data.data;
      }
    }, (error: any) => {
    });
  }

  onItemSelectedSearch(event) {
    if (event && event.type && event.text) {
      this.isOpen = false;
      this.inputSearch = event.text;
      this.search(event.text, event.id, event.type);

      if (event.id && event.slug && event.type === 'story') {
        this.navigateUrl.goTo('story/' + event.id + '/' + event.slug);
      } else if (event.id && event.type === 'author') {
        this.navigateUrl.goTo('author/' + event.id);
      } else {
        this.navigateUrl.goTo('search', {q: event.text, t: event.type});
      }
    }
  }

  goToSearch() {
    if (this.inputSearch && this.inputSearch !== '') {
      this.isOpen = false;
      this.search(this.inputSearch, null, 'text');
      this.navigateUrl.goTo('search', {q: this.inputSearch, t: 'text'});
    }
  }

  search(term: any, refId: any, type: any) {
    this.searchingService.search(term, refId, type, this.author).subscribe((response: any) => {
    }, (error: any) => {
    });
  }

  deleteSearchText(event) {
    let search = null;
    if (event && event.action && event.action.us_id) {
      search =  event.action.us_id;
    }
    this.searchingService.deleteSearch(search, this.author).subscribe((response: any) => {
      if (response.status) {
        if (this.listSearching && this.listSearching.last) {
          this.listSearching.last = this.listSearching.last.filter(e =>  e.us_id !== search);
        }
      }
    }, (error: any) => {
    });
  }

  deleteAllSearchText() {
    this.searchingService.clearSearch(this.author).subscribe((response: any) => {
      if (response.status) {
        this.listSearching.last = null;
      }
    }, (error: any) => {
    });
  }
}
