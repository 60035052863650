import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { StoryService } from 'src/app/service/story.service';
import { StoriesService } from 'src/app/service/stories.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-profile-list-story',
  templateUrl: './profile-list-story.component.html',
  styleUrls: ['./profile-list-story.component.css'],
  providers: [TranslatePipe]
})
export class ProfileListStoryComponent implements OnInit, OnChanges {
  @Input() author: any;

  auth: boolean;
  loading: boolean;
  uid: string;

  activePage = 1;
  limit = 10;
  totalData = 0;
  noData: boolean;
  storyList: any[] = [];
  defaultImage: string;
  re = /\-/gi;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private storiesService: StoriesService,
  ) { }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Change', changes);
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
    if (this.author === this.uid) {
      this.getWorkStories(this.author, true);
    } else {
      this.getWorkStories(this.author, false);
    }
  }

  getWorkStories(author_id, status) {
    this.loading = true;
    this.storiesService.getAuthorStories(this.activePage, this.limit, author_id, status, this.uid).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.storyList = [];
        data.data.forEach(e => {
          this.storyList.push({
            id: e.story_id,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : this.defaultImage),
            url: e.story_title ? e.story_title : '-',
            title: e.story_title ? e.story_title : '-',
            slug: e.story_slug ? e.story_slug : '-',
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            authorUid: e.uid,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            synopsis: e.story_description ? e.story_description : '-',
            lastUpdate: e.story_modified_date ? e.story_modified_date.replace(this.re, '/') : e.story_modified_date,
            complete: ( e.story_is_complete === '0' ) ? false : true,
            published: ( e.story_is_published === '0' ) ? false : true
          });
        });
        this.totalData = data.totalData;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  onChangePage(page) {
    this.activePage = page;
    if (this.author === this.uid) {
      this.getWorkStories(this.author, true);
    } else {
      this.getWorkStories(this.author, false);
    }
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }
}
