import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { APP_CONFIG } from '../model/application.properties';

/**
 * Created by samuel silalahi on 24/01/2020.
 */

@Injectable({
  providedIn: 'root'
})
export class LangService {
  constructor(
    @Inject(APP_CONFIG) private config,
    private localStorageService: LocalStorageService
  ) {}

  translate(key: string, dynamicVal?: any) {
    const translation = key;
    let currentLang = this.localStorageService.getItem('pl');
    if (!currentLang) {
      currentLang = this.config.defaultLanguage;
    }
    if (this.config.dictionary[currentLang]) {
      let result = this.config.dictionary[currentLang][key];
      /**
       * Agar bisa menyisipkan dynamic value di tengah text
       * Usage:
       *   'TrxApproval':'Transaction with ref Number: {refNum} has been {status}'
       *   this.i18n.translate('TrxApproval',{refNum:'001',status:'Approved/Rejected'})
       */
      if (dynamicVal) {
        for (const k in dynamicVal) {
          if (k) {
            result = result.replace('{' + k + '}', dynamicVal[k]);
          }
        }
      }
      /**
       * request = key if result not found
       */
      if (!result) {
        result = key;
      }
      return result;
    }
    return translation;
  }
}
