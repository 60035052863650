/**
 * Created by samuel silalahi on 24/01/2020.
 */
import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken('app.config');

// const SERVICE_BASE_URL: string = process.env.API_URL;

const LANG_EN = {
  projectName: 'Gramedia Wirting Project',

  // Menu
  menuHome: 'HOME',
  menuGenre: 'GENRE',
  menuEvent: 'EVENT',
  menuBlog: 'BLOG',
  menuTheWritersShow: 'THE WRITERS\' SHOW',
  menuAboutUs: 'ABOUT US',
  menuFaq: 'GUIDELINES',
  menuGuide: 'HELP',
  menuDisclaimer: 'DISCLAIMER',
  menuMobileHome: 'Home',
  menuMobileGenre: 'Genre',
  menuMobileWriting: 'Write',
  menuMobileMyCollection: 'Collection',
  menuMobileAccount: 'Account',
  userMenuProfile: 'MY PROFILE ',
  userMenuMyCollection: 'MY COLLECTION',
  userMenuNotification: 'NOTIFICATION',
  userMenuWorkStories: 'MY STORIES',
  userMenuSetting: 'SETTINGS',
  userMenuAccount: 'ACCOUNT',
  userMenuChangePassword: 'CHANGE PASSWORD',
  userMenuLanguage: 'LANGUAGE',
  userMenuLangEnglish: 'ENGLISH',
  userMenuLangIndonesia: 'INDONESIA',
  userMenuLogout: 'SIGN OUT',

  // Button
  btnLogin: 'LOG IN / REGISTER',
  btnLoginGoogle: 'Log in using Google',
  btnLoginSubmit: 'SIGN IN',
  btnRegisterGoogle: 'Register with Goolge',
  btnRegisterSubmit: 'REGISTER',
  btnResetPasswordSubmit: 'RESET PASSWORD',
  btnRenewPasswordSubmit: 'RENEW YOUR PASSWORD',
  btnLoginBack: 'RE-LOG IN',
  btnGoToLogin: 'Log in',
  btnGoToHome: 'Back to Home',
  btnRegister: 'REGISTER',
  btnSave: 'SAVE',
  btnSaveSmall: 'Save',
  btnLogout: 'SIGN OUT',
  btnWriting: 'WRITE',
  btnBannerInviteJoin: 'Yes,I want to join!',
  btnBuyBook: 'Buy a Book',
  btnFollow: 'FOLLOW',
  btnFollowSmall: 'Follow',
  btnUnfollow: 'UNFOLLOW',
  btnUnfollowSmall: 'Unfollow',
  btnSend: 'SEND',
  btnFilterGenre: 'GENRE',
  btnFilterSort: 'SORT',
  btnChangePassword: 'Change Password',
  btnAuthor: 'Author',
  btnCommentReply: 'REPLY',
  btnCommentCancel: 'CANCEL',
  btnViewRecomendedStories: 'GO TO RECOMMENDATION STORIES NOW',
  btnSearch: 'FIND',
  btnLetsWrite: 'Come on, WRITE!',
  btnAddStories: 'Add another Story',
  btnAddChapter: 'Add Chapter',
  btnContinueWriting: 'Continue Writing',
  btnCreateStories: 'WRITE A STORY',
  btnRead: 'READ',
  btnEditStories: 'EDIT STORIES',
  btnMyCollection: 'COLLECTION',
  btnDelete: 'DELETE',
  btnDeleteSmall: 'Delete',
  btnEditProfile: 'EDIT PROFILE',
  btnSeePopularAuthor: 'CHECK OUT POPULAR AUTHORS',
  btnChangeCover: 'Change Cover',
  btnCancel: 'CANCEL',
  btnSkip: 'SKIP',
  btnNext: 'NEXT',
  btnEdit: 'EDIT',
  btnPreview: 'PREVIEW',
  btnPublish: 'PUBLISH',
  btnAddNewChapter: 'ADD A CHAPTER STORY',
  btnBackToHome: 'Please Go Back To Homepage',
  btnFindStory: 'Find a Story',

  // Link
  linkGoToLogin: 'Log in',
  linkGoToRegister: 'Register',
  linkForgotPassword: 'Forgot Password',
  linkLoginBack: 'log in',
  linkGoToHome: 'Home',
  linkGenre: 'GENRE',
  linkMyCollection: 'MY COLLECTION',
  linkListChapter: 'Chapter',
  linkVote: 'Vote',
  linkShare: 'Share',
  linkComment: 'Comment',
  linkOk: 'OK',
  linkCommentReply: 'Reply',
  linkDelete: 'Delete',
  linkEdit: 'Edit',
  linkCommentPinned: 'Pin it',
  linkCommentUnpinned: 'Unpin it',
  linkCommentReport: 'Report',
  linkOpenMoreCommentReply: 'View',
  linkCloseMoreCommentReply: 'Close',
  linkCommentReplies: 'replies',
  linkOpenMoreComments: 'Show More',
  linkCloseMoreComments: 'Show Less',
  linkViewDetailEvent: 'See Detail >>',
  linkPublish: 'Publish',
  linkUnpublish: 'Unpublish',

  // Label Social Media
  labelFacebook: 'Facebook',
  labelTwitter: 'Twitter',
  labelWhatsapp: 'Whatsapp',
  labelInstagram: 'Instagram',
  labelCopyLink: 'Copy Link',

  // Label - Section
  labelSecContinueReading: 'Continue Reading',
  labelSecRecomendationStories: 'Recommendation Stories',
  labelSecPopularStories: 'Popular Stories',
  labelSecNewStories: 'Newest Stories',
  labelSecPublishStories: 'Published Stories',
  labelSecPopularAuthor: 'Popular Authors',
  lagelSecRecomedationEvent: 'Recommendation Events',
  labelSecBlogs: 'Blog GWP',

  // Breadcrumb and title
  labelBreadcrumbHome: 'Home',
  labelBreadcrumbAboutus: 'About Us',
  labelBreadcrumbGenre: 'Genre',
  labelBreadcrumbChangePassword: 'Change Password',
  labelBreadcrumbContinueReading: 'Continue Reading',
  labelBreadcrumbDisclaimer: 'Disclaimer',
  labelBreadcrumbUserhelp: 'Help',
  labelBreadcrumbUserguide: 'Guidelines',
  labelBreadcrumbEvent: 'Event',
  labelBreadcrumbEventDetail: 'Event Detail',
  labelBreadcrumbWorkstories: 'My Stories',
  labelBreadcrumbAccountSetting: 'Account Setting',
  labelBreadcrumbRecStories: 'Recommendation Stories',
  labelBreadcrumbPublished: 'Published Stories',
  labelBreadcrumbPopularAuthor: 'Popular Authors',
  labelBreadcrumbNotification: 'Notification',
  labelBreadcrumbMyCollection: 'My Collection',
  labelBreadcrumbTermCondition: 'Term & Conditions',

  // Label - General
  labelSearch: 'Search',
  labelBackTo: 'Back to',
  labelWelcome: 'Welcome,',
  labelSeeAll: 'View All',
  labelTheEnd: 'THE END',
  labelStoriesLastUpdate: 'Last Updated',
  labelStoriesLastRead: 'Last Read',
  labelCountStories: 'Story',
  labelCountAuthorStories: 'Stories',
  labelCountStoriesSmall: 'stories',
  labelCountFollower: 'follower',
  labelCountFollowing: 'following',
  labelCountEventSmall: 'event',
  labelCountAuthor: 'Authors',
  labelCountAuthorSmall: 'authors',
  labelCountGenre: 'Genre',
  labelCountMember: 'Member',
  labelCountViews: 'views',
  labelCountVotes: 'votes',
  labelCountComments: 'comments',
  labelFollowed: 'Followed',
  labelBannerRed1: 'Do you want to get a chance to publish your writing?',
  labelBannerRed2: 'Join now and share your interesting story here!',
  labelSubscribeInfo: 'Stay connected with us, enter your email right under here!',
  labelConnectToMediaSocial: 'Stay Connected',
  labelCleanAll: 'Clear All',
  labelShowPagingCount: 'Showing',
  labelShowPagingFromCount: 'from',
  labelComment: 'Comments',
  labelCommentPinned: 'Comment Pinned by',
  labelCommentEdited: 'Edited',
  labelAskSearcUserhelp: 'Hi, how can we help?',
  placeholderSearchUserhelp: 'Find Help',
  labelEventDate: 'Date and Time',
  labelEventTime: ' ',
  labelEventTimeRegion: 'WIB',
  labelEventLocation: 'Location ',
  labelEventShare: 'Share Event',
  labelStoriesShare: 'Share Stories',
  labelStoriesChapter: 'Share Chapter',
  labelOldNotification: 'Earlier',
  labelNewNotification: 'Latest',
  labelSocialMedia: 'Social Media',
  labelProfile: 'Profil',
  labelExample: 'Example',
  placeholderInputLink: 'Enter Link',
  placeholderWriteYourStory: 'Write your story...',
  labelChapterSuccessPublish: 'Congratulations!',
  labelChapterBab: ' ',
  messageChapterSuccessPublish: 'has been published!',
  subMessageChapterSuccessPublish: 'You can find it here!',
  labelShare: 'Share',
  labelWordsCount: 'Words',

  labelAll: 'All',
  labelOrderFilter: 'Sort by',
  labelOrderByAsc: 'A - Z',
  labelOrderByDesc: 'Z - A',
  labelOrderByNewest: 'Latest',
  labelOrderByOldest: 'Earlier',
  lableOrderByPopular: 'Most Popular',
  placeholderChoose: 'Select',

  // Label Tab
  labelTabSynopsis: 'SYNOPSIS',
  labelTabTableOfContent: 'TABLE OF CONTENT',
  labelTabStory: 'STORY',
  labelTabAuthor: 'AUTHOR',
  labelTabStoryDetail: 'STORY DETAIL',
  labelTabStoryTitle: 'Chapter Title',
  labelWarningOnCopyPaste: 'NOTES: Untuk hasil terbaik, silakan gunakan ctrl + shift + v / cmd + shift + v saat menyalin dari Aplikasi Lain.',

  // Label - Input - Placeholder
  labelOr: 'or',
  labelUsernameEmail: 'Username / Email',
  placeholderUsernameEmail: 'Type Your Username / Email',
  labelErrReqUsernameEmail: 'Username / email is required!',
  labelPassword: 'Password',
  labelConfirmPassword: 'Confirm Password',
  placeholderPassword: 'Type Your Password',
  placeholderConfirmPassword: 'Confirm your password',
  labelErrReqPassword: 'Password is required!',
  labelErrMinPassword: 'Minimum of 8 characters with a mix of letters, numbers and symbols!',
  labelInvalidPasswordConfirmation: 'Password confirmation is not equal to the input password',
  labelRememberMe: 'Remember Me',
  labelNoAccount: 'Don\'t have an account?',
  labelUsername: 'Username',
  placeholderUsername: 'Type Your Username',
  labelErrReqUsername: 'Username is required!',
  labelErrMinUsername: 'Minimum 4 characters!',
  labelErrMaxUsername: 'Maximum 50 chacters!',
  labelErrExistUsername: 'Username has been registered!',
  labelEmail: 'Email',
  placeholderEmail: 'Type Your Email',
  labelErrReqEmail: 'Email is required!',
  labelErrFormatEmail: 'Email is not valid!',
  labelGenre: 'Genre',
  labelErrReqGenre: 'Genre is required!',
  labelNotFoundGenre: 'Genre Not Available',
  labelChooseGenre: 'Select Genre',
  labelErrReqCaptcha: 'reCaptcha must be checked!',
  labelyesAccount: 'Already have an Account?',
  labelPleaseRegister: 'Register to GWP',
  labelPleaseLogin: 'Login to your GWP account',
  labelNextStepRegister: 'One more step to complete',
  labelAlreadyRemember: 'You remembered?',

  labelTermConditionLogin: 'If you log in, you\'ve agree to the',
  labelTermConditionRegister: 'If you register, you\'ve agree to the',
  labelTermCondition: 'Terms and Condition',
  labelTermConditionWith: 'along with',
  labelTermConditionRule: 'Privacy Policy',

  labelResetPasswordTitle: 'Reset Password',
  labelResetPasswordInstruction1: 'Please fill in',
  labelResetPasswordInstruction2: 'the email',
  labelResetPasswordInstruction3: 'used to register.',
  labelResetPasswordInstruction4: 'An email with your username and link to reset password will be sent to you.',

  labelRenewPasswordTitle: 'Renew Your Password',
  labelNewPassword: 'New Password',
  labelErrReqNewPassword: 'New password is required!',
  placeholderNewPassword: 'Type Your New Password',
  labelReNewPassword: 'Confirm Password',
  placeholderReNewPassword: 'Type Your Confirm Password',
  labelErrReqReNewPassword: 'Confirm password is required!',
  labelErrPasswordNotMatch: 'Password doesn\'t match!',
  labelOldPassword: 'Old Password',
  placeholderOldPassword: 'Type Your Old Password',
  labelErrReqOldPassword: 'Old password is required!',

  labelRegisterSuccessTitle: 'Registration Successful',
  labelRegisterSuccessInfo1: 'Please check the email you registered',
  labelRegisterSuccessInfo2: 'to verify your Gramedia Writing Project account.',
  labelRegisterSuccessWelcome: 'Welcome to Gramedia Writing Project.',

  labelActivationSuccess: 'Activation Successful',
  labelActivationFailed: 'Activation Failed',
  labelActivationAlready: 'Your GWP account has successfully activated.',
  labelActivationSuccessInfo: 'Please log in with registered email and password.',
  labelActivationFailedInfo: 'Please click the activation link again in your email in a few moments, or contact the GWP Admin.',

  labelGWPCompetition: 'The Writers\' Show',
  labelFullName: 'Full Name',
  placeholderFullName: 'Type Your Full Name',
  labelErrReqFullName: 'Full name is required!',
  labelUniqueName: 'Pen Name (Username)',
  placeholderUniqueName: 'Type Your Pen Name (Username)',
  labelErrReqUniqueName: 'Pen Name (Username) is required!',
  labelErrExistUniqueName: 'Pen Name (Username) have been registered!',
  labelBirthday: 'Date of Birth',
  placeholderBirthday: 'Type Your Date of Birth',
  labelErrReqBirthDay: 'Date of birth is required!',
  labelPlaceBirthday: 'Place of Birth',
  placeholderPlaceBirthday: 'Type Your Place of Birth',
  labelErrReqPlaceBirthDay: 'Place of birth is required!',
  labelSex: 'Gender',
  labelChooseSex: 'Select Gender',
  labelErrReqSex: 'Gender is required!',
  labelPhoneNumber: 'Mobile Number',
  placeholderPhoneNumber: 'Example: 08123456789',
  labelErrReqPhoneNumber: 'Mobile number is required!',
  labelErrMaxPhoneNumber: 'Maximum of 13 numbers!',
  labelBio: 'Bio',
  placeholderBio: 'Type Your Bio',
  labelErrMaxBio: 'Maximum 250 chacters!',
  labelAccount: 'Account',
  labelProvince: 'Province',
  placehoderProvince: 'Select Province',
  labelProvinceNotFound: 'Province Not Found',
  labelErrReqProvince: 'Province is required!',
  labelCity: 'City',
  placehoderCity: 'Select City',
  labelCityNotFound: 'City Not Found',
  labelErrReqCity: 'City is required!',
  labelErrMaxChapterTitle: 'Maximum 250 chacters!',
  labelStoryTitle: 'Story Title',
  placeholderStoryTitle: 'Type Your Story Title',
  lavelErrMaxStoryTitle: 'Maximum 50 characters',
  labelUploadCover: 'Upload Cover',
  labelChangeCover: 'CHANGE COVER',
  labelUploadCoverNote: '*Notes : ',
  labelUploadCoverNoteResolution: '650 x 963 pixels is the recommended resolution',
  labelUploadCoverNoteSize: '500 KB Max',
  labelUploadCoverErrorSize: 'Image size is more than 500 KB',
  labelSynopsis: 'Synopsis',
  placeholderSynopsis: 'Type Your Synopsis',
  labelErrMaxSynopsis: 'Maximum 800 chacters',
  labelErrMaxWordsSynopsis: 'Maximum 100 words',
  labelTag: 'Tag',
  placeholderTag: 'Type Your Tag',
  labelStoryMature: 'Adult Content',
  labelStoryComplete: 'Story Complete',

  placeholderEmailSubscribe: 'Enter Your Email',

  placeholderSearchBox: 'Find Story Title, Author',
  labelSearchStories: 'Story',
  labelSearchAuthor: 'Author',
  labelLastSearch: 'Latest Search',
  labelPopularSearch: 'Popular Search',
  labelTagSearch: 'Tag',

  labelResultSearch: 'Story search results from',
  placeholderWriteComment: 'Write Your Comment Here',

  labelChooseCheckbox: 'Select',
  labelCancelChooseCheckbox: 'Cancel Selection',
  labelChooseAllCheckbox: 'Select',
  labelCancelChooseAllCheckbox: 'Cancel All Selection',

  // Label - story/chapter status
  labelPublishStories: 'Published',
  labelDraftStories: 'Draft',
  labelNotSaveStories: 'Not Saved Yet',
  lableSavedStories: 'Saved',
  lableSavedStoriesFailed: 'Failed to Save',
  labelSavingStories: 'Saving...',
  lablePreviewStories: 'Preview',
  lableAlreadySavedStories: 'Saved',

  // Label - empty state
  labelNoSearchStories: 'There\'s no stories that you\'re looking for',
  labelNoSearchAuthor: 'There\'s no authors that you\'re looking for',
  labelNoStoriesRead: 'You haven\'t read any story',
  labelNoSearchUserhelp: 'There\'s no help available yet',
  labelNoSearchEvent: 'The event you\'re looking for is not available',
  labelNoEventSuggest: 'Look forward to our latest event via email',
  labelNoStories: 'There\'s no stories yet',
  labelNoStoriesAvailabel: 'story not yet available!',
  labelNoRecomendationStories: 'There\'s no recommended stories yet',
  labelNoPublishedStories: 'There\'s no published stories that you\'re looking for',
  labelNoPopularAuthor: 'There\'s no popular author that you\'re looking for',
  labelNoNotification: 'There\'s no notifications yet',
  labelNoMyCollection: 'You don\'t have any collections yet',
  labelNoFollower: 'There\'s no followers yet',
  labelNoFollowing: 'There\'s no following yet',
  labelNoChapterStories: 'There\'s No Story Chapter.',
  labelTitleNoStory: 'Looking for the story that has lost?',
  labelMessageNoStory: 'The past should be left in the past, so does these(this) story. It\'s more than enough to keep them in your heart.',
  labelDescriptionNoStory: 'The page that you are looking for is not available.',
  labelTitleNoCollection: 'There\'s no stories in your library yet.',
  labelMessageNoCollection: 'Let\'s find your favorite stories!',

  // Label notification
  labelSettingNotification: 'Notification',
  labelSettingFollowMe: 'Followers',
  labelSettingFollowMeDesc: 'You received information about a new followers',
  labelSettingLikeMyStories: 'Liked Chapters',
  labelSettingLikeMyStoriesDesc: 'Receiving information that someone likes your chapters',
  labelSettingCommentStories: 'Commented on synopsis of your story or chapter',
  labelSettingCommentStoriesDesc: 'Receive information commented on by someone about the synopsis of your story or chapter',

  // Label notification conent
  followingYou: 'following you',
  commentStory: 'commented on',
  commentChapter: 'commented on a chapter of',
  likeStory: 'liked story',
  likeChapter: 'liked a chapter of',

  // Label event status
  live: 'ONGOING',
  coming: 'COMING SOON',
  done: 'FINISHED',
  dateRange: ' to ',
  eventAll: 'All',
  eventLive: 'Ongoing',
  eventComing: 'Coming Soon',
  eventDone: 'Finished',

  // Alert message verified
  loginVerifiedMessage1: 'Your account has been registered but',
  loginVerifiedMessage2: 'not verified',
  loginVerifiedMessage3: 'Your Email:',
  loginVerifiedMessage4: 'Please check our verification email sent to your email address or click here to resend verification email',
  loginVerifiedMessage5: 'click here!',

  // Alert message transform
  failed: 'Failed',
  success: 'Success',
  usernameExist: 'Username has been registered.',
  emailExist: 'Email has been registered.',
  usernameEmailFailed: 'Username / Email is not registered.',
  usernameFailed: 'Username is not registered.',
  emailFailed: 'Email is not registered.',
  loginFailed: 'Your Username/Email or Password does not match. Please reenter again!',
  registerFailed: 'Please try to register again later.',
  registerSuccess: 'Please check your email for the account verification process.',
  resendVSuccess: 'Email sent, please check your email!',
  resendVFailed: 'There\'s a technical difficulty in our system, please try again!',
  forgotPasswordFailed: 'Try again in a few moments.',
  forgotPasswordSuccess: 'Please check your email.',
  renewPasswordFailed: 'Password update failed.',
  renewPasswordSuccess: 'Password succesfully changed.',
  renewPasswordNotSame: 'Old password doesn\'t match.',
  renewPasswordUserNotFound: 'User not found.',
  renewRePasswordNotSame: 'Password confirmation does not match.',
  userAlreadyRegister: 'Email have been registered as a participant.',
  userRegisterFailed: 'Error! Please try again later.',
  userSuccessRegister: 'Email successfully registered, please check your email!',
  emailSuccessRegister: 'Email successfully registered.',
  emailFailedRegister: 'Unable to register email. Please try again later.',
  emailAlreadyRegister: 'Email have been registered as a participant, please check your email!',
  emailExistRegister: 'Email or username have been registered.',
  subscribeSuccess: 'Email subcription success!',
  subscribeNotSend: 'Subcription failed! Email wasn\'t delivered properly!',
  subscribeExist: 'Subcription failed! The email you entered has been registered for email subcription.',
  subscribeFailed: 'Subcription failed! Please try again later!',
  notificationChangeSuccess: 'Notification successfully updated',
  notificationChangeFailed: 'Notification update failed',
  profileChangeSuccess: 'Profile successfully updated',
  profileChangeFailed: 'Profile update failed',

  // Toast message transform
  voteStorySuccess: 'Story successfully voted!',
  voteStoryFailed: 'Story vote failed. Please try again later!',
  linkCopied: 'Link copied!',

  // Confirm message transform
  confirmMDeleteComment: 'Are you sure to delete this comment?',
  confirmTDeleteComment: 'Delete Comment',
  confirmDelete: 'Delete',
  confirmMPinComment: 'Are you sure to pin this comment?',
  confirmTPinComment: 'Pin This Comment',
  confirmMUnpinComment: 'Are you sure to unpin this comment?',
  confirmTUnpinComment: 'Unpin This Comment',
  confirmSure: 'Yes',
  confirmMReportComment: 'Select Report Category: ',
  confirmTReportComment: 'Report Comment',
  confirmBtnSendReport: 'Send Report',
  confirmBtnCancel: 'Cancel',
  confirmMDelStories: 'Are you sure to delete this story?',
  confirmTDelStories: 'Delete Story',
  confirmMPublishStories: 'Are you sure to publish this story?',
  confirmTPublishStories: 'Publish Story',
  confirmBtnPublishStories: 'Publish',
  confirmMUnpublishStories: 'Are you sure to unpublish this story?',
  confirmMUnpublishStories_a: 'Story draft cannot be seen by others, they can\'t read, vote or comment.',
  confirmTUnpublishStories: 'Unpublish Story',
  confirmTUnpublishStories_a: 'Unpublish Story?',
  confirmBtnUnpublishStories: 'Unpublish',
  confirmMDelChapter: 'Are you sure to delete this chapter?',
  confirmTDelChapter: 'Delete Chapter',
  confirmBtnDelChapter: 'Delete',
  confirmTDeleteLibrary: 'Delete collection',
  confirmFDeleteLibrary: 'Are you sure to delete',
  confirmSingleDeleteLibrary: 'this story from',
  confirmMLDeleteLibrary: 'story from',
  confirmNLDeleteLibrary: 'My Collection?',

  // Label tooltip
  tooltipStoryTitle: 'Put your story in the right category to ensure a positive reading experience for the reader. Adult categorized stories, intended for readers 18 years and over.',
  tooltipSynopsis: 'To attract reader\'s interest to read the story, please write/give a description to your story. ',
  tooltipTag: 'Adding tags helps increasing exposure for your story in searches. Tag must be a word or concept that reflects the theme or subgenre of your story. Tag can be separated by using coma, space and enter.',
  tooltipGenre: 'It will help readeres to identify what type of story you wrote.',
  tooltipStoryMature: 'User must be 18 or above to read this story.',
  tooltipCreateStoryInfo1: 'Make sure your story is suitable for all ages.',
  tooltipCreateStoryInfo2: 'For more information, please read the guidelines',
};

const LANG_ID = {
  projectName: 'Gramedia Wirting Project',

  // Menu
  menuHome: 'Beranda',
  menuGenre: 'Genre',
  menuEvent: 'Acara',
  menuBlog: 'Blog',
  menuTheWritersShow: 'THE WRITERS\' SHOW',
  menuAboutUs: 'Tentang Kami',
  menuFaq: 'Panduan',
  menuGuide: 'Bantuan',
  menuDisclaimer: 'Disclaimer',
  menuMobileHome: 'Beranda',
  menuMobileGenre: 'Genre',
  menuMobileWriting: 'Tulis',
  menuMobileMyCollection: 'Koleksiku',
  menuMobileAccount: 'Akun',
  userMenuProfile: 'Profil Saya',
  userMenuMyCollection: 'Koleksiku',
  userMenuNotification: 'Notifikasi',
  userMenuWorkStories: 'Cerita Saya',
  userMenuSetting: 'Pengaturan',
  userMenuAccount: 'Akun',
  userMenuChangePassword: 'Ubah Kata Sandi',
  userMenuLanguage: 'Bahasa',
  userMenuLangEnglish: 'Inggris',
  userMenuLangIndonesia: 'Indonesia',
  userMenuLogout: 'Keluar',

  // Button
  btnLogin: 'MASUK / DAFTAR',
  btnLoginGoogle: 'Masuk dengan Google',
  btnLoginSubmit: 'MASUK',
  btnRegisterGoogle: 'Daftar dengan Google',
  btnRegisterSubmit: 'DAFTAR',
  btnResetPasswordSubmit: 'RESET KATA SANDI',
  btnRenewPasswordSubmit: 'PERBARUI KATA SANDI',
  btnLoginBack: 'KEMBALI MASUK',
  btnGoToLogin: 'Masuk Sekarang',
  btnGoToHome: 'Kembali ke Beranda',
  btnRegister: 'MENDAFTAR',
  btnSave: 'SIMPAN',
  btnSaveSmall: 'Simpan',
  btnLogout: 'Keluar',
  btnWriting: 'Tulis Cerita',
  btnBannerInviteJoin: 'Ya, Saya Mau',
  btnBuyBook: 'Beli Buku',
  btnFollow: 'FOLLOW',
  btnFollowSmall: 'Follow',
  btnUnfollow: 'UNFOLLOW',
  btnUnfollowSmall: 'Unfollow',
  btnSend: 'KIRIM',
  btnFilterGenre: 'GENRE',
  btnFilterSort: 'URUTKAN',
  btnChangePassword: 'Ubah Kata Sandi',
  btnAuthor: 'Penulis',
  btnCommentReply: 'BALAS',
  btnCommentCancel: 'BATAL',
  btnViewRecomendedStories: 'LIHAT CERITA REKOMENDASI',
  btnSearch: 'CARI',
  btnLetsWrite: 'AYO, MENULIS!',
  btnAddStories: 'Tambah Cerita',
  btnAddChapter: 'Tambah Bab',
  btnContinueWriting: 'Lanjut Menulis',
  btnCreateStories: 'BUAT CERITA',
  btnRead: 'BACA',
  btnStartRead: 'Mulai Baca',
  btnEditStories: 'EDIT CERITA',
  btnMyCollection: 'KOLEKSI',
  btnDelete: 'HAPUS',
  btnDeleteSmall: 'Hapus',
  btnEditProfile: 'EDIT PROFILE',
  btnSeePopularAuthor: 'LIHAT PENULIS POPULER',
  btnChangeCover: 'Ubah Cover',
  btnCancel: 'BATAL',
  btnSkip: 'LEWATI',
  btnNext: 'BERIKUTNYA',
  btnEdit: 'EDIT',
  btnPreview: 'PREVIEW',
  btnPublish: 'PUBLISH',
  btnAddNewChapter: 'TAMBAH BAB CERITA',
  btnBackToHome: 'Kembali Ke Beranda',
  btnFindStory: 'Cari Cerita',

  // Link
  linkGoToLogin: 'Masuk',
  linkGoToRegister: 'Daftar',
  linkForgotPassword: 'Lupa Kata Sandi',
  linkLoginBack: 'kembali masuk',
  linkGoToHome: 'Beranda',
  linkGenre: 'GENRE',
  linkMyCollection: 'KOLEKSIKU',
  linkListChapter: 'Chapter',
  linkVote: 'Vote',
  linkShare: 'Share',
  linkComment: 'Comment',
  linkOk: 'OK',
  linkCommentReply: 'Balas',
  linkDelete: 'Hapus',
  linkEdit: 'Edit',
  linkCommentPinned: 'Sematkan',
  linkCommentUnpinned: 'Tanggalkan',
  linkCommentReport: 'Laporkan',
  linkOpenMoreCommentReply: 'Lihat',
  linkCloseMoreCommentReply: 'Tutup',
  linkCommentReplies: 'balasan',
  linkOpenMoreComments: 'Tampilkan Lebih Banyak',
  linkCloseMoreComments: 'Tampilkan Lebih Sedikit',
  linkViewDetailEvent: 'Lihat Detail >>',
  linkPublish: 'Publish',
  linkUnpublish: 'Unpublish',

  // Label Social Media
  labelFacebook: 'Facebook',
  labelTwitter: 'Twitter',
  labelWhatsapp: 'Whatsapp',
  labelInstagram: 'Instagram',
  labelCopyLink: 'Copy Link',

  // Label - Section
  labelSecContinueReading: 'Lanjut Membaca',
  labelSecRecomendationStories: 'Cerita Rekomendasi',
  labelSecPopularStories: 'Cerita Populer',
  labelSecNewStories: 'Cerita Terbaru',
  labelSecPublishStories: 'Cerita Sudah Terbit',
  labelSecPopularAuthor: 'Penulis Populer',
  lagelSecRecomedationEvent: 'Rekomendasi Acara',
  labelSecBlogs: 'Blog GWP',

  // Breadcrumb and title
  labelBreadcrumbHome: 'Beranda',
  labelBreadcrumbAboutus: 'Tentang Kami',
  labelBreadcrumbGenre: 'Genre',
  labelBreadcrumbChangePassword: 'Ubah Kata Sandi',
  labelBreadcrumbContinueReading: 'Lanjut Membaca',
  labelBreadcrumbDisclaimer: 'Disclaimer',
  labelBreadcrumbUserhelp: 'Bantuan',
  labelBreadcrumbUserguide: 'Panduan',
  labelBreadcrumbEvent: 'Acara',
  labelBreadcrumbEventDetail: 'Detail Acara',
  labelBreadcrumbWorkstories: 'Cerita Saya',
  labelBreadcrumbAccountSetting: 'Pengaturan Akun',
  labelBreadcrumbRecStories: 'Cerita Rekomendasi',
  labelBreadcrumbPublished: 'Cerita Terbit',
  labelBreadcrumbPopularAuthor: 'Penulis Populer',
  labelBreadcrumbNotification: 'Notifikasi',
  labelBreadcrumbMyCollection: 'Koleksiku',
  labelBreadcrumbTermCondition: 'Syarat & Ketentuan',

  // Label - General
  labelSearch: 'Cari',
  labelBackTo: 'Kembali ke',
  labelWelcome: 'Selamat Datang,',
  labelSeeAll: 'Lihat Semua',
  labelTheEnd: 'TAMAT',
  labelStoriesLastUpdate: 'Terakhir Update',
  labelStoriesLastRead: 'Terakhir Membaca',
  labelCountStories: 'Cerita',
  labelCountAuthorStories: 'Cerita',
  labelCountStoriesSmall: 'cerita',
  labelCountFollower: 'followers',
  labelCountFollowing: 'following',
  labelCountEventSmall: 'acara',
  labelCountAuthor: 'Penulis',
  labelCountAuthorSmall: 'penulis',
  labelCountGenre: 'Genre',
  labelCountMember: 'Member',
  labelCountViews: 'views',
  labelCountVotes: 'votes',
  labelCountComments: 'comments',
  labelFollowed: 'Followed',
  labelBannerRed1: 'Kamu mau meraih kesempatan menerbitkan karya tulismu?',
  labelBannerRed2: 'Ayo, gabung sekarang dan bagikan tulisan menarikmu disini.',
  labelSubscribeInfo: 'Mau terhubung terus dengan kami, isi emailmu di bawah sini!',
  labelConnectToMediaSocial: 'Tetap Terhubung',
  labelCleanAll: 'Hapus Semua',
  labelShowPagingCount: 'Menampilkan',
  labelShowPagingFromCount: 'dari',
  labelComment: 'Komentar',
  labelCommentPinned: 'Disematkan oleh',
  labelCommentEdited: 'Telah diedit',
  labelAskSearcUserhelp: 'Hai, ada yang dapat kami bantu?',
  placeholderSearchUserhelp: 'Temukan Bantuan',
  labelEventDate: 'Waktu dan Tanggal',
  labelEventTime: 'Jam',
  labelEventTimeRegion: 'WIB',
  labelEventLocation: 'Lokasi Acara',
  labelEventShare: 'Bagikan Acara',
  labelStoriesShare: 'Bagikan Cerita',
  labelStoriesChapter: 'Bagikan Bab Cerita',
  labelOldNotification: 'Terdahulu',
  labelNewNotification: 'Terbaru',
  labelSocialMedia: 'Media Sosial',
  labelProfile: 'Profil',
  labelExample: 'Contoh',
  placeholderInputLink: 'Masukan Link',
  placeholderWriteYourStory: 'Tulis cerita kamu...',
  labelChapterSuccessPublish: 'Selamat!',
  labelChapterBab: 'Bab',
  messageChapterSuccessPublish: 'telah berhasil terpublish!',
  subMessageChapterSuccessPublish: 'Kamu bisa mencarinya disini!',
  labelShare: 'Bagikan',
  labelWordsCount: 'Kata',

  labelAll: 'Semua',
  labelOrderFilter: 'Urutkan Berdasarkan',
  labelOrderByAsc: 'A - Z',
  labelOrderByDesc: 'Z - A',
  labelOrderByNewest: 'Terbaru',
  labelOrderByOldest: 'Terlama',
  lableOrderByPopular: 'Terpopuler',
  placeholderChoose: 'Pilih',

  // Label Tab
  labelTabSynopsis: 'SINOPSIS',
  labelTabTableOfContent: 'DAFTAR ISI',
  labelTabStory: 'CERITA',
  labelTabAuthor: 'PENULIS',
  labelTabStoryDetail: 'DETAIL CERITA',
  labelTabStoryTitle: 'Judul Bab',
  labelWarningOnCopyPaste: 'NOTES: Untuk hasil terbaik, silakan gunakan ctrl + shift + v / cmd + shift + v jika menyalin dari Ms. Words.',

  // Label - Input - Placeholder
  labelOr: 'atau',
  labelUsernameEmail: 'Username / Email',
  placeholderUsernameEmail: 'Masukan Username / Email',
  labelErrReqUsernameEmail: 'Username / email wajib diisi!',
  labelPassword: 'Password',
  labelConfirmPassword: 'Konfirmasi Password Anda',
  placeholderPassword: 'Masukan Password',
  placeholderConfirmPassword: 'Masukkan Password anda sekali lagi',
  labelErrReqPassword: 'Password wajib diisi!',
  labelErrMinPassword: 'Minimal 8 karakter dengan campuran huruf, angka, dan simbol!',
  labelInvalidPasswordConfirmation: 'Konfirmasi password harus sama dengan password yang telah dimasukkan',
  labelRememberMe: 'Ingat Aku',
  labelNoAccount: 'Belum Punya Akun?',
  labelUsername: 'Username',
  placeholderUsername: 'Masukan Username',
  labelErrReqUsername: 'Username wajib diisi!',
  labelErrMinUsername: 'Minimal 4 karakter!',
  labelErrMaxUsername: 'Maksimal 50 karakter!',
  labelErrExistUsername: 'Username sudah digunakan!',
  labelEmail: 'Email',
  placeholderEmail: 'Masukan Email',
  labelErrReqEmail: 'Email wajib diisi!',
  labelErrFormatEmail: 'Format email tidak valid!',
  labelGenre: 'Genre',
  labelErrReqGenre: 'Genre wajib dipilih!',
  labelNotFoundGenre: 'Genre belum tersedia',
  labelChooseGenre: 'Pilih Genre',
  labelErrReqCaptcha: 'reCaptcha wajib dicentang!',
  labelyesAccount: 'Sudah Punya Akun?',
  labelPleaseRegister: 'Mulai mendaftar di GWP',
  labelPleaseLogin: 'Masukan akun GWP Kamu',
  labelNextStepRegister: 'Satu langkah lagi untuk melengkapi',
  labelAlreadyRemember: 'Sudah ingat ya?',

  labelTermConditionLogin: 'Jika Anda login, Anda menerima',
  labelTermConditionRegister: 'Jika Anda daftar, Anda menerima',
  labelTermCondition: 'Syarat dan Ketentuan',
  labelTermConditionWith: 'serta',
  labelTermConditionRule: 'Kebijakan Privasi',

  labelResetPasswordTitle: 'Reset Kata Sandi',
  labelResetPasswordInstruction1: 'Masukan',
  labelResetPasswordInstruction2: 'alamat email',
  labelResetPasswordInstruction3: 'yang kamu gunakan untuk mendaftar.',
  labelResetPasswordInstruction4: 'Kami akan mengirimkan email berisi nama pengguna dan tautan untuk mereset kata sandi kepadamu.',

  labelRenewPasswordTitle: 'Perbarui Sandi',
  labelNewPassword: 'Kata Sandi Baru',
  labelErrReqNewPassword: 'Kata sandi baru wajib diisi!',
  placeholderNewPassword: 'Masukan Kata Sandi Baru',
  labelReNewPassword: 'Konfimasi Kata Sandi',
  placeholderReNewPassword: 'Masukan Konfirmasi Kata Sandi',
  labelErrReqReNewPassword: 'Konfirmasi kata sandi wajib diisi!',
  labelErrPasswordNotMatch: 'Kata sandi belum sesuai!',
  labelOldPassword: 'Kata Sandi Lama',
  placeholderOldPassword: 'Masukan Kata Sandi Lama',
  labelErrReqOldPassword: 'Kata sandi lama harus diisi',

  labelRegisterSuccessTitle: 'Registrasi Berhasil',
  labelRegisterSuccessInfo1: 'Silakan cek e-mail Anda yang terdaftar',
  labelRegisterSuccessInfo2: 'untuk melakukan verifikasi akun Gramedia Writing Project.',
  labelRegisterSuccessWelcome: 'Selamat datang di Gramedia Writing Project.',

  labelActivationSuccess: 'Aktivasi Berhasil',
  labelActivationFailed: 'Aktivasi Tidak Berhasil',
  labelActivationAlready: 'Akun GWP Anda sudah aktif.',
  labelActivationSuccessInfo: 'Silakan login menggunakan email dan password yang terdaftar.',
  labelActivationFailedInfo: 'Mohon klik kembali link aktivasi pada email Anda beberapa saat lagi, atau hubungi Admin GWP.',

  labelGWPCompetition: 'The Writers\' Show',
  labelFullName: 'Nama Lengkap',
  placeholderFullName: 'Masukan Nama Lengkap',
  labelErrReqFullName: 'Nama lengkap wajib diisi!',
  labelUniqueName: 'Nama Pena (Username)',
  placeholderUniqueName: 'Masukan Nama Pena (Username)',
  labelErrReqUniqueName: 'Nama pena (username) wajib diisi!',
  labelErrExistUniqueName: 'Nama pena (username) sudah terdaftar!',
  labelBirthday: 'Tanggal Lahir',
  placeholderBirthday: 'Masukan Tanggal Lahir',
  labelErrReqBirthDay: 'Tanggal lahir wajib diisi!',
  labelPlaceBirthday: 'Tempat Lahir',
  placeholderPlaceBirthday: 'Masukan Tempat Lahir',
  labelErrReqPlaceBirthDay: 'Tempat lahir wajib diisi!',
  labelSex: 'Jenis Kelamin',
  labelChooseSex: 'Pilih Jenis Kelamin',
  labelErrReqSex: 'Jenis kelamin wajib dipilih!',
  labelPhoneNumber: 'Nomor Handphone',
  placeholderPhoneNumber: 'Contoh: 08123456789',
  labelErrReqPhoneNumber: 'Nomor handphone wajib diisi!',
  labelErrMaxPhoneNumber: 'Maksimal 13 angka!',
  labelBio: 'Bio',
  placeholderBio: 'Masukan Bio',
  labelErrMaxBio: 'Maksimal 250 karakter!',
  labelAccount: 'Akun',
  labelProvince: 'Provinsi',
  placehoderProvince: 'Pilih Provinsi',
  labelProvinceNotFound: 'Provinsi Tidak Ditemukan',
  labelErrReqProvince: 'Provinsi wajib dipilih!',
  labelCity: 'Kota/Kabupaten',
  placehoderCity: 'Pilih Kota/Kabupaten',
  labelCityNotFound: 'Kota/Kabupaten Tidak Ditemukan',
  labelErrReqCity: 'Kota/Kabupaten wajib dipilih!',
  labelErrMaxChapterTitle: 'Maksimal 250 karakter',
  labelStoryTitle: 'Judul Cerita',
  placeholderStoryTitle: 'Masukan Judul Cerita',
  lavelErrMaxStoryTitle: "Maksimal 50 karakter",
  labelUploadCover: 'Upload Cover',
  labelChangeCover: 'UBAH COVER',
  labelUploadCoverNote: '*Catatan : ',
  labelUploadCoverNoteResolution: 'Rekomendasi resolusi cover 650 x 963 pixels',
  labelUploadCoverNoteSize: 'Maksimal ukuran cover 500 KB',
  labelUploadCoverErrorSize: 'Ukuran gambar lebih dari 500 KB',
  labelSynopsis: 'Sinopsis',
  placeholderSynopsis: 'Masukan Sinopsis',
  labelErrMaxSynopsis: 'Maksimal 800 karakter',
  labelErrMaxWordsSynopsis: 'Maksimal 100 kata',
  labelTag: 'Tag',
  placeholderTag: 'Masukan Tag',
  labelStoryMature: 'Konten Dewasa',
  labelStoryComplete: 'Story Complete',

  placeholderEmailSubscribe: 'Masukan Email Kamu',

  placeholderSearchBox: 'Cari',
  labelSearchStories: 'Cerita',
  labelSearchAuthor: 'Penulis',
  labelLastSearch: 'Pencarian Terakhir',
  labelPopularSearch: 'Pencarian Popular',
  labelTagSearch: 'Tag',

  labelResultSearch: 'Hasil pencarian cerita dari',
  placeholderWriteComment: 'Tulis Komentar Disini',

  labelChooseCheckbox: 'Pilih',
  labelCancelChooseCheckbox: 'Batal Pilih',
  labelChooseAllCheckbox: 'Pilih Semua',
  labelCancelChooseAllCheckbox: 'Batal Pilih Semua',

  // Label - story/chapter status
  labelPublishStories: 'Published',
  labelDraftStories: 'Draft',
  labelNotSaveStories: 'Belum Disimpan',
  lableSavedStories: 'Disimpan',
  lableSavedStoriesFailed: 'Gagal Disimpan',
  labelSavingStories: 'Menyimpan...',
  lablePreviewStories: 'Preview',
  lableAlreadySavedStories: 'Tersimpan',

  // Label - empty state
  labelNoSearchStories: 'Belum ada cerita yang kamu cari',
  labelNoSearchAuthor: 'Belum ada Penulis yang kamu cari',
  labelNoStoriesRead: 'Belum ada cerita yang kamu baca',
  labelNoSearchUserhelp: 'Belum ada bantuan yang kamu cari',
  labelNoSearchEvent: 'Belum ada acara yang kamu cari',
  labelNoEventSuggest: 'Nantikan acara terbaru kami melalui email',
  labelNoStories: 'Belum Ada Cerita',
  labelNoStoriesAvailabel: 'cerita belum tersedia!',
  labelNoRecomendationStories: 'Belum ada cerita yang direkomendasikan',
  labelNoPublishedStories: 'Belum ada terbitan yang kamu cari',
  labelNoPopularAuthor: 'Belum ada penulis populer yang kamu cari',
  labelNoNotification: 'Belum ada notifikasi',
  labelNoMyCollection: 'Belum ada koleksi kamu',
  labelNoFollower: 'Belum ada Follower',
  labelNoFollowing: 'Belum ada Following',
  labelNoChapterStories: 'Belum Ada Bab Cerita.',
  labelTitleNoStory: 'Sedang mencari kisah yang hilang?',
  labelMessageNoStory: 'Terkadang kisah yang lampau tak perlu ditelusuri, cukup dikenang dalam hati.',
  labelDescriptionNoStory: 'Halaman yang Anda cari tidak dapat ditemukan.',
  labelTitleNoCollection: 'Belum ada cerita di perpustakaanmu.',
  labelMessageNoCollection: 'Temukan cerita favoritmu, yuk!',

  // Label notification
  labelSettingNotification: 'Notifikasi',
  labelSettingFollowMe: 'Mengikuti Saya',
  labelSettingFollowMeDesc: 'Dapatkan Informasi mengenai siapa saja yang mengikutimu di GWP',
  labelSettingLikeMyStories: 'Menyukai Cerita',
  labelSettingLikeMyStoriesDesc: 'Dapatkan Informasi mengenai siapa saja yang menyukai ceritamu',
  labelSettingCommentStories: 'Mengomentari Cerita',
  labelSettingCommentStoriesDesc: 'Dapatkan informasi mengenai siapa saja yang mengomentari ceritamu',

  // Label notification conent
  followingYou: 'mengikuti kamu',
  commentStory: 'mengomentari cerita',
  commentChapter: 'mengomentari chapter cerita',
  likeStory: 'menyukai cerita',
  likeChapter: 'menyukai chapter cerita',

  // Label event status
  live: 'BERLANGSUNG',
  coming: 'AKAN DATANG',
  done: 'SELESAI',
  dateRange: ' s.d ',
  eventAll: 'Semua',
  eventLive: 'Berlangsung',
  eventComing: 'Akan Datang',
  eventDone: 'Selesai',

  // Alert message verified
  loginVerifiedMessage1: 'Akun yang kamu masukan sudah terdaftar namun',
  loginVerifiedMessage2: 'belum terverifikasi',
  loginVerifiedMessage3: 'Emailmu:',
  loginVerifiedMessage4: 'Silakan cek kembali email verifikasi dari GWP atau klik untuk mengirim ulang email verifikasi',
  loginVerifiedMessage5: 'disini!',

  // Alert message transform
  failed: 'Gagal',
  success: 'Berhasil',
  usernameExist: 'Username sudah terdaftar.',
  emailExist: 'Email sudah terdaftar',
  usernameEmailFailed: 'Username / Email tidak terdaftar.',
  usernameFailed: 'Username tidak terdaftar.',
  emailFailed: 'Email tidak terdaftar.',
  loginFailed: 'Username / Email atau Password Anda tidak sesuai. Mohon periksa kembali!',
  registerFailed: 'Mohon lakukan pendaftaran beberapa saat lagi.',
  registerSuccess: 'Silahkan cek email Anda untuk proses verifikasi akun.',
  resendVSuccess: 'Email sudah terkirim, silakan periksa emailmu!',
  resendVFailed: 'Maaf ada kesalahan pada sistem, silakan ulangi kembali!',
  forgotPasswordFailed: 'Silahkan coba beberapa saat lagi.',
  forgotPasswordSuccess: 'Silahkan cek email Anda.',
  renewPasswordFailed: 'Kata sandi gagal diperbarui.',
  renewPasswordSuccess: 'Kata sandi berhasil diperbarui.',
  renewPasswordNotSame: 'Kata sandi lama tidak sesuai',
  renewPasswordUserNotFound: 'Pengguna tidak ditemukan',
  renewRePasswordNotSame: 'Konfirmasi kata sandi tidak sesuai',
  userAlreadyRegister: 'Email telah terdaftar sebagai peserta.',
  userRegisterFailed: 'Gagal mendaftar! Mohon coba sesaat lagi.',
  userSuccessRegister: 'Email berhasil didaftarkan, silahkan periksa email Anda.',
  emailSuccessRegister: 'Email berhasil didaftarkan.',
  emailFailedRegister: 'Email gagal didaftarkan. Mohon coba beberapa saat lagi.',
  emailAlreadyRegister: 'Email sudah terdaftar sebagai peserta, silahkan periksa email Anda.',
  emailExistRegister: 'Email atau username sudah terdaftar.',
  subscribeSuccess: 'Subscribe email berhasil!',
  subscribeNotSend: 'Subscribe tidak berhasil! Tetapi email tidak terkirim!',
  subscribeExist: 'Subscribe tidak berhasil! Email yang Anda masukan sudah terdaftar pada email subscribe.',
  subscribeFailed: 'Subscribe tidak berhasil! Mohon ulangi beberapa saat lagi!',
  notificationChangeSuccess: 'Sukses memperbarui notifikasi',
  notificationChangeFailed: 'Gagal memperbarui notifikasi',
  profileChangeSuccess: 'Berhasil memperbarui profile',
  profileChangeFailed: 'Gagal memperbarui profile',

  // Toast message transform
  voteStorySuccess: 'Cerita berhasil di vote',
  voteStoryFailed: 'Cerita gagal di vote, coba sesaat lagi',
  linkCopied: 'Tautan berhasil disalin',

  // Confirm message transform
  confirmMDeleteComment: 'Apa kamu yakin mau menghapus komentar ini?',
  confirmTDeleteComment: 'Hapus Komentar',
  confirmDelete: 'Hapus',
  confirmMPinComment: 'Apa kamu yakin mau menyematkan komentar ini?',
  confirmTPinComment: 'Sematkan Komentar',
  confirmMUnpinComment: 'Apa kamu yakin mau menanggalkan komentar ini?',
  confirmTUnpinComment: 'Tanggalkan Komentar',
  confirmSure: 'Yakin',
  confirmMReportComment: 'Pilih Kategori Laporan: ',
  confirmTReportComment: 'Report Komentar',
  confirmBtnSendReport: 'Kirim Laporan',
  confirmBtnCancel: 'Batal',
  confirmMDelStories: 'Apa kamu yakin mau menghapus cerita ini?',
  confirmTDelStories: 'Hapus cerita',
  confirmMPublishStories: 'Apakah kamu yakin untuk publish cerita ini?',
  confirmTPublishStories: 'Publish Cerita',
  confirmBtnPublishStories: 'Publish',
  confirmMUnpublishStories: 'Apakah kamu yakin untuk unpublish cerita ini?',
  confirmMUnpublishStories_a: 'Draf cerita ini tidak dapat lagi dilihat oleh pengguna lain, sehingga tidak dapat dibaca, diberi vote, atau dikomentari.',
  confirmTUnpublishStories: 'Unpublish Cerita',
  confirmTUnpublishStories_a: 'Batalkan publikasi cerita?',
  confirmBtnUnpublishStories: 'Unpublish',
  confirmMDelChapter: 'Apakah kamu yakin untuk menghapus cerita ini?',
  confirmTDelChapter: 'Hapus Cerita',
  confirmBtnDelChapter: 'Hapus',
  confirmTDeleteLibrary: 'Hapus Koleksi',
  confirmFDeleteLibrary: 'Apakah kamu yakin menghapus',
  confirmSingleDeleteLibrary: 'cerita ini dari',
  confirmMLDeleteLibrary: 'cerita ini dari',
  confirmNLDeleteLibrary: 'Koleksiku?',


  // Label tooltip
  tooltipStoryTitle: 'Masukan ceritamu pada kategori yang tepat untuk memastikan pengalaman membaca yang positif. Cerita berkategori dewasa dikhususkan untuk pembaca berusia 18 tahun ke atas.',
  tooltipSynopsis: 'Menarik minat pembaca untuk mengikuti ceritamu lebih lanjut. Ayo, berikan deskripsi cerita keseluruhan dengan cara yang menarik!',
  tooltipTag: 'Menambahkan tag dapat membantu meningkatkan pemunculan ceritamu dalam pencarian. Tag harus berupa kata atau konsep yang mencerminkan tema dan subgenre ceritamu. Tag dapat dipisahkan menggunakan koma, spasi, dan enter.',
  tooltipGenre: 'Informasi ini akan membantu pembaca untuk mengetahui jenis genre dari ceritamu.',
  tooltipStoryMature: 'Cerita ini untuk dibaca pengguna 18 tahun ke atas.',
  tooltipCreateStoryInfo1: 'Pastikan cerita kamu cocok untuk semua pembaca.',
  tooltipCreateStoryInfo2: 'Untuk info lebih lanjut, silakan baca Panduan GWP',
};

const SERVICE_LIST = {
  getListUser: 'GET_LIST_USER'
};

export const appConfig = {
  appName: 'GWP',
  appFullName: 'Gramedia Writing Project',
  appVersion: '2.0.0',
  year: '2019',
  company: 'PT. Rekata Sembilan Belas',
  logo: 'assets/images/logo/logo.png',
  logoWhite: 'assets/images/logo/logo_white.png',
  logoBlack: 'assets/images/logo/logo_black.png',
  // serviceBaseUrl: SERVICE_BASE_URL,
  serviceList: SERVICE_LIST,
  // 'tokenUserName': 'un',
  // 'tokenMenuUser': 'menus',
  // 'tokenUserRole': 'role',
  // 'tokenUserRegion': 'region',
  // 'tokenUserRegionName': 'regionName',
  // 'tokenUserArea': 'area',
  // 'tokenKey': 'sk',
  // 'tokenService': 'services',
  // 'tokenBranchCode': 'bc',
  // 'tokenBranchName': 'bn',
  // 'tokenUserId': 'uid',
  // 'tokenAppName': 'ap',
  // 'tokenAppVersion': 'vr',
  // 'tokenBranches': 'branches',
  // 'tokenDebiturName': 'namaDebitur',
  // 'tokenUrlCommonService': 'urlCommonService',
  prefLang: 'pl',
  dictionary: {
    ID: LANG_ID,
    un: LANG_ID,
    EN: LANG_EN
  },
  defaultLanguage: 'ID'
};
