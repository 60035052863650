import { Component, OnInit, Input, AfterContentChecked, ViewChild, ElementRef } from '@angular/core';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { SearchingService } from 'src/app/service/searching.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Nl2brPipe } from 'src/app/shared/pipe/nl2br.pipe';
import { SomethingChangeService } from 'src/app/service/something-change.service';

@Component({
  selector: 'app-story-detail-synopsis',
  templateUrl: './story-detail-synopsis.component.html',
  styleUrls: ['./story-detail-synopsis.component.css'],
  providers: [ Nl2brPipe ]
})
export class StoryDetailSynopsisComponent implements OnInit, AfterContentChecked {

  @Input() storyDetail: any;
  @ViewChild('myTopElement', { static: true }) topSynopsis: ElementRef;
  storySynopsis: any;
  storyTags: any[];
  storyGenre: string;
  uid: any;
  storySynopsisCount: Number = 0;
  seeMore: Boolean = false;
  synopasisCollapseMobile: Boolean = false;
  showTag: Boolean = false;
  constructor(
    private navigateUrl: GoToService,
    private searchingService: SearchingService,
    private sanitizer: DomSanitizer,
    private nl2br: Nl2brPipe,
    private somethingChange: SomethingChangeService
  ) {
    this.somethingChange.getMessage().subscribe((response: any) => {
      if (response.event === 'changeSize') {
        this.seeMore = false;
        this.synopasisCollapseMobile = ( this.storySynopsisCount >= 240 ) ? true : false;
        if (response.message.width < 768) {
          // mobile
          this.showTag = ( this.storySynopsisCount < 240 ) ? true : false;
          this.seeMore = ( this.storySynopsisCount < 240 ) ? true : false;
        }
      }
    });
   }

  ngOnInit() {
    this.uid = localStorage.getItem('uid');
    this.storySynopsisCount = this.storyDetail.synopsis_count;
    this.synopasisCollapseMobile = ( this.storySynopsisCount >= 240 ) ? true : false;
    this.seeMore = ( this.storySynopsisCount < 240 ) ? true : false;

    if (window.innerWidth >= 768) {
      this.showTag = true;
    } else {
      this.showTag = this.storySynopsisCount < 240 ? true : false;
    }
  }

  ngAfterContentChecked() {
    this.storySynopsis = (this.storyDetail && this.storyDetail.synopsis) ?
    this.sanitizer.bypassSecurityTrustHtml(this.nl2br.transform(this.storyDetail.synopsis, null)) : '-';
    this.storyTags = this.storyDetail && this.storyDetail.tagList ? this.storyDetail.tagList : null;
    this.storyTags = this.storyTags ? this.storyTags.filter(x => x !== '') : this.storyTags;
    this.storyGenre = this.storyDetail && this.storyDetail.genre ? this.storyDetail.genre : '-';
  }

  tagEvent(tag: any) {
    let param: String = tag;
    param  = param.replace(/[^a-zA-Z ]/g, '');
    param  = param.split(' ').join('-');
    this.navigateUrl.goTo('search', {q: param , t: 'tag'});
    this.searchingService.search(tag, null, 'tag', this.uid).subscribe((response: any) => {
    }, (error: any) => {
    });
  }

  activeExpand() {
    this.topSynopsis.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.seeMore = ! this.seeMore;
    this.synopasisCollapseMobile = !this.synopasisCollapseMobile;
    this.showTag  = true;
  }
}
