import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-gwp-share-dialog',
  templateUrl: './gwp-share-dialog.component.html',
  styleUrls: ['./gwp-share-dialog.component.css']
})
export class GwpShareDialogComponent implements OnInit {

  title: string;
  shareUrl: string;
  shareFbUrl: string;
  shareTwUrl: string;
  shareWaUrl: string;
  shareMWaUrl: string;
  link: string;

  constructor(public dialogRef: MatDialogRef<GwpShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmShareDialogModel) {
      this.title  = data.title;
      this.link   = data.link;
      if (data && data.link) {
        this.shareUrl = data.link.copy;
        this.shareFbUrl = data.link.fb;
        this.shareTwUrl = data.link.tw;
        this.shareWaUrl = data.link.wa;
        this.shareMWaUrl = data.link.mwa;
      }
    }

  ngOnInit() {
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  popupwindow(url, title, w, h) {
    const y = window.outerHeight / 2 + window.screenY - ( h / 2);
    const x = window.outerWidth / 2 + window.screenX - ( w / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }

  copyLink(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.dialogRef.close({'copied' : true});
  }

}

export class ConfirmShareDialogModel {
  constructor(public title: string, public link: any) {
  }
}
