import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  providers: [TranslatePipe]
})
export class AboutComponent implements OnInit {
  loading: boolean;

  aboutData: any[];
  aboutTitle = 'Gramedia Writing Project';
  aboutContent: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    private pageService: PageService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.getAboutData();
    this.seo.addMetaTag('Tentang Kami');
  }

  getAboutData() {
    this.loading = true;
    this.pageService.getAbout().subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.aboutData = data.data;
        this.aboutData.forEach(e => {
          this.aboutTitle = e.aboutus_title;
          this.aboutContent = e.aboutus_content;
        });
      }
    }, (error) => {
      this.loading = false;
    });
  }

}
