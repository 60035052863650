import { AlphaNumbersOnlyDirective } from './../shared/directive/alpha-numbers-only.directive';
import { NumbersOnlyDirective } from './../shared/directive/numbers-only.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecodeHtmlEntitiesModule } from 'decode-html-entities';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from '../app.routing';

// Revamp
import { LayoutComponent, RemoveSlashPipe, RemoveAmpCharPipe, DateTimeFormatPipe, RemoveQuestCharFirstPipe, RemoveNewLinePipe } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component'; // unused again :: samuel
import { FooterComponent } from './footer/footer.component';
// End Revamp

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { NgxPaginationModule } from 'ngx-pagination';

// For Share Component
import { SelectsizeComponent } from '../component/selectsize/selectsize.component';

// sprint 8
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// sprint 11
import { BlockCopyPasteDirective } from 'src/app/shared/directive/block-copy-paste.directive';

import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  NativeDateAdapter,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatSlideToggleModule,
  MatSidenavModule,
  MatCardModule,
  MatButtonModule,
  MatTabsModule,
  MatExpansionModule,
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialog,
  MatCheckboxModule
} from '@angular/material';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppDateAdapter, APP_DATE_FORMATS } from '../shared/date.adapter';
import { MatSnackBarComponent } from '../component/mat-snack-bar/mat-snack-bar.component';

// Revamp
import { SharedModule } from '../shared/shared/shared.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { LandingComponent } from '../components/landing/landing.component';
import { HomeComponent } from '../components/home/home.component';
import { LoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';
import { RegisterSuccessComponent } from '../components/register/register-success.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../components/forgot-password/change-password.component';

import { EventComponent } from '../components/event/event.component';
import { EventDetailComponent } from '../components/event-detail/event-detail.component';
import { AboutComponent } from '../components/about/about.component';
import { GuidanceComponent } from '../components/guidance/guidance.component';
import { FaqComponent } from '../components/faq/faq.component';
import { DisclaimerComponent } from '../components/disclaimer/disclaimer.component';

import { ProfileComponent } from '../components/profile/profile.component';
import { ProfileListStoryComponent } from './../components/profile/profile-list-story/profile-list-story.component';
import { ProfileListFollowComponent } from './../components/profile/profile-list-follow/profile-list-follow.component';
import { EditProfileComponent } from '../components/profile/edit-profile/edit-profile.component';

import { CategoryComponent } from '../components/category/category.component';
import { PopularAuthorComponent } from '../components/popular-author/popular-author.component';
import { ContinueReadingComponent } from '../components/continue-reading/continue-reading.component';
import { WorkStoriesComponent } from '../components/work-stories/work-stories.component';

import { CreateStoryComponent } from '../components/create-story/create-story.component';
import { CreateStoryTableContentComponent } from './../components/create-story/create-story-table-content/create-story-table-content.component';
import { CreateStoryFormComponent } from './../components/create-story/create-story-form/create-story-form.component';
import { CreateStoryContentComponent } from './../components/create-story/create-story-content/create-story-content.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SearchingComponent } from '../components/searching/searching.component';
import { SearchPageDetailComponent } from '../components/search-page-detail/search-page-detail.component';

import { ChangePasswordUserComponent } from '../components/change-password-user/change-password-user.component';
import { StoryDetailComponent } from '../components/story-detail/story-detail.component';
import { StoryDetailSynopsisComponent } from './../components/story-detail/story-detail-synopsis/story-detail-synopsis.component';
import { StoryDetailTableContentComponent } from './../components/story-detail/story-detail-table-content/story-detail-table-content.component';
import { StoryDetailCommentComponent } from './../components/story-detail/story-detail-comment/story-detail-comment.component';
import { NotificationUserComponent } from '../components/notification-user/notification-user.component';
import { SettingNotificationComponent } from '../components/setting-notification/setting-notification.component';
import { PublishedStoryComponent } from '../components/published-story/published-story.component';
import { ChapterDetailComponent } from './../components/chapter-detail/chapter-detail.component';
import { ChapterDetailCommentComponent } from './../components/chapter-detail/chapter-detail-comment/chapter-detail-comment.component';
import { MobileMainNavComponent } from './mobile-main-nav/mobile-main-nav.component';
import { RecomendationStoryComponent } from './../components/recomendation-story/recomendation-story.component';
import { LombaGwpComponent } from './../components/lomba-gwp/lomba-gwp.component';
import { RegisterActivationComponent } from '../components/register/register-activation.component';

// sprint 6
import { MyLibraryComponent } from '../components/my-library/my-library.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { ListFollowComponent } from '../components/profile/list-follow/list-follow.component';
import { AutosizeModule } from 'ngx-autosize';
import { TermConditionComponent } from '../components/term-condition/term-condition.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AlphabetSpaceOnlyDirective } from '../shared/directive/alphabet-space-only.directive';

import { QuillModule } from 'ngx-quill';

// AppComponent
@NgModule({
  imports: [
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    TextMaskModule,
    ReactiveFormsModule,
    DecodeHtmlEntitiesModule,
    routing,
    NgSelectModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    NgxIntlTelInputModule,
    NgxMatIntlTelInputModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    NgxPasswordToggleModule,
    SharedModule.forRoot(),
    MatSlideToggleModule,
    MatSidenavModule,
    CarouselModule,
    MatTabsModule,
    RichTextEditorModule,
    DialogModule,
    DragDropModule,
    MatExpansionModule,
    MatDialogModule,
    MatCheckboxModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    AutosizeModule,
    ImageCropperModule,
    QuillModule.forRoot()
  ],
  declarations: [
    MatSnackBarComponent,
    SelectsizeComponent,
    LayoutComponent,
    HeaderComponent,
    MobileMainNavComponent,
    FooterComponent,
    SidebarComponent,
    LandingComponent,
    HomeComponent,
    AboutComponent,
    EventComponent,
    EventDetailComponent,
    FaqComponent,
    DisclaimerComponent,
    GuidanceComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    RegisterComponent,
    CategoryComponent,
    PopularAuthorComponent,
    RegisterSuccessComponent,
    RegisterActivationComponent,
    WorkStoriesComponent,
    CreateStoryComponent,
    CreateStoryFormComponent,
    CreateStoryTableContentComponent,
    CreateStoryContentComponent,
    RemoveSlashPipe,
    RemoveAmpCharPipe,
    DateTimeFormatPipe,
    RemoveQuestCharFirstPipe,
    RemoveNewLinePipe,
    LombaGwpComponent,
    ProfileComponent,
    ProfileListStoryComponent,
    ProfileListFollowComponent,
    ListFollowComponent,
    EditProfileComponent,
    ContinueReadingComponent,
    SearchingComponent,
    SearchPageDetailComponent,
    ChangePasswordUserComponent,
    StoryDetailComponent,
    StoryDetailSynopsisComponent,
    StoryDetailTableContentComponent,
    StoryDetailCommentComponent,
    NotificationUserComponent,
    SettingNotificationComponent,
    PublishedStoryComponent,
    ChapterDetailComponent,
    ChapterDetailCommentComponent,
    MyLibraryComponent,
    RecomendationStoryComponent,
    NumbersOnlyDirective,
    AlphaNumbersOnlyDirective,
    AlphabetSpaceOnlyDirective,
    BlockCopyPasteDirective,
    TermConditionComponent
  ],
  exports: [
    MatSnackBarComponent,
    SelectsizeComponent,
    LayoutComponent,
    AboutComponent,
    EventComponent,
    EventDetailComponent,
    FaqComponent,
    DisclaimerComponent,
    GuidanceComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    RegisterComponent,
    CategoryComponent,
    PopularAuthorComponent,
    RegisterSuccessComponent,
    RegisterActivationComponent,
    WorkStoriesComponent,
    CreateStoryComponent,
    LombaGwpComponent,
    LandingComponent,
    HomeComponent,
    ProfileComponent,
    ProfileListStoryComponent,
    ProfileListFollowComponent,
    EditProfileComponent,
    ContinueReadingComponent,
    SearchingComponent,
    SearchPageDetailComponent,
    ChangePasswordUserComponent,
    StoryDetailComponent,
    StoryDetailSynopsisComponent,
    StoryDetailTableContentComponent,
    StoryDetailComponent,
    NotificationUserComponent,
    SettingNotificationComponent,
    PublishedStoryComponent,
    ChapterDetailComponent,
    ChapterDetailCommentComponent,
    RecomendationStoryComponent,
    MatDialogModule,
    MatButtonModule,
    MyLibraryComponent,
    TermConditionComponent
  ]
})
export class TemplateModule { }
