import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user.model';
import { ApiService } from './api.service';
import { Stories } from '../model/stories.model';

@Injectable({
  providedIn: 'root'
})

export class SearchingService {

  response: any;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  historySearch(uid: any) {
    const params = uid ? ('?uid=' + uid) : '';
    return this.http.get(this.apiService.historySearchURL + params );
  }

  search(search: string, refId: any, type: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('ref', refId);
    formData.append('type', type);
    formData.append('search', search);
    this.response = this.http.post(this.apiService.searchURL, formData);
    return this.response;
  }

  suggestSearch(search: string, uid: any) {
      const params = uid ? ('?uid=' + uid + '&search=' + search) : '?search=' + search;
      return this.http.get(this.apiService.suggestSearchURL + params );
  }

  deleteSearch(search: any, uid: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    formData.append('search', search);
    return this.http.post(this.apiService.deleteSearchURL, formData);
  }

  clearSearch(uid: any) {
    const formData: FormData = new FormData();
    formData.append('uid', uid);
    return this.http.post(this.apiService.clearSearchURL, formData);
  }

  getAuthor(author, page, limit, search = '') {
    const params = '?author=' + author + '&limit=' + limit + '&page=' + page + '&search=' + search;
    return this.http.get(this.apiService.searchAuthorURL + params);
  }

  getStory(page, limit, order = '', category = '', search = '', type: any) {
    let params = '?limit=' + limit + '&page=' + page + '&search=' + search + '&type=' + type;
    if (order !== '') {
      params += '&order=' + order;
    }
    if (category !== '') {
      params += '&cat=' + category;
    }
    return this.http.get<Stories>(this.apiService.searchStoryURL + params);
  }

  getTag(page, limit, search = '') {
    const params = '?limit=' + limit + '&page=' + page + '&search=' + search;
    return this.http.get(this.apiService.searchTagURL + params);
  }

}
