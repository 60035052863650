import { Injectable } from '@angular/core';

/**
 * Created by samuel silalahi on 24/01/2020.
 */

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  setItem(key: string, val: string) {
    sessionStorage.setItem(key, val);
  }

  getItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  clearItem() {
    sessionStorage.clear();
  }
}
