export class User {
  uid = '';
  uusername = '';
  uemail = '';
  upassword = '';
  ufullname = '';
  usummary = '';
  udescription = '';
  dateBirth = '';
  usex = '';
  uage = '';
  ubirthday = '';
  uaddress1 = '';
  uaddress2 = '';
  uimage = '';
  uimage_thumb = '';
  ucover_image: any = '';
  upropinsi: any = '';
  upropinsi_id: any = '';
  ucity: any = '';
  ucity_id: any = '';
  ufacebook_id = '';
  utwitter_id = '';
  uinstagram_id = '';
  ugoogle_id = '';
  ufavorite_category = '';
  ubirthday_place = '';
  uphone = '';
  ujobs = '';
  ujoin_date = '';
  ustone_red = '';
  ustone_blue = '';
  ustone_yellow = '';
  ulast_login = '';
  ulast_logout = '';
  uis_online = '';
  is_notification = '';
  ualways_offline = '';
  umodified_date = '';
  ustatus = '';
  story_is_published = '';
  total_stories = 0;
  counter?: any;
}
