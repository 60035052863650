import { SettingService } from 'src/app/service/setting.service';
import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { SeoService } from 'src/app/shared/service/seo.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { AuthGuard } from 'src/app/auth.guard';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css'],
  providers: [TranslatePipe]
})
export class TermConditionComponent implements OnInit, AfterViewInit {
  @ViewChild('stickyMenu', {static: true}) menuElement: ElementRef;
  auth: boolean;
  loading: boolean;

  termSideMenuHeader: any[];
  termData: any[];
  termSideMenuContent: any[];
  termLastExpand: any;

  fixed: boolean;
  menuPosition: any;
  opened: boolean;
  yOffset = -80;
  screenWidth: number;

  constructor(
    public authGuard: AuthGuard,
    private settingService: SettingService,
    private seo: SeoService  ) {
      this.getScreenSize();
      if (this.screenWidth < 768) {
        this.yOffset = -40;
      } else {
        this.yOffset = -80;
      }
  }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.getTermConditionContent();
    this.seo.addMetaTag('Term & Condition - Privacy Policy');
  }

  ngAfterViewInit(): void {
    this.menuPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 180) {
        this.fixed = true;
    } else {
        this.fixed = false;
    }
  }

  getTermConditionContent() {
    this.loading = true;
    this.settingService.getSettingTermConditionContent().subscribe((data: any) => {
      this.loading = false;
      if (data && data.data) {
        this.termData = data.data;
        this.termData.forEach(() => {
          this.termData['expand'] = false;
        });
      }
    }, () => {
      this.loading = false;
    });
  }

  expandTerm(currentEvent) {
    const currIndex = this.termData.findIndex(e => e.tc_id === currentEvent.tc_id);
    if (this.termLastExpand) {
      const lastIndex = this.termData.findIndex(e => e.tc_id === this.termLastExpand.tc_id);
      this.termData[lastIndex]['expand'] = false;
    }
    this.termData[currIndex]['expand'] = true;
    this.termLastExpand = currentEvent;
    setTimeout(() => {
      this.scroll(currentEvent);
    }, 200);
  }

  scroll(evt) {
    const id = 'target' + evt.tc_id;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + this.yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  doClickSideNav() {
    this.opened = !this.opened;
  }

}
