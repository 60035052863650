import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'app-search-expand',
  templateUrl: './search-expand.component.html',
  styleUrls: ['./search-expand.component.css']
})
export class SearchExpandComponent implements OnInit {

  @Input() auth = false;
  @Input() keyword = '';

  @Output() selectItem: EventEmitter<any> = new EventEmitter();
  @Output() removeItem: EventEmitter<any> = new EventEmitter();
  @Output() clearItem: EventEmitter<any> = new EventEmitter();
  @Output() closeSearch: EventEmitter<any> = new EventEmitter();

  _last: any;
  _popular: any;
  _author: any;
  _story: any;
  _tag:   any;

  constructor() { }

  ngOnInit() { }

  closeSearchOverlay() {
    this.closeSearch.emit();
  }

  @Input()
  set popular(param: any) {
    if (param !== '' && param && param.length > 0) {
      this._popular = param;
    } else {
      this._popular = null;
    }
  }

  get popular(): any {
    return this._popular;
  }

  @Input()
  set story(param: any) {
    if (param !== '' && param && param.length > 0) {
      this._story = param;
    } else {
      this._story = null;
    }
  }

  get story(): any {
    return this._story;
  }

  @Input()
  set last(param: any) {
    if (param !== '' && param && param.length > 0) {
      this._last = param;
    } else {
      this._last = null;
    }
  }

  get last(): any {
    return this._last;
  }

  @Input()
  set author(param: any) {
    if (param !== '' && param && param.length > 0) {
      this._author = param;
    } else {
      this._author = null;
    }
  }

  get author(): any {
    return this._author;
  }

  @Input()
  set tag(param: any) {
    if (param !== '' && param && param.length > 0) {
      this._tag = param;
    } else {
      this._tag = null;
    }
  }

  get tag(): any {
    return this._tag;
  }

  doSelectItem(id: any, text: any, slug: any, type: any) {
    this.selectItem.emit({
        id: id,
        text: text,
        slug: slug,
        type: type
      });
  }

  doRemoveItem(event) {
    this.removeItem.emit({ action: event });
  }

  doRemoveAll() {
    this.clearItem.emit({ action: 'all' });
  }

}
