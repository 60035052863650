import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Stories } from '../model/stories.model';
import { Chapters } from '../model/chapters.model';
import { Comment } from '../model/comment.model';
import { UnlockChapter } from '../model/unlock-chapter.model';
import { ApiService } from './api.service';

@Injectable()
export class StoriesService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  // Sprint 2
  getSectionStories(limit, section, category = '') {
    let params = '?limit=' + limit + '&sec=' + section;
    let uid = localStorage.getItem('uid');

    if (category !== '') {
      params += '&cat=' + category;
    }
    if (uid) {
      params += '&uid=' + uid;
    }
    return this.http.get<Stories>(this.apiService.sectionStoriesURL + params);
  }

  // Sprint 2
  getAllStories(page, limit, order = '', category = '') {
    let params = '?limit=' + limit + '&page=' + page;
    let uid = localStorage.getItem('uid');

    if (order !== '') {
      params += '&order=' + order;
    }
    if (category !== '') {
      params += '&cat=' + category;
    }
    if (uid) {
      params += '&uid=' + uid;
    }
    return this.http.get<Stories>(this.apiService.allStoriesURL + params);
  }

  // ================================================================

  // getAllStories() {
  //   return this.http.get<Stories>(this.apiService.allStoriesURL);
  // }

  // getCountStories() {
  //   return this.http.get<Stories>(this.apiService.countStoriesURL);
  // }

  // getStoriesByAuthorId(id: string) {
  //   return this.http.get<Stories>(this.apiService.allStoriesURL + '/' + id);
  // }

  // Sprint 3
  getAuthorStories(page, limit, author, status = true, uid=null) {
    let params = '?limit=' + limit + '&page=' + page + '&author=' + author + '&status=' + (status ? 1 : 0);
    if (uid) {
      params += '&uid=' + uid;
    }

    return this.http.get<Stories>(this.apiService.authorStoriesUrl + params );
  }

  // Sprint 3
  deleteStories(story, author) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('author', author);
    return this.http.post<Stories>(this.apiService.deleteStoriesURL, formData);
  }

  // Sprint 3
  doSubmitCreateStory(data, story, author, edit) {
    const formData: FormData = new FormData();
    if (data.cover) {
      formData.append('image', data.cover, data.cover.name);
    }
    formData.append('title', data.title);
    formData.append('author', author);
    formData.append('description', data.synopsis);
    formData.append('tags', data.tagList);
    formData.append('genre', data.genre);
    formData.append('mature', (data.matureType ? '1' : '0'));
    formData.append('complete', (data.complete ? '1' : '0'));
    if (edit) {
      formData.append('action', 'update');
    } else {
      formData.append('action', 'create');
    }
    if (story && story !== '') {
      formData.append('sid', story);
      return this.http.post<Stories>(this.apiService.updateStoriesURL, formData);
    } else {
      return this.http.post<Stories>(this.apiService.createStoriesURL, formData);
    }
  }

  uploadChapterImage(data, author) {
    const params = '/' + author;
    const formData: FormData = new FormData();
    formData.append('UploadFiles', data, data.name)

    return this.http.post<any>(this.apiService.uploadImageChapterURL + params, formData);
  }

  // Sprint 3
  getStoriesById(sid, slug, author) {
    const params = '?sid=' + sid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Stories>(this.apiService.getstoriesByIdURL + params );
  }

  // Sprint 3
  doSubmitCreateChapter(data, story, chapter, author, publish) {
    const formData: FormData = new FormData();
    if (story && story !== '') {
      formData.append('sid', story);
    }
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('author', author);
    if (publish) {
      formData.append('publish', 'publish');
    } else {
      formData.append('publish', 'draft');
    }

    if (chapter && chapter !== '') {
      formData.append('cid', chapter);
      return this.http.post<Chapters>(this.apiService.updateChapterURL, formData);
    } else {
      return this.http.post<Chapters>(this.apiService.createChapterURL, formData);
    }
  }

  publishStory(story, author, publish) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('author', author);
    formData.append('publish', publish);

    return this.http.post<Stories>(this.apiService.publishStoryURL, formData);
  }

  // Sprint 3
  publishChapter(story, chapter, author, publish) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('cid', chapter);
    formData.append('author', author);
    if (publish) {
      formData.append('publish', 'publish');
    } else {
      formData.append('publish', 'unpublish');
    }
    return this.http.post<Stories>(this.apiService.publishChapterURL, formData);
  }

  // Sprint 3
  deleteChapter(story, chapter, author) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('cid', chapter);
    formData.append('author', author);
    return this.http.post<Stories>(this.apiService.deleteChapterURL, formData);
  }

  // Sprint 3
  reorderChapter(story, chapter, author) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('chapter', JSON.stringify(chapter));
    formData.append('author', author);
    return this.http.post<Stories>(this.apiService.reorderChapterURL, formData);
  }

  // Sprint 3
  getChapterById(sid, cid, slug, author) {
    const params = '?sid=' + sid + '&cid=' + cid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Stories>(this.apiService.getChapterByIdURL + params );
  }

  // Sprint 3
  getChaptersAuthorByStoryId(sid, slug, author) {
    const params = '?sid=' + sid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Chapters>(this.apiService.getChaptersAuthorByStoryIdURL + params);
  }

  // Sprint 4
  getStoriesDetailById(sid, slug, author) {
    const params = '?sid=' + sid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Chapters>(this.apiService.getStoriesDetailByIdURL + params);
  }

  // Sprint 4
  getChaptersByStoryId(sid, slug, author) {
    const params = '?sid=' + sid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Chapters>(this.apiService.getChaptersByStoryIdURL + params);
  }

  // Sprint 4
  getChaptersDetailById(sid, cid, slug, author) {
    const params = '?sid=' + sid +  '&cid=' + cid + '&slug=' + slug + '&author=' + author;
    return this.http.get<Chapters>(this.apiService.getChaptersDetailByIdURL + params);
  }

  // Sprint 3
  doVoteChapter(story, chapter, uid, author, status) {
    const formData: FormData = new FormData();
    formData.append('sid', story);
    formData.append('cid', chapter);
    formData.append('uid', uid);
    formData.append('author', author);
    if (status) {
      formData.append('status', '1');
    } else {
      formData.append('status', '0');
    }
    return this.http.post<Chapters>(this.apiService.chapterVoteByIdURL, formData);
  }

  // createStory(
  //   selectedFile: any,
  //   title: string,
  //   authorId: string,
  //   descriptions: string,
  //   tags: string,
  //   cat: string,
  //   mature: string
  // ) {
  //   const formData: FormData = new FormData();
  //   formData.append('image', selectedFile, selectedFile.name);
  //   formData.append('title', title);
  //   formData.append('authorId', authorId);
  //   formData.append('descriptions', descriptions);
  //   formData.append('tags', tags);
  //   formData.append('cat', cat);
  //   formData.append('mature', mature);

  //   return this.http.post<Stories>(this.apiService.createStoryURL, formData);
  // }

  // createStory2(
  //   title: string,
  //   authorId: string,
  //   descriptions: string,
  //   tags: string,
  //   cat: string,
  //   mature: string
  // ) {
  //   const formData: FormData = new FormData();
  //   formData.append('title', title);
  //   formData.append('authorId', authorId);
  //   formData.append('descriptions', descriptions);
  //   formData.append('tags', tags);
  //   formData.append('cat', cat);
  //   formData.append('mature', mature);

  //   return this.http.post<Stories>(this.apiService.createStoryURL, formData);
  // }

  // updateStory(
  //   storyId: string,
  //   title: string,
  //   authorId: string,
  //   descriptions: string,
  //   tags: string,
  //   cat: string,
  //   mature: string,
  //   status: string,
  //   complete: string
  // ) {
  //   const formData: FormData = new FormData();
  //   formData.append('storyId', storyId);
  //   formData.append('title', title);
  //   formData.append('authorId', authorId);
  //   formData.append('descriptions', descriptions);
  //   formData.append('tags', tags);
  //   formData.append('cat', cat);
  //   formData.append('mature', mature);
  //   formData.append('status', status);
  //   formData.append('complete', complete);

  //   return this.http.post<Stories>(this.apiService.updateStoryURL, formData);
  // }

  // updateStory2(
  //   storyId: string,
  //   selectedFile: any,
  //   title: string,
  //   authorId: string,
  //   descriptions: string,
  //   tags: string,
  //   cat: string,
  //   mature: string,
  //   status: string,
  //   complete: string
  // ) {
  //   const formData: FormData = new FormData();
  //   formData.append('storyId', storyId);
  //   formData.append('image', selectedFile, selectedFile.name);
  //   formData.append('title', title);
  //   formData.append('authorId', authorId);
  //   formData.append('descriptions', descriptions);
  //   formData.append('tags', tags);
  //   formData.append('cat', cat);
  //   formData.append('mature', mature);
  //   formData.append('status', status);
  //   formData.append('complete', complete);

  //   return this.http.post<Stories>(this.apiService.updateStoryURL, formData);
  // }

  // getChaptersByStoryId(id: number) {
  //   return this.http.get<Chapters>(this.apiService.chaptersByStoryIdURL + id);
  // }

  // getChaptersByStoryId_new(id: any, authorId: string) { // Delete this : Samuel
  //   const formData: FormData = new FormData();
  //   formData.append('id', id);
  //   formData.append('authorId', authorId);

  //   return this.http.post<Stories>(
  //     this.apiService.chaptersByStoryIdNewURL,
  //     formData
  //   );
  // }

  // getChaptersByStoryIdDetail(id: any, authorId: string) {
  //   const formData: FormData = new FormData();
  //   formData.append('id', id);
  //   formData.append('authorId', authorId);

  //   return this.http.post<Stories>(
  //     this.apiService.chaptersByStoryIdDetailURL,
  //     formData
  //   );
  // }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getChapterCommentById(storyId: number, chapterId: string) {
    return this.http.get<Comment>(
      this.apiService.commentChapterURL + storyId + '/' + chapterId
    );
  }

  // Sprint 2
  getContinueReading(page, limit, author, type = '', order = '' ) {
    let params = '?limit=' + limit + '&page=' + page + '&author=' + author;
    if (type !== '') {
      params += '&type=' + type;
    }
    if (order !== '') {
      params += '&order=' + order;
    }
    return this.http.get<Stories>(this.apiService.continueReadingStoriesURL + params );
  }

  // Sprint 4
  lastContinueReading(page, limit, author, type = '') {
    let params = '?limit=' + limit + '&page=' + page + '&author=' + author;
    if (type !== '') {
      params += '&type=' + type;
    }
    return this.http.get<Stories>(this.apiService.continueReadingStoriesURL + params );
  }

  getPopularStoriesAll(offset: number) {
    return this.http.get<Stories>(
      this.apiService.popularStoriesAllURL + '?limit=10&page=' + offset
    );
  }

  lockChapter(chapterId: string, stoneQty: string, action: string) {
    const formData: FormData = new FormData();

    formData.append('chapterId', chapterId);
    formData.append('stoneQty', stoneQty);
    formData.append('action', action);

    return this.http.post<Chapters>(this.apiService.lockChapterURL, formData);
  }

  unlockChapter(
    chapterId: string,
    storyId: string,
    authorId: string,
    stoneLock: string,
    uid: string
  ) {
    const formData: FormData = new FormData();

    formData.append('chapterId', chapterId);
    formData.append('storyId', storyId);
    formData.append('authorId', authorId);
    formData.append('stoneLock', stoneLock);
    formData.append('uid', uid);

    return this.http.post<Chapters>(this.apiService.unlockChapterURL, formData);
  }

  getUnlockChapterByParams(
    storyId: string,
    chapterId: string,
    authorId: string,
    uid: string
  ) {
    const formData: FormData = new FormData();

    formData.append('storyId', storyId);
    formData.append('chapterId', chapterId);
    formData.append('authorId', authorId);
    formData.append('uid', uid);

    return this.http.post<UnlockChapter>(
      this.apiService.getUnlockChapterURL,
      formData
    );
  }

  getStoriesByCategory(catId: string) {
    return this.http.get<Stories>(this.apiService.storiesByCategoryURL + catId);
  }

  getStoriesByCategoryPagination(catId: string, offset: number) {
    return this.http.get<Stories>(
      this.apiService.paginationStoriesURL +
        '/' +
        catId +
        '?limit=10&page=' +
        offset
    );
  }

  getPopularStoriesByCategory(catId: string) {
    return this.http.get<Stories>(
      this.apiService.popularStoriesByCategoryURL + catId
    );
  }

  getPopularStoriesByCategoryPagination(catId: string, offset: number) {
    return this.http.get<Stories>(
      this.apiService.popularStoriesByCategoryPaginationURL +
        catId +
        '/' +
        '?limit=10&page=' +
        offset
    );
  }

  getAllCategoryStories(offset: number) {
    return this.http.get<Stories>(
      this.apiService.paginationStoriesURL + '?limit=10&page=' + offset
    );
  }

  // sprint 5
  getSectionRecomendedStories(category: any, uid: any) {
    const params = '?cat=' + category + '&uid=' + uid;
    return this.http.get<Stories>(this.apiService.sectionRecomendedStoriesURL + params );
  }
  // sprint 5
  getRecomendedStories(page, limit, uid) {
    const params = '?limit=' + limit + '&page=' + page + '&uid=' + uid;
    return this.http.get<Stories>(this.apiService.recomendedStoriesURL + params);
  }
}
