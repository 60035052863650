import { SeoService } from 'src/app/shared/service/seo.service';
// import { forEach } from '@angular/router/src/utils/collection';
import { map } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { ActivatedRoute } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { environment } from 'src/environments/environment.prod';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';
import { ConfirmSharePublishModel, GwpSharePublishComponent } from 'src/app/shared/component/gwp-share-publish/gwp-share-publish.component';
import { MatDialog } from '@angular/material';
import { SafeHtmlPipe } from 'src/app/shared/pipe/safe-html.pipe';
import { ConfirmShareDialogModel, GwpShareDialogComponent } from 'src/app/shared/component/gwp-share-dialog/gwp-share-dialog.component';

declare let jQuery: any;

@Component({
  selector: 'app-work-stories',
  templateUrl: './work-stories.component.html',
  styleUrls: ['./work-stories.component.css'],
  providers: [TranslatePipe, SafeHtmlPipe]
})
export class WorkStoriesComponent implements OnInit {

  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;

  isParam: boolean;
  activePage = 1;
  limit = 10;
  totalData = 0;
  noData: boolean;
  storyList: any[] = [];
  defaultImage: string;

  copied = false;
  shareUrl = '';
  shareWhatsappUrl: any = '';
  shareMWhatsappUrl: any = '';
  fbUrl = 'https://www.facebook.com/sharer/sharer.php';
  shareFacebookUrl: any = '';
  twUrl = 'https://twitter.com/intent/tweet';
  shareTwitterUrl: any = '';
  alerts: any[] = [];
  re = /\-/gi;

  // sprint 10
  imageNoMyStories: String;

  defaultGenreImageMap: Map<string, string>;
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private dialog: MatDialog,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private storiesService: StoriesService,
    private safeHtml: SafeHtmlPipe,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.seo.addMetaTag('Cerita Saya');
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage = environment.imageStoryDefaultV2;
    this.imageNoMyStories = environment.imageNoMyStories;
    this.route.params.subscribe( params => {
      if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
      }

      if (this.isParam) {
        this.getWorkStories(this.uid);
      }
    });

    if (!this.isParam) {
      this.getWorkStories(this.uid);
    }

    this.defaultGenreImageMap = new Map<string, string>();
    // this.defaultGenreImageMap.set("26", environment.imageThrillerDefault);
    // this.defaultGenreImageMap.set("100", environment.imageFictionDefault);
    this.defaultGenreImageMap.set("26", environment.imageStoryDefaultV2);
    this.defaultGenreImageMap.set("100", environment.imageStoryDefaultV2);
  }

  getWorkStories(uid) {
    this.loading = true;
    this.storiesService.getAuthorStories(this.activePage, this.limit, uid, true, uid).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.storyList = [];
        let orderChapter = 1;
        data.data.forEach(e => {
          var defImage = this.defaultGenreImageMap.get(e.category_id);
          if (defImage == undefined) {
            defImage = this.defaultImage;
          }
          this.storyList.push({
            id: e.story_id,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : defImage),
            url: environment.homeUrl + '/story/' + e.story_id + '/' + e.story_slug,
            title: e.story_title ? e.story_title : '-',
            slug: e.story_slug ? e.story_slug : '-',
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            authorUid: e.uid,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            synopsis: e.story_description ? e.story_description : '-',
            lastUpdate: e.story_modified_date ? e.story_modified_date.replace(this.re, '/') : e.story_modified_date,
            complete: ( e.story_is_complete === '0' ) ? false : true,
            published: ( e.story_is_published === '0' ) ? false : true,
            chapters: (e.story_chapters.length > 0) ? e.story_chapters : null
          });
          orderChapter++;
        });
        this.totalData = data.totalData;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  iterateChapter(id: any) {
    return (id < 10 ) ? '0' + id : id;
  }

  continueWriting(event) {
    this.navigateUrl.goTo('ustory/' + event.id + '/' + event.slug, {q: 'content'});
  }

  editWorkStories(event) {
    this.navigateUrl.goTo('ustory/' + event.id + '/' + event.slug, {q: 'detail'});
  }

  deleteWorkStories(event) {
    if (!this.auth) {
      return;
    } else {
      const message = this.translate.transform('confirmMDelStories');
      const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTDelStories'), message, '', this.translate.transform('confirmDelete'));
      const dialogRef = this.dialog.open(GwpDialogComponent, {
        maxWidth: '416px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.loading = true;
          this.storiesService.deleteStories(event.id, this.uid).subscribe( (data: any) => {
            this.loading = false;
            if (data.status) {
              this.getWorkStories(this.uid);
            }
          }, (error) => {
            this.loading = false;
          });
        }
      });
    }
  }

  publishWorkStories(event, publish: string) {
    if(!this.auth) {
      return;
    } else {
      let message;
      let dialogData;

      if (publish === 'publish') {
        message = this.translate.transform('confirmMPublishStories');
        dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTPublishStories'), message, '', this.translate.transform('confirmBtnPublishStories'));
      } else {
        message = this.translate.transform('confirmMUnpublishStories_a');
        dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTUnpublishStories_a'), message, '', this.translate.transform('confirmBtnUnpublishStories'));
      }

      const dialogRef = this.dialog.open(GwpDialogComponent, {
        maxWidth: '416px',
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.loading = true;
          this.storiesService
            .publishStory(event.id, this.uid, publish)
            .subscribe( (data: any) => {
              this.loading = false;
              if (data.status) {
                this.getWorkStories(this.uid);
                if (publish === 'publish') {
                  this.doClickShare(event, 'share_publish');
                }
              }
          }, (error) => {
            this.loading = false;
          });
        }
      });
    }
  }

  onChangePage(page) {
    this.activePage = page;
    this.navigateUrl.goTo('work-stories/p/' + this.activePage);
  }

  doClickShare(event, type: string = 'share_button') {
    console.log(event);
    this.copied = false;
    this.shareUrl = event.url ? event.url : '';
    this.shareWhatsappUrl = this.safeHtml.transform2('https://web.whatsapp.com/send?text=' + this.shareUrl);
    this.shareMWhatsappUrl = this.safeHtml.transform2('whatsapp://send?text=' + this.shareUrl);
    this.shareFacebookUrl = this.fbUrl + '?u=' + encodeURIComponent(this.shareUrl) + '&amp;src=sdkpreparse';
    this.shareTwitterUrl = this.twUrl + '?text=' + encodeURIComponent(event.title) + '&url=' + encodeURIComponent(this.shareUrl);

    let mediaURLs = {
      copy: this.shareUrl,
      fb: this.shareFacebookUrl,
      tw: this.shareTwitterUrl,
      mwa: this.shareMWhatsappUrl,
      wa: this.shareWhatsappUrl,
    };

    let dialogData: any;
    let dialogRef: any;
    let messageShare: any;

    if (type === 'share_button') {
      dialogData = new ConfirmShareDialogModel(this.translate.transform('labelStoriesShare'), mediaURLs);
      dialogRef = this.dialog.open(GwpShareDialogComponent, {
        maxWidth: '310px',
        data: dialogData
      });
    } else {
      messageShare = this.translate.transform('labelChapterBab') + ' ' + event.title + ' ' + this.translate.transform('messageChapterSuccessPublish');
      dialogData = new ConfirmSharePublishModel(this.translate.transform('labelChapterSuccessPublish'), mediaURLs,
        messageShare,
        this.translate.transform('subMessageChapterSuccessPublish'),
        false
        // ( !this.dataStoryOld ) ? this.dataStoryForm.tagList : this.dataStoryOld.tagList
      );
      dialogRef = this.dialog.open(GwpSharePublishComponent, {
        maxWidth: '272px',
        data: dialogData
      });
    }

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.copied) {
        this.addAlert(this.translate.transform('linkCopied'));
      }
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  addAlert(message): void {
    this.alerts.push({
      type: '',
      msg: `` + message,
      timeout: 5000
    });
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

}
