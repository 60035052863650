import { SeoService } from 'src/app/shared/service/seo.service';
import { GoToService } from '../../shared/service/go-to.service';
import { UserService } from 'src/app/service/user.service';
import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertMassageService } from 'src/app/shared/service/alert-massage.service';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';

@Component({
  selector: 'app-change-password-user',
  templateUrl: './change-password-user.component.html',
  styleUrls: ['./change-password-user.component.css'],
  providers: [TranslatePipe]
})
export class ChangePasswordUserComponent implements OnInit {

  @ViewChild('errMsg', { static: true }) errMsg: ElementRef;

  changePasswordForm:  FormGroup;
  validForm = false;
  loading = false;
  errorMessage = '';

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private formBuilder: FormBuilder,
    private translate: TranslatePipe,
    private alertMassage: AlertMassageService,
    private renderer: Renderer2,
    private userService: UserService,
    private seo: SeoService
  ) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')]],
      confirmationNewPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$#@$!%*?&`~^_+=:;\\\\|{}\'\"()[\\\]/,.\-])[A-Za-zd$#@$!%*?&`~^_+=:;\\\\|{}\'\"()\[\\\]/,.\-].{7,}')]]
    }, {validator: this.checkPasswords });
  }

  ngOnInit() {
    this.seo.addMetaTag('Ubah Kata Sandi');
  }

  checkPasswords(group: FormGroup) {
    const password        = group.get('newPassword').value;
    const confirmPassword = group.get('confirmationNewPassword').value;
    const response        = password === confirmPassword ? null : { notSame: true };
    return response;
  }

  get form() {
    return this.changePasswordForm.controls;
  }

  doSubmit() {

    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
    } else {
      this.loading = true;
      this.userService.changePasswordUser(
        localStorage.getItem('uid'),
        this.form.oldPassword.value,
        this.form.newPassword.value,
        this.form.confirmationNewPassword.value).then((response: any) => {
          this.loading = false;
          if (response.status) {
            this.errorMessage = this.alertMassage.show('success',  this.translate.transform('renewPasswordSuccess'));
            setTimeout(() => {
                  this.changePasswordForm.reset();
            }, 2000);
          } else if (response.message && response.message === 'passwrong') {
            this.errorMessage = this.alertMassage.show('failed',  this.translate.transform('renewPasswordNotSame'));
          } else if (response.message && response.message === 'invalid') {
            this.errorMessage = this.alertMassage.show('failed',  this.translate.transform('renewPasswordUserNotFound'));
          } else if (response.message && response.message === 'passdiff') {
            this.errorMessage = this.alertMassage.show('failed',  this.translate.transform('renewRePasswordNotSame'));
          } else {
            this.errorMessage = this.alertMassage.show('failed',  this.translate.transform('renewPasswordFailed'));
          }
          this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', this.errorMessage);
          setTimeout (() => {
            this.renderer.setProperty(this.errMsg.nativeElement, 'innerHTML', null);
          }, 5000);
      }).catch((error: any) => {
        this.loading = false;
      });
    }

  }


}
