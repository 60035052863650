import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightbold'
})
export class HighlightBoldPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args || args === ' ') {
      return value;
    }
    const re = new RegExp(args, 'gi');
    return value.replace(re, '<span class=\'bold\'>$&</span>');
  }
}
