import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../model/event.model';
import { ApiService } from './api.service';

@Injectable()
export class EventService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getEvent(filter: any = 'all', page: number = 1, limit = 9) {
    let params: any;
    params = '?filter=' + filter + '&page=' + page + '&limit=' + limit;
    return this.http.get<Event[]>(this.apiService.eventURL + params);
  }

  getEventById(event: number, slug: string) {
    const params = '?event=' + event + '&slug=' + slug;
    return this.http.get<Event>(this.apiService.eventDetailURL + params);
  }
}
