import { Injectable } from '@angular/core';
// import { MatSnackBarComponent } from "../component/mat-snack-bar/mat-snack-bar.component";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptService {

  constructor(
    // private snackBar: MatSnackBarComponent
  ) { }

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {

      /*if (!request.headers.has('Content-Type')) {
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      request = request.clone({ headers: request.headers.set('Freaky', 'Jolly') });*/
      return next.handle(request).pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
              console.log('HAHAHA');
              console.log(error.status);
            if (error.status === 401) {
              // refresh token
            } else if (error.status === 0) {
              // this.snackBar.openSnackBar("No response from server",'X','red-snackbar');
              return throwError('No response from server');
            } else {
              console.log('Ini Error', error);
              return throwError(error);
            }
          })
        );
  }
}
