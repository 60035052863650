import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputFilterService {
  constructor() {}

  numericOnly(event: any) {
    // Allow 0-9 :: add this (keypress)="numericOnly($event)"
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    const key = event.charCode;

    if (key === 0) {
      return;
    }

    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }

  alphabetOnly(event: any) {
    // Allow a-z and A-Z :: add this (keypress)="alphabetOnly($event)"
    const pattern = /[a-zA-Z]/;
    const inputChar = String.fromCharCode(event.charCode);
    const key = event.charCode;

    if (key === 0 || key === 32) {
      // 32 => space
      return;
    }

    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }

  alphaNumbericOnly(event: any) {
    // Allow 0-9 and a-z and A-Z :: add this (keypress)="alphaNumbericOnly($event)"
    const pattern = /[0-9a-zA-Z]/;
    const inputChar = String.fromCharCode(event.charCode);
    const key = event.charCode;

    if (key === 0) {
      return;
    }

    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }

  alphaNumericWithSpace(event: any) {
    // Allow 0-9 and a-z and A-Z and space :: add this (keypress)="alphaNumericWithSpace($event)"
    const pattern = /[0-9a-zA-Z]/;
    const inputChar = String.fromCharCode(event.charCode);
    const key = event.charCode;

    if (key === 0 || key === 32) {
      return;
    }

    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }

}
