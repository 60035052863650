import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd} from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { APP_CONFIG } from './shared/model/application.properties';
import { CanonicalService } from './shared/service/canonical.service';
import { SettingService } from 'src/app/service/setting.service';
import { SeoService } from './shared/service/seo.service';
import { HostListener } from '@angular/core';
import { SomethingChangeService } from 'src/app/service/something-change.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'gwp-web';
  maintenance: boolean;
  scrHeight: any;
  scrWidth: any;
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    private router: Router,
    private seo: SeoService,
    private canonical: CanonicalService,
    private settingService: SettingService,
    private somethingChange: SomethingChangeService) {
      this.routeEvent(this.router);
      this.checkMaintenance();
    }

  ngOnInit() {
    this.canonical.setCanonicalURL();
    this.seo.addMetaTag();
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      this.somethingChange.sendMessage('changeSize', {height: this.scrHeight, width:  this.scrWidth});
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
      //   this.loading = true;
      }
      if (e instanceof NavigationEnd) {
        this.canonical.updateCanonicalURL();
        this.checkMaintenance();
      }
    });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  checkMaintenance() {
    this.settingService.getSettingMaintenance().subscribe((data: any) => {
      if (data.status) {
        this.maintenance = true;
      }
    });
  }

}
