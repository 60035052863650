import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Stone } from '../model/stone.model';
import { User } from '../model/user.model';
import {ApiService} from './api.service';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()

export class StoneService {

    // getDetailStoryById(id: any): any {
    //     throw new Error("Method not implemented.");
    // }

    constructor(private http: HttpClient, private apiService: ApiService) { }

    // getStone() {
    //     return this.http.get<Stone[]>(this.apiService.stoneURL);
    // }

    getUser(uid: string) {
        return this.http.get<User>(this.apiService.getUserURL + uid);
    }

    getUsers() {
        return this.http.get<User>(this.apiService.getUserURL);
    }

    // createStone(tr_id: string, tr_qty: string, tr_nominal: string) {
    //     const formData: FormData = new FormData();
    //     formData.append('tr_id', tr_id);
    //     formData.append('tr_qty', tr_qty);
    //     formData.append('tr_nominal', tr_nominal);

    //     return this.http.post<Stone>(this.apiService.addStoneURL, formData);
    // }


    // addStone(stoneQty: string, stonePrice: string): Observable<any> {
    //     console.log(stoneQty, stonePrice);
    //     return this.http.post<any>(this.apiService.addStoneURL, JSON.stringify(stoneQty + '/' + stonePrice), httpOptions).pipe(
    //         tap((_stone) => console.log('added stone')),
    //         catchError(this.handleError<any>('addStone'))
    //     );
    // }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
