import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Bank } from '../model/bank.model';
import {ApiService} from './api.service';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


@Injectable()

export class BankService {

    constructor(private http: HttpClient, private apiService: ApiService) { }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
