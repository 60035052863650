import { UserService } from 'src/app/service/user.service';
import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { AuthGuard } from 'src/app/auth.guard';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-profile-list-follow',
  templateUrl: './profile-list-follow.component.html',
  styleUrls: ['./profile-list-follow.component.css'],
  providers: [TranslatePipe]
})
export class ProfileListFollowComponent implements OnInit, OnChanges {
  @Input() author: any;
  @Input() tabActive = 2;
  @Output() tabChange: EventEmitter<any> = new EventEmitter();
  @Output() tabFollow: EventEmitter<any> = new EventEmitter();

  auth: boolean;
  loading: boolean;
  uid: string;

  defaultImage: string;

  selectedTabIndex: any = 0;
  noDataFollowing: boolean;
  noDataFollower: boolean;
  followingList: any[];
  followerList: any[];

  // sprint 10
  imageNoFollower: string;
  imageNoFollowing: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    this.defaultImage     = environment.imageAuthorDefault;
    this.imageNoFollower  = environment.imageNoFollower;
    this.imageNoFollowing = environment.imageNoFollowing;

    if (this.tabActive === 3) {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Change', changes);
    this.auth = this.authGuard.isLoggedIn();
    if (localStorage.getItem('login_status')) {
      this.uid = localStorage.getItem('uid');
    }
    if (this.tabActive === 2) {
      this.selectedTabIndex = 0;
      this.getFollower(this.uid, this.author);
    } else {
      this.selectedTabIndex = 1;
      this.getFollowing(this.uid, this.author);
    }
  }

  getFollowing(uid, author) {
    this.loading = true;
    this.userService.getFollowingByid(uid, author).subscribe((data: any) => {
      this.loading = false;
      this.followingList = [];
      data.data.forEach(e => {
        this.followingList.push({
          fullname: (e.ufullname && e.ufullname !== '') ? e.ufullname : '-',
          username:  (e.uusername && e.uusername !== '') ? e.uusername : '-',
          authorUid: e.follow_author_id,
          followed: e.followed,
          process: false,
          src: e.uimage ? e.uimage : (e.uimage_thumb ? e.uimage_thumb : this.defaultImage)
        });
      });

      if (data.status) {
        this.noDataFollowing = false;
      } else {
        this.noDataFollowing = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  getFollower(uid, author) {
    this.loading = true;
    this.userService.getFollowerByid(uid, author).subscribe((data: any) => {
      this.loading = false;
      this.followerList = [];
      data.data.forEach(e => {
        this.followerList.push({
          fullname: (e.ufullname && e.ufullname !== '') ? e.ufullname : '-',
          username:  (e.uusername && e.uusername !== '') ? e.uusername : '-',
          authorUid: e.follow_user_id,
          followed: e.followed,
          process: false,
          src: e.uimage ? e.uimage : (e.uimage_thumb ? e.uimage_thumb : this.defaultImage)
        });
      });

      if (data.status) {
        this.noDataFollower = false;
      } else {
        this.noDataFollower = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  // tabFollow
  onActionFollow(event, action) {
    let index: any;
    if (this.tabActive === 2) {
      index = this.followerList.findIndex(e => e.authorUid === event.authorUid);
      this.followerList[index].process = true;
    } else {
      index = this.followingList.findIndex(e => e.authorUid === event.authorUid);
      this.followingList[index].process = true;
    }

    this.userService.followUser(this.uid, event.authorUid, action).then((data: any) => {
      if (this.tabActive === 2) {
        this.followerList[index].process = false;
      } else {
        this.followingList[index].process = false;
      }

      if (data.status) {
        if (this.tabActive === 2) {
          if (action === 'follow') {
            this.followerList[index].followed = true;
          } else if (action === 'unfollow') {
            this.followerList[index].followed = false;
          }
        } else {
          if (action === 'follow') {
            this.followingList[index].followed = true;
          } else if (action === 'unfollow') {
            this.followingList[index].followed = false;
          }
        }
        this.tabFollow.emit({
          status: true,
          action: action,
        });
      }
    }).catch((error: any) => {
      if (this.tabActive === 2) {
        this.followerList[index].process = false;
      } else {
        this.followingList[index].process = false;
      }
    });
  }

  onTabChanged(event) {
    this.selectedTabIndex = event.index;
    this.tabChange.emit({
      active: event.index === 0 ? 2 : 3
    });
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }
}
