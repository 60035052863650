import { SeoService } from 'src/app/shared/service/seo.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { ActivatedRoute } from '@angular/router';
import { StoriesService } from 'src/app/service/stories.service';
import { AuthGuard } from 'src/app/auth.guard';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-continue-reading',
  templateUrl: './continue-reading.component.html',
  styleUrls: ['./continue-reading.component.css'],
  providers: [TranslatePipe]
})
export class ContinueReadingComponent implements OnInit, OnDestroy {

  auth: boolean;
  loading: boolean;
  loginStatus: string;
  uid: string;

  isParam: boolean;
  activePage = 1;
  limit = 12;
  totalData = 0;
  noData: boolean;
  storyList: any[] = [];

  orderingOptions = [{id: 'asc', text: 'labelOrderByAsc'},
                    {id: 'desc', text: 'labelOrderByDesc'},
                    {id: 'newest', text: 'labelOrderByNewest'},
                    {id: 'oldest', text: 'labelOrderByOldest'},
                    {id: 'popular', text: 'lableOrderByPopular'}];

  orderingActiveId = 'popular';
  orderingActiveText = 'lableOrderByPopular';
  defaultImage: string;
  re = /\-/gi;

  // sprint 10
  imageNoContinueReading: String;
  constructor(
    @Inject(APP_CONFIG) private appConfig,
    public navigateUrl: GoToService,
    private translate: TranslatePipe,
    public authGuard: AuthGuard,
    private route: ActivatedRoute,
    private storiesService: StoriesService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.seo.addMetaTag('Lanjut Membaca');
    this.defaultImage = environment.imageStoryDefaultV2;
    this.imageNoContinueReading = environment.imageNoContinueReading;
    if (localStorage.getItem('countinueReadingLastOrderOption')) {
      const selectedOrder = this.orderingOptions.find(x => x.id === localStorage.getItem('countinueReadingLastOrderOption'));
      this.orderingActiveId = selectedOrder.id;
      this.orderingActiveText = selectedOrder.text;
    }

    if (localStorage.getItem('login_status')) {
      this.loginStatus = localStorage.getItem('login_status');
      this.uid = localStorage.getItem('uid');
    }

    this.route.params.subscribe( params => {
      if (params && params['page']) {
        this.isParam = true;
        this.activePage = Number(params['page']);
      }

      if (this.isParam) {
        this.getContinueReadingStories(this.uid);
      }
    });

    if (!this.isParam) {
      this.getContinueReadingStories(this.uid);
    }
  }

  getContinueReadingStories(uid) {
    this.loading = true;
    this.storiesService.getContinueReading(this.activePage, this.limit, uid, 'list', this.orderingActiveId).subscribe((data: any) => {
      this.loading = false;
      if (data.data) {
        this.storyList = [];
        data.data.forEach(e => {
          this.storyList.push({
            id: e.story_id,
            uid: e.uid,
            slug: e.story_slug,
            src: e.story_image_cover ? e.story_image_cover : (e.story_image_thumb ? e.story_image_thumb : this.defaultImage),
            title: e.story_title ? e.story_title : '-',
            lastRead: e.lastRead ? e.lastRead.replace(this.re, '/') : e.lastRead,
            author: e.ufullname ? e.ufullname : '-',
            authorId: e.uusername,
            authhorUid: e.uid,
            view: e.story_views,
            like: e.story_likes,
            comment: e.story_comments,
            synopsis: e.story_description ? e.story_description : '-',
            complete: ( e.story_is_complete === '0' ) ? false : true
          });
        });
        this.totalData = data.totalData;
      }

      if (data.status) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  onErrorImage(event) {
    event.target.src = this.defaultImage;
  }

  onChangePage(page) {
    this.activePage = page;
    this.navigateUrl.goTo('continue-reading/p/' + this.activePage);
  }


  // sprint 4
  changeOrder(evt) {
    const selectedOrder     = this.orderingOptions.find(x => x.id === evt.id);
    this.orderingActiveId   = selectedOrder.id;
    this.orderingActiveText = selectedOrder.text;
    localStorage.setItem('countinueReadingLastOrderOption', selectedOrder.id);
    this.getContinueReadingStories(this.uid);
  }

  ngOnDestroy() {
    localStorage.removeItem('countinueReadingLastOrderOption');
  }

}
