import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Page, SocialProof } from '../model/page.model';
import { ApiService } from './api.service';

@Injectable()
export class PageService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  // Check this by Samuel
  getPages() {
    return this.http.get<Page[]>(this.apiService.pagesURL);
  }

  // Check this by Samuel
  getPageById(id: number) {
    return this.http.get<Page>(this.apiService.pagesURL + id);
  }

  // Sprint 1
  getAbout() {
    return this.http.get<Page[]>(this.apiService.aboutusURL);
  }

  // Sprint 1
  getFaq() {
    return this.http.get<Page[]>(this.apiService.userHelpURL);
  }

  // Sprint 1
  getDisclaimer() {
    return this.http.get<Page[]>(this.apiService.disclaimerURL);
  }

  // Sprint 1
  getGuidance() {
    return this.http.get<Page[]>(this.apiService.guidanceURL);
  }

  getSocialProofs() {
    return this.http.get<SocialProof[]>(this.apiService.socialProofsURL)
  }
}
