import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmDialogModel, GwpDialogComponent } from 'src/app/shared/component/gwp-dialog/gwp-dialog.component';
import { AuthGuard } from 'src/app/auth.guard';
import { GoToService } from 'src/app/shared/service/go-to.service';
import { APP_CONFIG } from 'src/app/shared/model/application.properties';
import { User } from 'src/app/model/user.model';
import { TranslatePipe } from 'src/app/shared/pipe/translate.pipe';
import { CommentService } from 'src/app/service/comment.service';
import { Nl2brPipe } from './../../../shared/pipe/nl2br.pipe';

declare let jQuery: any;

@Component({
  selector: 'app-story-detail-comment',
  templateUrl: './story-detail-comment.component.html',
  styleUrls: ['./story-detail-comment.component.css'],
  providers: [ Nl2brPipe ]
})
export class StoryDetailCommentComponent implements OnInit {
  commentForm: FormGroup;
  commentChildForm: FormGroup;
  commentEdit: FormGroup;

  uid: any;
  @Input() ownerStory: any;
  loading: Boolean = false;
  moreComment: Boolean = false;
  commentList: any[] = [];
  @Output() commenSend: EventEmitter<any> = new EventEmitter();
  @Output() eventEmit: EventEmitter<any> = new EventEmitter();
  @Output() commentEditSend: EventEmitter<any> = new EventEmitter();

  @Input() dataTypeReportComment: any;
  @Input() countComment: Number = 0;
  // sprint 7
  auth: Boolean = false;
  fullname: string = '';
  logo: String = '';
  user: User[];

  @ViewChild('errMsg', { static: false }) errMsg: ElementRef;

  idAfterReply: any = 0;
  dataEditComment: any;
  commentEditMention: String = '';
  textIndent = 120;
  idStory: any;
  isOnMobile: Boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authGuard: AuthGuard,
    public navigateUrl: GoToService,
    @Inject(APP_CONFIG) private appConfig,
    private translate: TranslatePipe,
    private commentService: CommentService,
    private nl2br: Nl2brPipe
  ) {

    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });

    this.commentChildForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });

    this.commentEdit = this.formBuilder.group({
      commentEdit: ['', Validators.required]
    });
  }

  get form() {
    return this.commentForm.controls;
  }

  get forms() {
    return this.commentChildForm.controls;
  }

  get formEdit() {
    return this.commentEdit.controls;
  }

  ngOnInit() {
    this.auth = this.authGuard.isLoggedIn();
    this.uid  = localStorage.getItem('uid');
    this.logo = this.appConfig.logo;
    this.fullname = localStorage.getItem('Fullname');
    if (window.innerWidth <= 1024) {
      this.isOnMobile = true;
    }
  }

  sendComment() {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentForm.invalid) {
      this.commenSend.emit({comment: this.form.comment.value, parentId: 0});
      this.commentForm.reset();
    }
  }

  sendCommentChild(data: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentChildForm.invalid) {
      this.commenSend.emit({comment: this.forms.comment.value, parentId: data.scomment_id, toWho: data.uid});
      this.commentChildForm.reset();
    }
  }

  goToUser() {
    // console.log(item);
  }

  @Input()
  set storyId(id: any){
    this.idStory  = id;
  }

  @Input()
  set idNotClosed(param: any) {
    this.idAfterReply = param;
  }

  get idNotClosed(): any {
    return this.idAfterReply;
  }

  @Input()
  set comment(param: any) {
    this.commentList = param;
    this.commentList.map((list: any) => {
      list.seeChild = this.idNotClosed === list.scomment_id;
      list.reply = false;
      list.alreadyLike = false;
      list.edit = false;
      list.alreadyEdited = list.scomment_is_edited === '1';
      if (list.childComment.length > 0) {
        list.childComment.map((item: any) => {
          item.reply = false;
          item.alreadyLike = false;
          item.alreadyEdited = item.scomment_is_edited === '1';
          item.edit = false;
        });
      }
    });
  }

  get comment(): any {
    return this.commentList;
  }

  openReply(data) {
    this.commentForm.reset();
    this.commentChildForm.reset();
    this.commentList.map((list: any) => {
      if (list.scomment_id === data.scomment_id) {
        list.reply = !list.reply;
      } else {
        list.reply = false;
      }
      if (list.childComment.length > 0) {
        list.childComment.map((item: any) => {
          if (item.scomment_id === data.scomment_id) {
            item.reply = !item.reply;
          } else {
            item.reply = false;
          }
        });
      }
    });
  }

  openChildComment(data) {
    this.commentList.map((list: any) => {
      if (list.scomment_id === data.scomment_id) {
        list.seeChild = !list.seeChild;
      } else {
        list.seeChild = false;
      }
    });
  }

  openEdit(data, position, type) {
    if (type === 2) {
      this.formEdit.commentEdit.setValue('');
      data.edit = !data.edit;
    } else {
      this.dataEditComment = data;
      this.commentEditMention = (data.uusername && data.uusername !== '') ? '@' + data.uusername : '@' + data.ufullname;
      const indent = this.commentEditMention.replace(/\s/g, '');
      this.textIndent = (indent.length + 1) * 10;
      data.scomment_text = this.nl2br.transform(data.scomment_text, 'blank');
      this.formEdit.commentEdit.setValue(data.scomment_text.replace(/<br\s*\/?>/gi, '\n'));
      if (position === 1) {
        this.commentList.map((list: any) => {
          if (list.scomment_id === data.scomment_id) {
            list.edit = !list.edit;
          } else {
            list.edit = false;
          }
          if (list.childComment.length > 0) {
            list.childComment.map((item: any) => {
              item.edit = false;
            });
          }
        });
      } else {
        this.commentList.map((list: any) => {
          list.edit = false;
          if (list.childComment.length > 0) {
            list.childComment.map((item: any) => {
              if (item.scomment_id === data.scomment_id) {
                item.edit = !item.edit;
              } else {
                item.edit = false;
              }
            });
          }
        });
      }
    }
  }


  sendEditComment(data) {
    this.auth = this.authGuard.isLoggedIn();
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentEdit.invalid) {
      this.commentEditSend.emit({comment: this.formEdit.commentEdit.value, id: data.scomment_id, parentId: 0});
      this.commentEdit.reset();
    }
  }

  sendEditCommentChild(data, parentId) {
    this.auth = this.authGuard.isLoggedIn();
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (!this.commentEdit.invalid) {
      this.commentEditSend.emit({comment: this.formEdit.commentEdit.value, id: data.scomment_id, parentId: parentId});
      this.commentEdit.reset();
    }
  }

  deleteComment(comment: any, position: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    const message = this.translate.transform('confirmMDeleteComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTDeleteComment'), message, '', this.translate.transform('confirmDelete'));
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({commentId: comment.scomment_id, type: 1, position: position});
      }
    });
  }

  pinComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    const message = this.translate.transform('confirmMPinComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTPinComment'), message, '');
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({commentId: comment.scomment_id, type: 2});
      }
    });
  }

  reportComment(comment: any) {
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    if (comment.reported) {
      return;
    }
    const message = this.translate.transform('confirmMReportComment');
    const dialogData = new ConfirmDialogModel(2, this.translate.transform('confirmTReportComment'), message, this.dataTypeReportComment);
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '536px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.status) {
        this.eventEmit.emit({commentId: comment.scomment_id, type: 3, typeReport: dialogResult.data});
        comment.reported = true;
      }
    });
  }

  likeComment(comment: any) {
    console.log(comment);
    if (!this.auth) {
      jQuery('#loginModal').appendTo('body').modal('show');
      return;
    }
    let likeStatus = 1;
    if (comment.is_like) {
      likeStatus = 0;
    }
    this.commentService.likeCommentStory(
      comment.scomment_id,
      this.uid,
      likeStatus,
      this.idStory
    ).subscribe((response: any) => {
      if (response.status) {
        if (likeStatus === 1) {
          comment.scomment_likes ++;
          comment.is_like = true;
        } else {
          if (comment.scomment_likes >= 0) {
            comment.scomment_likes --;
          } else {
            comment.scomment_likes = 0;
          }
          comment.is_like = false;
        }
      }
    }, () => {
    });
  }

  viewMoreComment() {
    this.moreComment = !this.moreComment;
  }

  unPinComment(comment: any) {
    const message = this.translate.transform('confirmMUnpinComment');
    const dialogData = new ConfirmDialogModel(1, this.translate.transform('confirmTUnpinComment'), message, '');
    const dialogRef = this.dialog.open(GwpDialogComponent, {
      maxWidth: '416px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventEmit.emit({commentId: comment.scomment_id, type: 6});
      }
    });
  }

  doClickLogin(event) {
    jQuery('#loginModal').appendTo('body').modal(event);
  }

}
